import React from "react";

// Contexts
import {useModal} from "../../../general/Modal";
import {CreditsProvider} from "../../../collections/home/Credits";

// Forms
// import {ListOptionForm} from "../../../../components/forms/modules/home/List/ListOption";

const CreditsPageContext = React.createContext({});

const CreditsPageProvider = (props) =>
{
	const {modals} = useModal();

	// const ShowListOptionForm = (internalName = '', listOption = {}) =>
	// {
	// 	AddModal(<ListOptionForm internalName = {internalName} listOption = {listOption}/>);
	// }

	const value =
	{
		
	}

	return (
		<CreditsProvider>
			<CreditsPageContext.Provider value={value}>
				{props.children}
				{modals}
			</CreditsPageContext.Provider>
		</CreditsProvider>
	);
}

const useCreditsPage = () => React.useContext(CreditsPageContext);

export {CreditsPageProvider, useCreditsPage};