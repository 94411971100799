const SetCategories = (category) =>
{
	return category;
}

const CategoriesReducer = (state = {}, action) =>
{
	switch (action.type)
	{
		case 'LOAD_CATEGORIES':
			return {
				...state,
				categories: action.categories.map(category => SetCategories(category))
			}

		case 'CREATE_CATEGORY':
			return {
				...state,
				categories: [...state.categories, SetCategories(action.category)],
			}

		case 'UPDATE_CATEGORY':
			return {
				...state,
				categories: state.categories.map(category => category._id === action.category._id ? SetCategories(action.category) : category)
			}

		case 'DELETE_CATEGORY':
		{
			return {
				...state,
				categories: state.categories.filter(category => category._id !== action.category._id)
			}
		}

		case 'SET_LATEST_CATEGORY_CODE':
		{
			return {
				...state,
				code: action.code
			}	
		}

		default:
			return state;
	}
}

export {CategoriesReducer};