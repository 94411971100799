import React from 'react';
import {Panel, Section, Header, Body, Card, Text, Image} from 'crack-ux';

// Contexts
import {useTraductor} from '../../../../contexts/general/Traductor';
import {useSession} from '../../../../contexts/general/Session';
import {useBusiness} from '../../../../contexts/general/Business';
import {useModules} from '../../../../contexts/collections/home/Modules';
import {useModulesPage} from '../../../../contexts/pages/business/home/Modules';

// Views
import {PriceCard} from '../../../../components/views/PriceCard';

// Components
import CRM from './CRM.json';
import Store from './Store.json';

// Styles
import './Modules.css';

const Info = (props) =>
{
	const {module, language: lang, action} = props;

	const [currentPicture, setCurrentPicture] = React.useState(null);

	const plansRef = React.useRef(null);
	const mediaRef = React.useRef(null);

	const {Translate} = useTraductor();
	const {session} = useSession();
	const {business} = useBusiness();
	const {currentModule} = useModulesPage();
	const {modulesByName} = useModules();
	
	const language = React.useMemo(() => lang || session?.user?.preferences?.language, [lang, session?.user?.preferences?.language]);

	const info = React.useMemo(() =>
	{
		const moduleToCheck = module?.route || currentModule;

		switch(moduleToCheck)
		{
			case 'crm':
				return CRM;

			case 'store':
				return Store;

			default: 
				return null;
		}

	}, [module, currentModule]);

	React.useEffect(() =>
	{
		if(info)
		{
			const slider = plansRef.current;
	
			let isDown = false;
			let startX;
			let scrollLeft;
	
			const HandleMouseDown = (e) =>
			{
				isDown = true;
				slider.classList.add('active');
				startX = e.pageX - slider.offsetLeft;
				scrollLeft = slider.scrollLeft;
			}
	
			const HandleMouseLeave = (e) =>
			{
				isDown = false;
				slider.classList.remove('active');
			}
	
			const HandleMouseUp = (e) =>
			{
				isDown = false;
				slider.classList.remove('active');
			}
	
			const HandleMouseMove = (e) =>
			{
				if(!isDown) return;
				e.preventDefault();
				const x = e.pageX - slider.offsetLeft;
				const walk = (x - startX) * 3; //scroll-fast
				slider.scrollLeft = scrollLeft - walk;
			}
	
			slider.addEventListener('mousedown', HandleMouseDown);
			slider.addEventListener('mouseleave', HandleMouseLeave);
			slider.addEventListener('mouseup', HandleMouseUp);
			slider.addEventListener('mousemove', HandleMouseMove);
	
			return () => {
				slider.removeEventListener('mousedown', HandleMouseDown);
				slider.removeEventListener('mouseleave', HandleMouseLeave);
				slider.removeEventListener('mouseup', HandleMouseUp);
				slider.removeEventListener('mousemove', HandleMouseMove);
			};
		}
	}, [info]);

	React.useEffect(() =>
	{
		if(info)
		{
			const slider = mediaRef.current;
	
			let isDown = false;
			let startX;
			let scrollLeft;
	
			const HandleMouseDown = (e) =>
			{
				isDown = true;
				slider.classList.add('active');
				startX = e.pageX - slider.offsetLeft;
				scrollLeft = slider.scrollLeft;
			}
	
			const HandleMouseLeave = (e) =>
			{
				isDown = false;
				slider.classList.remove('active');
			}
	
			const HandleMouseUp = (e) =>
			{
				isDown = false;
				slider.classList.remove('active');
			}
	
			const HandleMouseMove = (e) =>
			{
				if(!isDown) return;
				e.preventDefault();
				const x = e.pageX - slider.offsetLeft;
				const walk = (x - startX) * 3; //scroll-fast
				slider.scrollLeft = scrollLeft - walk;
			}
	
			slider.addEventListener('mousedown', HandleMouseDown);
			slider.addEventListener('mouseleave', HandleMouseLeave);
			slider.addEventListener('mouseup', HandleMouseUp);
			slider.addEventListener('mousemove', HandleMouseMove);
	
			return () => {
				slider.removeEventListener('mousedown', HandleMouseDown);
				slider.removeEventListener('mouseleave', HandleMouseLeave);
				slider.removeEventListener('mouseup', HandleMouseUp);
				slider.removeEventListener('mousemove', HandleMouseMove);
			};
		}
	}, [info]);

	React.useEffect(() =>
	{
		if(info)
		{
			setCurrentPicture(info.media.pictures[0])
		}
	}, [info])

	return (
		info
		? <>
			{!module &&
			<Header>
				<Text class='bold'>{Translate(modulesByName[currentModule]?.name, language)}: {business.plans?.[currentModule]?.planConfig?.title ? `Plan ${Translate(business.plans?.[currentModule]?.planConfig?.title, language)}` : Translate('No Active Plan', language)}</Text>
			</Header>}
			<Body class='vertical-scroll' style={{height: 'calc(100% - 49px)'}}>
				<Panel class='desktop4'>
					<Card>
						<Header>
							<Text class='bold' type='p'>{Translate(info.name, language)}</Text>
						</Header>
						<Body class='description vertical-scroll' style={{height: 'calc(56vh + 48px)'}}>
							{info.description[language].map((text, index) =>
							{
								return <Text key={index} type='p'>{text}</Text>
							})}
						</Body>
					</Card>
				</Panel>
				<Panel class='desktop8 no-padding'>
					<Panel style={{height: '56vh'}}>
						<Card>
							<Header>
								<Text class='bold'>{Translate(currentPicture?.title, language)}</Text>
							</Header>
							<Body style={{height: 'calc(100% - 49px)'}}>
								<Image src={`/home/${info.media.folder}/${currentPicture?.picture}`}/>
							</Body>
						</Card>
					</Panel>
					<Panel class='thumbnails'>
						<Card reference={mediaRef} class='pointer horizontal-scroll no-break' style={{padding: 15}}>
							{info.media.pictures.map((picture, index) =>
							{
								return <img key={index} className='thumbnail pointer' src={`/home/${info.media.folder}/${picture.picture}`} alt={info.media.folder} onClick={() => {setCurrentPicture(picture)}} style={{height: 64}}/>
							})}
						</Card>
					</Panel>
				</Panel>
				<Panel class='center'>
					<Text class='blue' size={24} style={{marginTop: 50}}>{Translate('Pay According to your Consumption', language)}</Text>
					<Text class='plans-text center' type='p'>{language === 'en' ? "We offer best Post-Paid plans so you can choose the one that best suits your budget and needs. This way, you'll have access to the tools and services you need to take your business to the next level. And if you ever reach the usage limit, don't worry. Simply pay for what you consume and keep going without interruptions. We hope you enjoy your experience with us!" : 'Ofrecemos los mejores planes Post-Pago para que elijas el que mejor se adapta a tu presupuesto y necesidades. De esta manera, tendrás acceso a las herramientas y servicios que necesitas para llevar tu negocio al siguiente nivel. Y si en algún momento llegas al límite de uso, no te preocupes. Simplemente pagas lo que consumas y sigue adelante sin interrupciones. ¡Esperamos que disfrutes de tu experiencia con nosotros!'}</Text>
				</Panel>
				<Section reference={plansRef} class='pointer horizontal-scroll no-break center' style={{marginBottom: 25}}>
					{info.plans.map((plan, index) =>
					{
						const currentPlan = business?.plans?.[currentModule]?.planConfig?.title === plan.name;

						return <PriceCard key={index} {...plan} action={action} language={language} currentPlan={currentPlan}/>
					})}
				</Section>
			</Body>
		</> 
		: <></>
	);
}

export {Info}