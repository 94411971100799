import {Section} from 'crack-ux';

// Components
import {Profile} from './Profile';
import {Notifications} from './Notifications';

// Contexts
import {useBusiness} from '../../contexts/layouts/Business';

const Account = () =>
{
	const {currentTab} = useBusiness();

	return (
		<Section class='full-height'>
			<Section class='full-height' style={{display: currentTab === 'profile' ? 'block' : 'none'}}>
				<Profile/>
			</Section>
			<Section class='full-height' style={{display: currentTab === 'notifications' ? 'block' : 'none'}}>
				<Notifications/>
			</Section>
		</Section>
	);
}

export {Account};