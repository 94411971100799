import React from 'react';
import {Card, Box, Header, Body, Footer, Text, Form, Button} from 'crack-ux';
import {Generic, Amount} from 'crack-functions';
import moment from 'moment';

// Components
import {Detail} from './Detail';

// Contexts
import {useTraductor} from '../../../../../contexts/general/Traductor';
import {useStore} from '../../../../../contexts/modules/Store';
import {useAcquisitionsPage} from '../../../../../contexts/pages/business/store/Acquisitions';
import {useProviders} from '../../../../../contexts/collections/store/Providers';
import {useMoves} from '../../../../../contexts/collections/store/Moves';

const Acquisition = () =>
{
	const {Translate} = useTraductor();
	const {ShowProviderForm} = useStore();
	const {productsSelected, provider, SetProvider, ClearAll} = useAcquisitionsPage();
	const {providers} = useProviders();
	const {CreateMove} = useMoves();

	const products = React.useMemo(() => Generic.ObjectToArray(productsSelected), [productsSelected]);

	const total = React.useMemo(() =>
	{
		let total = 0

		if(products.length > 0)
		{
			products.forEach(product =>
			{
				total = total + Amount.CalculateTotal({quantity: product.amount, price: product.purchasePrice, tax: 0});
			});
		}
		
		return total;
	}, [products]);

	const HandleSubmit = () =>
	{
		const details = [];

		for(let key in productsSelected)
		{
			details.push(
			{
				product: key,
				quantity: productsSelected[key].amount,
				price: productsSelected[key].purchasePrice,
				total: productsSelected[key].total
			})
		}

		const move =
		{
			name: provider?.name || Translate('Unnamed'),
			nit: provider?.nit || '',
			contact: provider?._id || null,
			type: 2,
			details,
			total,
			date: moment(), 
			annulled: false
		}

		CreateMove(move, productsSelected, 'acquisition').then(response =>
		{
			if(response.status === 200)
			{
				ClearAll();
			}	
		});
	}

	return (
		<Card>
			<Header>
				<Text class='bold'>{Translate('Voucher')}</Text>
			</Header>
			<Header>
				<Form
					inputs =
					{[
						{
							name: 'provider',
							text: Translate('Provider'),
							class: 'desktop7',
							type: 'autocomplete',
							options: providers.map(provider =>
							{
								return {
									text: `${provider.name}${provider.nit ? ` (${provider.nit})` : ''}`,
									value: provider._id,
									data: provider
								}
							}),
							value: provider?._id || null,
							Change: (response) => SetProvider(response.data)
						},
						{
							type: 'content',
							content: <Button class='background-blue white full-width' text={Translate('Add Provider')} Click={() => ShowProviderForm()}/>,
							class: 'desktop5'
						}
					]}
				/>
			</Header>
			<Body class='no-padding' style={{height: 'calc(100vh - (2 * var(--header)) - 187px)'}}>
				<Detail/>
			</Body>
			<Footer class='right'>
				<Box class='float-left' style={{paddingTop: 10}}>
					<Text class='bold' size={20}>Total:</Text>
					<Text size={20} style={{marginLeft: 5}}>{Amount.CurrencyFormat(total)}</Text>
				</Box>
				<Button class='background-blue white' text={Translate('Save')} Click={HandleSubmit}/>
				<Button text={Translate('Clear All')} Click={ClearAll}/>
			</Footer>
		</Card>
	)
}

export {Acquisition}