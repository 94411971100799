import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Section, Body, Footer, Form, Button, Text} from 'crack-ux';

// Contexts
import {useSession} from '../../../contexts/general/Session';
import {useHomePage} from '../../../contexts/pages/public/Home';
import {usePublic} from '../../../contexts/layouts/Public';

const SignIn = (props) =>
{
	const {language} = props;

	const [data, setData] = React.useState({});
	const [response, setResponse] = React.useState(null);
	const [messages, setMessages] = React.useState({});

	const navigate = useNavigate();
	const {Login} = useSession();
	const {ShowSignUpForm} = usePublic();
	const {ShowForceLogin} = useHomePage();

	const HandleSubmit = React.useCallback(async (force = false) =>
	{
		if(data.email && data.password)
		{
			const response = await Login({...data, force});

			if(response.status === 202 && response.data?.logged)
			{
				ShowForceLogin(() => HandleSubmit(true), response.data?.preferences);
			}
			else
			{
				const color = response.status === 200 ? 'green' : 'red';
	
				setResponse(
					<Section class='center'>
						<Text class={color}>{response.message}</Text>
					</Section>
				);
	
				if(response.status === 200)
				{
					setTimeout(() =>
					{
						navigate('/p/business');
					}, 1000);
				}
			}
		}
		else
		{
			const email = data.email ? null : <Text class='red'>{language === 'en' ? 'Username or Email is required' : 'Usuario or Email es requerido'}</Text>;
			const password = data.password ? null : <Text class='red'>{language === 'en' ? 'Password is required' : 'Contraseña es requerida'}</Text>;
			
			setMessages(
			{
				...messages,
				email,
				password,
			});
		}
	}, [Login, ShowForceLogin, data, messages, navigate, language]);

	return (
		<>
			<Body class='vertical-scroll full-width' style={{maxHeight: 'calc(100vh - 412px)'}}>
				<Form
					inputs =
					{[
						{
							name: 'email',
							text: language === 'en' ? 'Username or Email' : 'Usuario o Email',
							placeholder: language === 'en' ? 'Enter your username or email' : 'Ingresa tu usuario o email',
							Change: () => setMessages({...messages, email: null}),
							message: messages.email,
						},
						{
							name: 'password',
							text: language === 'en' ? 'Password' : 'Contraseña',
							type: 'password',
							placeholder: language === 'en' ? 'Enter your password' : 'Ingresa tu contraseña',
							Change: () => setMessages({...messages, password: null}),
							message: messages.password
						}
					]}
					response = {response}
					Change = {(response) => setData(response)}
					Submit = {() => HandleSubmit()}
					Enter = {() => HandleSubmit()}
					autoFocus = {false}
				/>
			</Body>
			<Footer>
				<Section>
					<Button class='link blue no-padding' text={language === 'en' ? 'Forgot my password' : 'Olvide mi contraseña'} Click= {() => navigate('./recover-password')}/>
				</Section>
				<Button class='background-blue white' text={language === 'en' ? 'Login' : 'Ingresar'} Click={() => HandleSubmit()} style={{width: '100%'}}/>
				<Section class='center' style={{marginTop: 10}}>
					<Text>{language === 'en' ? 'You do not have an account?' : 'No tienes una cuenta?'}<Text class='blue pointer' Click={ShowSignUpForm} style={{marginLeft: 5}}>{language === 'en' ? 'Signup now' : 'Resgistrate ahora'}</Text></Text>
				</Section>
			</Footer>
		</>
	)
}

export {SignIn};