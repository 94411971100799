import React from 'react';
import {Table} from 'crack-ux';

// Contexts
import {useTraductor} from '../../../../../contexts/general/Traductor';
import {useTemplatesPage} from '../../../../../contexts/pages/business/crm/Templates';
import {useTemplates} from '../../../../../contexts/collections/crm/Templates';

const WhatsappTemplatesTable = (props) =>
{
	const {Translate} = useTraductor();
	const {ShowTemplateForm, ShowDeleteTemplateWarning, templateType} = useTemplatesPage();

	const {templates} = useTemplates();

	const body = React.useMemo(() =>
	{
		return templates.filter(template => template.channel === templateType.value);
	}, [templates, templateType])

	return (
		<Table
			header =
			{[
				{
					width: 80,
					name: 'codeText',
					text: Translate('Code')
				},
				{
					name: 'name',
					text: Translate('Name'),
					maxWidth: 240,
				},
				{
					name: 'content',
					text: Translate('Content'),
					maxWidth: 300,
					title: 'content'
				},
				{
					name: 'finishChatText',
					text: Translate('Finish Chat')
				}
			]}
			body = {body}
			actions =
			{[
				{
					text: Translate('New Template'),
					class: 'background-blue white',
					section: 'grouped',
					Click: () => ShowTemplateForm({}, {templateType})
				},
				{
					text: Translate('Edit Template'),
					icon:
					{
						class: 'icon-pencil'
					},
					section: 'context',
					Click: (template) => ShowTemplateForm(template, {templateType})
				},
				{
					text: Translate('Delete Template'),
					class: 'red',
					icon:
					{
						class: 'icon-trash'
					},
					section: 'context',
					Click: (template) => ShowDeleteTemplateWarning(template)
				}
			]}
			searcher =
			{
				{text: Translate('Search')}
			}
			texts =
			{
				{
					noRecords:Translate('There Are Not Records To Show'),
					records:Translate('Records'),
					fields: Translate('Fields'),
					settings: Translate('Settings'),
					export: Translate('Export')
				}
			}
			filters = 
			{{
				columns:
				{
					editable: true
				},
				Apply:
				{
					text: Translate('Apply'),
					Click: () => {}
				},
				Reset:
				{
					text: Translate('Reset'),
					Click: () => {}
				}
			}}
		/>
	)
};

export {WhatsappTemplatesTable};