import React from "react";

// Contexts
import {useModal} from "../../../general/Modal";
import {WhatsappSessionProvider} from "../../../main/WhatsappSession";
import {ChatBotsProvider} from "../../../collections/crm/ChatBots";

// Static Data
import channels from '../../../../static-data/Channels.json';

const IntegrationsContext = React.createContext({});

const IntegrationsProvider = (props) =>
{
	const [channel, setChannel] = React.useState(channels.find(channel => channel.value === 'whatsapp'));

	const {modals} = useModal();
	
	const value =
	{
		channel,
		SetChannel: setChannel,
	}

	return (
		<ChatBotsProvider>
			<WhatsappSessionProvider>
				<IntegrationsContext.Provider value={value}>
					{props.children}
					{modals}
				</IntegrationsContext.Provider>
			</WhatsappSessionProvider>
		</ChatBotsProvider>
	)
}

const useIntegrations = () => React.useContext(IntegrationsContext);

export {IntegrationsProvider, useIntegrations};