import React from 'react';

// Context
import {useSocket} from '../../general/Socket';
import {useBusiness} from '../../general/Business';

// Reducers
import {StaffReducer, SetMember} from '../../../reducers/collections/Staff/Staff';

const Staff = React.createContext({});

const StaffProvider = (props) =>
{
	const [state, dispatch] = React.useReducer(StaffReducer, {staff: []});
	const [basePermissions, setBasePermissions] = React.useState({});
	const [currentMember, setCurrentMember] = React.useState({});
	const [eventId, setEventId] = React.useState('');
	const [oldSocket, setOldSocket] = React.useState({});

	const Socket = useSocket();
	const {Fetch, business} = useBusiness();

	const room = React.useMemo(() => `staff_${business._id}`, [business._id]);
	const unique = React.useMemo(() =>
	{
		return eventId ? `MEMBER_${eventId}` : 'MEMBER';
	}, [eventId]);
	
	const LoadStaff = React.useCallback((staff = []) =>
	{
		dispatch({type: 'LOAD_STAFF', staff});
	}, []);

	const UpdateStaffAndPermissions = React.useCallback(() =>
	{
		if(business._id)
		{
			const requests =
			[
				Fetch('/staff/read'),
				Fetch('/staff/permissions')
			];

			Promise.all(requests).then(responses =>
			{
				if(responses.every(response => response.status === 200))
				{
					LoadStaff(responses[0].data);
					setBasePermissions(responses[1].data);
				}
			});
		}
	}, [Fetch, business._id, LoadStaff]);

	React.useEffect(() =>
	{
		const events =
		[
			{
				name: `CREATE_${unique}`,
				Function: (member) =>
				{
					dispatch({type: 'CREATE_MEMBER', member});
				}
			},
			{
				name: `UPDATE_${unique}`,
				Function: (member) =>
				{
					dispatch({type: 'UPDATE_MEMBER', member});

					if(member._id === currentMember._id)
					{
						setCurrentMember(SetMember(member));
					}
				}
			},
			{
				name: `DELETE_${unique}`,
				Function: (member) =>
				{
					dispatch({type: 'DELETE_MEMBER', member});
				}
			},
			{
				name: `RELOAD_STAFF_${unique}`,
				Function: () =>
				{
					UpdateStaffAndPermissions();
				}
			}
		];

		Socket.ConnectEvents(room, events, oldSocket);

		if(oldSocket.unique !== unique)
		{
			setOldSocket({room, events, unique});
		}
	}, [Socket, business._id, oldSocket.unique, currentMember, room, unique, oldSocket, UpdateStaffAndPermissions]);

	React.useEffect(() =>
	{
		UpdateStaffAndPermissions();
	}, [UpdateStaffAndPermissions]);

	const Invite = async (staffMember) =>
	{
		const url = '/staff/invite';

		staffMember._socket = room;

		return await Fetch(url, staffMember);
	}

	const StatusStaffMember = async (staffMember) =>
	{
		const data =
		{
			_id: staffMember._id,
			status: staffMember.status === 0 ? 1 : 0,
			_socket: room
		}

		return await Fetch('/staff/update-status', data);
	}

	const ChangePermission = async (_id, permissions = {}) =>
	{
		const data =
		{
			_id,
			permissions,
			_socket: room
		};

		return await Fetch('/staff/update-permission', data);
	}

	const availableStaff = React.useMemo(() =>
	{
		return state.staff.filter(staffMemberItem => staffMemberItem.accepted);
	}, [state.staff]);

	const value =
	{
		code: state.code,
		staff: state.staff,
		basePermissions,
		currentMember,
		availableStaff,
		setEventId: setEventId,
		SetCurrentMember: setCurrentMember,
		ChangePermission,
		LoadStaff,
		Invite,
		StatusStaffMember,
		UpdateStaffAndPermissions,
	}

	return <Staff.Provider value={value} {...props}/>
}

const useStaff = () => React.useContext(Staff);

export {StaffProvider, useStaff};