import {Text} from 'crack-ux';
import {Amount} from 'crack-functions';
import moment from 'moment';

const invoicePaid =
{
	'0': 'Not Paid',
	'1': 'Partial Paid',
	'2': 'Full Paid',
};

const SetInvoice = (invoice) =>
{
	invoice.codeText = `INVO-${invoice.code}`;

	invoice.dateText = invoice.info?.date ? moment(invoice.info.date).format('DD/MM/YYYY') : '';
	invoice.numberText = invoice.info?.number || '';
	invoice.descriptionText = invoice.info?.description || '';
	invoice.totalText = <Text class='right'>{Amount.CurrencyFormat(invoice.total)}</Text>;
	invoice.statusText = invoice.voided ? 'Voided' : invoicePaid[invoice.paid];

	invoice.detail = invoice.detail.map(detail =>
	{
		detail.subtotal = Amount.CalculateTotal({quantity: detail.quantity, price: detail.unitPrice, tax: 0});
		detail.quantityText = <Text class='right'>{detail.quantity}</Text>;
		detail.subtotalText =  <Text class='right'>{Amount.CurrencyFormat(detail.subtotal)}</Text>;
		detail.totalText =  <Text class='right'>{Amount.CurrencyFormat(detail.total)}</Text>;
		detail.unitPriceText = <Text class='right'>{Amount.CurrencyFormat(detail.unitPrice)}</Text>;

		return detail;
	});

	invoice.subtotal = invoice.detail.reduce((acc, curr) => acc + curr.subtotal, 0);
	invoice.subtotalText = <Text class='right'>{Amount.CurrencyFormat(invoice.subtotal)}</Text>;

	invoice.payments = invoice.payments.map(payment => 
	{
		payment.amountText = <Text class='right'>{Amount.CurrencyFormat(payment.amount,{currency: 'usd', region: 'en-US'})}</Text>;

		return payment;
	})

	return invoice;
}

const InvoicesReducer = (state = {}, action) =>
{
	switch (action.type)
	{
		case 'LOAD_INVOICES':
			return {
				...state,
				invoices: action.invoices.map(invoice => SetInvoice(invoice))
			}

		case 'CREATE_INVOICE':
			return {
				...state,
				invoices: [SetInvoice(action.invoice), ...state.invoices],
			}

		case 'UPDATE_INVOICE':
			return {
				...state,
				invoices: state.invoices.map(invoice => invoice._id === action.invoice._id ? SetInvoice(action.invoice) : invoice)
			}

		case 'DELETE_INVOICE':
		{
			return {
				...state,
				invoices: state.invoices.filter(invoice => invoice._id !== action.invoice._id)
			}
		}

		case 'SET_LATEST_INVOICE_CODE':
		{
			return {
				...state,
				code: action.code
			}	
		}

		default:
			return state;
	}
}

export {InvoicesReducer};