import React from "react";
import {Content, Panel} from 'crack-ux';

// Views
import {ProductsGrid} from "../../../../components/views/modules/store/ProductsGrid";

// Forms
import {Acquisition} from "../../../../components/forms/modules/store/Acquisition";

// Contexts
import {AcquisitionsPageProvider} from "../../../../contexts/pages/business/store/Acquisitions";

// Styles
import "./Acquisitions.css";

const Acquisitions = () =>
{
	return (
		<Content class='store-acquisitions'>
			<Panel class='no-padding full-height' swipe='horizontal'>
				<Panel class='desktop7 no-padding-vertical no-padding-left'>
					<ProductsGrid _type='acquisitions'/>
				</Panel>
				<Panel class='desktop5 no-padding'>
					<Acquisition/>
				</Panel>
			</Panel>
		</Content>
	)
}

const AcquisitionsWithProviders = () =>
{
	return (
		<AcquisitionsPageProvider>
			<Acquisitions/>
		</AcquisitionsPageProvider>
	)
}

export {AcquisitionsWithProviders as Acquisitions};