import React from 'react';
import {Card, Header, Body, Footer, Text, Button, Radio} from 'crack-ux';

// Contexts
import {useTraductor} from '../../../../contexts/general/Traductor';
import {useModal} from '../../../../contexts/general/Modal';
import {useEvents} from '../../../../contexts/collections/scheduler/Events';

const ConfirmEventRecurrence = (props) =>
{
	const {event, action} = props;

	const [actionType, setActionType] = React.useState('0');

	const {Translate} = useTraductor();
	const {CloseModal} = useModal();
	const {DeleteEvent, UpdateEvent} = useEvents();

	return (
		<Card>
			<Header>
				<Text class='bold'>{Translate('Apply to')}</Text>
			</Header>
			<Body style = {{width: 320}}>
				<Radio
					options =
					{[
						{
							text: Translate('This event'),
							value: '0'
						},
						{
							text: Translate('This event at the end'),
							value: '1'
						},
						{
							text: Translate('All recurrence'),
							value: '2'
						}
					]}
					selected = {actionType}
					Change = {(response) => setActionType(response.value)}
				/>
			</Body>
			<Footer class='right'>
				<Button
					text = {Translate('Confirm')}
					class = 'background-blue white'
					Click = {() =>
					{
						if(action === 'delete')
						{
							DeleteEvent({...event, actionType});
							CloseModal();
						}
						else if(action === 'update')
						{
							UpdateEvent({...event, actionType});
							CloseModal();
							setTimeout(CloseModal, 1000);
						}
					}}
				/>
				<Button
					text = {Translate('Cancel')}
					Click = {CloseModal}
				/>
			</Footer>
		</Card>
	);
}

export {ConfirmEventRecurrence}