import React from "react";
import {useLocation} from "react-router-dom";

// Contexts
import {useModal} from "../general/Modal";

const BusinessContext = React.createContext({});

const BusinessProvider = (props) =>
{
    const [showMenu, setShowMenu] = React.useState(false);
	const [showAccount, setShowAccount] = React.useState(false);
	const [currentTab, setCurrentTab] = React.useState('profile');

	const location = useLocation();
	const {modals} = useModal();

	const path = React.useMemo(() => 
	{
		const pathSplitted = location.pathname.split('/');

		return {
			full: location.pathname,
			module: pathSplitted[3],
			menu: pathSplitted[4],
		}
	}, [location.pathname]);

	React.useEffect(() =>
	{
		setShowMenu(false);
	}, [path]);

	const value =
	{
		path,
		showMenu,
		showAccount,
		currentTab,
		SetShowMenu: setShowMenu,
		SetShowAccount: setShowAccount,
		SetCurrentTab: setCurrentTab,
		SwitchMenu: () => setShowMenu(!showMenu),
		SwitchAccount: () => setShowAccount(!showAccount)
	}

	return (
        <BusinessContext.Provider value={value}>
            {props.children}
            {modals}
        </BusinessContext.Provider>
	);
}

const useBusiness = () => React.useContext(BusinessContext);

export {BusinessProvider, useBusiness};