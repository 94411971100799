import React from 'react';
import {Tabs} from 'crack-ux';

// Contexts
import {useTraductor} from '../../../../contexts/general/Traductor';

// Components
import {NotesTab} from './NotesTab';
import {DocumentsTab} from './DocumentsTab';
import {ScheduledMessagesTab} from './ScheduledMessages';
import {EventsTab} from './Events';

const TabsGenerator = (index, contact) =>
{
	switch(index)
	{
		case 'notes':
			return <NotesTab contact={contact}/>

		case 'documents':
			return <DocumentsTab contact={contact}/>

		case 'scheduled-messages':
			return <ScheduledMessagesTab contact={contact}/>

		case 'events':
			return <EventsTab contact={contact}/>

		default:
			break;
	}
};

const InfoTabs = (props) =>
{
	const {contact} = props;

	const [tabsComponents, setTabsComponents] = React.useReducer((state, updates) => ({...state, ...updates}), {});
	const [currentTab, setCurrentTab] = React.useState('events');

	const {Translate} = useTraductor();

	React.useEffect(() =>
	{
		setTabsComponents({[currentTab]: TabsGenerator(currentTab, contact)});
	}, [currentTab, contact]);

	const tabs =
	[
		{
			name: Translate('Events'),
			content: tabsComponents['events'],
			identifier: 'events'
		},
		{
			name: Translate('Scheduled Messages'),
			content: tabsComponents['scheduled-messages'],
			identifier: 'scheduled-messages'
		},
		{
			name: Translate('Notes'),
			content: tabsComponents['notes'],
			identifier: 'notes'
		},
		{
			name: Translate('Documents'),
			content: tabsComponents['documents'],
			identifier: 'documents'
		},
	];

	return (
		<Tabs
			class = 'background-transparent'
			current = {currentTab}
			tabs = {tabs}
			Change = {(currentTab) =>
			{
				setCurrentTab(currentTab.identifier);
			}}
		/>
	)
};

export {InfoTabs};