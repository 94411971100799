import React from 'react'
import {GoogleMap, Marker} from '@react-google-maps/api';
import Geocode from "react-geocode";

class Map extends React.Component
{
	constructor(props)
	{
		super(props)

		this.state =
		{
			 
			latitude: -16.496008734161734,
			longitude: -68.13365539583876,
			zoom: 16
		};  
	}

	componentDidMount = () => 
	{
		Geocode.setApiKey("AIzaSyBKlbA051wqGwFbzw5jYhUSuWnMBsjs5XU");
		Geocode.setLanguage("en");
		Geocode.setRegion("es");
		Geocode.setLocationType("ROOFTOP");
		
		if(navigator.geolocation)
		{
			if (this.props.addresRequired !== '')
			{  
				this.ChangeLocationAddres(this.props.addresRequired);
			}
			
			// Error just in EMC. 
			// navigator.geolocation.getCurrentPosition((position) => {
			// // navigator.geolocation.watchPosition((position) => {
			// 	console.log(position)
			// 	this.setState({
			// 		latitude: position.coords.latitude,
			// 		longitude: position.coords.longitude,
			// 	}, ()=>
			// 	{this.ShowAddres()});
			// },
			// (error) => {
			// 	console.error('Error code ='+error.code+' - '+error.message)
			// });
		}
	}

	componentDidUpdate(prevProps)
	{
		if(this.props.addresRequired !== prevProps.addresRequired)
		{
			if (this.props.addresRequired)
			{
				if (this.props.addresRequired !== '')
				{
					this.ChangeLocationAddres(this.props.addresRequired);
				}
				else 
				{
					this.ChangeLocationAddres('Sydney Australia')
				}
			}
			else 
			{
				this.ChangeLocationAddres('Sydney Australia')
			}
		}

		if(this.props.latitude && this.props.latitude !== prevProps.latitude)
		{
			this.setState({latitude: this.props.latitude})
		}

		if(this.props.longitude && this.props.longitude !== prevProps.longitude)
		{
			this.setState({longitude: this.props.longitude})
		}
	}

	//get Coords from addres
	ChangeLocationAddres = (response) =>
	{
		Geocode.fromAddress(response).then(
			(response) => {
				const  lat = response.results[0].geometry.location.lat; 
				const lng = response.results[0].geometry.location.lng; 
				this.ShowAddres(lat, lng)
			},
			(error) => {
				// console.error(error);
			}
		)
	}

	//Show addres in map from lat and lng
	ShowAddres = (lat, lng) =>
	{
		// Geocode.fromLatLng(this.state.latitude, this.state.longitude).then((response) => 
		Geocode.fromLatLng(lat, lng).then((response) => 
		{
			this.setState(
			{
				latitude: lat,
				longitude: lng,
			})
			this.props.GetAddress(response)
		},
		(error) => 
		{
			// console.error(error);
		});
	}

	MapClick = (event) =>  
	{
		const lat = event.latLng.lat();
		const lng = event.latLng.lng();
		// this.setState({
		// 	latitude: event.latLng.lat(),
		// 	longitude: event.latLng.lng()
		// }, ()=>{this.ShowAddres()})

		this.ShowAddres(lat, lng, true)
	}

	render()
	{
		const mapStyle = 
		{
			width: '100%',
			height: '100%',
			maxHeight: 'calc(100vh - var(--header) - 125px)'
		}

		const center = 
		{
			lat: this.state.latitude,
			lng: this.state.longitude
		};

		const mapOptions =
		{
			scrollwheel: true,
			zoomControlOptions: {
				style: 'SMALL'
			},
			// mapTypeControlOptions: {
			// 	position: 'BOTTOM_RIGHT'     // this makes the map type control disappear
			// },
			draggable: true,
			rotateControl: false,
			scaleControl: false,
			streetViewControl: false,
			panControl: false,
			// styles: [{ stylers: [{ 'saturation': -100 }, { 'gamma': 0.8 }, { 'lightness': 5 }, { 'visibility': 'on' }] }]
		};

		const googleMapProps =
		{
			mapContainerStyle: mapStyle,
			center: center,
			zoom: this.state.zoom,
			onClick: (response) => this.MapClick(response),
			options: mapOptions
		}

		return <GoogleMap {...googleMapProps}>
					<Marker position={{ lat: this.state.latitude, lng: this.state.longitude }}/>
				</GoogleMap>
	}
}

export {Map}