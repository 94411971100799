import React from 'react';

// Context
import {useSocket} from '../../general/Socket';
import {useBusiness} from '../../general/Business';

// Reducers
import {CategoriesReducer} from '../../../reducers/collections/store/Categories';

const Categories = React.createContext({});

const CategoriesProvider = (props) =>
{
	const [state, dispatch] = React.useReducer(CategoriesReducer, {categories: [], code: 0});
	const [currentCategory, setCurrentCategory] = React.useState({});
	const [eventId, setEventId] = React.useState('');
	const [oldSocket, setOldSocket] = React.useState({});

	const Socket = useSocket();
	const {Fetch, business} = useBusiness();

	const room = React.useMemo(() => `store_categories_${business._id}`, [business._id]);
	const unique = React.useMemo(() =>
	{
		return eventId ? `STORE_CATEGORY_${eventId}` : 'STORE_CATEGORY';
	}, [eventId]);

	React.useEffect(() =>
	{
		Fetch('/store/categories/code').then(response =>
		{
			if(response.status === 200)
			{
				dispatch({type: 'SET_LATEST_CATEGORY_CODE', code: response.data});
			}
		});
	}, [Fetch]);

	React.useEffect(() =>
	{
		const events =
		[
			{
				name: `CREATE_${unique}`,
				Function: (category) =>
				{
					dispatch({type: 'CREATE_CATEGORY', category});

					if(unique === 'STORE_CATEGORY') dispatch({type: 'SET_LATEST_CATEGORY_CODE', code: state.code + 1});
				}
			},
			{
				name: `UPDATE_${unique}`,
				Function: (category) => dispatch({type: 'UPDATE_CATEGORY', category})
			},
			{
				name: `DELETE_${unique}`,
				Function: (category) => dispatch({type: 'DELETE_CATEGORY', category})
			}
		];

		if(unique !== 'STORE_CATEGORY')
		{
			events.push
			({
				name: 'CREATE_CATEGORY',
				Function: () =>
				{
					dispatch({type: 'SET_LATEST_CATEGORY_CODE', code: state.code + 1});
				}
			})
		}

		Socket.ConnectEvents(room, events, oldSocket);

		if(oldSocket.unique !== unique)
		{
			setOldSocket({room, events, unique});
		}
	}, [Socket, room, unique, oldSocket, state.code]);

	const LoadCategories = React.useCallback((categories = []) =>
	{
		dispatch({type: 'LOAD_CATEGORIES', categories});
	}, []);

	const CreateCategory = async (category) =>
	{
		category.code = state.code + 1;
		category._socket = room;

		return await Fetch('/store/category/create', category);
	}

	const UpdateCategory = async (category) =>
	{
		category._socket = room;

		return await Fetch('/store/category/update', category);
	}

	const DeleteCategory = async (category) =>
	{
		const data =
		{
			_id: category._id,
			_socket: room
		}

		return await Fetch('/store/category/delete', data);
	}

	const value =
	{
		code: state.code,
		categories: state.categories,
		currentCategory,
		SetCurrentCategory: setCurrentCategory,
		SetEventId: setEventId,
		LoadCategories,
		CreateCategory,
		UpdateCategory,
		DeleteCategory
	}

	return (
		<Categories.Provider value={value}>
			{props.children}
		</Categories.Provider>
	)
}

const useCategories = () => React.useContext(Categories);

export {CategoriesProvider, useCategories};