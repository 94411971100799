import React from 'react';
import {Section} from 'crack-ux';

// Components
import {Documents} from '../../../../components/tables/modules/storage/Documents/Documents';

const DocumentsTab = (props) =>
{
	return (
		<Section class='full-height'>
			<Documents from='crm' module={{text: 'CRM', value: 'crm'}} draggableRows={true}/>
		</Section>
	);
};

export {DocumentsTab};