const SetSystemVariable = (systemVariable) =>
{
	systemVariable.variableText = `{{${systemVariable.variable}}}`;	

	return systemVariable;
}

const SystemVariablesReducer = (state = {}, action) =>
{
	switch (action.type)
	{
		case 'LOAD_ALL_SYSTEM_VARIABLES':
			const cloneSystemVariables = {...action.systemVariables};

			for(let section in action.systemVariables)
			{
				cloneSystemVariables[section].variables = action.systemVariables[section].variables.map(systemVariable => SetSystemVariable(systemVariable));
			}

			return {
				...state,
				systemVariables: cloneSystemVariables
			}

		default:
			return state;
	}
}

export {SystemVariablesReducer};