import React from 'react';
import {Card, Header, Body, Footer, Text, Button} from 'crack-ux';

// Contexts
import {useModal} from '../../../../contexts/general/Modal';
import {useTraductor} from '../../../../contexts/general/Traductor';
import {useBusiness} from '../../../../contexts/general/Business';
import {useModulesPage} from '../../../../contexts/pages/business/home/Modules';
import {useModules} from '../../../../contexts/collections/home/Modules';

const AddModuleConfirm = (props) =>
{
	const {plan} = props;

	const {CloseModal} = useModal();
	const {Translate} = useTraductor();
	const {GetBusiness} = useBusiness();
	const {currentModule} = useModulesPage();
	const {modulesByName, AddModule} = useModules();

	const module = modulesByName[currentModule] || {};

	return (
		<Card>
			<Header>
				<Text class='bold'>{Translate('Add Module')}: {module.name}</Text>
			</Header>
			<Body style = {{width: 320}}>
			<Text>{Translate('Are you sure want to select the following plan')}: {Translate(plan.name)}?</Text>
			<Text>{Translate('You can choose another plan whenever you want')}</Text>
			</Body>
			<Footer class='right'>
				<Button
					text = {Translate('Continue')}
					class = 'background-blue white'
					Click = {() =>
					{
						AddModule(currentModule, plan.id).then(response =>
						{
							if(response.status === 200)
							{
								CloseModal();
								GetBusiness();
							}
						});
					}}
				/>
				<Button
					text = {Translate('Cancel')}
					Click = {CloseModal}
				/>
			</Footer>
		</Card>
	);
}

export {AddModuleConfirm}