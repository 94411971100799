import React from 'react';
import {Section, Button, Select, Box, Icon} from 'crack-ux';
import {Editor, EditorState, RichUtils, convertFromRaw, convertToRaw} from 'draft-js';

// Contexts
import {useTraductor} from '../../contexts/general/Traductor';

// Css
import './TextEditor.css';

const fonts = [8, 9, 10, 11, 12, 14, 16, 18, 20, 24];

const customStyleMap =
{
	...fonts.reduce((array, value) => ({ ...array, [`font_size_${value}`]: {fontSize: value}}), {}),
	align_left: {textAlign: 'left'},
	align_center: {textAlign: 'center'},
	align_right: {textAlign: 'right'},
};

const TextEditor = (props) =>
{
	const {content, replaceContent, Change} = props;

	const [editorState, setEditorState] = React.useState(content ? EditorState.createWithContent(convertFromRaw(JSON.parse(content))) : EditorState.createEmpty());

	const {Translate} = useTraductor();

	const editor = React.createRef();

	React.useEffect(() =>
	{
		if(typeof replaceContent === 'string')
		{
			setEditorState(replaceContent ? EditorState.createWithContent(convertFromRaw(JSON.parse(replaceContent))) : EditorState.createEmpty());
			Change(replaceContent);
		}
	}, [replaceContent, Change]);

	const FocusEditor = () =>
	{
		editor.current.focus();
	}

	const InlineClick = (style) =>
	{
		setEditorState(RichUtils.toggleInlineStyle(editorState, style));
	};
	
	const BlockClick = (style) =>
	{
		setEditorState(RichUtils.toggleBlockType(editorState, style));
	};

	return (
		<Section class='text-editor-container' Click={FocusEditor}>
			<Section style={{marginBottom: 10}}>
				<Box>
					<Select
						text = {Translate('Font Size')}
						options = {fonts.map(item => ({text: item, value: `font_size_${item}`}))}
						selected = 'font_size_12'
						style =
						{{
							minWidth: 60
						}}
						MouseDown = {(response) => InlineClick(response.value)}
					/>
				</Box>
				<Button text='B' MouseDown={() => InlineClick('BOLD')} style={{marginLeft: 10, verticalAlign: 'bottom', fontWeight: 'bold'}}/>
				<Button text='I' MouseDown={() => InlineClick('ITALIC')} style={{verticalAlign: 'bottom', fontStyle: 'italic'}}/>
				<Button text='S' MouseDown={() => InlineClick('UNDERLINE')} style={{verticalAlign: 'bottom', textDecoration: 'underline'}}/>
				<Button MouseDown={() => BlockClick('unordered-list-item')} style={{verticalAlign: 'bottom', padding: '9px 7px 10px 7px'}}>
					<Icon class='icon-list-bullet' size={11}/>
				</Button>
				{/* <Button class='margin_left_xs' MouseDown={() => BlockClick('align_left')} style={{height: 36, verticalAlign: 'bottom'}}>
					<Icon class='icon-align-left'/>
				</Button>
				<Button class='margin_left_xs' MouseDown={() => BlockClick('align_center')} style={{height: 36, verticalAlign: 'bottom'}}>
					<Icon class='icon-align-center'/>
				</Button>
				<Button class='margin_left_xs' MouseDown={() => BlockClick('align_right')} style={{height: 36, verticalAlign: 'bottom'}}>
					<Icon class='icon-align-right'/>
				</Button> */}
			</Section>
			<Section style={{height: 300}}>
				<Editor
					ref = {editor}
					editorState = {editorState}
					onChange = {(editorState) =>
					{
						setEditorState(editorState);
						Change(JSON.stringify(convertToRaw(editorState.getCurrentContent())));
					}}
					customStyleMap = {customStyleMap}
				/>
			</Section>
		</Section>
	);
}

TextEditor.defaultProps =
{
	content: '',
	Change: () => {}
};

export {TextEditor};