const SetInvoices = (invoice) =>
{
	return invoice;
}

const InvoicesReducer = (state = {}, action) =>
{
	switch (action.type)
	{
		case 'LOAD_INVOICES':
			return {
				...state,
				invoices: action.invoices.map(invoice => SetInvoices(invoice))
			}

		case 'CREATE_INVOICE':
			return {
				...state,
				invoices: [...state.invoices, SetInvoices(action.invoice)],
			}

		case 'UPDATE_INVOICE':
			return {
				...state,
				invoices: state.invoices.map(invoice => invoice._id === action.invoice._id ? SetInvoices(action.invoice) : invoice)
			}

		case 'DELETE_INVOICE':
		{
			return {
				...state,
				invoices: state.invoices.filter(invoice => invoice._id !== action.invoice._id)
			}
		}

		case 'SET_LATEST_INVOICE_CODE':
		{
			return {
				...state,
				code: action.code
			}	
		}

		default:
			return state;
	}
}

export {InvoicesReducer};