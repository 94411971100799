import React from "react";

// Contexts
import {useModal} from "../../general/Modal";

// Forms
import {BusinessForm} from '../../../components/forms/Business';

const Business = React.createContext({});

const BusinessProvider = (props) =>
{
	const {modals, AddModal} = useModal();

	const ShowBusinessForm = (case_ = {}) =>
	{
		AddModal(<BusinessForm case_ = {case_}/>);
	}
	
	const value =
	{
		ShowBusinessForm
	}

	return <Business.Provider value={value}>
				{props.children}
				{modals}
			</Business.Provider>
}

const useBusiness = () => React.useContext(Business);

export {BusinessProvider, useBusiness};