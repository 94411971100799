import {Content, Panel, Card, Header, Body, Footer, Box, Text, Image} from 'crack-ux';

// Context
import {useTraductor} from '../../../contexts/general/Traductor';
import {usePublic} from '../../../contexts/layouts/Public';

const clients =
[
	{
		logo: 'crack-srl.jpg'
	},
	{
		logo: 'rt-soluciones.jpg'
	},
	{
		logo: 'native-media-work.jpg'
	},
	{
		logo: 'audifonos-digitales-bolivia.jpg'
	},
];

const ClientLogo = (props) =>
{
	const {logo} = props;

	return (
		<Panel class='client-logo desktop3 mobile6'>
			<Image src={`/home-page/clients/${logo}`}/>
		</Panel>
	)
}

const featuresFooter =
[
	{
		image: 'process.png',
		title: 'Streamline Process',
		description:
		{
			en: 'Optimize your efficiency with our innovative Streamline Process system. Reduce time and improve productivity with advanced and personalized solutions',
			es: 'Optimice su eficiencia con nuestro innovador sistema de procesos simplificados. Reduzca el tiempo y mejore la productividad con soluciones personalizadas y avanzadas',
		},
	},
	{
		image: 'customer-data.png',
		title: 'Automatic Customer Data Capture',
		description:
		{
			en: 'Improve your data collection process with our revolutionary Automatic Customer Data Capture system. Save time and increase efficiency with an advanced and personalized solution',
			es: 'Mejore su proceso de recolección de datos con nuestro revolucionario sistema de captura automática de datos de clientes. Ahorre tiempo y aumente la eficiencia con una solución avanzada y personalizada',
		},
	},
]

const features =
[
	...featuresFooter,
	{
		image: 'whatsapp.png',
		title: 'Whatsapp Scheduled Messages',
		description:
		{
			en: 'Maximize your efficiency with Scheduled WhatsApp Messages. Automatically send messages at your desired date and time, without being present. Improve your communication with just one click!',
			es: 'Maximice su eficiencia con Mensajes Programados de WhatsApp. Envíe mensajes automáticamente en la fecha y hora deseada, sin estar presente. ¡Mejore su comunicación con un solo clic!',
		},
	},
	{
		image: 'chat-bot.png',
		title: 'Whatsapp Bot',
		description:
		{
			en: 'Automate your customer service with our advanced WhatsApp Bot. Improve efficiency and response time to customers with an intelligent and personalized system',
			es: 'Automatice su atención al cliente con nuestro avanzado Bot de WhatsApp. Mejore la eficiencia y la rapidez en la respuesta a sus clientes con un sistema inteligente y personalizado',
		},
	},
	{
		image: 'mass-messages.png',
		title: 'Whatsapp Mass Messages',
		description:
		{
			en: 'Expand your reach and reach more customers with our powerful WhatsApp Mass Messaging. With our system, sending messages to multiple contacts has never been so easy and effective',
			es: 'Amplifique su alcance y llegue a más clientes con nuestra potente Mensajería Masiva de WhatsApp. Con nuestro sistema, enviar mensajes a múltiples contactos nunca ha sido tan fácil y efectivo',
		},
	},
]

const Feature = (props) =>
{
	const {image, title, description, language} = props;

	const {Translate} = useTraductor();

	return (
		<Card style={{width: 240}}>
			<Header class='no-border' style={{height: 160, padding: 25}}>
				<Image src={`/home-page/features/${image}`}/>
			</Header>
			<Body style={{paddingTop: 0, paddingBottm: 0}}>
				<Text class='bold' size={16} style={{lineHeight: 1.25}}>{Translate(title, language)}</Text>
			</Body>
			<Footer class='no-border' style={{paddingTop: 0}}>
				<Text type='p'>{description[language]}</Text>
			</Footer>
		</Card>
	)
}

const Features = (props) =>
{
	const {language} = props;

	const {Translate} = useTraductor();
	const {featuresRef} = usePublic();

	return (
		<Content reference={featuresRef} class='features section background-white no-padding'>
			<Content class='features-container laptop no-padding'>
				<Panel class='features-intro desktop6 relative'>
					<Box>
						<Header class='no-border'>
							<Text class='title blue'>{Translate('Features', language)}</Text>
						</Header>
						<Body>
							<Text class='justify' type='p'>{language === 'en' ? 'Our CRM software is designed to help you better manage your customer interactions and streamline processes. With powerful data capture capabilities, you can efficiently store and organize important customer information. The solution also includes tools for personalized messaging, allowing you to reach out to your customers at scale, as well as an automated system to quickly respond to common inquiries.' : 'Nuestro software CRM está diseñado para ayudarlo a administrar mejor las interacciones con sus clientes y agilizar los procesos. Con poderosas capacidades de captura de datos, puede almacenar y organizar de manera eficiente la información importante del cliente. La solución también incluye herramientas para mensajería personalizada, lo que le permite comunicarse con sus clientes a escala, así como un sistema automatizado para responder rápidamente a consultas comunes.'}</Text>
						</Body>
						<Footer class='no-border center'>
							{featuresFooter.map((feature, index) =>
							{
								return <Feature key={index} {...feature} language={language} />
							})}
						</Footer>
					</Box>
				</Panel>
				<Panel class='features-cards desktop6 relative center no-padding'>
					<Box>
						{features.map((feature, index) =>
						{
							return <Feature key={index} {...feature} language={language} />
						})}
					</Box>
				</Panel>
			</Content>
			<Content class='laptop'>
				<Panel class='clients center'>
					<Text class='subtitle'>{language === 'en' ? 'Some of Our Clients' : 'Agunos de Nuestros Clientes'}</Text>
					<Card>
						{clients.map((client, index) =>
						{
							return <ClientLogo key={index} {...client}/>
						})}
					</Card>
				</Panel>
			</Content>
		</Content>
	)
}

export {Features};