import React from 'react';
import moment from 'moment';

// Context
import {useSocket} from '../../general/Socket';
import {useBusiness} from '../../general/Business';
import {useProducts} from './Products';

// Reducers
import {MovesReducer} from '../../../reducers/collections/store/Moves';

const GetSalesByDay = (sales, productsObj) =>
{
	const productsData = {};
	const datesData = {};

	sales.forEach(sale =>
	{
		const saleDate = moment(sale.date).format('DD/MM/YYYY');

		if(datesData[saleDate])
		{
			datesData[saleDate].total += sale.total;
		}
		else
		{
			datesData[saleDate] =
			{
				date: saleDate,
				total: sale.total
			}
		}
	
		sale.details.forEach(detail =>
		{
			if(productsData[detail.product])
			{
				productsData[detail.product].quantity += detail.quantity;
				productsData[detail.product].minPrice = (productsData[detail.product].minPrice < detail.price) ? productsData[detail.product].minPrice : detail.price;
				productsData[detail.product].maxPrice = (productsData[detail.product].maxPrice > detail.price) ? productsData[detail.product].maxPrice : detail.price;
				productsData[detail.product].total += detail.total;
			}
			else
			{
				productsData[detail.product] =
				{
					codeText: productsObj[detail.product].codeText,
					name: productsObj[detail.product].name,
					quantity: detail.quantity,
					minPrice: detail.price,
					maxPrice: detail.price,
					total: detail.total
				}
			}
		})
	});

	return {productsData, datesData};
}

const Moves = React.createContext({});

const MovesProvider = (props) =>
{
	const [state, dispatch] = React.useReducer(MovesReducer, {moves: [], code: 0});
	const [currentMove, setCurrentMove] = React.useState({});
	const [eventId, setEventId] = React.useState('');
	const [oldSocket, setOldSocket] = React.useState({});

	const Socket = useSocket();
	const {Fetch, business} = useBusiness();
	const {products, UpdateProduct} = useProducts();

	const room = React.useMemo(() => `store_moves_${business._id}`, [business._id]);
	const unique = React.useMemo(() =>
	{
		return eventId ? `STORE_MOVE_${eventId}` : 'STORE_MOVE';
	}, [eventId]);

	React.useEffect(() =>
	{
		Fetch('/store/moves/code').then(response =>
		{
			if(response.status === 200)
			{
				dispatch({type: 'SET_LATEST_MOVE_CODE', code: response.data});
			}
		});
	}, [Fetch]);

	React.useEffect(() =>
	{
		const events =
		[
			{
				name: `CREATE_${unique}`,
				Function: (move) =>
				{
					dispatch({type: 'CREATE_MOVE', move});

					if(unique === 'STORE_MOVE') dispatch({type: 'SET_LATEST_MOVE_CODE', code: state.code + 1});
				}
			},
			{
				name: `UPDATE_${unique}`,
				Function: (move) => dispatch({type: 'UPDATE_MOVE', move})
			},
			{
				name: `DELETE_${unique}`,
				Function: (move) => dispatch({type: 'DELETE_MOVE', move})
			}
		];

		if(unique !== 'STORE_MOVE')
		{
			events.push
			({
				name: 'CREATE_MOVE',
				Function: () =>
				{
					dispatch({type: 'SET_LATEST_MOVE_CODE', code: state.code + 1});
				}
			})
		}

		Socket.ConnectEvents(room, events, oldSocket);

		if(oldSocket.unique !== unique)
		{
			setOldSocket({room, events, unique});
		}
	}, [Socket, room, unique, oldSocket, state.code]);

	const LoadMoves = React.useCallback((moves = []) =>
	{
		dispatch({type: 'LOAD_MOVES', moves});
	}, []);

	const CreateMove = async (move, productsSelected, _type='sale') =>
	{
		move.code = state.code + 1;
		move._socket = room;

		const result = await Fetch('/store/move/create', move);
		
		const productPromises = [];

		if(result.status === 200)
		{
			for(let i = 0; i < products.length; i++)
			{
				if(productsSelected[products[i]._id])
				{
					const productUpdated = UpdateProduct(
					{
						...products[i],
						quantity:
						{
							...products[i].quantity,
							current: _type === 'sale' ? products[i].quantity.current - productsSelected[products[i]._id].amount : products[i].quantity.current + productsSelected[products[i]._id].amount
						}
					});

					productPromises.push(productUpdated);
				}
			}

			await Promise.all(productPromises);
			
			return result;
		}
		else
		{
			return result;
		}
	}

	const UpdateMove = async (move) =>
	{
		move._socket = room;
	
		delete move.status;
	
		return await Fetch('/store/move/update', move);
	}

	const AnnulledMove = async (move) =>
	{
		if(!move.annulled)
		{
			move._socket = room;
			move.annulled = true;
	
			delete move.status;
	
			const result = await Fetch('/store/move/update', move);

			const productPromises = [];

			if(result.status === 200)
			{
				for(let i = 0; i < move.details.length; i++)
				{
					const product = products.find(product => product._id === move.details[i].product);

					if(product)
					{
						const productUpdated = UpdateProduct(
						{
							...product,
							quantity:
							{
								...product.quantity,
								current: move.type === 1 ? product.quantity.current + move.details[i].quantity : product.quantity.current - move.details[i].quantity
							}
						});

						productPromises.push(productUpdated);
					}
				}
			}

			await Promise.all(productPromises);

			return result;
		}
	}

	const DeleteMove = async (move) =>
	{
		const data =
		{
			_id: move._id,
			_socket: room
		}

		return await Fetch('/store/move/delete', data);
	}

	const value =
	{
		code: state.code,
		moves: state.moves,
		currentMove,
		SetCurrentMove: setCurrentMove,
		SetEventId: setEventId,
		LoadMoves,
		CreateMove,
		UpdateMove,
		AnnulledMove,
		DeleteMove
	}

	return (
		<Moves.Provider value={value}>
			{props.children}
		</Moves.Provider>
	)
}

const useMoves = () => React.useContext(Moves);

export {MovesProvider, useMoves, GetSalesByDay};