
import React from 'react';
import {Card, Panel, Header, Body, Footer, Text, Button} from 'crack-ux';

// Contexts
import {useTraductor} from '../../../../../contexts/general/Traductor';
import {useModal} from '../../../../../contexts/general/Modal';

// Tables
import {ContactList} from '../../../../../pages/business/crm/Contacts/ContactList';

const ScheduledMessageContactsForm = ({contacts, filteredContacts, setFilteredContacts}) =>
{
	const [selected, setSelected] = React.useState(filteredContacts);

	const {CloseModal} = useModal();
	const {Translate} = useTraductor();

	React.useEffect(() =>
	{
		setSelected(filteredContacts);
	}, [filteredContacts]);

	return (
		<Card>
			<Panel class='no-padding' style={{height: 745, maxHeight: 'calc(100vh - 67px - 40px)', width: 1096, maxWidth: 'calc(100vw - 40px)'}} swipe='horizontal'>
				<Panel class='full-height no-padding'>
					<Header>
						<Text class='bold'>{Translate('New Scheduled Message')}</Text>
					</Header>
					<Body class='vertical-scroll no-padding' style={{height: 'calc(100% - var(--header))'}}>
						<ContactList contacts={contacts} from='scheduled-messages' selected={selected} setSelected={setSelected}/>
					</Body>
				</Panel>
			</Panel>
			<Footer class='right'>
				<Button
					class = 'background-blue white'
					text = {Translate('Apply')}
					Click = {() =>
					{
						setFilteredContacts(selected);
						CloseModal();
					}}
				/>
				<Button
					text = {Translate('Cancel')}
					Click = {CloseModal}
				/>
			</Footer>
		</Card>
	);
}

ScheduledMessageContactsForm.defaultProps =
{
	contacts: [],
	filteredContacts: [],
	setFilteredContacts: () => {}
};

export {ScheduledMessageContactsForm}