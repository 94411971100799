import React from 'react';

// Context
import {useSocket} from '../../general/Socket';
import {useBusiness} from '../../general/Business';

// Reducers
import {CompaniesReducer} from '../../../reducers/collections/crm/Companies';

const Companies = React.createContext({});

const CompaniesProvider = (props) =>
{
	const [state, dispatch] = React.useReducer(CompaniesReducer, {companies: [], code: 0});
	const [currentCompany, setCurrentCompany] = React.useState({});
	const [eventId, setEventId] = React.useState('');
	const [oldSocket, setOldSocket] = React.useState({});

	const Socket = useSocket();
	const {Fetch, business} = useBusiness();

	const room = React.useMemo(() => `crm_companies_${business._id}`, [business._id]);
	const unique = React.useMemo(() =>
	{
		return eventId ? `CRM_COMPANY_${eventId}` : 'CRM_COMPANY';
	}, [eventId]);

	React.useEffect(() =>
	{
		Fetch('/crm/companies/code').then(response =>
		{
			if(response.status === 200)
			{
				dispatch({type: 'SET_LATEST_COMPANY_CODE', code: response.data});
			}
		});
	}, [Fetch]);

	React.useEffect(() =>
	{
		const events =
		[
			{
				name: `CREATE_${unique}`,
				Function: (company) =>
				{
					dispatch({type: 'CREATE_COMPANY', company});

					if(unique === 'CRM_COMPANY') dispatch({type: 'SET_LATEST_COMPANY_CODE', code: state.code + 1});
				}
			},
			{
				name: `UPDATE_${unique}`,
				Function: (company) => dispatch({type: 'UPDATE_COMPANY', company})
			},
			{
				name: `DELETE_${unique}`,
				Function: (company) => dispatch({type: 'DELETE_COMPANY', company})
			}
		];

		if(unique !== 'CRM_COMPANY')
		{
			events.push
			({
				name: 'CREATE_COMPANY',
				Function: () =>
				{
					dispatch({type: 'SET_LATEST_COMPANY_CODE', code: state.code + 1});
				}
			})
		}

		Socket.ConnectEvents(room, events, oldSocket);

		if(oldSocket.unique !== unique)
		{
			setOldSocket({room, events, unique});
		}
	}, [Socket, room, unique, oldSocket, state.code]);

	const LoadCompanies = React.useCallback((companies = []) =>
	{
		dispatch({type: 'LOAD_COMPANIES', companies});
	}, []);

	const AddCompanies = React.useCallback((companies = [], from = 0) =>
	{
		dispatch({type: 'ADD_COMPANIES', companies, from});
	}, []);

	const CreateCompany = async (company) =>
	{
		company.code = state.code + 1;
		company._socket = room;

		return await Fetch('/crm/company/create', company);
	}

	const UpdateCompany = async (company) =>
	{
		company._socket = room;

		return await Fetch('/crm/company/update', company);
	}

	const DeleteCompany = async (company) =>
	{
		const data =
		{
			_id: company._id,
			_socket: room
		}

		return await Fetch('/crm/company/delete', data);
	}

	const value =
	{
		code: state.code,
		companies: state.companies,
		currentCompany,
		SetCurrentCompany: setCurrentCompany,
		SetEventId: setEventId,
		LoadCompanies,
		AddCompanies,
		CreateCompany,
		UpdateCompany,
		DeleteCompany
	}

	return (
		<Companies.Provider value={value}>
			{props.children}
		</Companies.Provider>
	)
}

const useCompanies = () => React.useContext(Companies);

export {CompaniesProvider, useCompanies};