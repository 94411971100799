import React from 'react';
import {Card, Header, Body, Footer, Text, Button} from 'crack-ux';

// Contexts
import {useTraductor} from '../../../../contexts/general/Traductor';
import {useModal} from '../../../../contexts/general/Modal';
import {useStorages} from '../../../../contexts/collections/storage/Storage';

const DeleteStorage = (props) =>
{
	const {document} = props;

	const {CloseModal} = useModal();
	const {Translate} = useTraductor();

	const {Delete} = useStorages();

	const HandleDelete = () =>
	{
		if(document.readOnly) return;

		Delete(document).then(response =>
		{
			if(response.status === 200)
			{
				CloseModal();
			}
		})
	}

	return (
		<Card>
			<Header>
				<Text class='bold'>{Translate(`Delete ${document.file ? 'File' : 'Folder'}`)}</Text>
			</Header>
			<Body>
				<Text>{Translate('Are you sure you want to delete')}: {document.name} {!document.file && document.items > 0 ? `(${document.items} ${Translate('items')})` : ''}?</Text>
			</Body>
			<Footer class='right'>
				<Button class='background-blue white' text={Translate('Continue')} Click={HandleDelete}/>
				<Button text={Translate('Cancel')} Click={CloseModal}/>
			</Footer>
		</Card>
	)
}

export {DeleteStorage};