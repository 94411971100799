import React from 'react';
import {Generic} from 'crack-functions';

// Context
import {useSocket} from '../../general/Socket';
import {useBusiness} from '../../general/Business';

// Reducers
import {StoragesReducer} from '../../../reducers/collections/storage/Storage';

const StoragesContext = React.createContext({});

const StoragesProvider = (props) =>
{
	const [state, dispatch] = React.useReducer(StoragesReducer, {storages: []});
	const [storagesById, setStoragesById] = React.useState({});
	// const [eventId, setEventId] = React.useState('');
	const [modules, setModules] = React.useState([]);
	const [oldSocket, setOldSocket] = React.useState({});

	const Socket = useSocket();
	const {Fetch, business} = useBusiness();

	const room = React.useMemo(() => `storage_storages_${business._id}`, [business._id]);
	const unique = 'STORAGE_STORAGE';

	React.useEffect(() =>
	{
		const events =
		[
			{
				name: `CREATE_${unique}`,
				Function: (storage) =>
				{
					if(modules.find(module => module === storage._folder))
					{
						dispatch({type: 'CREATE_STORAGE', storage});
	
						setStoragesById(current => ({...current, [storage._id]: storage}));
					}
				}
			},
			{
				name: `UPDATE_${unique}`,
				Function: (storage) =>
				{
					dispatch({type: 'UPDATE_STORAGE', storage});

					setStoragesById(current => ({...current, [storage._id]: storage}));
				}
			},
			{
				name: `DELETE_${unique}`,
				Function: (storage) => dispatch({type: 'DELETE_STORAGE', storage})
			}
		];

		Socket.ConnectEvents(room, events, oldSocket);

		if(oldSocket.unique !== unique)
		{
			setOldSocket({room, events, unique});
		}
	}, [Socket, room, unique, oldSocket, state.code, modules]);

	const LoadStorages = React.useCallback((storages = []) =>
	{
		dispatch({type: 'LOAD_STORAGE', storages});
		setStoragesById(Generic.ArrayToObjectByIdentifier(storages));
	}, []);

	const CreateStorage = async (storage) =>
	{
		storage._socket = room;

		return await Fetch('/storage/business/create', storage);
	}

	const UpdateStorage = async (storage) =>
	{
		storage._socket = room;

		return await Fetch('/storage/business/update', storage);
	}

	const UploadDocuments = async (data) =>
	{
		data._socket = room;

		return await Fetch('/storage/business/upload-documents', data);
	}

	const Delete = async (storage) =>
	{
		const data =
		{
			_id: storage._id,
			_socket: room
		}

		return await Fetch('/storage/business/delete', data);
	}

	const PublicPrivate = async (storage) =>
	{
		const data =
		{
			_id: storage._id,
			isPublic: !storage.isPublic
		};

		return await UpdateStorage(data);
	}

	const value =
	{
		storages: state.storages,
		storagesById,
		// SetEventId: setEventId,
		SetModules: setModules,
		LoadStorages,
		CreateStorage,
		UpdateStorage,
		Delete,
		UploadDocuments,
		PublicPrivate,
	}

	return (
		<StoragesContext.Provider value={value}>
			{props.children}
		</StoragesContext.Provider>
	)
}

const useStorages = () => React.useContext(StoragesContext);

export {StoragesProvider, useStorages};