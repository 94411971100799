import React from "react";

// Contexts
import {useModal} from "../general/Modal";
import {StoragesProvider} from "../collections/storage/Storage";
import {CustomersProvider} from "../collections/store/Customers";
import {ProvidersProvider} from "../collections/store/Providers";
import {ProductsProvider} from "../collections/store/Products";
import {CategoriesProvider} from "../collections/store/Categories";
import {MovesProvider} from "../collections/store/Moves";

// Views
import {MoveDetail} from "../../components/views/modules/store/MoveDetail/MoveDetail";
import {Moves} from "../../components/views/modules/store/Moves/Moves";

// Forms
import {CustomerForm} from "../../components/forms/modules/store/Customer";
import {ProviderForm} from "../../components/forms/modules/store/Provider";
import {ProductForm} from "../../components/forms/modules/store/Product";
import {CategoryForm} from "../../components/forms/modules/store/Category";

// Warnings
import {DeleteCustomer} from "../../components/warnings/store/DeleteCustomer";
import {DeleteProvider} from "../../components/warnings/store/DeleteProvider";
import {DeleteProduct} from "../../components/warnings/store/DeleteProduct";
import {DeleteCategory} from "../../components/warnings/store/DeleteCategory";

const StoreContext = React.createContext({});

const StoreProvider = (props) =>
{
	const {AddModal, modals} = useModal();

	const ShowMoveDetailView = (move = {}) =>
	{
		AddModal(<MoveDetail move={move}/>);
	}

	const ShowCustomerForm = (customer = {}) =>
	{
		AddModal(<CustomerForm customer={customer}/>);
	}

	const ShowProviderForm = (provider = {}) =>
	{
		AddModal(<ProviderForm provider={provider}/>);
	}

	const ShowProductForm = (product = {}) =>
	{
		AddModal(<ProductForm product={product}/>);
	}

	const ShowCategoryForm = (category = {}) =>
	{
		AddModal(<CategoryForm category={category}/>);
	}

	const ShowDeleteCustomerWarning = (customer = {}) =>
	{
		AddModal(<DeleteCustomer customer={customer}/>);
	}

	const ShowDeleteProviderWarning = (provider = {}) =>
	{
		AddModal(<DeleteProvider provider={provider}/>);
	}

	const ShowDeleteProductWarning = (product = {}) =>
	{
		AddModal(<DeleteProduct product={product}/>);
	}

	const ShowDeleteCategoryWarning = (category = {}) =>
	{
		AddModal(<DeleteCategory category={category}/>);
	}

	const ShowMoves = ({type, contact}) =>
	{
		AddModal(<Moves type = {type} contact={contact}/>);
	}

	const value =
	{
		ShowMoveDetailView,
		ShowCustomerForm,
		ShowProviderForm,
		ShowProductForm,
		ShowCategoryForm,
		ShowDeleteCustomerWarning,
		ShowDeleteProviderWarning,
		ShowDeleteProductWarning,
		ShowDeleteCategoryWarning,
		ShowMoves,
	}

	return (
		<StoragesProvider>
			<CustomersProvider>
				<ProvidersProvider>
					<ProductsProvider>
						<CategoriesProvider>
							<MovesProvider>
								<StoreContext.Provider value={value}>
									{props.children}
									{modals}
								</StoreContext.Provider>
							</MovesProvider>
						</CategoriesProvider>
					</ProductsProvider>
				</ProvidersProvider>
			</CustomersProvider>
		</StoragesProvider>
	)
}

const useStore = () => React.useContext(StoreContext);

export {StoreProvider, useStore}