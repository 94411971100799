import React from "react";

// Contexts
import {useModal} from "../../../general/Modal";

// Forms
import {BusinessInfoForm} from "../../../../components/forms/BusinessInfo/BusinessInfo";
import {Location} from "../../../../components/forms/Location";

const BusinessInfo = React.createContext({});

const BusinessInfoProvider = (props) =>
{
	const {modals, AddModal} = useModal();

	const [displayBusinessInfoForm, setDisplayBusinessInfoForm] = React.useState(false);

	const ShowBusinessInfoForm = (business = {}) =>
	{
		AddModal(<BusinessInfoForm business = {business}/>);
	}

	const ShowLocationForm = (params = {}) =>
	{
		const
		{
			location = {},
			GetLocation = () => {}
		} = params;

		AddModal(<Location location = {location} GetLocation = {GetLocation}/>);
	}
	
	const value =
	{
		displayBusinessInfoForm,
		ShowBusinessInfoForm,
		ShowLocationForm,
		SetDisplayBusinessInfoForm: setDisplayBusinessInfoForm
	}

	return <BusinessInfo.Provider value={value}>
				{props.children}
				{modals}
			</BusinessInfo.Provider>
}

const useBusinessInfo = () => React.useContext(BusinessInfo);

export {BusinessInfoProvider, useBusinessInfo};