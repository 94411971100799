import React from 'react';
import {Card, Header, Body, Footer, Button, Text, FileList} from 'crack-ux';

// Context
import {useScreen} from '../../../../../contexts/general/Screen';
import {useModal} from '../../../../../contexts/general/Modal';
import {useTraductor} from '../../../../../contexts/general/Traductor';
import {useStorages} from '../../../../../contexts/collections/storage/Storage';

// Functions
import Validations from '../../../../../objects/Validate';

const UploadFilesForm = ({module, parent}) =>
{
    const [form, setForm] = React.useState({files: [], filesNames: []});
	const [messages, setMessages] = React.useState({});

	const {height} = useScreen();
    const {CloseModal} = useModal();
    const {Translate} = useTraductor();

	const {UploadDocuments} = useStorages();

	const HandleSubmit = async () =>
	{
		const data =
		{
			father: parent._id,
			filesNames: form.filesNames,
			_files:
			{
				storage: form.files
			},
			_folder: module.value
		};

		const result = await UploadDocuments(data);

		setMessages(Validations.ParseByField(result.validations));
		
		if(result.status === 200)
		{
			CloseModal();
		}
	}

	return (
		<Card>
			<Header>
				<Text class='bold'>{Translate('Upload Files')}</Text>
			</Header>
			<Body class='vertical-scroll' style={{width: '400px', maxWidth: 'calc(100vw - 40px)'}}>
				<FileList
					style = {{height: (300 + 49 + 67 + 40) > height ? (height - 49 - 67 - 40) : 300}}
					UpdateFiles = {(files, filesNames) => setForm({files, filesNames})}
				/>
				{messages.message}
			</Body>
			<Footer class='right'>
				<Button
					text = {Translate('Save')}
					class = 'background-blue white'
					Click = {HandleSubmit}
				/>
				<Button
					text = {Translate('Cancel')}
					Click = {CloseModal}
				/>
			</Footer>
		</Card>
	)
}

export {UploadFilesForm};