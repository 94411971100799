import React from "react";

// contexts
import {useModal} from "../../general/Modal";

// Confirm
import {ForceLogin} from "../../../components/warnings/public/ForceLogin/ForceLogin";
import {ContactUsForm} from "../../../components/forms/ContactUs/ContactUs";

const HomeContext = React.createContext({});

const HomeProvider = (props) =>
{
	const {modals, AddModal} = useModal();

	const ShowForceLogin = (Confirm = () => {}, preferences = {}) =>
	{
		AddModal(<ForceLogin Confirm = {Confirm} preferences = {preferences}/>);
	}

	const ShowContactUs = (from, language, tags) =>
	{
		AddModal(<ContactUsForm from = {from} language = {language} tags = {tags}/>);
	}

	const value =
	{
		ShowForceLogin,
		ShowContactUs,
	}

	return ( 		
        <HomeContext.Provider value={value}>
            {props.children}
			{modals}
        </HomeContext.Provider>
	);
}

const useHomePage = () => React.useContext(HomeContext);

export {HomeProvider, useHomePage};