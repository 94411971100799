import React from "react";
import {Tabs} from 'crack-ux';

// Tables
import {SystemVariablesTable} from "../../tables/SystemVariables/SystemVariables";

// Contexts
import {useTraductor} from "../../../contexts/general/Traductor";

const SystemVariablesTabs = (props) =>
{
	const {sections} = props;

	const [currentTab, setCurrentTab] = React.useState('crm-contact');

	const {Translate} = useTraductor();

	const tabs = React.useMemo(() =>
	{
		const tabs = [];

		for(let i in sections)
		{
			tabs.push
			({
				name: Translate(sections[i].title),
				content: <SystemVariablesTable variables = {sections[i].variables}/>,
				identifier: i
			});
		}

		return tabs;
	}, [sections, Translate]);

	return (
		<Tabs
			tabs = {tabs}
			current = {currentTab}
			Change = {(currentTab) =>
			{
				setCurrentTab(currentTab.identifier);
			}}
		/>
	);
}

export {SystemVariablesTabs}