import React from 'react';
// import {Link} from 'react-router-dom';
import {Text, Section, Body} from 'crack-ux';

// Styles
import './Notifications.css';

// Contexts
// import {useTraductor} from '../../contexts/general/Traductor';
// import {useBusiness} from '../../contexts/general/Business';
import {useBusiness as useBusinessLayout} from '../../contexts/layouts/Business';
import {useNotifications} from '../../contexts/main/Notifications';

const Notifications = (props) =>
{
	// const {Translate} = useTraductor();
	// const {business} = useBusiness();
	const {currentTab} = useBusinessLayout();

	const {notifications, ViewNotifications} = useNotifications();

	React.useEffect(() =>
	{
		if(currentTab === 'notifications')
		{
			ViewNotifications();
		}
	}, [currentTab, ViewNotifications]);

	const notificationsSection = React.useMemo(() =>
	{
		return notifications.slice(0, 10).map((notification, key) =>
		{
			return (
				<Section class='notification-item' key={key}>
					<Text class='bold block' size={10}>{notification.title}</Text>
					<Text class='notification-desc' size={10}>{notification.description}</Text>
					<Section class='notification-info right'>
						{notification.iconsComponent}
						<Text class='gray' size={10}>{notification.sentDateText}</Text>
					</Section>
				</Section>
			)
		})
	}, [notifications]);

	// const notificationsLink = React.useMemo(() => `/platform/${business.uniqueName}/notifications`, [business.uniqueName]);

	return (
		<Section class={`profile-notifications`}>
			{/* <Body class='no-padding vertical-scroll' style={{height: 'calc(100% - 49px)'}}> */}
			<Body class='no-padding vertical-scroll' style={{height: 'calc(100%)'}}>
				{notificationsSection}
			</Body>
			{/* <Footer class='center'>
				<Link to={notificationsLink}>
					<Text class='blue pointer'>{Translate('View all notifications')}</Text>
				</Link>
			</Footer> */}
		</Section>
	)
}

export {Notifications};
