import React from 'react';
import {Card, Header, Body, Footer, Form, Button, Text} from 'crack-ux';

// Context
import {useModal} from '../../../contexts/general/Modal';
import {useSession} from '../../../contexts/general/Session';
import {useBusiness} from '../../../contexts/main/Business';
import {useTraductor} from '../../../contexts/general/Traductor';

// Objects
import Validate from '../../../objects/Validate';

const BusinessForm = (props) =>
{
	const [form, setForm] = React.useState();
	const [messages, setMessages] = React.useState({});
	
	const {session, UpdateSession} = useSession();
	const {CloseModal} = useModal();
	const {CreateBusiness} = useBusiness();
	const {Translate} = useTraductor();
	
	if(session.user.businessOwner.length < session.user.businessLimit)
	{
		const HandleSubmit = async () =>
		{
			const newForm = {...form};
			const uniqueNameValidation = Validate.ValidateUniqueName(newForm.uniqueName);
			newForm.uniqueName = newForm.uniqueName?.toLowerCase();

			setMessages({uniqueName: uniqueNameValidation});

			if(uniqueNameValidation === null)
			{
				const result = await CreateBusiness(newForm);

				setMessages(Validate.ParseByField(result.validations));
				
				if(result.status === 200)
				{
					UpdateSession();
					CloseModal();
				}
			}

		}

		return (
			<Card>
				<Header>
					<Text class='bold'>{Translate('New Business')}</Text>
				</Header>
				<Body class='vertical-scroll' style={{width: '320px'}}>
					<Form
						inputs =
						{
							[
								{
									name: 'name',
									text: Translate('Name'),
									message: messages.name
								},
								{
									name: 'uniqueName',
									text: Translate("Unique Name"),
									Change: () => setMessages({...messages, uniqueName: null}),
									message: messages.uniqueName
								}
							]                        
						}
						Change = {(response) => setForm(response)}
						Submit = {HandleSubmit}
					/>
				</Body>
				<Footer class='right'>
					<Button
						text = {Translate('Save')}
						class = 'background-blue white'
						Click = {HandleSubmit}
					/>
					<Button
						text = {Translate('Cancel')}
						Click = {CloseModal}
					/>
				</Footer>
			</Card>
		) 
	}
	else
	{
		return (
			<Card>
				<Header>
					<Text class='green'>Notification</Text>
				</Header>
				<Body class='vertical-scroll' style={{width: '320px', maxWidth: '100%'}}>
					<Text class='justify' type='p'>
						You have reached the allowed business limit, if you want to increase this limit, please contact us:
						<a href='https://wa.me/59169710985' target='_blank' rel='noreferrer'>
							<Text class='blue pointer'>&nbsp;+591 69710985</Text>
						</a>
					</Text>
				</Body>
				<Footer class='right'>
					<Button
						text = 'Cancel'
						Click = {CloseModal}
					/>
				</Footer>
			</Card>
		)
	}
}

export {BusinessForm};