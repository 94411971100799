import React from 'react';
import {Card, Header, Body, Footer, Form, Button, Text} from 'crack-ux';

// Context
import {useModal} from '../../../../../contexts/general/Modal';
import {useTraductor} from '../../../../../contexts/general/Traductor';
import {useStorages} from '../../../../../contexts/collections/storage/Storage';

// Functions
import Validations from '../../../../../objects/Validate';

const DocumentForm = ({document, module, parent}) =>
{
    const [form, setForm] = React.useState(document);
	const [messages, setMessages] = React.useState({});

    const {CloseModal} = useModal();
    const {Translate} = useTraductor();

	const {CreateStorage, UpdateStorage} = useStorages();

	const HandleSubmit = async () =>
	{
		const cloneForm = {...form};

		cloneForm._folder = module.value;
		cloneForm.father = parent._id;

		const result = document._id ? await UpdateStorage({...cloneForm, _id: document._id}) : await CreateStorage(cloneForm);

		setMessages(Validations.ParseByField(result.validations));
		
		if(result.status === 200)
		{
			CloseModal();
		}
	}

	const docType = React.useMemo(() => (document.file ? 'File' : 'Folder'), [document.file]);
	const inputs = React.useMemo(() =>
	{
		const inputs =
		[{
			name: 'name',
			text: Translate('Name'),
			message: messages.name,
			value: form.name
		}];

		if(document._id && document.file)
		{
			inputs.push
			({
				name: 'isPublic',
				text: Translate('Public'),
				value: form.isPublic,
				type: 'checkbox'
			});
		}

		return inputs;
	}, [document, form, messages, Translate]);

	return (
		<Card>
			<Header>
				<Text class='bold'>{document._id ? Translate(`Edit ${docType}`) : Translate(`New ${docType}`)}</Text>
			</Header>
			<Body class='vertical-scroll' style={{width: '400px', maxWidth: 'calc(100vw - 40px)'}}>
				<Form
					inputs = {inputs}
					Change = {(response) => setForm(response)}
					Submit = {HandleSubmit}
				/>
			</Body>
			<Footer class='right'>
				<Button
					text = {Translate('Save')}
					class = 'background-blue white'
					Click = {HandleSubmit}
				/>
				<Button
					text = {Translate('Cancel')}
					Click = {CloseModal}
				/>
			</Footer>
		</Card>
	)
}

export {DocumentForm};