import React from "react";
import {Generic} from 'crack-functions';

// Reducers
import {BaseReducer} from "./BaseReducer";

const BaseContext = React.createContext();

const BaseContextProvider = (props) =>
{
	const {list, item} = props;

	const [state] = React.useReducer(BaseReducer, {list, [list]: props[list]});

	const Create = async (record) =>
	{
		// dispatch({type: 'CREATE', list, record});

		return await Generic.Fetch(`/${item}/create`, record);
	}

	const Update = () =>
	{
		console.log('update');
	}

	const Delete = () =>
	{
		console.log('delete');
	}

	const value =
	{
		[list]: state[list],
		Create,
		Update,
		Delete,
	}

	return <BaseContext.Provider value={value} {...props}/>;
}

const useBaseContext = () => React.useContext(BaseContext);

export {BaseContextProvider, useBaseContext};