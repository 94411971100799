import React from 'react';
import {Table, Avatar} from 'crack-ux';
import {Generic} from 'crack-functions';

// Contexts
import {useTraductor} from '../../../../../contexts/general/Traductor';
import {useStore} from '../../../../../contexts/modules/Store';
import {useStorages} from '../../../../../contexts/collections/storage/Storage';
import {useProducts} from '../../../../../contexts/collections/store/Products';
import {useCategories} from '../../../../../contexts/collections/store/Categories';

const ProductsTable = () =>
{
	const {Translate} = useTraductor();

	const {ShowProductForm, ShowDeleteProductWarning} = useStore();

	const {storagesById} = useStorages();
	const {products, UpdateProduct} = useProducts();
	const {categories} = useCategories();

	const categoriesObj = React.useMemo(() => Generic.ArrayToObjectByIdentifier(categories), [categories]);

	const productsPictures = React.useMemo(() =>
	{
		const result = {};

		products.forEach(product =>
		{
			const url = storagesById?.[product?.pictures?.[0]?.file]?.publicURL || '/assets/box.png';

			result[product._id] = <Avatar class='background-white' src={url} error={'/assets/box.png'} size={50} round={false}/>
		});

		return result;
	}, [products, storagesById]);

	return (
		<Table
			class = 'hover pointer'
			header = 
			{[
				{
					name: 'status',
					text: Translate('Status'),
					width: 75
				},
				{
					name: 'pictureMiniature',
					text: Translate('Picture'),
					width: 80
				},
				{
					name: 'codeText',
					text: Translate('Code'),
					width: 90
				},
				{
					name: 'name',
					text: Translate('Product Name'),
					width: 200
				},
				{
					name: 'categoriesText',
					text: Translate('Categories')
				},
				{
					name: 'currentQuantity',
					text: Translate('Quantity'),
					width: 82
				},
				{
					name: 'purchaseText',
					text: Translate('Purchase Price'),
					width: 135
				},
				{
					name: 'salesText',
					text: Translate('Sales Price'),
					width: 120
				},
				// {
				// 	name: 'grossProfit',
				// 	text: Translate('Gross Profit'),
				// 	width: 120
				// },
				// {
				// 	name: 'grossMargin',
				// 	text: Translate('Gross Margin'),
				// 	width: 120
				// },
			]}
			body = {products.map(product =>
			{
				const categoriesFiltered = product.categories ? product.categories.map(category => categoriesObj[category].name) : [];
				product.categoriesText = categoriesFiltered.join(', ');

				if(productsPictures[product._id]) product.pictureMiniature = productsPictures[product._id];

				return product;
			})}
			actions =
			{[
				{
					text: Translate('New Product'),
					class: 'background-blue white',
					section: 'grouped',
					Click: () => ShowProductForm()
				},
				{
					text: Translate('Edit Product'),
					section: 'context',
					icon:
					{
						class: 'icon-pencil'
					},
					Click: (product) => ShowProductForm(product)
				},
				{
					text: Translate('Enable/Disable'),
					section: 'context',
					icon:
					{
						class: 'icon-dot-circled'
					},
					Click: (product) => UpdateProduct({...product, available: !product.available})
				},
				{
					text: Translate('Delete Product'),
					class: 'red',
					section: 'context',
					icon:
					{
						class: 'icon-trash'
					},
					Click: (product) => ShowDeleteProductWarning(product)
				}
			]}
			searcher =
			{
				{text: Translate('Search')}
			}
			texts =
			{
				{
					noRecords:Translate('There Are Not Records To Show'),
					records:Translate('Records'),
					fields: Translate('Fields'),
					settings: Translate('Settings'),
					export: Translate('Export')
				}
			}
			filters = 
			{{
				columns:
				{
					editable: true
				},
				Apply:
				{
					text: Translate('Apply'),
					Click: () => {}
				},
				Reset:
				{
					text: Translate('Reset'),
					Click: () => {}
				}
			}}
		/>
	)
}

export {ProductsTable};