import React from "react";

// Contexts
import {useModal} from "../../../general/Modal";

// Forms
import {ChatBotForm} from "../../../../components/forms/modules/crm/ChatBot/ChatBot";

// Warnings
import {DeleteChatBot} from "../../../../components/warnings/crm/DeleteChatBot/DeleteChatBot";

// Static Data
import channels from '../../../../static-data/Channels.json';

const ChatBotsContext = React.createContext({});

const ChatBotsProvider = (props) =>
{
	const [channel, setChannel] = React.useState(channels.filter(channel => !channel.hidden)[0]);

	const {AddModal} = useModal();

	const ShowChatBotForm = (chatBot = {}) =>
	{
		AddModal(<ChatBotForm chatBot = {chatBot} channel = {channel}/>);
	}

	const ShowDeleteChatBot = (chatBot = {}) =>
	{
		AddModal(<DeleteChatBot chatBot = {chatBot}/>);
	}
	
	const value =
	{
		channel,
		SetChannel: setChannel,
		ShowChatBotForm,
		ShowDeleteChatBot
	}

	return (
		<ChatBotsContext.Provider value={value}>
			{props.children}
		</ChatBotsContext.Provider>
	)
}

const useChatBots = () => React.useContext(ChatBotsContext);

export {ChatBotsProvider, useChatBots};