import React from 'react';
import {Card, Header, Body, Footer, Button, Text} from 'crack-ux';

// Context
import {useModal} from '../../../../../contexts/general/Modal';
import {useContacts} from '../../../../../contexts/collections/crm/Contacts';
import {useTraductor} from '../../../../../contexts/general/Traductor';

// Components
import {SimpleContactForm} from './SimpleContact';

// Functions
import Validations from '../../../../../objects/Validate';

const ContactForm = ({contact}) =>
{
    const [form, setForm] = React.useState({});
	const [messages, setMessages] = React.useState({});

	const {CreateContact, UpdateContact} = useContacts();
    const {CloseModal} = useModal();

    const {Translate} = useTraductor();

	const HandleSubmit = async () =>
	{
		const result = contact._id ? await UpdateContact({...form, salesFlow: contact.salesFlow, _id: contact._id}) : await CreateContact(form);

		setMessages(Validations.ParseByField(result.validations));

		if(result.status === 200)
		{
			CloseModal();
		}
	}

	return (
		<Card>
			<Header>
				<Text class='bold'>{contact._id ? Translate('Edit Contact') : Translate('New Contact')}</Text>
			</Header>
			<Body class='vertical-scroll' style={{width: 400, maxWidth: 'calc(100vw - 40px)'}}>
				<SimpleContactForm contact={contact} messages={messages} Change={setForm}/>
			</Body>
			<Footer class='right'>
				<Button
					text = {Translate('Save')}
					class = 'background-blue white'
					Click = {HandleSubmit}
				/>
				<Button
					text = {Translate('Cancel')}
					Click = {CloseModal}
				/>
			</Footer>
		</Card>
	)
}

export {ContactForm};