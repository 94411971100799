import {Content, Footer, Box, Text} from 'crack-ux';

// Context
import {useTraductor} from '../../../contexts/general/Traductor';

const EndPage = (props) =>
{
	const {language} = props;

	const {Translate} = useTraductor();

	return (
		<Footer class='end-page no-border'>
			<Content class='laptop no-padding'>
				<Text>Copyright © 2023 Crack SRL All Right Reserved</Text>
				<Box class='policies'>
					<Text style={{marginRight: 15}}>{Translate('Privacy Policy', language)}</Text>
					<Text>{Translate('Terms of Use', language)}</Text>
				</Box>
			</Content>
		</Footer>
	)
}

export {EndPage};