const SetChatBot = (chatBot) =>
{
	chatBot.mainText = chatBot.main ? 'Yes' : 'No';
	chatBot.chatStatusText = chatBot.chatStatus === 1 ? 'Finish Chat' : chatBot.chatStatus === 2 ? 'Request Operator' : '';

	if(Array.isArray(chatBot.files))
	{
		chatBot.filesAsObj = chatBot.files.map(file =>
		({
			_id: file
		}));
	}

	return chatBot;
}

const ChatBotsReducer = (state = {}, action) =>
{
	switch (action.type)
	{
		case 'LOAD_CHAT_BOTS':
			return {
				...state,
				chatBots: action.chatBots.map(chatBot => SetChatBot(chatBot))
			}

		case 'CREATE_CHAT_BOT':
			return {
				...state,
				chatBots: [...state.chatBots, SetChatBot(action.chatBot)],
			}

		case 'UPDATE_CHAT_BOT':
			return {
				...state,
				chatBots: state.chatBots.map(chatBot => chatBot._id === action.chatBot._id ? SetChatBot(action.chatBot) : chatBot)
			}

		case 'DELETE_CHAT_BOT':
		{
			return {
				...state,
				chatBots: state.chatBots.filter(chatBot => chatBot._id !== action.chatBot._id)
			}
		}

		default:
			return state;
	}
}

export {ChatBotsReducer};