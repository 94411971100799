import {Text as TextF} from 'crack-functions';

const defaultPhone = {country: '591', area: '', number: ''};
const phoneFields = Object.keys(defaultPhone);
const capitalizedPhoneFields = phoneFields.map(field => TextF.CapitalizeOne(field));

class Phones
{
	static GetPhoneFromFields(data, key)
	{
		const result = {...defaultPhone};

		capitalizedPhoneFields.forEach((field, index) =>
		{
			if(data[`${key}${field}`])
			{
				result[phoneFields[index]] = data[`${key}${field}`];
			}
		});

		return result;
	}

	static GetFormFields(phones)
	{
		const fields = {};

		if(Array.isArray(phones))
		{
			phones.forEach(phone =>
			{
				capitalizedPhoneFields.forEach((field, index) =>
				{
					fields[`${phone.type}${field}`] = phone[phoneFields[index]];
				});
			});
		}

		return fields;
	}

	static GetPhoneTexts(phones, config = {})
	{
		const {joinSymbol = ' ', plusSymbol = '+', byType = false} = config;
		const phonesResult = [];
		const objectPhones = {};

		if(Array.isArray(phones))
		{
			phones.forEach(phone =>
			{
				if(phone.number)
				{
					const phoneParts = [];
	
					phoneFields.forEach(field =>
					{
						if(phone[field] !== undefined && phone[field] !== null)
						{
							const value = field === 'country' ? `${plusSymbol}${phone[field]}` : phone[field];
	
							phoneParts.push(value);
						}
					});
	
					phonesResult.push(phoneParts.join(joinSymbol));
					objectPhones[`${phone.type}Text`] = phoneParts.join(joinSymbol);
				}
			});
		}

		return byType ? objectPhones : phonesResult;
	}
}

export default Phones;