import React from 'react';
import {Content, Panel} from 'crack-ux';

// Views
import {Loading} from '../../../../components/views/Loading';
import {SystemVariablesTabs} from '../../../../components/views/SystemVariables/SystemVariables';

// Contexts
import {useSystemVariables} from '../../../../contexts/collections/home/SystemVariables';

const SystemVariables = (props) =>
{
	const {systemVariables} = useSystemVariables();

	const loading = React.useMemo(() =>
	{
		return Object.keys(systemVariables).length === 0;
	}, [systemVariables]);

	if(loading)
	{
		return <Loading/>;
	}
	else
	{
		// const text = 'Hola {{NAME}} {{LAST-NAME}} Bienvenido a {{BUSINESS-NAME}}';
		// const data =
		// {
		// 	main:
		// 	{
		// 		name: 'Juan',
		// 		lastName: 'Topo'
		// 	},
		// 	business:
		// 	{
		// 		name: 'Sice'
		// 	}
		// };
		// const section = 'crm-contact';

		// console.log('Replaced::: ', VariableReplacer(text, data, section));

		return (
			<Content style={{height: 'calc(100vh - var(--header))'}}>
				<Panel class='no-padding full-height'>
					<SystemVariablesTabs sections = {systemVariables}/>
				</Panel>
			</Content>
		);
	}
}

export {SystemVariables};