import React from 'react';
import {Table} from 'crack-ux';

// Contexts
import {useTraductor} from '../../../../../contexts/general/Traductor';

const InvoiceDetailsTable = (props) =>
{
	const {detail} = props;

	const {Translate} = useTraductor();

	const body = React.useMemo(() =>
	(
		detail.map(invoice =>
		{
			// invoice.statusText = Translate(invoice.statusText);
			return invoice;
		})
	), [detail]);

	return (
		<Table
			class = 'hover'
			header = 
			{[
				{
					name: 'module',
					text: Translate('Module')
				},
				{
					name: 'description',
					text: Translate('Description')
				},
				{
					name: 'quantityText',
					text: Translate('Quantity')
				},
				{
					name: 'unitPriceText',
					text: Translate('Unit Price')
				},
				{
					name: 'subtotalText',
					text: Translate('Total')
				}
			]}
			body = {body}
			actions = {false}
			searcher = {false}
			texts =
			{
				{
					noRecords:Translate('There Are Not Records To Show'),
					records:Translate('Records'),
					fields: Translate('Fields'),
					settings: Translate('Settings'),
					export: Translate('Export')
				}
			}
			filters = {false}
			footer = {false}
			pager =
			{{
				rows: 100
			}}
		/>
	)
}

export {InvoiceDetailsTable};