import React from "react";

// Contexts
import {useModal} from "../general/Modal";
import {StoragesProvider} from "../collections/storage/Storage";

// Forms
import {DocumentForm} from "../../components/forms/modules/storage/Folder/Folder";
import {UploadFilesForm} from "../../components/forms/modules/storage/UploadFiles/UploadFiles";
import {FileViewer} from "../../components/views/FileViewer/FileViewer";

// Warnings
import {DeleteStorage} from "../../components/warnings/storage/DeleteStorage/DeleteStorage";
import {DeleteReadOnly} from "../../components/warnings/storage/DeleteReadOnly/DeleteReadOnly";

const StorageModuleContext = React.createContext({});

const StorageModuleProvider = (props) =>
{
	const {AddModal, modals} = useModal();

	const ShowDocumentForm = (document = {}, {module = {}, parent}) =>
	{
		AddModal(<DocumentForm document = {document} module = {module} parent = {parent}/>);
	}

	const ShowUploadFilesForm = ({module = {}, parent}) =>
	{
		AddModal(<UploadFilesForm module = {module} parent = {parent}/>);
	}

	const ShowDeleteWarning = (document = {}) =>
	{
		AddModal(<DeleteStorage document = {document}/>);
	}

	const ShowReadOnlyWarning = (document = {}) =>
	{
		AddModal(<DeleteReadOnly document = {document}/>);
	}

	const ShowFileViewer = (document = {}) =>
	{
		AddModal(<FileViewer document = {document}/>);
	}

	const value =
	{
		ShowDocumentForm,
		ShowUploadFilesForm,
		ShowDeleteWarning,
		ShowReadOnlyWarning,
		ShowFileViewer,
	}

	return (
		<StoragesProvider>
			<StorageModuleContext.Provider value={value}>
				{props.children}
				{modals}
			</StorageModuleContext.Provider>
		</StoragesProvider>
	)
}

const useStorageModule = () => React.useContext(StorageModuleContext);

export {StorageModuleProvider, useStorageModule}