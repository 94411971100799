import React from 'react';
import {Generic} from 'crack-functions';
import {useParams, useNavigate} from 'react-router-dom';

// Layouts
import {Loading} from '../../../components/views/Loading';

// Context
import {useSession} from '../../../contexts/general/Session';

const AcceptedInvitation = () =>
{
	const params = useParams();
	const navigate = useNavigate();
	const {CheckSession} = useSession();

	React.useEffect(() =>
	{
		const data =
		{
			business: params.business,
			user: params.user
		};

		Generic.Fetch('/staff/accept-invitation', data).then(response =>
		{
			navigate('/');
		});
	}, [CheckSession, navigate, params]);

	return <Loading/>
}

export {AcceptedInvitation};