import React from 'react';
import {Card, Header, Body, Footer, Form, Text, Button} from 'crack-ux';

// Context
import {useModal} from '../../../../../contexts/general/Modal';
import {useTraductor} from '../../../../../contexts/general/Traductor';
import {useBusiness} from '../../../../../contexts/general/Business';
import {useStaff} from '../../../../../contexts/collections/staff/Staff';

// Validation
import Validations from '../../../../../objects/Validate';

const StaffInvitation = (props) =>
{
	const [form, setForm] = React.useState({});
	const [response, setResponse] = React.useState(null);
	const [validations, setValidations] = React.useState({});
	const [action, setAction] = React.useState(null); // 0.- Create, 1.- Invite, 2.- Already invited

	const {Translate} = useTraductor();
	const {CloseModal} = useModal();
	const {Fetch} = useBusiness();

	const {Invite} = useStaff();

	const CheckEmail = React.useCallback(() =>
	{
		const {email} = form;

		if(email)
		{
			setResponse(null);

			Fetch('/staff/find-by-email', {email}).then(response =>
			{
				setValidations(Validations.ParseByField(response.validations));

				if(response.status === 200)
				{
					setAction(response.data.action);

					if([1, 2].includes(response.data.action))
					{
						setForm(response.data.user);

						if(response.data.action === 2)
						{
							setResponse(<Text class='red'>{Translate('This user already is a member')}</Text>);
							setAction(null);
						}
					}
				}
			});
		}
		else
		{
			setResponse(<Text class='red'>{Translate('Email field is required')}</Text>);
		}
	}, [Fetch, form, Translate]);

	const SendInvitation = React.useCallback(() =>
	{
		Invite(form).then(response =>
		{
			if(response.status === 200)
			{
				CloseModal();
			}
		});
	}, [form, CloseModal, Invite]);

	const inputs = React.useMemo(() =>
	{
		let inputs = [];

		if([0, 1].includes(action))
		{
			const disabled = action === 1;

			inputs =
			[
				{
					name: 'name',
					text: 'Name',
					value: form.name,
					disabled,
				},
				{
					name: 'lastName',
					text: 'Last Name',
					value: form.lastName,
					disabled,
				},
				{
					name: 'code',
					text: 'Code',
					class: 'desktop4',
					type: 'select',
					options:
					[
						{
							text: '+591',
							value: 591
						}
					],
					value: form.code || 591,
					disabled,
				},
				{
					name: 'number',
					text: 'Number',
					class: 'desktop8',
					type: 'number',
					value: form.number,
					disabled,
				}
			];
		}

		return inputs;
	}, [action, form]);

	const HandleSubmit = React.useCallback(() =>
	{
		if(action === null)
		{
			CheckEmail(form.email);
		}
		else if([0, 1].includes(action))
		{
			SendInvitation();
		}
	}, [action, form, CheckEmail, SendInvitation]);

	return (
		<Card style={{width: 280}}>
			<Header>
				<Text class='bold'>{Translate('Invite Member')}</Text>
			</Header>
			<Body>
				<Form
					inputs =
					{[
						{
							name: 'email',
							text: 'Email',
							value: form.email,
							message: validations.email,
							disabled: [0, 1].includes(action),
						},
						...inputs
					]}
					response = {response}
					Change = {(response) => setForm(response)}
					Submit = {HandleSubmit}
				/>
			</Body>
			<Footer class='right'>
				<Button class='background-blue white' text={Translate(action === null ? 'Search User' : 'Invite User')} Click={HandleSubmit}/>
				<Button text={Translate('Cancel')} Click={CloseModal}/>
			</Footer>
		</Card>
	)
}

StaffInvitation.defaultProps =
{
	submit: false,
	cancel: false,
}

export {StaffInvitation};