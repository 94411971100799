import React from 'react';
import {Card, Header, Icon, Image} from 'crack-ux';

// Context
import {TraductorProvider} from '../../../contexts/general/Traductor';
import {useModal} from '../../../contexts/general/Modal';

// Forms
import {SignIn} from '../../forms/SignIn';

// Style
import './Login.css';

const Login = (props) =>
{
	const {language, modal} = props;

	const {CloseModal} = useModal();

	return (
		<Card class='login'>
			<Header class='signin_or_signup relative' style={{height: 180}}>
				<Image class='fill' src='/logo_horizontal.jpg'/>
				{modal && <Icon class='icon-cancel absolute pointer' style={{top: 0, right: -3, color: '#444444'}} Click={CloseModal}/>}
			</Header>
			<SignIn language={language}/>
		</Card>
	)
}

Login.defaultProps =
{
	modal: false
}

const LoginWithProviders = (props) =>
{
	return (
		<TraductorProvider>
			<Login {...props}/>
		</TraductorProvider>
	)
}

export {LoginWithProviders as Login};