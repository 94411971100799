import React from "react";
import {Card, Header, Body, Text, Form, Footer, Button} from 'crack-ux';

// Functions
import {useTraductor} from "../../../../../contexts/general/Traductor";

// Context
// import {useSession} from '../../../../contexts/general/Session';
import {useModal} from "../../../../../contexts/general/Modal";
import {useInvoices} from "../../../../../contexts/collections/invoicing/Invoices";


const InvoicingSettingsForm = (props) =>
{
	const [form, setForm] = React.useState({});

    const {Translate} = useTraductor();
	const {CloseModal} = useModal();

	const {UpdateBusinessInvoice} = useInvoices();

	const HandleSubmit = async () =>
	{
		const newForm = {...form};

		console.log('formulario de invoice settings', form);

		const result = await UpdateBusinessInvoice(newForm);
		
		console.log('result invoice settings', result);
		
		if(result.status === 200)
		{
			CloseModal();
		}
	}

	return (
		<Card>
			<Header>
				<Text class='bold'>{Translate('Invoicing Settings')}</Text>
			</Header>
			<Body class='vertical-scroll' style={{width: '320px'}}>
				<Form
					inputs =
					{
						[
							{
								name: 'nit',
								text: Translate('NIT'),
								// message: messages.name
							},
							{
								name: 'authorizationCode',
								text: Translate("Authorization Code"),
								// Change: () => setMessages({...messages, uniqueName: null}),
								// message: messages.uniqueName
							}
						]                        
					}
					Change = {(response) => setForm(response)}
					Submit = {HandleSubmit}
				/>
			</Body>
			<Footer class='right'>
				<Button
					text = {Translate('Save')}
					class = 'background-blue white'
					Click = {HandleSubmit}
				/>
				<Button
					text = {Translate('Cancel')}
					Click = {CloseModal}
				/>
			</Footer>
		</Card>
	)
}

export {InvoicingSettingsForm};