import React from "react";

// Contexts
import {useModal} from "../general/Modal";
import {InvoicesProvider} from "../collections/invoicing/Invoices";

// Forms
import {InvoiceForm} from "../../components/forms/modules/invoicing/Invoice";
import {InvoicingSettingsForm} from "../../components/forms/modules/invoicing/InvoiceSettings";

const Invoicing = React.createContext({});

const InvoicingProvider =  (props) =>
{
	const {AddModal, modals} = useModal();

	const ShowInvoiceForm = (invoice = {}) =>
	{
		AddModal(<InvoiceForm invoice = {invoice}/>);
	}

	const ShowInvoicingSettingsForm = ( settings = {}) =>
	{
		AddModal(<InvoicingSettingsForm settings = {settings}/>);
	}

	const value =
	{
		ShowInvoiceForm,
		ShowInvoicingSettingsForm
	}

	return (
		<InvoicesProvider>
			<Invoicing.Provider value={value}>
				{props.children}
				{modals}
			</Invoicing.Provider>
		</InvoicesProvider>
	)
}

const useInvoicing = () => React.useContext(Invoicing);

export {InvoicingProvider, useInvoicing};