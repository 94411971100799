import React from 'react';
import {Table} from 'crack-ux';

// Contexts
import {useTraductor} from '../../../../../contexts/general/Traductor';
import {useInvoicesPage} from '../../../../../contexts/pages/business/home/Invoices';
import {useInvoices} from '../../../../../contexts/collections/home/Invoices';

const InvoicesTable = (props) =>
{
	const {Translate} = useTraductor();

	const {ShowInvoiceDetails} = useInvoicesPage();

	const {invoices} = useInvoices();

	const body = React.useMemo(() =>
	(
		invoices.map(invoice =>
		{
			invoice.statusText = Translate(invoice.statusText);

			return invoice;
		})
	), [invoices, Translate]);

	return (
		<Table
			class = 'hover'
			header = 
			{[
				{
					name: 'numberText',
					text: Translate('Number')
				},
				{
					name: 'dateText',
					text: Translate('Date')
				},
				{
					name: 'descriptionText',
					text: Translate('Description')
				},
				{
					name: 'totalText',
					text: Translate('Total')
				},
				{
					name: 'statusText',
					text: Translate('Status')
				}
			]}
			body = {body}
			actions =
			{[
				{
					text: Translate('View Detail'),
					section: 'context',
					icon:
					{
						class: 'icon-clipboard'
					},
					Click: (invoice) => ShowInvoiceDetails(invoice)
				},
			]}
			searcher =
			{
				{text: Translate('Search')}
			}
			texts =
			{
				{
					noRecords:Translate('There Are Not Records To Show'),
					records:Translate('Records'),
					fields: Translate('Fields'),
					settings: Translate('Settings'),
					export: Translate('Export')
				}
			}
			filters = 
			{{
				columns:
				{
					editable: true
				},
				Apply:
				{
					text: Translate('Apply'),
					Click: () => {}
				},
				Reset:
				{
					text: Translate('Reset'),
					Click: () => {}
				}
			}}
		/>
	)
}

export {InvoicesTable};