import React from 'react';

// Context
import {useSocket} from '../../general/Socket';
import {useBusiness} from '../../general/Business';

// Reducers
import {ProductsReducer} from '../../../reducers/collections/store/Products';

const Products = React.createContext({});

const ProductsProvider = (props) =>
{
	const [state, dispatch] = React.useReducer(ProductsReducer, {products: [], code: 0});
	const [currentProduct, setCurrentProduct] = React.useState({});
	const [eventId, setEventId] = React.useState('');
	const [oldSocket, setOldSocket] = React.useState({});

	const Socket = useSocket();
	const {Fetch, business} = useBusiness();

	const room = React.useMemo(() => `store_products_${business._id}`, [business._id]);
	const unique = React.useMemo(() =>
	{
		return eventId ? `STORE_PRODUCT_${eventId}` : 'STORE_PRODUCT';
	}, [eventId]);

	React.useEffect(() =>
	{
		Fetch('/store/products/code').then(response =>
		{
			if(response.status === 200)
			{
				dispatch({type: 'SET_LATEST_PRODUCT_CODE', code: response.data});
			}
		});
	}, [Fetch]);

	React.useEffect(() =>
	{
		const events =
		[
			{
				name: `CREATE_${unique}`,
				Function: (product) =>
				{
					dispatch({type: 'CREATE_PRODUCT', product});

					if(unique === 'STORE_PRODUCT') dispatch({type: 'SET_LATEST_PRODUCT_CODE', code: state.code + 1});
				}
			},
			{
				name: `UPDATE_${unique}`,
				Function: (product) => dispatch({type: 'UPDATE_PRODUCT', product})
			},
			{
				name: `DELETE_${unique}`,
				Function: (product) => dispatch({type: 'DELETE_PRODUCT', product})
			}
		];

		if(unique !== 'STORE_PRODUCT')
		{
			events.push
			({
				name: 'CREATE_PRODUCT',
				Function: () =>
				{
					dispatch({type: 'SET_LATEST_PRODUCT_CODE', code: state.code + 1});
				}
			})
		}

		Socket.ConnectEvents(room, events, oldSocket);

		if(oldSocket.unique !== unique)
		{
			setOldSocket({room, events, unique});
		}
	}, [Socket, room, unique, oldSocket, state.code]);

	const LoadProducts = React.useCallback((products = []) =>
	{
		dispatch({type: 'LOAD_PRODUCTS', products});
	}, []);

	const CreateProduct = async (product) =>
	{
		product.code = state.code + 1;
		product._socket = room;

		delete product.status;
		delete product.pictureMiniature;

		return await Fetch('/store/product/create', product);
	}

	const UpdateProduct = async (product) =>
	{
		product._socket = room;

		delete product.status;
		delete product.pictureMiniature;

		return await Fetch('/store/product/update', product);
	}

	const DeleteProduct = async (product) =>
	{
		const data =
		{
			_id: product._id,
			_socket: room
		}

		return await Fetch('/store/product/delete', data);
	}

	const value =
	{
		code: state.code,
		products: state.products,
		currentProduct,
		SetCurrentProduct: setCurrentProduct,
		SetEventId: setEventId,
		LoadProducts,
		CreateProduct,
		UpdateProduct,
		DeleteProduct
	}

	return (
		<Products.Provider value={value}>
			{props.children}
		</Products.Provider>
	)
}

const useProducts = () => React.useContext(Products);

export {ProductsProvider, useProducts};