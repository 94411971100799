import React from 'react';
import {Section, Text, Button} from 'crack-ux';
import {Generic, Amount} from 'crack-functions';

// Contexts
import {useTraductor} from '../../../../../contexts/general/Traductor';
import {useLists, defaultListColor} from '../../../../../contexts/collections/home/Lists';

// Css
import './Funnel.css';

const funnelFactor = 15;

const Funnel = ({contacts, setStageContacts, setContactsTitle}) =>
{
	const [percentType, setPercentType] = React.useState('stage');

	const {Translate} = useTraductor();
	const {listsByName} = useLists();

	const salesFlowList = React.useMemo(() =>
	{
		let lastStageContacts = [];
		const invertedStages = [...listsByName.salesFlow.workingItems.filter(item => !item.data.sideWay)].reverse().map(item =>
		{
			const stageContacts = contacts.filter(contact => contact.lastSaleFlow === item._id);

			lastStageContacts = [...lastStageContacts, ...stageContacts];

			const newItem = {...item, stageContacts: lastStageContacts};

			return newItem;
		});

		invertedStages.push
		({
			_id: 'contacts',
			stageContacts: contacts,
			text: Translate('Contacts'),
			color: '#e6e6e6'
		});

		const baseContactsSize = contacts.length;
		let lastStageContactsSize = baseContactsSize;

		return [...invertedStages].reverse().map((item, index) =>
		{
			const stageContactsSize = item.stageContacts.length;
			const stageContactsPercentage = `${Amount.Round(lastStageContactsSize > 0 ? stageContactsSize * 100 / lastStageContactsSize : 0)}%`;
			const baseContactsPercentage = `${Amount.Round(baseContactsSize > 0 ? stageContactsSize * 100 / baseContactsSize : 0)}%`;

			const backgroundColor = item.color || defaultListColor;
			// const color = Generic.HexIsLight(backgroundColor) ? 'var(--black)' : 'var(--white)';
			const color = Generic.HexIsLight(backgroundColor) ? '#333333' : '#efefef';

			lastStageContactsSize = stageContactsSize;

			return (
				<Section 
					key = {item._id}
					class = 'funnel-stage center pointer'
					style =
					{{
						backgroundColor,
						margin: 'auto',
						width: `calc(100% - ${index * funnelFactor}px)`,
						clipPath: `polygon(0% 0%, 100% 0%, calc(100% - ${funnelFactor / 2}px) 100%, ${funnelFactor / 2}px 100%)`,
						WebkitClipPath: `polygon(0% 0%, 100% 0%, calc(100% - ${funnelFactor / 2}px) 100%, ${funnelFactor / 2}px 100%)`,
					}}
					Click = {() =>
					{
						setStageContacts(item.stageContacts);
						setContactsTitle(item.text);
					}}
				>
					<Text class='block' size={16} style={{color}}>{item.text}</Text>
					<Text class='block' type='p' style={{color}}>{Translate('Contacts')}: {stageContactsSize} ({percentType === 'stage' ? stageContactsPercentage : baseContactsPercentage})</Text>
				</Section>
			);
		})
	}, [listsByName.salesFlow, contacts, percentType, Translate, setStageContacts, setContactsTitle]);

	return (
		<Section class='crm-funnel'>
			<Section class='right' style={{paddingBottom: 10}}>
				<Button class={percentType === 'stage' ? 'background-blue white' : 'default'} style={{margin: 0}} text={Translate('Stage')} Click={() => setPercentType('stage')}/>
				<Button class={percentType === 'total' ? 'background-blue white' : 'default'} style={{margin: 0}} text={Translate('Total')} Click={() => setPercentType('total')}/>
			</Section>
			{salesFlowList}
		</Section>
	);
}

Funnel.defaultProps =
{
	contacts: [],
	setStageContacts: () => {},
	setContactsTitle: () => {}
};

export {Funnel};