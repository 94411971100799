import React from 'react';
import {Panel, Card, Box, Body, Footer, Text, Image, Icon} from 'crack-ux';
import {Amount} from 'crack-functions';

// Contexts
import {useAcquisitionsPage} from '../../../../../contexts/pages/business/store/Acquisitions';
import {useSalesPointPage} from '../../../../../contexts/pages/business/store/SalesPoint';

const ProductCard = (product) =>
{
	const {_type} = product;

	const salesPointPage = useSalesPointPage();
	const acquisitionsPage = useAcquisitionsPage();

	const currentPage = React.useMemo(() => _type === 'sales' ? salesPointPage : acquisitionsPage, [_type, salesPointPage, acquisitionsPage]);

	const {productsSelected, AddProduct, RemoveProduct, ClearProduct} = currentPage;

	const price = React.useMemo(() =>
	{
		if(_type === 'sales')
		{
			return Amount.CurrencyFormat(product.price?.sale || 0)
		}
		else
		{
			return Amount.CurrencyFormat(product.price?.purchase || 0)
		}
	}, [_type, product.price]);

	// This section is made in an expanded way on purpose to facilitate the reading of the conditions - it is always needed to return true or false
	const limited = React.useMemo(() =>
	{
		if(_type === 'sales')
		{
			if(product.quantity.current === 0)
			{
				return true;
			}
			else
			{
				if(productsSelected[product._id])
				{
					if(productsSelected[product._id].amount < product.quantity.current)
					{
						return false;
					}
					else
					{
						return true;
					}
				}
				else
				{
					return false;
				}
			}
		}
		else
		{
			return false;
		}
	}, [product, productsSelected, _type]);
	
	return (
		<Panel class='product-card'>
			<Card class={`${limited && 'limited'}`}>
				<Body class='no-padding no-border relative'>
					{
						productsSelected[product._id] &&
						<Box class='amount absolute background-green white pointer no-selected' Click={() => RemoveProduct(product)}>
							<Text>{productsSelected[product._id].amount}</Text>
						</Box>
					}
					{
						productsSelected[product._id]?.amount &&
						<Icon class='icon-cancel cancel absolute background-red white pointer' Click={() => ClearProduct(product)}/>
					}
					<Image
						class = 'pointer no-selected'
						src = {product.pictureURL}
						error = '/assets/box.png'
						width = {115}
						height = {75}
						padding = {0}
						Click = {() => !limited && AddProduct(product)} title={product.description}
					/>
				</Body>
				<Footer class='no-border'>
					<Text class='block bold' type='p' title={product.name}>{product.name}</Text>
					<Text class='block' type='p' title={price}>{price}</Text>
				</Footer>
			</Card>
		</Panel>
	)
}

export {ProductCard};