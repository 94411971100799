import React from 'react';
import {Content, Card, Header, Body, Text} from 'crack-ux';

// Views
import {InvoiceTable} from '../../../../components/tables/modules/invoicing/Invoices/Invoice';

// Contexts
import {useTraductor} from '../../../../contexts/general/Traductor';

const Invoices = () =>
{
	const {Translate} = useTraductor();

	return (
		<Content>
			<Card>
				<Header>
					<Text>{Translate('Invoices')}</Text>
				</Header>
				<Body class='no-padding' style={{height: 'calc(100vh - var(--header) - 67px)'}}>
					<InvoiceTable/>
				</Body>
			</Card>
		</Content>
	);
}

export {Invoices};