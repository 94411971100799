import React from 'react';
import {Content, Panel, Card, Header, Body, Text} from 'crack-ux';

// Contexts
import {useTraductor} from '../../../../contexts/general/Traductor';
import {useCampaigns} from '../../../../contexts/collections/crm/Campaigns';
import {useContacts} from '../../../../contexts/collections/crm/Contacts';

// Tables
import {CampaignsTable} from '../../../../components/tables/modules/crm/Campaigns/Campaigns';
import {ContactList} from '../Contacts/ContactList';

// Views
import {Funnel} from '../../../../components/views/modules/crm/Funnel/Funnel';
import {SideAway} from '../../../../components/views/modules/crm/SideAway/SideAway';

const SalesFlow = () =>
{
	const [stageContacts, setStageContacts] = React.useState([]);
	const [contactsTitle, setContactsTitle] = React.useState('');

	const {Translate} = useTraductor();

	const {currentCampaign} = useCampaigns();
	const {contacts} = useContacts();

	const filteredContacts = React.useMemo(() =>
	{
		if(!currentCampaign._id)
		{
			return [];
		}
		if(currentCampaign._id === 'global')
		{
			return contacts;
		}
		else
		{
			return contacts.filter(contact => Array.isArray(contact.tags) && contact.tags.length > 0 && currentCampaign.tags.some(campaignTag => contact.tags.includes(campaignTag)));
		}
	}, [contacts, currentCampaign._id, currentCampaign.tags]);

	React.useEffect(() =>
	{
		setStageContacts(filteredContacts);
	}, [filteredContacts]);

	React.useEffect(() =>
	{
		setContactsTitle(Translate('Contacts'));
	}, [currentCampaign.name, Translate]);

	return (
		<Content class='no-padding'>
			<Panel class='no-padding full-height' swipe='horizontal'>
				<Panel class='desktop4 full-height'>
					<Card>
						<Header>
							<Text class='bold'>{Translate('Campaigns')}</Text>
						</Header>
						<Body class='no-padding' style={{height: 'calc(100% - var(--header))'}}>
							<CampaignsTable/>
						</Body>
					</Card>
				</Panel>
				<Panel class='desktop4 full-height'>
					<Card>
						<Header>
							<Text class='bold'>{Translate('Sales Flow')}</Text>
						</Header>
						<Body class='vertical-scroll' style={{height: 'calc(100% - var(--header))', padding: '15px 50px'}}>
							<Funnel contacts={filteredContacts} setStageContacts={setStageContacts} setContactsTitle={setContactsTitle}/>
							<br/>
							<br/>
							<SideAway contacts={filteredContacts} setStageContacts={setStageContacts} setContactsTitle={setContactsTitle}/>
						</Body>
					</Card>
				</Panel>
				<Panel class='desktop4 full-height'>
					<Card>
						<Header>
							<Text class='bold'>{currentCampaign.name}: {contactsTitle}</Text>
						</Header>
						<Body class='no-padding' style={{height: 'calc(100% - var(--header))'}}>
							<ContactList contacts = {stageContacts} from='sales-flow'/>
						</Body>
					</Card>
				</Panel>
			</Panel>
		</Content>
	)
}

export {SalesFlow};