import {Text, Avatar, Content, Panel, Box, Card, Header, Button, Footer} from 'crack-ux';

// Crack-Function
import {Text as TextF} from 'crack-functions';

// Components
import {BusinessInfoForm} from '../../../../components/forms/BusinessInfo/BusinessInfo';

// Contexts
import {useTraductor} from '../../../../contexts/general/Traductor';
import {BusinessInfoProvider, useBusinessInfo} from '../../../../contexts/pages/business/home/BusinessInfo';
import {useBusiness} from '../../../../contexts/general/Business';
import {ModalProvider} from '../../../../contexts/general/Modal';
import {BusinessProvider as BusinessMainProvider, useBusiness as useMainBusiness} from '../../../../contexts/main/Business';

const BusinessInfoBody = (props) =>
{
	const {displayBusinessInfoForm} = useBusinessInfo();
	const {business} = useBusiness();
 
	if(displayBusinessInfoForm)
	{
		return(
			<BusinessInfoForm business={business} from='display-business-info-form' key='editable'/>
		)
	}
	else
	{
		return <BusinessInfoForm business={business} from='display-business-info-form' disabled={true} key='notEditable'/>
	}
}

const FooterDisplayBusinessInfoForm = () =>
{
	const {displayBusinessInfoForm, SetDisplayBusinessInfoForm} = useBusinessInfo();
	const {Translate} = useTraductor();

	if(!displayBusinessInfoForm)
	{
		return (
			<Footer class='right'>
				<Button 
					class = 'background-blue white' 
					text = {displayBusinessInfoForm ? Translate('Cancel Edit') : Translate('Edit Info')}
					Click = {()=> displayBusinessInfoForm ? SetDisplayBusinessInfoForm(false) : SetDisplayBusinessInfoForm(true)}
				/>
			</Footer>
		)
	}
}


const BusinessInfo = () =>
{
	const {Translate} = useTraductor();
	const {business, GetBusiness} = useBusiness();
	const {UpdateBusiness} = useMainBusiness();

	
	return (
		<Content class='center'>
			<Panel class='no-padding' style={{height: 'calc(100vh - var(--header) - 20px)'}} swipe='horizontal'>
				<Panel class='desktop4 center full-height'>
					<Card class='relative'>
						<Box class='full-center'>
							<Avatar 
								size='218px'
								fontSize='128px'
								src= {business?.pictureURL || TextF.Initials(business?.name || '')}
								Change= {(picture) => 
								{
									const data =
									{
										_files:
										{
											picture: [picture]
										},
										_id: business._id
									}

									UpdateBusiness(data).then(response =>
									{
										if(response.status === 200)
										{
											GetBusiness();
										}
									});
								}}
							/>
							<Text class='block bold' style={{marginTop: 35, fontSize:24}}>{business?.name}</Text>
							<Text class='block' style={{marginTop: 5, fontSize:24}}>@{business.uniqueName}</Text>
						</Box>
					</Card>
				</Panel>
				<Panel class='desktop8 full-height left'>
					<Card class='full-height'>
						<Header class='bold'>
								<Text>
									{Translate('Business Info')}    
								</Text>                                
						</Header>
						<BusinessInfoBody/>
						<FooterDisplayBusinessInfoForm/>
					</Card>
				</Panel>
			</Panel>
		</Content>
	)
}

const BusinessInfoWithProviders = () =>
{
	return (
		<ModalProvider>
			<BusinessMainProvider>
				<BusinessInfoProvider>
					<BusinessInfo/>
				</BusinessInfoProvider>
			</BusinessMainProvider>
		</ModalProvider>
	)
}

export {BusinessInfoWithProviders as BusinessInfo};