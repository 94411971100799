import React from 'react';
import {Card, Header, Body, Footer, Form, Button, Text, FileList2} from 'crack-ux';

// Context
import {useModal} from '../../../../../contexts/general/Modal';
import {useTraductor} from '../../../../../contexts/general/Traductor';
import {useTemplates} from '../../../../../contexts/collections/crm/Templates';
import {useStorages} from '../../../../../contexts/collections/storage/Storage';

// Functions
import Validations from '../../../../../objects/Validate';

const TemplateForm = (props) =>
{
	const {templateType} = props;

    const [form, setForm] = React.useState(props.template);
	const [files, setFiles] = React.useState(Array.isArray(props.template.filesAsObj) ? props.template.filesAsObj : []);
	const [messages, setMessages] = React.useState({});

    const {CloseModal} = useModal();
    const {Translate} = useTraductor();

	const {CreateTemplate, UpdateTemplate} = useTemplates();
	const {storagesById} = useStorages();

	const HandleSubmit = async () =>
	{
		const cloneForm = {...form};

		cloneForm.channel = templateType.value;
		cloneForm.files = files.map(file =>
		{
			const cloneFile = {...file};

			if(cloneFile.localFile)
			{
				cloneFile.replaceFile = true;
			}

			return cloneFile;
		});
		cloneForm._files =
		{
			wsTemplate: files.map(file => file.localFile)
		};

		const result = props.template._id ? await UpdateTemplate({...cloneForm, _id: props.template._id}) : await CreateTemplate(cloneForm);

		setMessages(Validations.ParseByField(result.validations));

		if(result.status === 200)
		{
			CloseModal();
		}
	}

	return (
		<Card>
			<Header>
				<Text class='bold'>{props.template._id ? Translate('Edit Template') : Translate('New Template')}</Text>
			</Header>
			<Body class='vertical-scroll' style={{width: '400px', maxWidth: 'calc(100vw - 40px)'}}>
				<Form
					inputs =
					{
						[
							{
								name: 'name',
								text: Translate('Template Name'),
								value: form.name,
								message: messages.name
							},
							{
								name: 'content',
								text: Translate("Content"),
								value: form.content,
								type: 'textarea',
								message: messages.content
							},
							{
								text: Translate('Files'),
								type: 'content',
								content: (
									<FileList2
										fileList = {files.map(file =>
										{
											file.src = storagesById?.[file?._id]?.publicURL;
		
											return file;
										})}
										Change = {(response) => setFiles(response.files)}
									/>
								)
							},
							{
								name: 'finishChat',
								text: Translate('Finish Chat'),
								value: form.finishChat,
								type: 'checkbox'
							}
						]                        
					}
					Change = {(response) => setForm(response)}
					Submit = {HandleSubmit}
				/>
			</Body>
			<Footer class='right'>
				<Button
					text = {Translate('Save')}
					class = 'background-blue white'
					Click = {HandleSubmit}
				/>
				<Button
					text = {Translate('Cancel')}
					Click = {CloseModal}
				/>
			</Footer>
		</Card>
	)
}

export {TemplateForm};