import React from 'react';
import {Table} from 'crack-ux';

// Contexts
import {useTraductor} from '../../../../../contexts/general/Traductor';
import {useInvoicing} from '../../../../../contexts/modules/Invoicing';
import {useInvoices} from '../../../../../contexts/collections/invoicing/Invoices';

const InvoiceTable = (props) =>
{
	const {Translate} = useTraductor();
	const {invoices} = useInvoices();
	const {ShowInvoiceForm} = useInvoicing();

	return (
		<Table
			header =
			{[
				{
					name: 'number',
					text: Translate('Code')
				},
				{
					name: 'clientName',
					text: Translate('Name')
				},
				{
					name: 'clientNit',
					text: Translate('Nit')
				},
				{
					name: 'dateTable',
					text: Translate('Date')
				},
				{
					name: 'total',
					text: Translate('Total')
				},
				{
					name: 'state',
					text: Translate('State')
				}
			]}
			body = {invoices}
			actions =
			{[
				{
					text: Translate('New Invoice'),
					class: 'background-blue white',
					section: 'grouped',
					Click: () => ShowInvoiceForm({})
				},
				{
					text: Translate('Edit Invoice'),
					icon:
					{
						class: 'icon-pencil'
					},
					section: 'context',
					//Click: (invoice) => ShowInvoiceForm(invoice)
				},
				{
					text: Translate('Delete Invoice'),
					class: 'red',
					icon:
					{
						class: 'icon-trash'
					},
					section: 'context',
					//Click: (invoice) => ShowDeleteInvoiceWarning(invoice)
				}
			]}
		/>
	)
}

export {InvoiceTable};