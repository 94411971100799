import React from 'react';
import {Section} from 'crack-ux';

// Contexts
import {useTraductor} from '../../../../contexts/general/Traductor';
import {useCRM} from '../../../../contexts/modules/CRM';
import {useContactsNotes} from '../../../../contexts/collections/crm/ContactsNotes';

// Components
import {ContactNotesTable} from '../../../../components/tables/modules/crm/ContactNotes/ContactNotes';

const NotesTab = (props) =>
{
	const {contact} = props;

	const {Translate} = useTraductor();

	const {ShowContactNoteForm, ShowDeleteNoteWarning} = useCRM();

	const {contactsNotes} = useContactsNotes();

	const notes = React.useMemo(() => contactsNotes[contact._id] || [], [contactsNotes, contact._id]);

	return (
		<Section class='full-height'>
			<ContactNotesTable
				notes = {notes}
				actions =
				{[
					{
						text: Translate('New Note'),
						class: 'background-blue white',
						section: 'grouped',
						Click: () => ShowContactNoteForm({}, {contact})
					},
					{
						text: Translate('Edit Note'),
						icon:
						{
							class: 'icon-pencil'
						},
						section: 'context',
						Click: (note) => ShowContactNoteForm(note, {contact})
					},
					{
						text: Translate('Delete Note'),
						class: 'red',
						icon:
						{
							class: 'icon-trash'
						},
						section: 'context',
						Click: (note) => ShowDeleteNoteWarning(note)
					}
				]}
				DoubleClickRow = {(note) => ShowContactNoteForm(note, {contact})}
			/>
		</Section>
	);
};

export {NotesTab};