import React from 'react';
import {Table} from 'crack-ux';

// Contexts
import {useTraductor} from '../../../../../contexts/general/Traductor';
import {useCRM} from '../../../../../contexts/modules/CRM';
import {useEventsConfig} from '../../../../../contexts/collections/scheduler/EventsConfig';
import {useEvents} from '../../../../../contexts/collections/scheduler/Events';

const EventsTable = ({contact}) =>
{
	const {Translate} = useTraductor();
	const {ShowEventConfigForm, ShowEventForm, ShowConfirmEventRecurrence} = useCRM();

	const {eventsConfig} = useEventsConfig();
	const {events, DeleteEvent} = useEvents();

	const body = React.useMemo(() =>
	{
		const Filter = a => a.crmContact === contact?._id;

		return events.filter(Filter).map(scheduledMessage =>
		{
			return scheduledMessage;
		});
	}, [events, contact]);

	const header = React.useMemo(() =>
	{
		const result =
		[
			{
				name: 'title',
				text: Translate('Title'),
				maxWidth: 240
			},
			{
				name: 'description',
				text: Translate('Description'),
				maxWidth: 300,
				title: 'description'
			},
			{
				name: 'dateText',
				text: Translate('Date')
			},
			{
				name: 'startTime',
				text: Translate('Start Time')
			},
			{
				name: 'endTime',
				text: Translate('End Time')
			},
		];

		return result;
	}, [Translate]);

	return (
		<Table
			header = {header}
			body = {body}
			actions =
			{[
				{
					text: Translate('New Event'),
					class: 'background-blue white',
					section: 'grouped',
					Click: () => ShowEventConfigForm
					({
						crmContact: contact?._id
					})
				},
				{
					text: Translate('Edit Event'),
					icon:
					{
						class: 'icon-pencil'
					},
					section: 'context',
					Click: (event) => ShowEventForm(event)
				},
				{
					text: Translate('Edit Recurrence'),
					icon:
					{
						class: 'icon-pencil'
					},
					section: 'context',
					Click: (event) =>
					{
						const foundEventConfig = eventsConfig.find(eventConfig => eventConfig._id === event.eventConfig);

						if(foundEventConfig)
						{
							ShowEventConfigForm(foundEventConfig);
						}
					}
				},
				{
					text: Translate('Delete Event'),
					class: 'red',
					icon:
					{
						class: 'icon-trash'
					},
					section: 'context',
					Click: (event) =>
					{
						const eventData =
						{
							_id: event._id,
						};

						const foundEventConfig = eventsConfig.find(eventConfig => eventConfig._id === event.eventConfig);

						if(foundEventConfig && foundEventConfig.isRecurring)
						{
							ShowConfirmEventRecurrence(eventData, {action: 'delete'});
						}
						else
						{
							DeleteEvent(eventData);
						}
					}
				},
			]}
			DoubleClickRow = {(event) => ShowEventForm(event)}
			searcher =
			{
				{text: Translate('Search')}
			}
			texts =
			{
				{
					noRecords:Translate('There Are Not Records To Show'),
					records:Translate('Records'),
					fields: Translate('Fields'),
					settings: Translate('Settings'),
					export: Translate('Export')
				}
			}
			filters = 
			{{
				columns:
				{
					editable: true
				},
				Apply:
				{
					text: Translate('Apply'),
					Click: () => {}
				},
				Reset:
				{
					text: Translate('Reset'),
					Click: () => {}
				}
			}}
		/>
	)
};

export {EventsTable};