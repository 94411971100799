import React from 'react';
import {Page, Content, Panel, Card, Section} from 'crack-ux';

// Styles
import './PageNotFound.css';

const PageNotFound = () =>
{
	return (
		<Page class='desktop'>
			<Content>
				<Panel>
					<Card>
						<Section class='center page_not_found'>
							<img src='/assets/page_not_found.avif' alt='Page Not Found'/>
						</Section>
					</Card>
				</Panel>
			</Content>
		</Page>
	)
}

export {PageNotFound};