import React from 'react';
import {Routes, Route} from 'react-router-dom';
import {Content} from 'crack-ux';

// Contexts
import {useBusiness} from '../../../../contexts/general/Business';
import {useMoves} from '../../../../contexts/collections/store/Moves';
import {ReportsPageProvider} from '../../../../contexts/pages/business/store/Reports';

// Views
import {Loading} from '../../../../components/views/Loading';

// Components
import {ReportsList} from './ReportsList';

// Reports
import {Sales} from './Sales';

const Reports = () =>
{
	const [loading, setLoading] = React.useState(true);

	const {Fetch} = useBusiness();
	const {LoadMoves} = useMoves();

	React.useEffect(() =>
	{
		const requests =
		[
			Fetch('/store/moves/read', {_where: {annulled: false} }),
		];

		Promise.all(requests).then(responses =>
		{
			if(responses.every(response => response.status === 200))
			{
				LoadMoves(responses[0].data || []);

				setLoading(false);
			}
		});
	}, [Fetch, LoadMoves]);

	if(loading)
	{
		return <Loading/>
	}
	else
	{
		return (
			<Content class='store-reports no-padding'>
				<Routes>
					<Route path='/sales' exact={true} element={<Sales/>}/>
					<Route path='/' element={<ReportsList/>}/>
				</Routes>
			</Content>
		)
	}
}

const ReportsWithProviders = () =>
{
	return (
		<ReportsPageProvider>
			<Reports/>
		</ReportsPageProvider>
	)
}

export {ReportsWithProviders as Reports};