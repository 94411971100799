import {Generic} from 'crack-functions';
import {Box, Icon} from 'crack-ux';
import moment from 'moment';

const icons =
{
	system: 'icon-laptop',
	chat: 'icon-chat',
	calendar: 'icon-calendar-empty'
};

const SetNotification = (notification) =>
{
	notification.viewFlag = !!notification.viewDate;
	notification.sentDate = new Date(notification.sentDate);
	notification.sentDateText = moment(notification.sentDate).format('DD/MM/YYYY HH:mm');
	notification.iconsComponent = (
		<Box class='float-left'>
			{icons[notification.attributes?.icon] && <Icon class={`${icons[notification.attributes.icon]} gray`} style={{fontSize: 12}}/>}
		</Box>
	);

	return notification;
}

const NotificationsReducer = (state = {}, action) =>
{
	switch (action.type)
	{
		case 'LOAD_NOTIFICATIONS':
			return {
				...state,
				notifications: action.notifications.map(notification => SetNotification(notification))
			}

		case 'CREATE_NOTIFICATION':
			return {
				...state,
				notifications: [SetNotification(action.notification), ...state.notifications],
			}

		case 'MULTIPLE_UPDATE_NOTIFICATION':
			const notificationsById = Generic.ArrayToObjectByIdentifier(action.notifications);

			return {
				...state,
				notifications: state.notifications.map(notification => notificationsById[notification._id] ? SetNotification(notificationsById[notification._id]) : notification)
			}

		default:
			return state;
	}
}

export {NotificationsReducer, SetNotification};