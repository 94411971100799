import React from 'react';
import {Table} from 'crack-ux';

// Contexts
import {useTraductor} from '../../../../../contexts/general/Traductor';
import {useCredits} from '../../../../../contexts/collections/home/Credits';

const creditsTypes =
{
	'0': 'Deposit',
	'1': 'Payment',
	'2': 'Bond',
	'3': 'Refund',
};

const CreditsTable = (props) =>
{
	const {Translate} = useTraductor();

	const {credits} = useCredits();

	const body = React.useMemo(() =>
	(
		credits.map(credit =>
		{
			credit.typeText = Translate(creditsTypes[credit.type] || '');

			return credit;
		})
	), [credits, Translate]);

	return (
		<Table
			class = 'hover'
			header = 
			{[
				{
					name: 'description',
					text: Translate('Description')
				},
				{
					name: 'typeText',
					text: Translate('Type')
				},
				{
					name: 'amountText',
					text: Translate('Amount')
				},
				{
					name: 'dateText',
					text: Translate('Date')
				}
			]}
			body = {body}
			actions = {[]}
			searcher =
			{
				{text: Translate('Search')}
			}
			texts =
			{
				{
					noRecords:Translate('There Are Not Records To Show'),
					records:Translate('Records'),
					fields: Translate('Fields'),
					settings: Translate('Settings'),
					export: Translate('Export')
				}
			}
			filters = 
			{{
				columns:
				{
					editable: true
				},
				Apply:
				{
					text: Translate('Apply'),
					Click: () => {}
				},
				Reset:
				{
					text: Translate('Reset'),
					Click: () => {}
				}
			}}
		/>
	)
}

export {CreditsTable};