import React from 'react';
import {Table} from 'crack-ux';

// Contexts
import {useTraductor} from '../../../../../contexts/general/Traductor';
import {useStore} from '../../../../../contexts/modules/Store';
import {useCustomers} from '../../../../../contexts/collections/store/Customers';

const CustomersTable = () =>
{
	const {Translate} = useTraductor();

	const {customers} = useCustomers();
	const {ShowCustomerForm, ShowDeleteCustomerWarning, ShowMoves} = useStore();

	return (
		<Table
			class = 'hover pointer'
			header = 
			{[
				{
					name: 'codeText',
					text: Translate('Code')
				},
				{
					name: 'name',
					text: Translate('Name')
				},
				{
					name: 'nit',
					text: Translate('DNI/NIT')
				}
			]}
			body = {customers}
			actions =
			{[
				{
					text: Translate('New Customer'),
					class: 'background-blue white',
					section: 'grouped',
					Click: () => ShowCustomerForm()
				},
				{
					text: Translate('List of Sales'),
					section: 'context',
					icon:
					{
						class: 'icon-clipboard'
					},
					Click: (customer) => ShowMoves({type: 1, contact: customer})
				},
				{
					text: Translate('Edit Customer'),
					section: 'context',
					icon:
					{
						class: 'icon-pencil'
					},
					Click: (customer) => ShowCustomerForm(customer)
				},
				{
					text: Translate('Delete Customer'),
					class: 'red',
					section: 'context',
					icon:
					{
						class: 'icon-trash'
					},
					Click: (customer) => ShowDeleteCustomerWarning(customer)
				}
			]}
			exportable = {false}
			searcher =
			{
				{text: Translate('Search')}
			}
			texts =
			{
				{
					noRecords:Translate('There Are Not Records To Show'),
					records:Translate('Records'),
					fields: Translate('Fields'),
					settings: Translate('Settings')
				}
			}
			filters = 
			{{
				columns:
				{
					editable: true
				},
				Apply:
				{
					text: Translate('Apply'),
					Click: () => {}
				},
				Reset:
				{
					text: Translate('Reset'),
					Click: () => {}
				}
			}}
		/>
	)
}

export {CustomersTable};