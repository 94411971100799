import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Card, Header, Body, Footer, Form, Button, Text, Icon, Section} from 'crack-ux';

// Context
import {useModal} from '../../../contexts/general/Modal';
import {useBusinessInfo} from '../../../contexts/pages/business/home/BusinessInfo';
import {useBusiness} from '../../../contexts/main/Business';
import {useTraductor} from '../../../contexts/general/Traductor';
import {useBusiness as useGeneralBusiness} from '../../../contexts/general/Business';

// Static data
import phoneCodes from '../../../static-data/PhoneCodes.json'

// Objects
import Validate from '../../../objects/Validate';

// CSS
import './BusinessInfo.css';

const GetDefaultPhone = () =>
({
	country: "591",
	area: null,
	number: ''
});

const GetDefaultEmail = () =>
({
	email: ''
});

const Container = (props) =>
{
	const {children, from} = props;

	if(from === 'display-business-info-form')
	{
		return <Section>{children}</Section>;
	}
	else
	{
		return <Card>{children}</Card>;
	}
}

const FormHeader = (props) =>
{
	const {from} = props;
	const {Translate} = useTraductor();
	if(from !== 'display-business-info-form')
	{
		return (
			<Header>
				<Text class='bold'>{Translate('Business Info')}</Text>
			</Header>
		)
	}	
}

const BusinessInfoForm = (props) =>
{
	const {business, from, disabled} = props;

	const [form, setForm] = React.useState(
	{
		...business,
		email: business?.emails?.[0]?.email
	});
	const [messages, setMessages] = React.useState({});
	const [location, setLocation] = React.useState(business.location || {});
	const [phones, setPhones] = React.useState(Array.isArray(business?.phones) ? [...business.phones.map(phone => ({...phone})), GetDefaultPhone()] : [GetDefaultPhone()]);
	const [emails, setEmails] = React.useState(Array.isArray(business?.emails) ? [...business.emails.map(email => ({...email})), GetDefaultEmail()] : [GetDefaultEmail()]);

	const navigate = useNavigate();
	const {GetBusiness} = useGeneralBusiness();
	const {CloseModal} = useModal();
	const {ShowLocationForm, displayBusinessInfoForm,SetDisplayBusinessInfoForm} = useBusinessInfo();
	const {UpdateBusiness} = useBusiness();
	const {Translate} = useTraductor();	 

	React.useEffect(() =>
	{
		setForm(business);
	}, [business]);

	React.useEffect(() =>
	{
		setPhones(Array.isArray(business?.phones) ? [...business.phones.map(phone => ({...phone})), GetDefaultPhone()] : [GetDefaultPhone()]);
	}, [business?.phones]);

	React.useEffect(() =>
	{
		setEmails(Array.isArray(business?.emails) ? [...business.emails.map(email => ({...email})), GetDefaultEmail()] : [GetDefaultEmail()]);
	}, [business?.emails]);

	const HandleSubmit = async () =>
	{
		const newForm = {...form};
		const uniqueNameValidation = Validate.ValidateUniqueName(newForm.uniqueName);

		setMessages({uniqueName: uniqueNameValidation});


		newForm.phones = phones.filter(phone => (phone.number !== '' && phone.number !== undefined && phone.number !== null)); 

		newForm.emails = emails.filter(email => (email.email !== '' && email.email !== undefined && email.email !== null));
		
		newForm._id = business._id;
		newForm.location = location;

		newForm.uniqueName = newForm.uniqueName.toLowerCase();

		if(uniqueNameValidation === null)
		{
			const result = await UpdateBusiness(newForm);
			
			setMessages(Validate.ParseByField(result.validations));

			if(result.status === 200)
			{
				if(business.uniqueName !== result.data.uniqueName)
				{
					navigate(`/b/${result.data.uniqueName}/home/business-info`);
				}
				else
				{
					GetBusiness();
				}
				displayBusinessInfoForm ? SetDisplayBusinessInfoForm(false) : CloseModal()
			}
		}
	}
	const emailInputs = React.useMemo(() =>
	{
		const inputs = [];
		for(let i = 0; i < emails.length; i++)
		{
			inputs.push
			({
				name: `email-${i}`,
				class: 'desktop11',
				text: Translate("Email"),
				value: emails[i]?.email,
				Change: (value) =>
				{
					const cloneEmails = [...emails];
					cloneEmails[i].email = value;
					setEmails(cloneEmails);
				},
				disabled
			});
			
			if(i === emails.length - 1)
			{
				inputs.push
				({
					type: 'content',
					class: `desktop1 business-info-input-icon-emails ${disabled ? 'disabled' : ''}`,
					content: 
					<Icon
						class= 'icon-plus pointer blue'
						Click={() =>
						{
							const cloneEmails = [...emails];

							cloneEmails.push(GetDefaultEmail());

							setEmails(cloneEmails);
						}}
					/>
				})
			}
			else
			{
				inputs.push
				({
					type: 'content',
					class: `desktop1 business-info-input-icon-emails ${disabled ? 'disabled' : ''}`,
					content: 
					<Icon
						class= 'icon-minus pointer red' 
						Click={() =>
						{
							const cloneEmails = [...emails];
		
							cloneEmails.splice(i, 1);
		
							setEmails(cloneEmails);
						}}
					/>
				})
			}
		}
		return inputs;
	}, [emails, Translate, disabled]);

	const phoneInputs = React.useMemo(() =>
	{
		const inputs = [];

		for(let i = 0; i < phones.length; i++)
		{
			inputs.push
			({
				name: `phone-${i}-country`,
				text: Translate("Country"),
				type: 'select',
				class: 'desktop3',
				value: String(phones[i]?.country) || "591",
				options: phoneCodes,
				Change: (option) =>
				{
					const clonePhones = [...phones];
					clonePhones[i].country = option.value;
					setPhones(clonePhones);
				},
				disabled
			});

			inputs.push
			({
				name: `phone-${i}-number`,
				text: Translate("Phones"),
				class: 'desktop8 business-info-input',
				value: phones[i]?.number,
				Change: (value) =>
				{
					const clonePhones = [...phones];
					clonePhones[i].number = value;
					setPhones(clonePhones);
				},
				disabled
			});

			if(i === phones.length - 1)
			{
				inputs.push
				({
					type: 'content',
					class: `desktop1 business-info-input-icon-phones ${disabled ? 'disabled' : ''}`,
					content: 
					<Icon
						class= 'icon-plus pointer blue'
						Click={() =>
						{
							const clonePhones = [...phones];
							clonePhones.push(GetDefaultPhone());
							setPhones(clonePhones);
						}}
					/>
				})
			}
			else
			{
				inputs.push
				({
					type: 'content',
					class: `desktop1 business-info-input-icon-phones ${disabled ? 'disabled' : ''}`,
					content: 
					<Icon
						class= 'icon-minus pointer red' 
						Click={() =>
						{
							const clonePhones = [...phones];
							clonePhones.splice(i, 1);
							setPhones(clonePhones);
						}}
					/>
				})
			}
		}
		return inputs;
	}, [phones, Translate, disabled]);

	const bodyWidth = from === 'display-business-info-form' ? '100%' : '500px';
	const bodyHeight = from === 'display-business-info-form' ? 'calc(100vh - var(--header) * 2 - 67px - 36px)' : '';

	return (
		<Container from={from}>
			<FormHeader from={from}/>
			<Body class='vertical-scroll' style={{width: bodyWidth, height: bodyHeight}}>
				<Form
					inputs =
					{
						[
							{
								name: 'name',
								text: Translate('Name'),
								value: form.name,
								message: messages.name,
								disabled
							},
							{
								name: 'uniqueName',
								text: Translate("Unique Name"),
								value: form.uniqueName,
								Change: () => setMessages({...messages, uniqueName: null}),
								message: messages.uniqueName,
								disabled
							},
							...emailInputs,
							...phoneInputs,
							{
								name: 'locationText',
								text: Translate("Location"),
								value: location.address,
								icon:
								{
									class: `icon-location background-blue white pointer ${disabled ? 'disabled' : ''}`,
									Click: () => ShowLocationForm
									({
										location: location,
										GetLocation: (newLocation) => setLocation(newLocation)
									})
								},
								disabled: true
							}
						]                        
					}
					Change = {(response) => setForm(response)}
					Submit = {HandleSubmit}
				/>
			</Body>
			{
				!disabled ?
				<Footer class='right'>
					<Button
						text = {Translate('Save')}
						class = 'background-blue white'
						Click = {HandleSubmit}
					/>
					<Button
						text = {Translate('Cancel')}
						Click = {() =>{displayBusinessInfoForm ? SetDisplayBusinessInfoForm(false) : CloseModal()}}
					/>
				</Footer> :
				<></>
			}
		</Container>
	) 
	
}

export {BusinessInfoForm};