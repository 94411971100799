import React from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';

// Contexts
import {useBusiness} from '../../../contexts/general/Business';
import {useModules} from '../../../contexts/main/Modules';
import {InvoicingProvider} from '../../../contexts/modules/Invoicing';

// Views
import {Loading} from '../../../components/views/Loading';

// Module Section
import {Invoices} from './Invoices';
import {InvoicingSettings} from './InvoicingSettings';
import {useInvoices} from '../../../contexts/collections/invoicing/Invoices';
import {ModalProvider} from '../../../contexts/general/Modal';

const Invoicing = () =>
{
	const [loading, setLoading] = React.useState(true);

	const {Fetch} = useBusiness();

	const {modulesByName} = useModules();
	const {LoadInvoices} = useInvoices();
	
	React.useEffect(() =>
	{
		Fetch('/invoicing/invoices/read').then(response =>
		{
			if(response.status === 200)
			{
				LoadInvoices(response.data);
				setLoading(false);
			}	
		});
	}, [Fetch, LoadInvoices]);

	const menu = React.useMemo(() => modulesByName?.invoicing?.menu || [], [modulesByName.invoicing]);
	const availableRoutes = React.useMemo(() =>
	{
		const result = [];
		const routesComponents =
		{
			'invoices': Invoices,
			'settings': InvoicingSettings,
		};

		menu.forEach(item =>
		{
			const Page = routesComponents[item.route];

			if(Page)
			{
				result.push(<Route key={item.route} path={`/${item.route}`} element={<Page/>}/>)
			}
		});

		return result;
	}, [menu]);

	if(loading)
	{
		return <Loading/>
	}
	else
	{
		return (
			<Routes>
				{availableRoutes}
				<Route path='*' element={<Navigate to={`./../home/${modulesByName.home?.menu?.[0]?.route}`}/>}/>
			</Routes>
		)
	}
}

const InvoicingWithProviders = () =>
{
	return (
		<ModalProvider>
			<InvoicingProvider>
				<Invoicing/>
			</InvoicingProvider>
		</ModalProvider>
	)
}

export {InvoicingWithProviders as Invoicing};