import React from 'react';
import {Table, Avatar} from 'crack-ux';
import {Generic, Amount} from 'crack-functions';

// Contexts
import {useTraductor} from '../../../../../contexts/general/Traductor';
import {useStorages} from '../../../../../contexts/collections/storage/Storage';
import {useProducts} from '../../../../../contexts/collections/store/Products';
import {useCategories} from '../../../../../contexts/collections/store/Categories';

const MoveDetailTable = (props) =>
{
	const {move} = props;

	const {Translate} = useTraductor();

	const {storagesById} = useStorages();
	const {products: allProducts} = useProducts();
	const {categories} = useCategories();

	const categoriesObj = React.useMemo(() => Generic.ArrayToObjectByIdentifier(categories), [categories]);

	const products = React.useMemo(() =>
	{
		const moveDetailProducts = [];

		allProducts.forEach(product =>
		{
			const productItem = move.details.find(detail => detail.product === product._id);

			if(productItem)
			{
				moveDetailProducts.push(
				{
					...product,
					detailQuantity: productItem.quantity,
					detailPrice: Amount.CurrencyFormat(productItem.price),
					detailTotal: Amount.CurrencyFormat(productItem.total),
				})
			}
		});

		return moveDetailProducts;
	},  [move, allProducts]);

	const productsPictures = React.useMemo(() =>
	{
		const result = {};

		products.forEach(product =>
		{
			const url = storagesById?.[product?.pictures?.[0]?.file]?.publicURL || '/assets/box.png';

			result[product._id] = <Avatar class='background-white' src={url} error={'/assets/box.png'} size={50} round={false}/>
		});

		return result;
	}, [products, storagesById]);

	return (
		<Table
			class = 'hover pointer'
			header =
			{[
				{
					name: 'pictureMiniature',
					text: Translate('Picture'),
					width: 80
				},
				{
					name: 'codeText',
					text: Translate('Code'),
					width: 90
				},
				{
					name: 'name',
					text: Translate('Product Name'),
					width: 200
				},
				{
					name: 'categoriesText',
					text: Translate('Categories')
				},
				{
					name: 'detailQuantity',
					text: Translate('Quantity'),
					width: 82
				},
				{
					name: 'detailPrice',
					text: Translate('Price'),
					width: 135
				},
				{
					name: 'detailTotal',
					text: Translate('Total'),
					width: 135
				},
			]}
			body = {products.map(product =>
			{
				const categoriesFiltered = product.categories ? product.categories.map(category => categoriesObj[category].name) : [];
				product.categoriesText = categoriesFiltered.join(', ');

				if(productsPictures[product._id]) product.pictureMiniature = productsPictures[product._id];

				return product;
			})}
			actions = {false}
			searcher = {false}
			footer = {false}
			pager =
			{{
				rows: 1000
			}}
		/>
	)
}

MoveDetailTable.defaultProps =
{
	move: {}
}

export {MoveDetailTable};