import moment from 'moment';

const GetFilterSentContacts = scheduledMessage =>
{
	let result = '';

	if(scheduledMessage.sent)
	{
		result = `${scheduledMessage.contacts?.length || 0}/${scheduledMessage.recipients?.length || 0}`;
	}

	return result;
}

const SetScheduledMessage = (scheduledMessage) =>
{
	scheduledMessage.sentText = scheduledMessage.sent ? 'Yes' : 'No';
	scheduledMessage.dateText = moment(scheduledMessage.date).format('DD/MM/YYYY hh:mm a');
	scheduledMessage.hourText = moment(scheduledMessage.date).format('hh:mm a');
	scheduledMessage.filterSentContactsText = GetFilterSentContacts(scheduledMessage);

	if(Array.isArray(scheduledMessage.files))
	{
		scheduledMessage.filesAsObj = scheduledMessage.files.map(file =>
		({
			_id: file
		}));
	}

	return scheduledMessage;
}

const ScheduledMessagesReducer = (state = {}, action) =>
{
	switch (action.type)
	{
		case 'LOAD_SCHEDULED_MESSAGES':
			return {
				...state,
				scheduledMessages: action.scheduledMessages.map(scheduledMessage => SetScheduledMessage(scheduledMessage))
			}

		case 'CREATE_SCHEDULED_MESSAGE':
			return {
				...state,
				scheduledMessages: [...state.scheduledMessages, SetScheduledMessage(action.scheduledMessage)],
			}

		case 'UPDATE_SCHEDULED_MESSAGE':
			return {
				...state,
				scheduledMessages: state.scheduledMessages.map(scheduledMessage => scheduledMessage._id === action.scheduledMessage._id ? SetScheduledMessage(action.scheduledMessage) : scheduledMessage)
			}

		case 'DELETE_SCHEDULED_MESSAGE':
		{
			return {
				...state,
				scheduledMessages: state.scheduledMessages.filter(scheduledMessage => scheduledMessage._id !== action.scheduledMessage._id)
			}
		}

		default:
			return state;
	}
}

export {ScheduledMessagesReducer};