import React from "react";

const Acquisitions = React.createContext({});

const AcquisitionsPageProvider = (props) =>
{
	const [provider, setProvider] = React.useState({});
	const [productsSelected, setProductsSelected] = React.useState({});

	const AddProduct = React.useCallback((product) =>
	{
		const cloneProductsSelected = {...productsSelected};

		if(cloneProductsSelected[product._id])
		{
			cloneProductsSelected[product._id].amount++;
		}
		else
		{
			cloneProductsSelected[product._id] =
			{
				...product,
				amount: 1,
				purchasePrice: product.price.purchase
			}
		}

		setProductsSelected(cloneProductsSelected);
	}, [productsSelected]);

	const RemoveProduct = React.useCallback((product) =>
	{
		if(productsSelected[product._id]?.amount)
		{
			const cloneProductsSelected = {...productsSelected};

			if(cloneProductsSelected[product._id].amount > 1)
			{
				cloneProductsSelected[product._id].amount--;
			}
			else
			{
				delete cloneProductsSelected[product._id];
			}

			setProductsSelected(cloneProductsSelected);
		}
	}, [productsSelected]);

	const ClearProduct = React.useCallback((product) =>
	{
		const cloneProductsSelected = {...productsSelected};
		delete cloneProductsSelected[product._id];

		setProductsSelected(cloneProductsSelected);
	}, [productsSelected]);

	const UpdatePrice = (product, price) =>
	{
		if(productsSelected[product._id])
		{
			const cloneProductsSelected = {...productsSelected};
			cloneProductsSelected[product._id].purchasePrice = price;
	
			setProductsSelected(cloneProductsSelected);
		}
	}

	const UpdateAmount = (product, amount) =>
	{
		if(productsSelected[product._id])
		{
			const cloneProductsSelected = {...productsSelected};
			cloneProductsSelected[product._id].amount = amount; 

			setProductsSelected(cloneProductsSelected);
		}
	}

	const ClearAll = () =>
	{
		setProvider({});
		setProductsSelected({});
	}

	const value =
	{
		provider,
		productsSelected,
		AddProduct,
		RemoveProduct,
		ClearProduct,
		UpdatePrice,
		UpdateAmount,
		SetProvider: setProvider,
		ClearAll
	}

	return (
		<Acquisitions.Provider value={value}>
			{props.children}
		</Acquisitions.Provider>
	)
}

const useAcquisitionsPage = () => React.useContext(Acquisitions);

export {AcquisitionsPageProvider, useAcquisitionsPage}