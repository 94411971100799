import React from "react";

// Contexts
import {useBusiness} from "../general/Business";

const WhatsappSession = React.createContext({});

const WhatsappSessionProvider = (props) =>
{
	const {Fetch} = useBusiness();

	const LogIn = async (phone) =>
	{
		return Fetch('/whatsapp/login', phone);
	}

	const LogOut = async (phone) =>
	{
		return Fetch('/whatsapp/logout', phone);
	}

	const CheckStatus = async (phone) =>
	{
		return Fetch('/whatsapp/check-status', phone);
	}
	
	const value =
	{
		LogIn,
		LogOut,
		CheckStatus
	}

	return <WhatsappSession.Provider value={value} {...props}/>;
}

const useWhatsappSession = () => React.useContext(WhatsappSession);

export {WhatsappSessionProvider, useWhatsappSession};