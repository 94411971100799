import React from 'react';

// Context
import {useSocket} from '../general/Socket';
import {useBusiness} from '../general/Business';

// Reducers
import {NotificationsReducer} from '../../reducers/main/Notifications';

// Contexts
import {useStaffSession} from '../general/StaffSession';

const NotificationsContext = React.createContext({});

const NotificationsProvider = (props) =>
{
	const [state, dispatch] = React.useReducer(NotificationsReducer, {notifications: []});
	const [oldSocket, setOldSocket] = React.useState({});
	const [date, setDate] = React.useState(new Date());

	const Socket = useSocket();
	const {business, Fetch} = useBusiness();
	const {member} = useStaffSession();

	const room = React.useMemo(() => `notifications_${business._id}`, [business._id]);
	const unique = React.useMemo(() =>
	{
		return member._id ? `NOTIFICATION_${member._id}` : 'NOTIFICATION';
	}, [member._id]);

	React.useEffect(() =>
	{
		let lastMinute = (new Date()).getMinutes();
		const timer = setInterval(() =>
		{
			const currentTime = new Date();

			if(lastMinute !== currentTime.getMinutes())
			{
				lastMinute = currentTime.getMinutes();

				setDate(currentTime);
			}
		}, 1000);

		return () => clearInterval(timer);
	}, []);

	React.useEffect(() =>
	{
		if(business._id && member._id)
		{
			Fetch('/notifications/read', {_where: {staff: member._id}}).then(response =>
			{
				if(response.status === 200)
				{
					dispatch({type: 'LOAD_NOTIFICATIONS', notifications: response.data || []});
				}
			});
		}
	}, [Fetch, business._id, member._id]);

	React.useEffect(() =>
	{
		const events =
		[
			{
				name: `CREATE_${unique}`,
				Function: (notification) =>
				{
					console.log('aaaaa create', notification);
					dispatch({type: 'CREATE_NOTIFICATION', notification});
				}
			},
			{
				name: `MULTIPLE_UPDATE_${unique}`,
				Function: (notifications) =>
				{
					console.log('aaaaa u', notifications);
					dispatch({type: 'MULTIPLE_UPDATE_NOTIFICATION', notifications})
				}
			}
		];

		Socket.ConnectEvents(room, events, oldSocket);

		if(oldSocket.unique !== unique)
		{
			setOldSocket({room, events, unique});
		}
	}, [Socket, room, unique, oldSocket]);

	const workingNotifications = React.useMemo(() =>
	{
		return state.notifications.filter(notification => notification.sentDate <= date).sort((a, b) => b.sentDate.getTime() - a.sentDate.getTime());
	}, [state.notifications, date]);

	const notSeen = React.useMemo(() =>
	{
		return workingNotifications.reduce((cnt, notification) => (cnt + (notification.viewFlag ? 0 : 1)), 0);
	}, [workingNotifications]);

	const ViewNotifications = React.useCallback(async () =>
	{
		if(notSeen > 0)
		{
			const data = {_socket: room}
	
			return await Fetch('/notifications/view', data);
		}
	}, [Fetch, room, notSeen]);

	// const PushSubscription = React.useCallback(() =>
	// {

	// }, []);

	const value =
	{
		code: state.code,
		notifications: workingNotifications,
		notSeen,
		ViewNotifications
	}

	return <NotificationsContext.Provider value={value} {...props}/>
}

const useNotifications = () => React.useContext(NotificationsContext);

export {NotificationsProvider, useNotifications};