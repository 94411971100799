import React from 'react';
import {Link} from 'react-router-dom';
import {Avatar, Card, Panel, Header, Box, Button, Text} from 'crack-ux';
import {Text as TextF} from 'crack-functions';

// Components
import {Loading} from '../../../../src/components/views/Loading';

// Contexts
import {useSession} from '../../../contexts/general/Session';
import {BusinessProvider, useBusiness} from '../../../contexts/pages/personal/Business';
import {BusinessProvider as BusinessProviderMain} from '../../../contexts/main/Business';
import {useTraductor} from '../../../contexts/general/Traductor';
import {maxUnpaidInvoices} from '../../../contexts/collections/home/Invoices';

import './Business.css';

const BusinessSection = (props) =>
{
	const {session} = useSession();
	const {ShowBusinessForm} = useBusiness();
	const {Translate} = useTraductor();

	return (
		<Panel class='no-padding'>
			<Panel>
				<Card>
					<Header class='no-border'>
						<Text class='semi-bold'>{Translate('Business')}</Text>
						<Box class='float-right center' style={{marginTop: '-9px'}}>
							<Button 
								class = 'background-blue white' 
								text = {Translate('New Business')}
								Click = {ShowBusinessForm}
							/>
						</Box>
					</Header>
				</Card>
			</Panel>
				{session.user.business.filter(business => business?.uniqueName).map((business, index) =>
				{
					const disabled = Array.isArray(business.unpaidInvoices) && business.unpaidInvoices.length > maxUnpaidInvoices;
					const classList = disabled ? 'pointer' : 'pointer';
					// const unpaidIndicator = disabled && <Text size={10} class='red unpaid-indicator'>{business.unpaidInvoices.length} {Translate(business.unpaidInvoices.length > 1 ? 'Unpaid Invoices' : 'Unpaid Invoice')}</Text>
					const defaultHomePage = disabled ? 'billings' : 'dashboard';

					return (
						<Panel key={index} class={`${classList} desktop4`}>
							<Link to={`/b/${business.uniqueName}/home/${defaultHomePage}`}>
								<Card class='pointer'>
									<Panel class='relative'>
										<Box class='desktop3 mobile2 center' style={{verticalAlign: 'middle', padding: 5}}>
											<Avatar 
												src = {business.pictureURL || '/assets/business.png'}
												size = {45}
												alt ='business-logo' 
												error={TextF.Initials(business.name)}
											/>
										</Box>
										<Box class='desktop9 mobile10 left center gray' style={{verticalAlign: 'middle', padding: 5}}>
											<Text class='block bold'>{business.name}</Text>
										</Box>
										{/* {unpaidIndicator} */}
									</Panel>
								</Card>
							</Link>
						</Panel>
					)
				})}
		</Panel>
	)
}

const Business = () =>
{
	const [loading, setLoading] = React.useState(true);

	const {session} = useSession();

	React.useEffect(() =>
	{
		if(session.user.business)
		{
			setLoading(false);
		}
	}, [session]);

	if(loading)
	{
		return (
			<Loading/>
		)
	}
	else
	{
		return (
			<BusinessSection/>
		);
	}
}

const BusinessWithProviders = () =>
{
	return (
		<BusinessProviderMain>
			<BusinessProvider>
				<Business/>
			</BusinessProvider>
		</BusinessProviderMain>
	)
}

export {BusinessWithProviders as Business};