import React from 'react';
import {Card, Header, Body, Footer, Text, Button} from 'crack-ux';

// Contexts
import {useTraductor} from '../../../../contexts/general/Traductor';
import {useModal} from '../../../../contexts/general/Modal';
import {useContactsNotes} from '../../../../contexts/collections/crm/ContactsNotes';

const DeleteNote = (props) =>
{
	const {note} = props;

	const {Translate} = useTraductor();
	const {CloseModal} = useModal();

	const {DeleteNote} = useContactsNotes();

	const HandleDeleteNote = () =>
	{
		DeleteNote(note).then(response =>
		{
			if(response.status === 200)
			{
				CloseModal();
			}
		});
	}

	return (
		<Card>
			<Header>
				<Text class='bold'>{Translate('Delete Note')}</Text>
			</Header>
			<Body>
				<Text>{Translate('Are you sure you want to delete next note?')}</Text>
				<Text class='block'>{note.title}</Text>
			</Body>
			<Footer class='right'>
				<Button class='background-red white' text={Translate('Continue')} Click={HandleDeleteNote}/>
				<Button text={Translate('Cancel')} Click={CloseModal}/>
			</Footer>
		</Card>
	)
}

export {DeleteNote};