import React from "react";
import {Tabs} from 'crack-ux';

// Contexts
import {useTraductor} from "../../../../contexts/general/Traductor";

// Tables
import {ScheduledMessagesTable} from "../../../../components/tables/modules/crm/ScheduledMessages/ScheduledMessages";

const BirthdayTabs = () =>
{
	const {Translate} = useTraductor();

	return (
		<Tabs
			tabs =
			{[
				{
					name: Translate('Sent'),
					content: <ScheduledMessagesTable historical = {true}/>
				},
				{
					name: Translate('Scheduled'),
					content: <ScheduledMessagesTable base = {true}/>
				},
			]}
		/>
	);
}

export {BirthdayTabs};