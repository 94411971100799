import React from 'react';
import {Content, Body, Panel, Header, Text, List, Card} from 'crack-ux';

// Contexts
import {useTraductor} from '../../../../contexts/general/Traductor';
import {FilesPageProvider, useFilesPage} from '../../../../contexts/pages/business/storage/Files';

// Tables
import {Documents} from '../../../../components/tables/modules/storage/Documents/Documents';

// Static Data
const filesModules = require('../../../../static-data/FilesModules.json');

const Files = () =>
{
	const {Translate} = useTraductor();
	const {module, SetModule} = useFilesPage();

	return (
		<Content>
			<Panel class='no-padding' style={{height: 'calc(100vh - var(--header) - 20px)'}}>
				<Panel class='full-height desktop3'>
					<Card>
						<Header>
							<Text class='bold'>{Translate('Folders')}</Text>
						</Header>
						<Body class='no-padding'>
							<List
								class = 'hover'
								currentClass = 'background-blue white'
								current = {module.value}
								options = {filesModules.filter(item => !item.hidden).map(item =>
								{
									return {
										identifier: item.value,
										text: Translate(item.text),
										Click: () => SetModule(item)
									}
								})}
							/>
						</Body>
					</Card>
				</Panel>
				<Panel class='full-height desktop9'>
					<Card>
						<Documents module={module}/>
					</Card>
				</Panel>
			</Panel>
		</Content>
	);
}

const FilesWithProviders = () =>
{
	return (
		<FilesPageProvider>
			<Files/>
		</FilesPageProvider>
	)
};

export {FilesWithProviders as Files};