import React from 'react';
import {Tabs} from 'crack-ux';

// Contexts
import {useTraductor} from '../../../../contexts/general/Traductor';

// Components
import {Permissions} from './Permissions';

const MemberTabs = (props) =>
{
	const {Translate} = useTraductor();

	return(
		<Tabs
			tabs=
			{[
				{
					name: Translate('Permissions'),
					content: <Permissions/>
				},
			]}
		/>
	)
}

export {MemberTabs};