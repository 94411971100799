import {Routes, Route} from 'react-router-dom';
import {Page} from 'crack-ux';

// Pages
import {Catalogue} from './Catalogue/Catalogue';

const Business = () =>
{
	return (
		<Page>
			<Routes>
				<Route path='/catalog' element={<Catalogue/>}/>
			</Routes>
		</Page>
	)
}

export {Business};