import React from 'react';
import {Generic} from 'crack-functions';

// Context
import {useSocket} from '../../general/Socket';
import {useBusiness} from '../../general/Business';

// Reducers
import {ListsReducer} from '../../../reducers/collections/Lists';

const defaultListColor = '#1889d4';
const listsWithColor =
[
	'salesFlow',
	'eventTypes',
];

const ListsContext = React.createContext({});

/**
 * This function will generate some util lists such as (select options, items by ids)
 * @param {Array} items An array of items
 * @returns {Object}
 */
const GetItemsLists = (items) =>
{
	const customItemsLists =
	{
		selectOptions: [],
		itemsById: {},
		workingItems: []
	};

	if(Array.isArray(items))
	{
		customItemsLists.workingItems = items.filter(item => !item._deleted).sort((a, b) => a.order - b.order);
		customItemsLists.selectOptions = customItemsLists.workingItems.map(item => ({text: item.text, value: item._id})); // TODO: Add data=item if it is necessary
		customItemsLists.itemsById = Generic.ArrayToObjectByIdentifier(customItemsLists.workingItems);
	}

	return customItemsLists;
}

/**
 * This function returns the additional fields for form for a specific internal name
 * @param {Object} internalName
 * @param {Object} listOption
 */
const GetFieldsForForm = (internalName, listOption) =>
{
	const result = {...listOption};

	if(listsWithColor.includes(internalName) && !listOption.color)
	{
		result.color = defaultListColor;
	}

	switch(internalName)
	{
		case 'salesFlow':
			result.sideWay = !!listOption.data?.sideWay;
		break;

		default:
		break;
	}

	return result;
}

const GetFieldsFromForm = (internalName, form) =>
{
	const result = {...form};

	switch(internalName)
	{
		case 'salesFlow':
			result.data =
			{
				sideWay: result.sideWay
			};

			delete result.sideWay;
		break;

		default:
		break;
	}

	return result;
}

const ListsProvider = (props) =>
{
	const [state, dispatch] = React.useReducer(ListsReducer, {lists: []});
	const [currentList, setCurrentList] = React.useState({});
	const [eventId, setEventId] = React.useState('');
	const [oldSocket, setOldSocket] = React.useState({});
	const [listsByName, setListsByName] = React.useState({});
	const [sorting, setSorting] = React.useState(false);

	const Socket = useSocket();
	const {Fetch, business} = useBusiness();

	const room = React.useMemo(() => `lists_${business._id}`, [business._id]);
	const unique = React.useMemo(() =>
	{
		return eventId ? `LIST_${eventId}` : 'LIST';
	}, [eventId]);

	React.useEffect(() =>
	{
		if(business._id)
		{
			Fetch('/lists/read').then(response =>
			{
				if(response.status === 200)
				{
					dispatch({type: 'LOAD_LISTS', lists: response.data || []});

					const listsObj = Generic.ArrayToObjectByIdentifier(response.data || [], 'internalName');

					for(let i in listsObj)
					{
						listsObj[i] = {...listsObj[i], ...GetItemsLists(listsObj[i].items)}
					}

					setListsByName(listsObj);
				}
			});
		}
	}, [Fetch, business._id]);
	
	React.useEffect(() =>
	{
		const events =
		[
			{
				name: `UPDATE_${unique}`,
				Function: (list) =>
				{
					setListsByName({...listsByName, [list.internalName]: {...list, ...GetItemsLists(list.items)}});
					dispatch({type: 'UPDATE_LIST', list});

					if(list._id === currentList._id)
					{
						setCurrentList(list);
					}
				}
			}
		];

		Socket.ConnectEvents(room, events, oldSocket);

		if(oldSocket.unique !== unique)
		{
			setOldSocket({room, events, unique});
		}
	}, [Socket, room, unique, oldSocket, listsByName, currentList]);

	const LoadLists = React.useCallback((lists = []) =>
	{
		dispatch({type: 'LOAD_LISTS', lists});

		setListsByName(Generic.ArrayToObjectByIdentifier(lists, 'internalName'));
	}, []);

	const UpdateList = async (list) =>
	{
		const url = '/list/update';

		list._socket = room;

		return await Fetch(url, list);
	}

	const AddItem = async(internalName, item) =>
	{
		if(listsByName[internalName])
		{
			const url = '/list/item/create';
	
			const data =
			{
				item: GetFieldsFromForm(internalName, item),
				internalName,
				_socket: room
			};
	
			return await Fetch(url, data);
		}
	}
	
	const UpdateItem = async(internalName, item) =>
	{
		if(listsByName[internalName])
		{
			const url = '/list/item/update';
	
			const data =
			{
				item: GetFieldsFromForm(internalName, item),
				internalName,
				_socket: room
			};
	
			return await Fetch(url, data);
		}
	}

	const DeleteItem = async(internalName, item) =>
	{
		if(listsByName[internalName])
		{
			const url = '/list/item/delete';
	
			const data =
			{
				item: {...item},
				internalName,
				_socket: room
			};

			delete data.item.colorComp;
			delete data.item.orderButtons;
	
			return await Fetch(url, data);
		}
	}

	const UpdateOrder = React.useCallback(async (internalName, order, direction = 'down') =>
	{
		if(listsByName[internalName])
		{
			const workingItems = listsByName[internalName].workingItems;
			const workingIndex = workingItems.findIndex(item => item.order === order);

			if(workingIndex !== -1)
			{
				const items = [];

				if(direction === 'up')
				{
					const secondItem = [...workingItems].reverse().find(item => item.order <= order - 1);

					if(secondItem?._id)
					{
						items.push({_id: workingItems[workingIndex]._id, order: secondItem.order});
						items.push({_id: secondItem._id, order});
					}
				}
				else if(direction === 'down')
				{
					const secondItem = workingItems.find(item => item.order >= order + 1);

					if(secondItem?._id)
					{
						items.push({_id: workingItems[workingIndex]._id, order: secondItem.order});
						items.push({_id: secondItem._id, order});
					}
				}

				if(items.length > 0)
				{
					setSorting(true);

					const url = '/list/items/sort';
	
					const data =
					{
						items,
						internalName,
						_socket: room
					};

					await Fetch(url, data);

					setSorting(false);
				}
			}
		}
	}, [listsByName, Fetch, room]);

	/**
	 * This function should help to store and assign the list options ids before save a document
	 * @param {Object} form A form result
	 * @param {array} internalNames An array of lists internal names
	 * @returns {Object} An object with list options ids
	 */
	const GetListFieldsValuesForForm = async(form, internalNames) =>
	{
		const fieldsValues = {};

		if(Array.isArray(internalNames))
		{
			for(let i = 0; i < internalNames.length; i++)
			{
				const fieldValue = form[internalNames[i]];

				if(fieldValue)
				{
					const items = listsByName[internalNames[i]].items || [];
					const foundItemValue = items.find(item => item._id === fieldValue || item.text === fieldValue)?._id || null;

					if(foundItemValue)
					{
						fieldsValues[internalNames[i]] = foundItemValue;
					}
					else
					{
						const response = await AddItem(internalNames[i], {text: fieldValue});

						if(response.status === 200)
						{
							fieldsValues[internalNames[i]] = response.data._id;
						}
					}
				}
				else
				{
					fieldsValues[internalNames[i]] = null;
				}
			}
		}

		return fieldsValues;
	}

	const value =
	{
		code: state.code,
		lists: state.lists,
		currentList,
		listsByName,
		sorting,
		SetEventId: setEventId,
		SetCurrentList: setCurrentList,
		LoadLists,
		UpdateList,
		AddItem,
		UpdateItem,
		DeleteItem,
		UpdateOrder,
		GetListFieldsValuesForForm,
	}

	return <ListsContext.Provider value={value} {...props}/>
}

const useLists = () => React.useContext(ListsContext);

export {ListsProvider, useLists, GetFieldsForForm, listsWithColor, defaultListColor};