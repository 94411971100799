import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Section} from 'crack-ux';

// Contexts
import {useSession} from '../../../contexts/general/Session';
import {HomeProvider} from '../../../contexts/pages/public/Home';

// Views
import {Loading} from '../../../components/views/Loading';

// Components
import {Menu} from './Menu';
import {Init} from './Init';
import {Features} from './Features';
// import {Services} from './Services';
import {Modules} from './Modules';
// import {FAQs} from './FAQs';
import {ContactUs} from './ContactUs';
import {EndPage} from './EndPage';

// Style
import './Home.css';

const Home = (props) =>
{
	const {language} = props;

	const navigate = useNavigate();
	const {session} = useSession();

	if(!session)
	{
		return <Loading/>;
	}
	else if(session.user)
	{
		setTimeout(() =>
		{
			navigate('/p/business');
		}, 1000);
	}
	else
	{
		return (
			<Section class='home'>
				<Menu language={language}/>
				<Init language={language}/>
				<Features language={language}/>
				{/* <Services language={language}/> */}
				<Modules language={language}/>
				{/* <FAQs language={language}/> */}
				<ContactUs language={language}/>
				<EndPage language={language}/>
			</Section>
		)
	}
}

const HomeWithProviders = props =>
(
	<HomeProvider>
		<Home {...props}/>
	</HomeProvider>
);

export {HomeWithProviders as Home};