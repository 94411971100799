import React from 'react';
import {Content, Card, Header, Body, Text} from 'crack-ux';

// Tables
import {CategoriesTable} from '../../../../components/tables/modules/store/Categories';

// Contexts
import {useTraductor} from '../../../../contexts/general/Traductor';

const Categories = () =>
{
	const {Translate} = useTraductor();

	return (
		<Content>
			<Card>
				<Header>
					<Text class='bold'>{Translate('Categories')}</Text>
				</Header>
				<Body class='no-padding' style={{height: 'calc(100vh - (var(--header) * 2) - 20px)'}}>
					<CategoriesTable/>
				</Body>
			</Card>
		</Content>
	)
}

const CategoriesWithProviders = () =>
{
	return <Categories/>;
};

export {CategoriesWithProviders as Categories};