import React from 'react';
import {Generic} from 'crack-functions';

// Contexts
import {useBusiness as useBusinessLayout} from '../layouts/Business';
import {useBusiness} from '../general/Business';
import {useSocket} from '../general/Socket';
import {useSession} from '../general/Session';
import {useStaffSession} from '../general/StaffSession';

// Reducers
import {ModulesReducer} from '../../reducers/main/Modules';

const Modules = React.createContext({});

const ModulesProvider = (props) =>
{
	const [state, dispatch] = React.useReducer(ModulesReducer, {modules: []});
	const [currentModule, setCurrentModule] = React.useState({});

	const {path} = useBusinessLayout();
	const {UpdateSession} = useSession();
	const {business, Fetch} = useBusiness();
	const {member} = useStaffSession();
	const Socket = useSocket();

	const LoadModules = React.useCallback((modules = []) =>
	{
		dispatch({type: 'LOAD_MODULES', modules});
	}, []);

	const UpdateModules = React.useCallback(() =>
	{
		if(business?._id && member._id)
		{
			Fetch('/modules/read').then(response =>
			{
				if(response.status === 200)
				{
					LoadModules(response.data || []);
				}
			});
		}
	}, [business?._id, member, Fetch, LoadModules]);

	React.useEffect(() =>
	{
		UpdateModules();
	}, [UpdateModules]);

	React.useEffect(() =>
	{
		if(typeof Socket.ConnectEvents === 'function')
		{
			if(business?._id && member) // working with a business
			{
				Socket.ConnectEvents(`modules_${business._id}`,
				[
					{
						name: `UPDATE_MODULES_${member._id}`,
						Function: (response) =>
						{
							UpdateSession().then(response =>
							{
								UpdateModules();
							});
						}
					}
				]);
			}
		}
	}, [Socket, business?._id, member, UpdateSession, UpdateModules]);

	React.useEffect(() =>
	{
		const module = state.modules.find(module => module.route === path.module);

		if(module)
		{
			setCurrentModule(module);
		}
	}, [path, state.modules]);

	const modulesByName = React.useMemo(() => Generic.ArrayToObjectByIdentifier(state.modules, 'route'), [state.modules]);

	const value =
	{
		modules: state.modules,
		modulesByName,
		currentModule,
		SetCurrentModule: setCurrentModule,
		LoadModules,
	}

	return <Modules.Provider value={value} {...props}/>
}

const useModules = () => React.useContext(Modules);

export {ModulesProvider, useModules};