import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {Section, Box, Header, Body, Text, List, Avatar, Icon} from 'crack-ux';
import {Generic, Text as TextF} from 'crack-functions';

// Contexts
import {useSession} from '../../contexts/general/Session';
import {useTraductor} from '../../contexts/general/Traductor';

// Styles
import './Profile.css';

const Profile = () =>
{
	const {session} = useSession();
	const {Translate} = useTraductor();
	const navigate = useNavigate();

	const fullName = React.useMemo(() => TextF.FullName(session.user?.name, session.user?.lastName), [session]);

	const Logout = React.useCallback(() =>
	{
		Generic.Fetch('/user/logout').then(response =>
		{
			if(response.status === 200)
			{
				navigate('/');
			}
		});
	}, [navigate]);

	return (
		<Section class='profile'>
			<Header class='center' style={{padding: 50}}>
				<Avatar class='background-green white' src={TextF.Initials(fullName)} size={128}/>
				<Text class='block' style={{marginTop: 25}} size={18}>{fullName}</Text>
				<Text class='block user'>@{session.user.user}</Text>
			</Header>
			<Body class='no-padding'>
				<List
					class = 'menu'
					options =
					{[
						{
							content: (
								<Link to='/p/business'>
									<Section class='option'>
										<Box>
											<Icon class='icon-building'/>
										</Box>
										<Text>{Translate('Business')}</Text>
									</Section>
								</Link>
							)
						},
						{
							content: (
								<Link to='/p/profile'>
									<Section class='option'>
										<Box>
											<Icon class='icon-vcard'/>
										</Box>
										<Text>{Translate('Profile')}</Text>
									</Section>
								</Link>
							)
						},
						{
							content: (
								<Link to='/p/preferences'>
									<Section class='option'>
										<Box>
											<Icon class='icon-sliders'/>
										</Box>
										<Text>{Translate('Preferences')}</Text>
									</Section>
								</Link>
							)
						},
						{
							content: (
								<Section class='option red' Click={Logout}>
									<Box>
										<Icon class='icon-logout'/>
									</Box>
									<Text>{Translate('Log Out')}</Text>
								</Section>
							)
						},
					]}
				/>
			</Body>
		</Section>
	)
}

export {Profile};