import {Link} from 'react-router-dom';
import {Panel, Card, Body, Footer, Text} from 'crack-ux';

// Contexts
import {useTraductor} from '../../../../contexts/general/Traductor';
import {useReportsPage} from '../../../../contexts/pages/business/store/Reports';

const ReportsList = () =>
{
	const {reports} = useReportsPage();
	const {Translate} = useTraductor();

	return reports.map((report) =>
	{
		return (
			<Panel key={report.identifier} style={{width: 200}}>
				<Link className='pointer' to={`./${report.route}`}>
					<Card>
						<Body class='center no-padding'>
							<img src={`/store/${report.picture}`} width={80} height={100} alt={report.picture}/>
						</Body>
						<Footer class='center'>
							<Text class='bold'>{Translate(report.text)}</Text>
						</Footer>
					</Card>
				</Link>
			</Panel>
		)
	});
}

export {ReportsList}