import React from "react";
import {useLocation} from "react-router-dom";

// Contexts
import {useModal} from "../general/Modal";
import {useBusiness} from "../general/Business";
import {useStaffSession} from "../general/StaffSession";
import {useContactsNotes} from "../collections/crm/ContactsNotes";
import {useMessages} from "../collections/crm/Messages";

// Forms
import {Location} from "../../components/forms/Location";
import {CompanyForm} from "../../components/forms/modules/crm/Company";
import {ChatBotOptionForm} from "../../components/forms/modules/crm/ChatBot/ChatBotOption";
import {CompanyContact} from "../../components/forms/modules/crm/CompanyContact/CompanyContact";
import {ContactForm} from "../../components/forms/modules/crm/Contact";
import {ScheduledMessageForm} from "../../components/forms/modules/crm/ScheduledMessage/ScheduledMessage";
import {ScheduledMessageContactsForm} from "../../components/forms/modules/crm/ScheduleMessageContacts/ScheduledMessageContacts";
import {ListOptionForm} from "../../components/forms/modules/home/List/ListOption";
import {CampaignForm} from "../../components/forms/modules/crm/Campaign/Campaign";
import {ContactNoteForm} from "../../components/forms/modules/crm/ContactNote/ContactNote";
import {DocumentForm} from "../../components/forms/modules/storage/Folder/Folder";
import {UploadFilesForm} from "../../components/forms/modules/storage/UploadFiles/UploadFiles";
import {FileViewer} from "../../components/views/FileViewer/FileViewer";
import {EventConfigForm} from "../../components/forms/modules/scheduler/EventConfig/EventConfig";
import {EventForm} from "../../components/forms/modules/scheduler/Event/Event";
import {ContactsListForm} from "../../components/forms/modules/crm/ContactsList/ContactsList";

// Warnings
import {DeleteChatBotOption} from "../../components/warnings/crm/DeleteChatBotOption";
import {DeleteCompanyWarning} from "../../components/warnings/crm/DeleteCompany/DeleteCompany";
import {DeleteContact} from "../../components/warnings/crm/DeleteContact";
import {UnlinkContact} from "../../components/warnings/crm/UnlinkContact/UnlinkContact";
import {DeleteScheduledMessage} from "../../components/warnings/crm/ScheduledMessage/ScheduledMessaje";
import {DeleteCampaign} from "../../components/warnings/crm/DeleteCampaign/DeleteCampaign";
import {DeleteCampaignReadOnly} from "../../components/warnings/crm/DeleteCampaignReadOnly/DeleteCampaignReadOnly";
import {DeleteNote} from "../../components/warnings/crm/DeleteContactNote/DeleteContactNote";
import {DeleteStorage} from "../../components/warnings/storage/DeleteStorage/DeleteStorage";
import {DeleteReadOnly} from "../../components/warnings/storage/DeleteReadOnly/DeleteReadOnly";
import {ConfirmEventRecurrence} from "../../components/warnings/scheduler/ConfirmEventRecurrence/ConfirmEventRecurrence";

// Warnings
// import {DeleteCustomer} from "../../components/warnings/store/DeleteCustomer";

const CRMContext = React.createContext({});

const CRMProvider = (props) =>
{
	const [contactIds, setContactIds] = React.useState([]);
	const [currentContactId, setCurrentContactId] = React.useState(null);

	const {pathname} = useLocation();
	const {AddModal, modals} = useModal();
	const {localStorage: businessLocalStorage, SaveLocalStorage: SaveBusinessLocalStorage} = useBusiness();
	const {permissions} = useStaffSession();

	const {LoadContactMessages} = useMessages();
	const {LoadContactNotes} = useContactsNotes();

	const localStorage = React.useMemo(() => businessLocalStorage.crm || {}, [businessLocalStorage]);

	React.useEffect(() => setCurrentContactId(null), [pathname]);

	React.useEffect(() =>
	{
		setContactIds(localStorage.contactIds || []);

		if(Array.isArray(localStorage.contactIds))
		{
			for(let i = 0; i < localStorage.contactIds.length; i++)
			{
				LoadContactNotes(localStorage.contactIds[i]);
				LoadContactMessages(localStorage.contactIds[i]);
			}
		}
	}, [localStorage, LoadContactNotes, LoadContactMessages]);

	const AddContactTab = (contactId) =>
	{
		const foundContactId = contactIds.find(item => item === contactId);

		if(!foundContactId)
		{
			setContactIds(current =>
			{
				const newContactIds = [...current, contactId];

				SaveBusinessLocalStorage({...businessLocalStorage, crm: {...localStorage, contactIds: newContactIds}});

				return newContactIds;
			});

			LoadContactMessages(contactId);
			LoadContactNotes(contactId);
		}
	}

	const RemoveContactTab = (contactId) =>
	{
		const foundContactId = contactIds.find(item => item === contactId);

		if(foundContactId)
		{
			const newContactIds = contactIds.filter(item => item !== contactId);

			SaveBusinessLocalStorage({...businessLocalStorage, crm: {...localStorage, contactIds: newContactIds}});

			setContactIds(newContactIds);
			setCurrentContactId(null);
		}
	}

	const ShowLocationForm = (location = {}, GetLocation = () => {}, params = {}) =>
	{
		const {readOnly = false} = params;
		AddModal(<Location location={location} GetLocation={GetLocation} readOnly={readOnly}/>);
	}
	
	const ShowCompanyForm = (company = {}) =>
	{
		AddModal(<CompanyForm company={company}/>);
	}

	const ShowChatBotOptionForm = (params = {}) =>
	{
		const
		{
			chatBot = {},
			option = {},
			Submit = () => {},
			channel = {}
		} = params;

		AddModal(<ChatBotOptionForm chatBot = {chatBot} option = {option} Submit = {Submit} channel = {channel}/>);
	}

	const ShowDeleteChatBotOption = (params = {}) =>
	{
		const
		{
			option = {},
			Delete = () => {}
		} = params;

		AddModal(<DeleteChatBotOption option = {option} Delete = {Delete}/>);
	}

	const ShowDeleteCompany = (company = {}) =>
	{
		AddModal(<DeleteCompanyWarning company = {company}/>);
	}

	const ShowCompanyContact = () =>
	{
		AddModal(<CompanyContact/>);
	}

	const ShowContactForm = (contact = {}) =>
	{
		AddModal(<ContactForm contact = {contact}/>);
	}

	const ShowDeleteContactWarning = (contact = {}, params = {}) =>
	{
		const
		{
			RemoveContactTab = () => {}
		} = params;

		AddModal(<DeleteContact contact = {contact} RemoveContactTab={RemoveContactTab}/>);
	}

	const ShowUnlinkContactWarning = (contact = {}) =>
	{
		AddModal(<UnlinkContact contact = {contact}/>);
	}

	const ShowScheduledMessageForm = (scheduledMessage = {}, params = {}) =>
	{
		const
		{
			channel = null,
			contact = null,
			type = null,
			base = false
		} = params;

		AddModal(<ScheduledMessageForm scheduledMessage = {scheduledMessage} channel = {channel} contact = {contact} type = {type} base = {base}/>);
	}

	const ShowDeleteScheduledMessage = (scheduledMessage = {}) =>
	{
		AddModal(<DeleteScheduledMessage scheduledMessage = {scheduledMessage}/>);
	}

	const ShowScheduledMessageContacts = (contacts, filteredContacts, setFilteredContacts) =>
	{
		AddModal(<ScheduledMessageContactsForm contacts={contacts} filteredContacts={filteredContacts} setFilteredContacts={setFilteredContacts}/>);
	}

	const ShowListOptionForm = (internalName = '', listOption = {}) =>
	{
		AddModal(<ListOptionForm internalName = {internalName} listOption = {listOption}/>);
	}

	const ShowCampaignForm = (campaign = {}) =>
	{
		AddModal(<CampaignForm campaign = {campaign}/>);
	}

	const ShowDeleteCampaign = (campaign = {}) =>
	{
		AddModal(<DeleteCampaign campaign = {campaign}/>);
	}

	const ShowDeleteCampaignReadOnly = () =>
	{
		AddModal(<DeleteCampaignReadOnly/>);
	}

	const ShowContactNoteForm = (note, params = {}) =>
	{
		const
		{
			contact = {}
		} = params;

		AddModal(<ContactNoteForm note = {note} contact = {contact}/>);
	}
	const ShowDeleteNoteWarning = (note = {}) =>
	{
		AddModal(<DeleteNote note = {note}/>);
	}

	const ShowDocumentForm = (document = {}, {module = {}, parent}) =>
	{
		AddModal(<DocumentForm document = {document} module = {module} parent = {parent}/>);
	}

	const ShowUploadFilesForm = ({module = {}, parent}) =>
	{
		AddModal(<UploadFilesForm module = {module} parent = {parent}/>);
	}

	const ShowDeleteWarning = (document = {}) =>
	{
		AddModal(<DeleteStorage document = {document}/>);
	}

	const ShowReadOnlyWarning = (document = {}) =>
	{
		AddModal(<DeleteReadOnly document = {document}/>);
	}

	const ShowFileViewer = (document = {}) =>
	{
		AddModal(<FileViewer document = {document}/>);
	}

	const ShowEventConfigForm = (eventConfig = {}) =>
	{
		AddModal(<EventConfigForm eventConfig = {eventConfig}/>);
	}

	const ShowEventForm = (event = {}) =>
	{
		AddModal(<EventForm event = {event}/>);
	}

	const ShowConfirmEventRecurrence = (event = {}, params = {}) =>
	{
		const
		{
			action
		} = params;

		AddModal(<ConfirmEventRecurrence event = {event} action = {action}/>);
	}

	const ShowContactsList = (contacts = []) =>
	{
		AddModal(<ContactsListForm contacts = {contacts}/>);
	}

	const Can = React.useCallback(id => permissions.Can('crm', id), [permissions]);

	const value =
	{
		contactIds,
		currentContactId,
		setCurrentContactId,
		AddContactTab,
		RemoveContactTab,
		ShowLocationForm,
		ShowCompanyForm,
		ShowChatBotOptionForm,
		ShowDeleteChatBotOption,
		ShowDeleteCompany,
		ShowCompanyContact,
		ShowContactForm,
		ShowDeleteContactWarning,
		ShowUnlinkContactWarning,
		ShowScheduledMessageForm,
		ShowDeleteScheduledMessage,
		ShowScheduledMessageContacts,
		ShowListOptionForm,
		ShowCampaignForm,
		ShowDeleteCampaign,
		ShowDeleteCampaignReadOnly,
		ShowContactNoteForm,
		ShowDeleteNoteWarning,
		ShowDocumentForm,
		ShowUploadFilesForm,
		ShowDeleteWarning,
		ShowReadOnlyWarning,
		ShowFileViewer,
		ShowEventConfigForm,
		ShowEventForm,
		ShowConfirmEventRecurrence,
		Can,
		ShowContactsList,
	}

	return (
		<CRMContext.Provider value={value}>
			{props.children}
			{modals}
		</CRMContext.Provider>
	)
}

const useCRM = () => React.useContext(CRMContext);

export {CRMProvider, useCRM}