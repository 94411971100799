import React from 'react';
import {Card, Header, Body, Footer, Text, Button} from 'crack-ux';

// Contexts
import {useTraductor} from '../../../../../contexts/general/Traductor';
import {useModal} from '../../../../../contexts/general/Modal';
import {useMoves} from '../../../../../contexts/collections/store/Moves';

// Components
import {MovesTable} from '../../../../tables/modules/store/Moves';

const Moves = ({type, contact}) =>
{
	const {Translate} = useTraductor();
	const {CloseModal} = useModal();

	const {moves} = useMoves();

	const workingMoves = React.useMemo(() =>
	(
		moves.filter(move => move.type === type && move.contact === contact?._id)
	), [moves, type, contact?._id]);

	return (
		<Card>
			<Header>
				<Text class='bold'>{Translate(type === 1 ? 'Sales' : 'Acquisitions')}</Text>
			</Header>
			<Body class='no-padding' style={{height: 'calc(100vh - var(--header) - 117px)', width: 900, maxWidth: 'calc(100vw - 40px)'}}>
				<MovesTable moves={workingMoves}/>
			</Body>
			<Footer class='right'>
				<Button text={Translate('Cancel')} Click={CloseModal}/>
			</Footer>
		</Card>
	)
}

export {Moves};