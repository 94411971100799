import React from 'react';

// Context
import {useSocket} from '../../general/Socket';
import {useBusiness} from '../../general/Business';

// Reducers
import {ProvidersReducer} from '../../../reducers/collections/store/Providers';

const Providers = React.createContext({});

const ProvidersProvider = (props) =>
{
	const [state, dispatch] = React.useReducer(ProvidersReducer, {providers: [], code: 0});
	const [currentProvider, setCurrentProvider] = React.useState({});
	const [eventId, setEventId] = React.useState('');
	const [oldSocket, setOldSocket] = React.useState({});

	const Socket = useSocket();
	const {Fetch, business} = useBusiness();

	const room = React.useMemo(() => `store_providers_${business._id}`, [business._id]);
	const unique = React.useMemo(() =>
	{
		return eventId ? `STORE_PROVIDER_${eventId}` : 'STORE_PROVIDER';
	}, [eventId]);

	React.useEffect(() =>
	{
		Fetch('/store/providers/code').then(response =>
		{
			if(response.status === 200)
			{
				dispatch({type: 'SET_LATEST_PROVIDER_CODE', code: response.data});
			}
		});
	}, [Fetch]);

	React.useEffect(() =>
	{
		const events =
		[
			{
				name: `CREATE_${unique}`,
				Function: (provider) =>
				{
					dispatch({type: 'CREATE_PROVIDER', provider});

					if(unique === 'STORE_PROVIDER') dispatch({type: 'SET_LATEST_PROVIDER_CODE', code: state.code + 1});
				}
			},
			{
				name: `UPDATE_${unique}`,
				Function: (provider) => dispatch({type: 'UPDATE_PROVIDER', provider})
			},
			{
				name: `DELETE_${unique}`,
				Function: (provider) => dispatch({type: 'DELETE_PROVIDER', provider})
			}
		];

		if(unique !== 'CONTACT')
		{
			events.push
			({
				name: 'CREATE_PROVIDER',
				Function: () =>
				{
					dispatch({type: 'SET_LATEST_STORE_PROVIDER_CODE', code: state.code + 1});
				}
			})
		}

		Socket.ConnectEvents(room, events, oldSocket);

		if(oldSocket.unique !== unique)
		{
			setOldSocket({room, events, unique});
		}
	}, [Socket, room, unique, oldSocket, state.code]);

	const LoadProviders = React.useCallback((providers = []) =>
	{
		dispatch({type: 'LOAD_PROVIDERS', providers});
	}, []);

	const CreateProvider = async (provider) =>
	{
		provider.code = state.code + 1;
		provider._socket = room;

		return await Fetch('/store/provider/create', provider);
	}

	const UpdateProvider = async (provider) =>
	{
		provider._socket = room;

		return await Fetch('/store/provider/update', provider);
	}

	const DeleteProvider = async (provider) =>
	{
		const data =
		{
			_id: provider._id,
			_socket: room
		}

		return await Fetch('/store/provider/delete', data);
	}

	const value =
	{
		code: state.code,
		providers: state.providers,
		currentProvider,
		SetCurrentProvider: setCurrentProvider,
		SetEventId: setEventId,
		LoadProviders,
		CreateProvider,
		UpdateProvider,
		DeleteProvider
	}

	return (
		<Providers.Provider value={value}>
			{props.children}
		</Providers.Provider>
	)
}

const useProviders = () => React.useContext(Providers);

export {ProvidersProvider, useProviders};