import React from 'react';
import {Table, Box} from 'crack-ux';
import {Generic} from 'crack-functions';

// Contexts
import {useTraductor} from '../../../../contexts/general/Traductor';
import {useMessages} from '../../../../contexts/collections/crm/Messages';
import {useLists} from '../../../../contexts/collections/home/Lists';
import {useCompanies} from '../../../../contexts/collections/crm/Companies';
import {useCRM} from '../../../../contexts/modules/CRM';

const defaultContactChatStatus =
{
	chatStatusNoChat: true,
	chatStatusAnswered: true,
	chatStatusNotAnswered: true,
	chatStatusChatBot: true,
	chatStatusScheduledMessage: true,
};

const ContactList = ({contacts, from, selected, setSelected}) =>
{
	const [filters, setFilters] = React.useState({showColumns: null, inputValues: {...defaultContactChatStatus, salesFlows: false}});

	const {Translate} = useTraductor();

	const {ShowContactForm, ShowDeleteContactWarning, ShowUnlinkContactWarning, ShowCompanyContact, AddContactTab, RemoveContactTab, Can} = useCRM();
	
	const {listsByName} = useLists();
	const {companies} = useCompanies();
	const {GetChatStatus} = useMessages();

	const ChangeFilters = React.useCallback((newFilters) =>
	{
		setFilters(newFilters);
	}, []);

	const genderItemsById = React.useMemo(() => listsByName.gender?.itemsById || {}, [listsByName.gender?.itemsById]);
	const salesFlowItemsById = React.useMemo(() => listsByName.salesFlow?.itemsById || {}, [listsByName.salesFlow?.itemsById]);

	const body = React.useMemo(() =>
	{
		const result = [];
		const companiesById = Generic.ArrayToObjectByIdentifier(companies);

		for(let i = 0; i < contacts.length; i++)
		{
			const contact = {...contacts[i]};
			const {type, statusColor, title} = GetChatStatus(contact);

			const salesFlows = Array.isArray(filters.inputValues.salesFlows) ? filters.inputValues.salesFlows : ['no-assigned', ...Object.keys(salesFlowItemsById)];

			const chatFilter = from === 'contacts' ? !!filters.inputValues[type] : true;
			const flowStageFilter = from !== 'company' ? salesFlows.includes(contact.lastSaleFlow) : true;

			if(chatFilter && flowStageFilter)
			{
				const companiesIds = Array.isArray(contact.companies) ? contact.companies : [];

				contact.chatStatus = <Box class={`crm-chat-status background-${statusColor}`} title={Translate(title)}/>
				contact.genderText = genderItemsById?.[contact.gender]?.text || '';
				contact.companiesText = companiesIds.reduce((acc, companyId) =>
				{
					if(companiesById[companyId])
					{
						acc.push(companiesById[companyId].name)
					}

					return acc;
				}, []).join(', ');

				contact.saleStageText = salesFlowItemsById?.[contact.lastSaleFlow]?.text || '';
				contact.previousSaleStageText = salesFlowItemsById?.[contact.previousSaleFlow]?.text || '';

				result.push(contact);
			}
		}

		return result;
	}, [contacts, companies, genderItemsById, salesFlowItemsById, GetChatStatus, Translate, filters.inputValues, from]);

	const inputFilters = React.useMemo(() =>
	{
		const result = from === 'contacts' ?
		[
			{
				title: Translate('Contact Chat Status'),
				type: 'title'
			},
			{
				name: 'chatStatusNoChat',
				text: Translate('No Chat'),
				type: 'checkbox',
				checked: true,
			},
			{
				name: 'chatStatusAnswered',
				text: Translate('Awaiting for Reply'),
				type: 'checkbox',
				checked: true,
			},
			{
				name: 'chatStatusNotAnswered',
				text: Translate('Already Replied'),
				type: 'checkbox',
				checked: true,
			},
			{
				name: 'chatStatusChatBot',
				text: Translate('Chat Bot Messages'),
				type: 'checkbox',
				checked: true,
			},
			{
				name: 'chatStatusScheduledMessage',
				text: Translate('Scheduled Messages'),
				type: 'checkbox',
				checked: true,
			},
		] : [];

		if(['contacts', 'scheduled-messages', 'sales-flow'].includes(from))
		{
			result.push
			(
				{
					title: Translate('Sales Flow'),
					type: 'title'
				},
				{
					name: 'salesFlows',
					type: 'autocomplete',
					options: [{value: 'no-assigned', text: Translate('No Assigned')}, ...(listsByName.salesFlow?.selectOptions || [])],
					multiple: true,
					selected: ['no-assigned', ...Object.keys(salesFlowItemsById)]
				},
			);
		}

		return result;
	}, [from, Translate, listsByName.salesFlow, salesFlowItemsById]);

	const header = React.useMemo(() =>
	{
		const companyExclude = ['chatStatus', 'companiesText'];
		const salesFLowExclude = ['chatStatus', 'companiesText', 'phoneText', 'genderText', 'age'];
		const header =
		[
			{
				name: 'chatStatus',
				text: Translate('Chat'),
				width: 70
			},
			{
				name: 'codeText',
				text: Translate('Code'),
				sortable:
				{
					type: 'number',
					field: 'code',
				}
			},
			{
				name: 'companiesText',
				text: Translate('Companies')
			},
			{
				name: 'fullName',
				text: Translate('Name'),
			},
			{
				name: 'phoneText',
				text: Translate('Phones')
			},
			{
				name: 'emailText',
				text: Translate('Emails')
			},
			{
				name: 'genderText',
				text: Translate('Gender'),
				sortable: 'string'
			},
			{
				name: 'age',
				text: Translate('Age'),
				sortable:
				{
					type: 'number',
					field: 'ageNumber',
				}
			},
			{
				name: 'tagsText',
				text: Translate('Tags'),
				sortable: 'string'
			},
			{
				name: 'saleStageText',
				text: Translate('Sale Stage'),
			},
		];

		if(['scheduled-message'].includes(from))
		{
			header.push
			({
				name: 'sentText',
				text: Translate('Sent'),
			});
		}

		if(['company', 'scheduled-messages', 'scheduled-message'].includes(from))
		{
			return header.filter(column => companyExclude.indexOf(column.name) === -1);
		}

		if(['sales-flow'].includes(from))
		{
			header.push
			({
				name: 'previousSaleStageText',
				text: Translate('Previous Sale Stage'),
			},);

			return header.filter(column => salesFLowExclude.indexOf(column.name) === -1);
		}

		return header;

	}, [from, Translate]);

	const actions = React.useMemo(() =>
	{
		if(['scheduled-messages', 'sales-flow', 'scheduled-message'].includes(from)) return [];

		let result = [];

		if(Can('create-contact'))
		{
			result.push
			({
				text: Translate('New Contact'),
				class: 'background-blue white',
				section: 'grouped',
				Click: () => from === 'company' ? ShowCompanyContact() : ShowContactForm({})
			});
		}

		if(from !== 'company')
		{
			result.push
			({
				text: Translate('Check Contact'),
				icon:
				{
					class: 'icon-vcard'
				},
				section: 'context',
				Click: (contact) => AddContactTab(contact._id)
			});
		}

		result.push
		({
			text: Translate('Edit Contact'),
			icon:
			{
				class: 'icon-pencil'
			},
			section: 'context',
			Click: (contact) => ShowContactForm(contact)
		});

		if(from === 'company')
		{
			result.push
			({
				text: Translate('Unlink Contact'),
				class: 'red',
				icon:
				{
					class: 'icon-trash'
				},
				section: 'context',
				Click: (contact) => ShowUnlinkContactWarning(contact)
			});
		}
		else
		{
			result.push
			({
				text: Translate('Delete Contact'),
				class: 'red',
				icon:
				{
					class: 'icon-trash'
				},
				section: 'context',
				Click: (contact) => ShowDeleteContactWarning(contact, {RemoveContactTab})
			});
		}

		return result;
	}, [from, AddContactTab, ShowCompanyContact, ShowContactForm, ShowDeleteContactWarning, RemoveContactTab, ShowUnlinkContactWarning, Translate, Can]);

	return (
		<Table
			header = {header}
			body = {body}
			actions = {actions}
			searcher =
			{
				{text: Translate('Search')}
			}
			texts =
			{
				{
					noRecords:Translate('There Are Not Records To Show'),
					records:Translate('Records'),
					fields: Translate('Fields'),
					settings: Translate('Settings'),
					export: Translate('Export'),
					selectedItems: Translate('Selected Items'),
					selectAll: Translate('Select All'),
					deselectAll: Translate('Deselect All'),
				}
			}
			filters =
			{{
				columns:
				{
					editable: true,
					show: filters.showColumns
				},
				inputs: inputFilters,
				Apply:
				{
					text: Translate('Apply'),
					Click: ChangeFilters
				},
				Reset:
				{
					text: Translate('Reset Filters'),
					Click: ChangeFilters
				}
			}}
			DoubleClickRow = {(contact) => [].indexOf(from) !== -1 ? () => {} : AddContactTab(contact._id)}
			exportable = {from !== 'scheduled-messages'}
			selected = {from === 'scheduled-messages' ? selected : false}
			ClickSelected = {setSelected}
		/>
	)
}

export {ContactList};