import {Section, Header, Body, Text, Table} from 'crack-ux';
import {Amount} from 'crack-functions';

// Contexts
import {useTraductor} from '../../../../../contexts/general/Traductor';

const ProductsSold = (props) =>
{
	const {products} = props;

	const {Translate} = useTraductor();

	return (
		<Section class='report-section'>
			<Header class='no-padding-horizontal'>
				<Text class='bold'>{Translate('Products')}</Text>
			</Header>
			<Body class='no-padding'>
				<Table
					header = 
					{[
						{
							name: 'codeText',
							text: Translate('Code')
						},
						{
							name: 'name',
							text: Translate('Product Name')
						},
						{
							name: 'quantity',
							text: Translate('Quantity')
						},
						{
							name: 'minPriceText',
							text: Translate('Min Price')
						},
						{
							name: 'maxPriceText',
							text: Translate('Max Price')
						},
						{
							name: 'averageText',
							text: Translate('Average Price')
						},
						{
							name: 'totalText',
							text: Translate('Total')
						},
					]}
					body = {products.map(product =>
					{
						product.minPriceText = Amount.CurrencyFormat(product.minPrice);	
						product.maxPriceText = Amount.CurrencyFormat(product.maxPrice);	
						product.averageText = Amount.CurrencyFormat(product.total / product.quantity);
						product.totalText = Amount.CurrencyFormat(product.total);

						return product;
					})}
					footer = {false}
					searcher = {false}
					pager =
					{{
						rows: 1000
					}}
				/>
			</Body>
		</Section>
	)

}

export {ProductsSold};