import React from "react";

// Contexts
import {useModal} from "../../../general/Modal";
import {useModules} from "../../../collections/home/Modules";

// Warnings
import {AddModuleConfirm} from "../../../../components/warnings/home/AddModule/AddModule";

const ModulesContext = React.createContext({});

const ModulesProviderPage = (props) =>
{
	const [currentModule, setCurrentModule] = React.useState(null);

	const {AddModal, modals} = useModal();
	const {modules} = useModules();

	React.useEffect(() =>
	{
		setCurrentModule(current =>
		{
			let result = null;
			const filteredModules = modules.filter(item => !item.native);

			if(filteredModules.length > 0)
			{
				const foundModule = filteredModules.find(item => item.syssec === current);

				if(foundModule)
				{
					result = current;
				}
				else
				{
					result = filteredModules[0].syssec;
				}
			}

			return result;
		});
	}, [modules]);

	const ShowAddModuleConfirm = plan =>
	{
		AddModal(<AddModuleConfirm plan = {plan}/>);
	}
	
	const value =
	{
		currentModule,
		setCurrentModule,
		ShowAddModuleConfirm,
	}

	return (
		<ModulesContext.Provider value={value}>
			{props.children}
			{modals}
		</ModulesContext.Provider>
	)
}

const useModulesPage = () => React.useContext(ModulesContext);

export {ModulesProviderPage, useModulesPage};