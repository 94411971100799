import React from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';

// Views
import {Loading} from '../../../components/views/Loading';

// Module Section
import {Files} from './Files';

// Contexts
import {ModalProvider} from '../../../contexts/general/Modal';
import {useBusiness} from '../../../contexts/general/Business';
import {useModules} from '../../../contexts/main/Modules';
import {StorageModuleProvider} from '../../../contexts/modules/Storage';
import {useStorages} from '../../../contexts/collections/storage/Storage';

const modulesList = ['business', 'store', 'crm'];

const Storage = () =>
{
	const [loading, setLoading] = React.useState(true);

	const {Fetch} = useBusiness();

	const {modulesByName} = useModules();
	const {LoadStorages, SetModules} = useStorages();

	React.useEffect(() =>
	{
		const requests =
		[
			Fetch('/storage/business/read', {_where: {_folder: {$in: modulesList}}}),
		];

		Promise.all(requests).then(responses =>
		{
			if(responses.every(response => response.status === 200))
			{
				LoadStorages(responses[0].data || []);
				SetModules(modulesList);

				setLoading(false);
			}
		});
	}, [Fetch, LoadStorages, SetModules]);

	const menu = React.useMemo(() => modulesByName?.storage?.menu || [], [modulesByName.storage]);
	const availableRoutes = React.useMemo(() =>
	{
		const result = [];
		const routesComponents =
		{
			'files': Files,
		};

		menu.forEach(item =>
		{
			const Page = routesComponents[item.route];

			if(Page)
			{
				result.push(<Route key={item.route} path={`/${item.route}`} element={<Page/>}/>)
			}
		});

		return result;
	}, [menu]);

	if(loading)
	{
		return <Loading/>
	}
	else
	{
		return (
			<Routes>
				{availableRoutes}
				<Route path='*' element={<Navigate to={`./../home/${modulesByName.home?.menu?.[0]?.route}`}/>}/>
			</Routes>
		)
	}
}

const StorageWithProviders = () =>
{
	return (
		<ModalProvider>
			<StorageModuleProvider>
				<Storage/>
			</StorageModuleProvider>
		</ModalProvider>
	)
}

export {StorageWithProviders as Storage};