import React from 'react';
import {Section, Header, Body, Table, Box, Text, Icon} from 'crack-ux';
import {Generic} from 'crack-functions';

// Contexts
import {useTraductor} from '../../../../../contexts/general/Traductor';
import {useStorageModule} from '../../../../../contexts/modules/Storage';
import {useCRM} from '../../../../../contexts/modules/CRM';
import {useStorages} from '../../../../../contexts/collections/storage/Storage';

const Documents = ({module, draggableRows, from}) =>
{
	const [folders, setFolders] = React.useState([{_id: null, name: ''}]);

	const {Translate} = useTraductor();

	const usePageContext = from === 'crm' ? useCRM : useStorageModule;

	const {ShowDocumentForm, ShowUploadFilesForm, ShowDeleteWarning, ShowReadOnlyWarning, ShowFileViewer} = usePageContext();

	const {storages, PublicPrivate} = useStorages();

	React.useEffect(() =>
	{
		setFolders([{_id: null, name: Translate(module.text)}]);
	}, [module, Translate]);

	const GoToFolder = React.useCallback((key) =>
	{
		const foldersClone = [...folders];

		key ++;

		if(foldersClone.length > 1 && key < foldersClone.length)
		{
			foldersClone.splice(key, foldersClone.length - key);

			setFolders(foldersClone);
		}
	}, [folders]);

	const HandleDownload = React.useCallback((document) =>
	{
		Generic.DownloadURI(`${document.publicURL}?action=download`, document.name);
	}, []);

	const parent = React.useMemo(() =>
	(
		folders[folders.length - 1]
	), [folders]);

	const moduleDocuments = React.useMemo(() =>
	(
		storages.filter(storage => storage._folder === module.value)
	), [storages, module.value]);

	const body = React.useMemo(() =>
	{
		return moduleDocuments.filter(document => document.father === parent._id).map(document =>
		{
			if(!document.file)
			{
				const items = moduleDocuments.filter(item => item.father === document._id).length;

				document.items = items;
				document.sizeText = `${items} items`;
			}

			return document;
		})
	}, [moduleDocuments, parent]);

	const history = React.useMemo(() =>
	{
		const arrayFolders = folders.map((folder, key) =>
		{
			const color = key < folders.length - 1 ? 'blue pointer' : '';

			return <Box key={key} Click={() => GoToFolder(key)}><Text class={color}>{folder.name}</Text></Box>;
		});

		return arrayFolders.reduce((prev, curr, key) => [...prev, prev.length === 0 ? <Icon class='icon-folder-open' style={{marginRight: 8}} key={`${key}_icon`}/> : <Text style={{margin: '0px 5px'}} key={`${key}_sep`}>/</Text>, curr], []);
	}, [folders, GoToFolder]);

	const actions = React.useMemo(() =>
	{
		const result = [];

		if(!parent.readOnly)
		{
			result.push
			({
				class: 'background-blue white',
				text: Translate('Create Folder'),
				Click: () => ShowDocumentForm({}, {module, parent}),
				section: 'grouped'
			});

			result.push
			({
				class: 'background-blue white',
				text: Translate('Upload Files'),
				Click: () => ShowUploadFilesForm({module, parent}),
				section: 'grouped'
			});
		}

		result.push
		({
			text: Translate('Download'),
			section: 'context',
			icon:
			{
				class: 'icon-download'
			},
			Click: (document) =>
			{
				if(document.file)
				{
					HandleDownload(document);
				}
			}
		});

		result.push
		({
			text: `${Translate('Public')}/${Translate('Private')}`,
			section: 'context',
			icon:
			{
				class: 'icon-exchange'
			},
			Click: (document) =>
			{
				if(document.file)
				{
					PublicPrivate(document);
				}
			}
		});

		result.push
		({
			text: Translate('Edit'),
			section: 'context',
			icon:
			{
				class: 'icon-pencil'
			},
			Click: (data) =>
			{
				if(!data.readOnly)
				{
					ShowDocumentForm(data, {module, parent});
				}
				else
				{
					ShowReadOnlyWarning(data);
				}
			}
		})

		result.push
		({
			class: 'red',
			text: Translate('Delete'),
			section: 'context',
			icon:
			{
				class: 'icon-trash'
			},
			Click: (data) =>
			{
				if(!data.readOnly)
				{
					ShowDeleteWarning(data);
				}
				else
				{
					ShowReadOnlyWarning(data);
				}
			}
		});

		return result;
	}, [module, parent, Translate, ShowDocumentForm, ShowUploadFilesForm, ShowDeleteWarning, ShowReadOnlyWarning, HandleDownload, PublicPrivate]);

	return (
		<Section class='sice-documents full-height'>
			<Header>
				{history}
			</Header>
			<Body class='no-padding' style={{height: 'calc(100% - 50px)'}}>
				<Table
					header =
					{[
						{
							name: 'typeIcon',
							text: Translate('Type')
						},
						{
							name: 'name',
							text: Translate('Name')
						},
						{
							name: 'sizeText',
							text: Translate('Size')
						},
						{
							name: 'dateCreatedText',
							text: Translate('Date Created')
						},
						{
							name: 'publicURLIcon',
							text: Translate('Public URL')
						}
					]}
					body = {body}
					actions = {actions}
					DoubleClickRow = {document =>
					{
						if(!document.file)
						{
							setFolders(current => [...current, document]);
						}
						else
						{
							ShowFileViewer(document);
						}
					}}
					draggable = {draggableRows ? {types: ['data'], Condition: (row) => row.file && row.isPublic} : false}
				/>
			</Body>
		</Section>
	);
};

Documents.defaultProps =
{
	draggableRows: false,
	view: 'both' // public, private, both
}

export {Documents};