import React from 'react';
import {Section, Panel, Card, Header, Body, Text} from 'crack-ux';
import {Generic} from 'crack-functions';

// Contexts
import {useTraductor} from '../../../../../contexts/general/Traductor';
import {useMoves, GetSalesByDay} from '../../../../../contexts/collections/store/Moves';
import {useProducts} from '../../../../../contexts/collections/store/Products';

// Views
import {Loading} from '../../../../../components/views/Loading';

// Components
import {ProductsSold} from './ProductsSold';
import {SalesFilters} from './SalesFilters';

// Style
import './Sales.css';
import moment from 'moment';
import { SalesChart } from './SalesChart';

const defaultFilter =
{
	period: 'current-month',
	startDate: moment().startOf('month'),
	endDate: moment().endOf('month'),
}

const Sales = () =>
{
	const [loading, setLoading] = React.useState(true);
	const [filter, setFilter] = React.useState({...defaultFilter});
	const [productsData, setProductsData] = React.useState([]);
	const [datesData, setDatesData] = React.useState([]);

	const {Translate} = useTraductor();
	const {moves} = useMoves();
	const {products} = useProducts();

	const productsObj = React.useMemo(() => Generic.ArrayToObjectByIdentifier(products), [products]);

	const GetProductsTable = React.useCallback(() =>
	{
		const sales = moves.filter(move => move.type === 1 && move.dateObj >= filter.startDate && move.dateObj <= filter.endDate);

		const {productsData, datesData} = GetSalesByDay(sales, productsObj);

		setDatesData(Generic.ObjectToArray(datesData));
		setProductsData(Generic.ObjectToArray(productsData));
	}, [moves, productsObj, filter]);

	React.useEffect(() =>
	{
		GetProductsTable();
		setLoading(false);
	}, [GetProductsTable]);

	const periodOptions = React.useMemo(() =>
	[
		{
			text: Translate('Current Month'),
			value: 'current-month'
		},
		{
			text: Translate('Current Quarter'),
			value: 'current-quarter'
		},
		{
			text: Translate('Current Year'),
			value: 'current-year'
		},
		{
			text: Translate('Custom Dates'),
			value: 'custom'
		}
	], [Translate]);

	const periodText = React.useMemo(() =>
	(
		periodOptions.find(option => option.value === filter.period).text
	), [periodOptions, filter]);

	return (
		<Section class='full-height'>
			<Panel class='desktop9 full-height'>
				{
					loading ?
					<Loading/> :
					<Card>
						<Header>
							<Text class='bold'>{Translate('Sales Report')}</Text>
						</Header>
						<Body class='no-padding vertical-scroll' style={{height: 'calc(100vh - (2 * var(--header)) - 21px)'}}>
							<SalesChart dates={datesData} periodText={periodText}/>
							<ProductsSold products={productsData}/>
						</Body>
					</Card>
				}
			</Panel>
			<Panel class='desktop3'>
				<SalesFilters filter={filter} SetFilter={setFilter} defaultFilter={{...defaultFilter}} periodOptions={periodOptions}/>
			</Panel>
		</Section>
	)
}

export {Sales};