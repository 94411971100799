import React from "react";
import {Generic} from "crack-functions";

const Business = React.createContext({});

const BusinessProvider = (props) =>
{
	const CreateBusiness = async (business) =>
	{
		return Generic.Fetch('/business/create', business);
	}
			
	const UpdateBusiness = async (business) =>
	{
		return Generic.Fetch('/business/update', business);
	}
	
	const value =
	{
		CreateBusiness,
		UpdateBusiness
	}

	return <Business.Provider value={value} {...props}/>;
}

const useBusiness = () => React.useContext(Business);

export {BusinessProvider, useBusiness};