import React from "react";

// Contexts
import {useModal} from "../general/Modal";

// Forms
import {SignUp} from '../../components/forms/SignUp';

const PublicContext = React.createContext({});

const PublicProvider = (props) =>
{
	const [showMenu, setShowMenu] = React.useState(false);

	const {AddModal, modals} = useModal();
	
	const homeRef = React.useRef();
	const featuresRef = React.useRef();
	const servicesRef = React.useRef();
	const modulesRef = React.useRef();
	const faqsRef = React.useRef();
	const contactUsRef = React.useRef();

	const GoTo = (section) =>
	{
		switch(section)
		{
			case 'features':
				featuresRef.current.scrollIntoView({ behavior: 'smooth' });
			break;

			case 'services':
				servicesRef.current.scrollIntoView({ behavior: 'smooth' });
			break;

			case 'modules':
				modulesRef.current.scrollIntoView({ behavior: 'smooth' });
			break;

			case 'faqs':
				faqsRef.current.scrollIntoView({ behavior: 'smooth' });
			break;

			case 'contact-us':
				contactUsRef.current.scrollIntoView({ behavior: 'smooth' });
			break;

			default:
				homeRef.current.scrollIntoView({ behavior: 'smooth' });
			break;
		}

		setShowMenu(false);
	}

	const ShowSignUpForm = () =>
	{
		AddModal(<SignUp from='modal'/>);
	}

	const value =
	{
		showMenu,
		homeRef,
		featuresRef,
		servicesRef,
		modulesRef,
		faqsRef,
		contactUsRef,
		setShowMenu,
		GoTo,
		ShowSignUpForm,
	}

	return ( 		
		<PublicContext.Provider value={value}>
			{props.children}
			{modals}
		</PublicContext.Provider>
	);
}

const usePublic = () => React.useContext(PublicContext);

export {PublicProvider, usePublic};