import React from "react"
import {Content, Panel, Card, Header, Body, Text} from 'crack-ux';

// Contexts
import {useTraductor} from "../../../../contexts/general/Traductor";
import {useBusiness} from "../../../../contexts/general/Business";
import {InvoicesPageProvider} from "../../../../contexts/pages/business/home/Invoices";
import {useInvoices} from "../../../../contexts/collections/home/Invoices";

// Components
import {Loading} from "../../../../components/views/Loading/Loading";
import {InvoicesTable} from "../../../../components/tables/modules/home/Invoices";

const Invoices = () =>
{
	const [loading, setLoading] = React.useState(true);

	const {Translate} = useTraductor();
	const {Fetch} = useBusiness();

	const {LoadInvoices} = useInvoices();

	React.useEffect(() =>
	{
		const requests =
		[
			Fetch('/invoices/read', {_sort: {'info.date': -1}})
		];

		Promise.all(requests).then(responses =>
		{
			if(responses.every(response => response.status === 200))
			{
				LoadInvoices(responses[0].data || []);

				setLoading(false);
			}
		});
	}, [Fetch, LoadInvoices]);

	if(loading) <Loading/>

	return (
		<Content>
			<Panel class='no-padding full-height'>
				<Card>
					<Header>
						<Text class='bold'>{Translate('Billing')}</Text>
					</Header>
					<Body class='no-padding' style={{height: 'calc(100% - var(--header))'}}>
						<InvoicesTable/>
					</Body>
				</Card>
			</Panel>
		</Content>
	);
}

const InvoicesWithProviders = props =>
(
	<InvoicesPageProvider>
		<Invoices {...props}/>
	</InvoicesPageProvider>
);

export {InvoicesWithProviders as Invoices};