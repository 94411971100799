import {Icon} from 'crack-ux';
import {Generic} from 'crack-functions';
import moment from 'moment';

const defaultImages =
{
	audio: '/assets/audio.png',
	excel: '/assets/excel.png',
	pdf: '/assets/pdf.png',
	ppt: '/assets/ppt.png',
	video: '/assets/video.png',
	word: '/assets/word.png',
	noPreview: '/assets/no-preview.jpg',
};

const GetSize = (storage) =>
{
	let result = '';

	if(storage.file)
	{
		let value = storage.file.size || 0;
		let XB = '';
		const KB = 1024;
		const MB = KB * 1024;
		const GB = MB * 1024;

		if(value > GB)
		{
			value /= GB;
			XB = 'GB';
		}
		else if(value > MB)
		{
			value /= MB;
			XB = 'MB';
		}
		else if(value > KB)
		{
			value /= KB;
			XB = 'KB';
		}
		else
		{
			XB = 'Bytes';
		}

		result = `${value.toFixed(2)} ${XB}`;
	}
	else
	{
		result = `0 items`;
	}

	return result;
}

const GetExtension = (storage) =>
{
	let extension = null;

	if(storage.file)
	{
		const fileArray = storage.file.filename.split('.');
		extension = fileArray[(fileArray.length - 1)];
	}

	return extension;
}

const GetProps = (storage) =>
{
	const icon =
	{
		style: storage.readOnly ? {color: '#999999'} : {color: '#444444'}
	};
	let defaultURL = defaultImages.noPreview;
	let extension = '';

	icon.class = 'icon-folder';

	if(storage.file)
	{
		extension = GetExtension(storage);

		if(extension === 'pdf')
		{
			icon.class = 'icon-file-pdf';
			defaultURL = defaultImages.pdf;
		}
		else if(extension === 'docx' || extension === 'doc')
		{
			icon.class = 'icon-file-word';
			defaultURL = defaultImages.word;
		}
		else if(extension === 'xlsx' || extension === 'xls')
		{
			icon.class = 'icon-file-excel';
			defaultURL = defaultImages.excel;
		}
		else if(extension === 'pptx' || extension === 'ppt')
		{
			icon.class = 'icon-file-powerpoint';
			defaultURL = defaultImages.ppt;
		}
		else if(extension === 'jpg' || extension === 'jpeg' || extension === 'png' || extension === 'gif')
		{
			icon.class = 'icon-file-image';
			defaultURL = storage.publicURL;
		}
		else if(['mp3', 'ogg', 'flac', 'wma'].indexOf(extension) !== -1)
		{
			icon.class = 'icon-file-audio';
			defaultURL = defaultImages.audio;
		}
		else if(['mp4', 'avi', 'flv'].indexOf(extension) !== -1)
		{
			icon.class = 'icon-file-video';
			defaultURL = defaultImages.video;
		}
		else
		{
			icon.class = 'icon-doc-text';
		}
	}

	return {icon, defaultURL, extension};
}

const SetStorage = (storage) =>
{
	const {icon, defaultURL, extension} = GetProps(storage);

	storage.extension = extension;
	storage.defaultURL = defaultURL;
	storage.typeIcon = <Icon {...icon}/>;
	storage.dateCreatedText = moment(new Date(storage.dateCreated)).format('DD/MM/YYYY');
	storage.sizeText = GetSize(storage);
	storage.src = storage.file ? storage.publicURL : null;
	storage.fullName = `${storage.name}${storage.extension ? `.${storage.extension}`: ''}`;

	if(storage.isPublic)
	{
		storage.publicURLIcon = <Icon class='icon-docs white background-blue' style={{padding: 5}} Click={() => Generic.CopyToClipboard(storage.publicURL)}/>
	}
	else
	{
		storage.publicURLIcon = <></>
	}

	return storage;
}

const StoragesReducer = (state = {}, action) =>
{
	switch (action.type)
	{
		case 'LOAD_STORAGE':
			return {
				...state,
				storages: action.storages.map(storage => SetStorage(storage))
			}

		case 'CREATE_STORAGE':
			return {
				...state,
				storages: [...state.storages, SetStorage(action.storage)],
			}

		case 'UPDATE_STORAGE':
			return {
				...state,
				storages: state.storages.map(storage => storage._id === action.storage._id ? SetStorage(action.storage) : storage)
			}

		case 'DELETE_STORAGE':
		{
			return {
				...state,
				storages: state.storages.filter(storage => storage._id !== action.storage._id)
			}
		}

		default:
			return state;
	}
}

export {StoragesReducer};