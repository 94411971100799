import React from 'react';
import {Form, Button, Icon} from 'crack-ux';
import {Dates} from 'crack-functions';

// Contexts
import {useTraductor} from '../../../../../contexts/general/Traductor';
import {useCRM} from '../../../../../contexts/modules/CRM';
import {useLists} from '../../../../../contexts/collections/home/Lists';
import {useCompanies} from '../../../../../contexts/collections/crm/Companies';

// Static data
import phoneCodes from '../../../../../static-data/PhoneCodes.json'

const GetDefaultPhone = () =>
({
	country: "591",
	area: null,
	number: '',
	phone: ''
});

const GetDefaultEmail = () =>
({
	email: ''
});

const SimpleContactForm = (props) =>
{
	const {contact, messages, Change, disabled} = props;

	const [form, setForm] = React.useState
	({
		...contact,
		dob: Dates.GetDateForForm(contact.dob),
		saleFlow: contact.lastSaleFlow !== 'no-assigned' ? contact.lastSaleFlow : null
	});
	const [listsForm, setListsForm] = React.useState(contact);
	const [contactCompanies, setContactCompanies] = React.useState(contact.companies || []);
	const [phones, setPhones] = React.useState(Array.isArray(contact?.phones) ? [...contact.phones.map(phone => ({...phone})), GetDefaultPhone()] : [GetDefaultPhone()]);
	const [emails, setEmails] = React.useState(Array.isArray(contact?.emails) ? [...contact.emails.map(email => ({...email})), GetDefaultEmail()] : [GetDefaultEmail()]);

	const {Translate} = useTraductor();
	const {ShowCompanyForm} = useCRM();

	const {listsByName} = useLists();
	const {companies} = useCompanies();

	React.useEffect(() =>
	{
		Change({...form, gender: listsForm.gender, companies: contactCompanies, phones, emails});
	}, [form, listsForm, contactCompanies, phones, emails, Change]);

	const phoneInputs = React.useMemo(() =>
	{
		const inputs = [];

		for(let i = 0; i < phones.length; i++)
		{
			inputs.push
			({
				name: `phone-${i}-country`,
				text: Translate("Country"),
				type: 'select',
				class: 'desktop4',
				value: String(phones[i]?.country) || "591",
				options: phoneCodes,
				Change: (option) =>
				{
					const clonePhones = [...phones];
					clonePhones[i].country = option.value;
					setPhones(clonePhones);
				},
				disabled
			});

			inputs.push
			({
				name: `phone-${i}-number`,
				text: Translate("Phones"),
				class: 'desktop7 business-info-input',
				value: phones[i]?.number,
				Change: (value) =>
				{
					const clonePhones = [...phones];
					clonePhones[i].number = value;
					setPhones(clonePhones);
				},
				disabled
			});

			if(i === phones.length - 1)
			{
				inputs.push
				({
					type: 'content',
					class: `desktop1 business-info-input-icon-phones ${disabled ? 'disabled' : ''}`,
					content: 
					<Icon
						class= 'icon-plus pointer blue'
						Click={() =>
						{
							const clonePhones = [...phones];
							clonePhones.push(GetDefaultPhone());
							setPhones(clonePhones);
						}}
					/>
				})
			}
			else
			{
				inputs.push
				({
					type: 'content',
					class: `desktop1 business-info-input-icon-phones ${disabled ? 'disabled' : ''}`,
					content: 
					<Icon
						class= 'icon-minus pointer red' 
						Click={() =>
						{
							const clonePhones = [...phones];
							clonePhones.splice(i, 1);
							setPhones(clonePhones);
						}}
					/>
				})
			}
		}
		return inputs;
	}, [phones, Translate, disabled]);

	const emailInputs = React.useMemo(() =>
	{
		const inputs = [];
		for(let i = 0; i < emails.length; i++)
		{
			inputs.push
			({
				name: `email-${i}`,
				class: 'desktop11',
				text: Translate("Email"),
				value: emails[i]?.email,
				Change: (value) =>
				{
					const cloneEmails = [...emails];
					cloneEmails[i].email = value;
					setEmails(cloneEmails);
				},
				disabled
			});
			
			if(i === emails.length - 1)
			{
				inputs.push
				({
					type: 'content',
					class: `desktop1 business-info-input-icon-emails ${disabled ? 'disabled' : ''}`,
					content: 
					<Icon
						class= 'icon-plus pointer blue'
						Click={() =>
						{
							const cloneEmails = [...emails];

							cloneEmails.push(GetDefaultEmail());

							setEmails(cloneEmails);
						}}
					/>
				})
			}
			else
			{
				inputs.push
				({
					type: 'content',
					class: `desktop1 business-info-input-icon-emails ${disabled ? 'disabled' : ''}`,
					content: 
					<Icon
						class= 'icon-minus pointer red' 
						Click={() =>
						{
							const cloneEmails = [...emails];
		
							cloneEmails.splice(i, 1);
		
							setEmails(cloneEmails);
						}}
					/>
				})
			}
		}
		return inputs;
	}, [emails, Translate, disabled]);

	return (
		<Form
			inputs =
			{
				[
					{
						name: 'name',
						text: Translate('Name'),
						value: form.name,
						message: messages.name,
						disabled
					},
					{
						name: 'lastName',
						text: Translate("Last Name"),
						value: form.lastName,
						message: messages.lastName,
						disabled
					},
					{
						name: 'gender',
						text: Translate('Gender'),
						value: listsForm?.gender,
						message: messages.gender,
						type: 'autocomplete',
						options: listsByName.gender?.selectOptions || [],
						Change: (response) =>
						{
							if(typeof response === 'object')
							{
								setListsForm({...listsForm, gender: response.value});
							}
							else
							{
								setListsForm({...listsForm, gender: response});
							}
						},
						disabled
					},
					{
						name: 'dob',
						text: Translate("Date of Birth"),
						type: 'date',
						value: form.dob,
						message: messages.dob,
						disabled
					},
					...phoneInputs,
					...emailInputs,
					{
						name: 'saleFlow',
						text: Translate('Sale Stage'),
						value: form.saleFlow,
						message: messages.saleFlow,
						type: 'select',
						options: listsByName.salesFlow?.selectOptions || [],
						disabled
					},
					{
						name: 'tags',
						text: Translate('Tags'),
						value: form.tags,
						type: 'tags',
						disabled
					},
					{
						name: 'companies',
						text: Translate('Companies'),
						class: 'desktop9',
						type: 'autocomplete',
						options: companies.map(company =>
						{
							return {
								text: company.name,
								value: company._id
							}	
						}),
						multiple: true,
						value: contactCompanies,
						Change: (companies) => setContactCompanies(companies),
						message: messages.companies,
						disabled
					},
					{
						class: 'desktop3',
						type: 'content',
						content: <Button class='full-width background-blue white' text={Translate('Add')} Click={() => ShowCompanyForm()} disabled={disabled}/>
					},
				]                        
			}
			Change = {setForm}
		/>
	);
};

SimpleContactForm.defaultProps =
{
	contact: {},
	Change: () => {},
	disabled: false
};

export {SimpleContactForm};