import React from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';
import {Section} from 'crack-ux';

// Contexts
import {useBusiness} from '../../../contexts/general/Business';
import {CRMProvider, useCRM} from '../../../contexts/modules/CRM';
import {useStaffSession} from '../../../contexts/general/StaffSession';
import {useModules} from '../../../contexts/main/Modules';
import {StoragesProvider, useStorages} from '../../../contexts/collections/storage/Storage';
import {CompaniesProvider, useCompanies} from '../../../contexts/collections/crm/Companies';
import {ContactsProvider, useContacts} from '../../../contexts/collections/crm/Contacts';
import {TemplatesProvider, useTemplates} from '../../../contexts/collections/crm/Templates';
import {ScheduledMessagesProvider, useScheduledMessages} from '../../../contexts/collections/crm/ScheduledMessages';
import {ChatBotsProvider, useChatBots} from '../../../contexts/collections/crm/ChatBots';
import {CampaignsProvider, useCampaigns} from '../../../contexts/collections/crm/Campaigns';
import {ContactsNotesProvider} from '../../../contexts/collections/crm/ContactsNotes';
import {MessagesProvider, useMessages} from '../../../contexts/collections/crm/Messages';
import {EventsConfigProvider, useEventsConfig} from '../../../contexts/collections/scheduler/EventsConfig';
import {EventsProvider, useEvents} from '../../../contexts/collections/scheduler/Events';

// Views
import {Loading} from '../../../components/views/Loading';
import {ContactWindows} from '../../../components/views/modules/crm/ContactWindows/ContactWindows';

// Module Section
import {ModalProvider} from '../../../contexts/general/Modal';
import {Contacts} from './Contacts';
import {Companies} from './Companies';
import {Templates} from './Templates';
import {ChatBots} from './ChatBots';
import {ScheduledMessages} from './ScheduledMessages';
import {SalesFlow} from './SalesFlow';

const firstLoadLimit = 5000;

const CRM = () =>
{
	const [loading, setLoading] = React.useState(true);

	const {Fetch} = useBusiness();

	const {contactIds} = useCRM();
	const {member} = useStaffSession();

	const {modulesByName} = useModules();
	const {LoadStorages, SetModules} = useStorages();
	const {LoadCompanies, AddCompanies} = useCompanies();
	const {LoadContacts, AddContacts} = useContacts();
	const {LoadTemplates} = useTemplates();
	const {LoadScheduledMessages} = useScheduledMessages();
	const {LoadChatBots} = useChatBots();
	const {LoadCampaigns} = useCampaigns();
	const {LoadMessages} = useMessages();
	const {LoadEventsConfig} = useEventsConfig();
	const {LoadEvents} = useEvents();

	const GetMoreCompanies = React.useCallback((total, loaded) =>
	{
		if(loaded < total)
		{
			setTimeout(() =>
			{
				Fetch('/crm/companies/read', {syssec: 'crm', _skip: loaded, _limit: firstLoadLimit}).then(response =>
				{
					if(response.status === 200)
					{
						AddCompanies(response.data || [], loaded);
						GetMoreCompanies(total, loaded + response.data.length);
					}
				});
			}, 10000);
		}
	}, [Fetch, AddCompanies]);

	const GetMoreContacts = React.useCallback((total, loaded) =>
	{
		if(loaded < total)
		{
			setTimeout(() =>
			{
				Fetch('/crm/contacts/read', {syssec: 'crm', _skip: loaded, _limit: firstLoadLimit}).then(response =>
				{
					if(response.status === 200)
					{
						AddContacts(response.data || [], loaded);
						GetMoreContacts(total, loaded + response.data.length);
					}
				});
			}, 1000);
		}
	}, [Fetch, AddContacts]);

	React.useEffect(() =>
	{
		const requests =
		[
			Fetch('/storage/business/read', {syssec: 'crm', _where: {_folder: {$in: ['crm']}}}),
			Fetch('/crm/companies/read', {syssec: 'crm', _limit: firstLoadLimit, _count: true}),
			Fetch('/crm/contacts/read', {syssec: 'crm', _limit: firstLoadLimit, _count: true}),
			Fetch('/crm/templates/read', {syssec: 'crm'}),
			Fetch('/crm/scheduled-messages/read', {syssec: 'crm'}),
			Fetch('/crm/chat-bots/read', {syssec: 'crm'}),
			Fetch('/crm/campaigns/read', {syssec: 'crm'}),
			Fetch('/crm/last-messages/read', {syssec: 'crm'}),
			Fetch(`/events-config/read`, {syssec: 'crm', _where: {staff: {$in: [member._id]}}}),
			Fetch(`/events/read`, {syssec: 'crm', _where: {staff: {$in: [member._id]}}}),
		];

		Promise.all(requests).then(responses =>
		{
			if(responses.every(response => response.status === 200))
			{
				LoadStorages(responses[0].data || []);
				LoadCompanies(responses[1].data || []);
				LoadContacts(responses[2].data || []);
				LoadTemplates(responses[3].data || []);
				LoadScheduledMessages(responses[4].data || []);
				LoadChatBots(responses[5].data || []);
				LoadCampaigns(responses[6].data || []);
				LoadMessages(responses[7].data || []);
				LoadEventsConfig(responses[8].data || []);
				LoadEvents(responses[9].data || []);

				SetModules(['crm']);

				setLoading(false);

				if(responses[1]._count > firstLoadLimit)
				{
					GetMoreCompanies(responses[1]._count, firstLoadLimit);
				}

				if(responses[2]._count > firstLoadLimit)
				{
					GetMoreContacts(responses[2]._count, firstLoadLimit);
				}
			}
		});
	}, [member._id, Fetch, LoadStorages, SetModules, LoadCompanies, LoadContacts, LoadTemplates, LoadScheduledMessages, LoadChatBots, LoadCampaigns, AddContacts, GetMoreCompanies, GetMoreContacts, LoadMessages, LoadEventsConfig, LoadEvents]);

	const menu = React.useMemo(() => modulesByName?.crm?.menu || [], [modulesByName.crm]);
	const availableRoutes = React.useMemo(() =>
	{
		const result = [];
		const routesComponents =
		{
			'contacts': Contacts,
			'companies': Companies,
			'templates': Templates,
			'bots': ChatBots,
			'scheduled-messages': ScheduledMessages,
			'sales-flow': SalesFlow,
		};

		menu.forEach(item =>
		{
			const Page = routesComponents[item.route];

			if(Page)
			{
				result.push(<Route key={item.route} path={`/${item.route}`} element={<Page/>}/>)
			}
		});

		return result;
	}, [menu]);

	const contentHeight = React.useMemo(() => contactIds.length > 0 ? 'calc(100% - 36px)' : '100%', [contactIds]);

	if(loading)
	{
		return <Loading/>
	}
	else
	{
		return (
			<Section class='crm-module full-height relative'>
				<Section style={{height: contentHeight}}>
					<Routes>
						{availableRoutes}
						<Route path='*' element={<Navigate to={`./../home/${modulesByName.home?.menu?.[0]?.route}`}/>}/>
					</Routes>
				</Section>
				<ContactWindows/>
			</Section>
		)
	}
}

const CRMWithProviders = () =>
{
	return (
		<ModalProvider>
			<StoragesProvider syssec='crm'>
				<CompaniesProvider>
					<ContactsProvider>
						<TemplatesProvider>
							<ScheduledMessagesProvider>
								<ChatBotsProvider>
									<ContactsNotesProvider>
										<MessagesProvider>
											<CampaignsProvider>
												<EventsConfigProvider syssec='crm'>
													<EventsProvider syssec='crm'>
														<CRMProvider>
															<CRM/>
														</CRMProvider>
													</EventsProvider>
												</EventsConfigProvider>
											</CampaignsProvider>
										</MessagesProvider>
									</ContactsNotesProvider>
								</ChatBotsProvider>
							</ScheduledMessagesProvider>
						</TemplatesProvider>
					</ContactsProvider>
				</CompaniesProvider>
			</StoragesProvider>
		</ModalProvider>
	)
}

export {CRMWithProviders as CRM};