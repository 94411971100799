import React from 'react';
import {Card, Box, Header, Body, Footer, Text, Button, Icon} from 'crack-ux';

// Contexts
import {useModal} from '../../../../../contexts/general/Modal';
import {useTraductor} from '../../../../../contexts/general/Traductor';

// Tables
import {MoveDetailTable} from '../../../../tables/modules/store/MoveDetail';

const MoveDetail = (props) =>
{
	const {move} = props;

	const {Translate} = useTraductor();
	const {CloseModal} = useModal();

	return (
		<Card>
			<Header>
				<Text class='bold'>{Translate(move.type === 1 ? 'Sales' : 'Acquisition')} {Translate('Move Detail')} ({move.codeText})</Text>
				<Box class='float-right'>
					<Icon class='icon-cancel pointer gray' Click={CloseModal}/>
				</Box>
			</Header>
			<Body class='no-padding' style={{height: 'calc(100vh - var(--header) - 117px)'}}>
				<MoveDetailTable move={move}/>
			</Body>
			<Footer class='right'>
				<Button text={Translate('Close')} Click={CloseModal}/>
			</Footer>
		</Card>
	)
}

export {MoveDetail};