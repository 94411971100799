
import React from 'react';
import {Card, Header, Body, Footer, Form, Text, Button} from 'crack-ux';
// import {Dates} from 'crack-functions';

// Contexts
import {useTraductor} from '../../../../../contexts/general/Traductor';
import {useModal} from '../../../../../contexts/general/Modal';
import {useCampaigns} from '../../../../../contexts/collections/crm/Campaigns';

// Functions
import Validations from '../../../../../objects/Validate';

const CampaignForm = (props) =>
{
	const {campaign} = props;

	const [form, setForm] = React.useState
	({
		...campaign,
		// startDate: Dates.GetDateForForm(campaign.startDate || new Date()),
		// endDate: Dates.GetDateForForm(campaign.endDate)
	});
	const [messages, setMessages] = React.useState({});

	const {CloseModal} = useModal();
	const {Translate} = useTraductor();

	const {CreateCampaign, UpdateCampaign} = useCampaigns();

	const HandleSubmit = async () =>
	{
		const campaignData =
		{
			...form,
			// startDate: Dates.GetDateFromForm(form.startDate),
			// endDate: Dates.GetDateFromForm(form.endDate)
		};

		const result = campaign._id ? await UpdateCampaign({...campaignData, _id: campaign._id}) : await CreateCampaign(campaignData);

		setMessages(Validations.ParseByField(result.validations));

		if(result.status === 200)
		{
			CloseModal();
		}
	}

	return (
		<Card>
			<Header>
				<Text class='bold'>{Translate(campaign._id ? 'Edit Campaign' : 'New Campaign')}</Text>
			</Header>
			<Body class='vertical-scroll' style={{maxHeight: 'calc(100vh - var(--header) - 67px)', width: 400}}>
				<Form
					inputs =
					{[
						{
							name: 'name',
							text: Translate('Name'),
							value: form.name,
							message: messages.name,
						},
						// {
						// 	name: 'startDate',
						// 	text: Translate('Start Date'),
						// 	message: messages.startDate,
						// 	value: form.startDate,
						// 	class: 'desktop6',
						// 	type: 'date',
						// },
						// {
						// 	name: 'endDate',
						// 	text: Translate('End Date'),
						// 	message: messages.endDate,
						// 	value: form.endDate,
						// 	class: 'desktop6',
						// 	type: 'date',
						// },
						{
							name: 'tags',
							text: Translate('Tags'),
							value: form.tags,
							type: 'tags',
						},
						
					]}
					Change = {setForm}
				/>
			</Body>
			<Footer class='right'>
				<Button
					class = 'background-blue white'
					text = {Translate('Save')}
					Click = {HandleSubmit}
				/>
				<Button
					text = {Translate('Cancel')}
					Click = {CloseModal}
				/>
			</Footer>
		</Card>
	);
}

export {CampaignForm}