const SetProvider = (provider) =>
{
	provider.codeText = `PROV-${provider.code}`;

	return provider;
}

const ProvidersReducer = (state = {}, action) =>
{
	switch (action.type)
	{
		case 'LOAD_PROVIDERS':
			return {
				...state,
				providers: action.providers.map(provider => SetProvider(provider))
			}

		case 'CREATE_PROVIDER':
			return {
				...state,
				providers: [...state.providers, SetProvider(action.provider)],
			}

		case 'UPDATE_PROVIDER':
			return {
				...state,
				providers: state.providers.map(provider => provider._id === action.provider._id ? SetProvider(action.provider) : provider)
			}

		case 'DELETE_PROVIDER':
		{
			return {
				...state,
				providers: state.providers.filter(provider => provider._id !== action.provider._id)
			}
		}

		case 'SET_LATEST_PROVIDER_CODE':
		{
			return {
				...state,
				code: action.code
			}	
		}

		default:
			return state;
	}
}

export {ProvidersReducer};