import React from 'react';
import {Section, Text} from 'crack-ux';
import {Generic, Amount} from 'crack-functions';

// Contexts
import {useTraductor} from '../../../../../contexts/general/Traductor';
import {useLists, defaultListColor} from '../../../../../contexts/collections/home/Lists';

// Css
import './SideAway.css';

const SideAway = ({contacts, setStageContacts, setContactsTitle}) =>
{
	const {Translate} = useTraductor();
	const {listsByName} = useLists();

	const sideAwayList = React.useMemo(() =>
	{
		const stages = [...listsByName.salesFlow.workingItems.filter(item => item.data.sideWay)].map(item =>
		{
			const stageContacts = contacts.filter(contact => contact.lastSaleFlow === item._id);

			return {...item, stageContacts};
		});

		const baseContactsSize = contacts.length;

		return stages.map((item) =>
		{
			const stageContactsSize = item.stageContacts.length;
			const baseContactsPercentage = `${Amount.Round(baseContactsSize > 0 ? stageContactsSize * 100 / baseContactsSize : 0)}%`;

			const backgroundColor = item.color || defaultListColor;
			const color = Generic.HexIsLight(backgroundColor) ? 'var(--black)' : 'var(--white)';

			return (
				<Section 
					key = {item._id}
					class = 'side-away-stage center pointer'
					style =	{{backgroundColor}}
					Click = {() =>
					{
						setStageContacts(item.stageContacts);
						setContactsTitle(item.text);
					}}
				>
					<Text class='block' size={16} style={{color}}>{item.text}</Text>
					<Text class='block' type='p' style={{color}}>{Translate('Contacts')}: {stageContactsSize} ({baseContactsPercentage})</Text>
				</Section>
			);
		})
	}, [listsByName.salesFlow, contacts, Translate, setStageContacts, setContactsTitle]);

	return (
		<Section class='crm-side-away'>
			{sideAwayList}
		</Section>
	);
}

SideAway.defaultProps =
{
	contacts: [],
	setStageContacts: () => {},
	setContactsTitle: () => {},
};

export {SideAway};