import React from 'react';
import {Table} from 'crack-ux';

// Context
import {useTraductor} from '../../../../../contexts/general/Traductor';
import {useStaffModule} from '../../../../../contexts/modules/Staff';
import {useStaff} from '../../../../../contexts/collections/staff/Staff';

const StaffTable = (props) =>
{
	const {Translate} = useTraductor();

	const {ShowStaffInvitationForm} = useStaffModule();

	const {staff, SetCurrentMember, StatusStaffMember} = useStaff();

	return (
		<Table
			class = 'current'
			header =
			{[
				{
					name: 'nameText',
					text: Translate('Name')
				},
				{
					name: 'lastNameText',
					text: Translate('Last Name')
				},
				{
					name: 'emailText',
					text: Translate('Email')
				},
				{
					name: 'phoneText',
					text: Translate('Phone')
				},
				{
					name: 'statusText',
					text: Translate('Status')
				},
				{
					name: 'invitationText',
					text: Translate('Invitation')
				}
			]}
			body = {staff}
			actions =
			{[
				{
					text: Translate('New Member'),
					class: 'background-blue white',
					section: 'grouped',
					Click: ShowStaffInvitationForm
				},/*,
				{
					text: 'Edit Member',
					section: 'context',
					icon:
					{
						class: 'icon-pencil'
					},
					Click: (staffMember) =>
					{
						setCurrentStaffMember(staffMember);
						setShowModal(true)
					}
				},*/
				{
					text: 'Active / Inactive',
					section: 'context',
					class: 'red',
					icon:
					{
						class: 'icon-switch'
					},
					Click: (staffMember) =>
					{
						StatusStaffMember(staffMember)
					}
				}
			]}
			ClickRow = {(row) => SetCurrentMember(row)}
			searcher =
			{
				{text: Translate('Search')}
			}
			texts =
			{
				{
					noRecords:Translate('There Are Not Records To Show'),
					records:Translate('Records'),
					fields: Translate('Fields'),
					settings: Translate('Settings'),
					export: Translate('Export')
				}
			}
			filters = 
			{{
				columns: {
					editable: true
				},
				Apply:{
					text: Translate('Apply'),
					Click: () => {}
				},
				Reset:{
					text: Translate('Reset'),
					Click: () => {}
				}
			}}
		/>
	)
}

StaffTable.defaultProps =
{
	actions: []
}

export {StaffTable};