
import React from 'react';
import {Routes, Route, useNavigate} from 'react-router-dom';
import {Page, Box, Header, Body, Text, ContextMenu, Avatar, Panel} from 'crack-ux';
import {Text as TextF} from 'crack-functions';

// Layouts
import {Loading} from '../../components/views/Loading';
import {PersonalProvider} from '../../contexts/layouts/Personal';

// Pages
import {Business} from '../../pages/personal/Business';
import {Preferences} from '../../pages/personal/Preferences';
import {Profile} from '../../pages/personal/Profile/Profile';

// Views
import {PageNotFound} from '../../components/views/PageNotFound';

// Components
//import {Menu} from './Menu';
import {ProfileMenuSettings} from './Menu';

// Contexts
import {SessionProvider, useSession} from '../../contexts/general/Session';
import {WorkerProvider} from '../../contexts/general/Worker';
import {SocketProvider} from '../../contexts/general/Socket.js';
import {TraductorProvider} from '../../contexts/general/Traductor';
import {ModalProvider, useModal} from '../../contexts/general/Modal';

// Style
import './Personal.css';

const PersonalHeader = () =>
{
	const {session} = useSession();

	return (
		<Header class='background-blue no-border'>
			<Box class='pointer'>
				<Text class='white' size={16} style={{lineHeight: 1}}>Sice</Text>
			</Box>
			<Box class='float-right'>
				<Text class='white' size={16} style={{marginRight: 10}} >{TextF.FullName(session?.user?.name, session?.user?.lastName)}</Text>
				<Avatar 
					class = 'center background-green white' 
					src = {session.user.pictureURL || TextF.Initials(TextF.FullName(session.user.name, session.user.lastName))} 
					style = {{marginTop: '-7px'}}
					error = {TextF.Initials(TextF.FullName(session.user.name, session.user.lastName))}
				/>
			</Box>
		</Header>
	)
}

const Personal = (props) =>
{
	const [loading, setLoading] = React.useState(true);
	const [showMenu, setShowMenu] = React.useState(false);

	const {session, CheckSession, GetSession} = useSession();
	const {modals} = useModal('layout');
	const navigate = useNavigate();

	React.useEffect(() =>
	{
		CheckSession().then(sessionStatus =>
		{
			if(sessionStatus)
			{
				GetSession();
			}
			else
			{
				navigate('/');
			}
		});
	}, [CheckSession, GetSession, navigate]);

	React.useEffect(() =>
	{
		if(session?.user)
		{
			setLoading(false);
		}
	}, [session]);

	if(loading)
	{
		return <Loading/>;
	}
	else
	{
		return (
			<Page class='personal'>
				<PersonalHeader showMenu={showMenu} setShowMenu={setShowMenu}/>
				{/* <Menu showMenu={showMenu} setShowMenu={setShowMenu}/> */}
				<Panel style={{height: 'calc(100vh - var(--header))'}} swipe='horizontal'>
					<Panel class='desktop4'>
						<ProfileMenuSettings/>
					</Panel>
					<Panel class='desktop8 no-padding full-height'>
						<Body class='vertical-scroll no-padding full-height'>
							<Routes>
								<Route path="/business" exact={true} element={<Business/>}/>
								<Route path="/profile" exact={true} element={<Profile/>}/>
								<Route path="/preferences" exact={true} element={<Preferences/>}/>
								<Route path="*" element={<PageNotFound/>}/>
							</Routes>
						</Body>
					</Panel>
				</Panel>
				{modals}
			</Page>
		)
	}
}

const PersonalWithProviders = (props) =>
{
	return (
		<SocketProvider>
			<SessionProvider>
				<WorkerProvider>
					<TraductorProvider>
						<ModalProvider id='layout'>
							<PersonalProvider>
								<ModalProvider>
									<Personal {...props}/>
									<ContextMenu/>
								</ModalProvider>
							</PersonalProvider>
						</ModalProvider>
					</TraductorProvider>
				</WorkerProvider>
			</SessionProvider>
		</SocketProvider>
	)
}

export {PersonalWithProviders as Personal};