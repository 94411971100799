import React from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';

// Views
import {Loading} from '../../../components/views/Loading';

// Module Section
import {SalesPoint} from './SalesPoint';
import {Acquisitions} from './Acquisitions';
import {Moves} from './Moves';
import {Customers} from './Customers';
import {Providers} from './Providers';
import {Products} from './Products';
import {Categories} from './Categories';
import {Reports} from './Reports/Reports';

// Contexts
import {ModalProvider} from '../../../contexts/general/Modal';
import {useModules} from '../../../contexts/main/Modules';
import {StoreProvider} from '../../../contexts/modules/Store';
import {useBusiness} from '../../../contexts/general/Business';
import {useStorages} from '../../../contexts/collections/storage/Storage';
import {useCustomers} from '../../../contexts/collections/store/Customers';
import {useProviders} from '../../../contexts/collections/store/Providers';
import {useProducts} from '../../../contexts/collections/store/Products';
import {useCategories} from '../../../contexts/collections/store/Categories';
import {useMoves} from '../../../contexts/collections/store/Moves';

const Store = () =>
{
	const [loading, setLoading] = React.useState(true);

	const {Fetch} = useBusiness();

	const {modulesByName} = useModules();
	const {LoadStorages, SetModules} = useStorages();
	const {LoadCustomers} = useCustomers();
	const {LoadProviders} = useProviders();
	const {LoadProducts} = useProducts();
	const {LoadCategories} = useCategories();
	const {LoadMoves} = useMoves();

	React.useEffect(() =>
	{
		const requests =
		[
			Fetch('/store/customers/read'),
			Fetch('/store/providers/read'),
			Fetch('/store/products/read'),
			Fetch('/store/categories/read'),
			Fetch('/storage/business/read', {_where: {_folder: {$in: ['store']}}}),
			Fetch('/store/moves/read', {_sort: {code: -1}})
		];

		Promise.all(requests).then(responses =>
		{
			if(responses.every(response => response.status === 200))
			{
				LoadCustomers(responses[0].data || []);
				LoadProviders(responses[1].data || []);
				LoadProducts(responses[2].data || []);
				LoadCategories(responses[3].data || []);
				LoadStorages(responses[4].data || []);
				LoadMoves(responses[5].data || []);

				SetModules(['store']);

				setLoading(false);
			}
		});
	}, [Fetch, LoadCustomers, LoadProviders, LoadProducts, LoadCategories, LoadStorages, SetModules, LoadMoves]);

	const menu = React.useMemo(() => modulesByName?.store?.menu || [], [modulesByName.store]);
	const availableRoutes = React.useMemo(() =>
	{
		const result = [];
		const routesComponents =
		{
			'sales-point': SalesPoint,
			'acquisitions': Acquisitions,
			'moves': Moves,
			'customers': Customers,
			'providers': Providers,
			'products': Products,
			'categories': Categories,
			'reports': Reports,
		};

		menu.forEach(item =>
		{
			const Page = routesComponents[item.route];

			if(Page)
			{
				result.push(<Route key={item.route} path={`/${item.route === 'reports' ? 'reports/*' : item.route}`} element={<Page/>}/>)
			}
		});

		return result;
	}, [menu]);

	if(loading)
	{
		return <Loading/>
	}
	else
	{
		return (
			<Routes>
				{availableRoutes}
				<Route path='*' element={<Navigate to={`./../home/${modulesByName.home?.menu?.[0]?.route}`}/>}/>
			</Routes>
		)
	}
}

const StoreWithProviders = () =>
{
	return (
		<ModalProvider>
			<StoreProvider>
				<Store/>
			</StoreProvider>
		</ModalProvider>
	)
}

export {StoreWithProviders as Store};