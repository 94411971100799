import React from "react";
import {Form, Body, Card, Text, Button, Header, Footer} from 'crack-ux';
import {Generic} from 'crack-functions';

// Functions
import {useTraductor} from "../../../contexts/general/Traductor";
import Validations from "../../../objects/Validate";

// Contexts
import {useModal} from "../../../contexts/general/Modal";

const ContactUsForm = ({from, language, tags}) =>
{
	const [message, setMessage] = React.useState('');
	const [messages, setMessages] = React.useState({});
	const [form, setForm] = React.useState({});
	const [deleteForm, setDeleteForm] = React.useState(false);
	const [formKey, setFormKey] = React.useState(Date.now());

	const {Translate} = useTraductor();

	const {CloseModal} = useModal();

	const HandleSubmit = () =>
	{
		if(form.name && form.lastName && form.email && ((from === 'page' && form.message) || from === 'modal') && form.phoneCountry && form.phoneNumber)
		{
			const formData =
			{
				...form,
				message: from === 'modal' ? language === 'en' ? `I'm interested in ${tags[1]}` : `Estoy interesado en el ${tags[1]}` : form.message,
				phone:
				{
					country: form.phoneCountry,
					number: form.phoneNumber
				},
				tags: ['Web Page', ...tags]
			};

			Generic.Fetch(`/sice/contact/message`, formData).then(response =>
			{
				setMessages(Validations.ParseByField(response.validations));

				if(response.status === 200)
				{
					setForm({});
					setMessage(<Text class='green'>{Translate('Success', language)}</Text>);

					if(from === 'modal')
					{
						CloseModal();
					}
					else if(from === 'page')
					{
						setTimeout(() =>
						{
							setMessage(null);
						}, 5000);
					}

					setDeleteForm(true);
					setFormKey(Date.now());
				}
			});
		}
		else
		{
			setMessage(<Text class='red'>{Translate('Please complete the form', language)}</Text>)
		}
	}

	const inputs = React.useMemo(() =>
	{
		const inputsArray =
		[
			{
				name: 'name',
				text: Translate('Name', language),
				class: 'desktop6',
				value: form.name,
				message: messages.name
			},
			{
				name: 'lastName',
				text: Translate('Last Name', language),
				class: 'desktop6',
				value: form.lastName,
				message: messages.lastName
			},
			{
				name: 'phoneCountry',
				text: Translate('Phone', language),
				value: form.phoneCountry || '591',
				class: 'mobile4',
				type: 'select',
				options:
				[
					{
						text: '+591',
						value: '591'
					}
				],
				disabled: true
			},
			{
				name: 'phoneNumber',
				value: form.phoneNumber || '',
				class: 'mobile8',
				type: 'number',
				message: messages.phone
			},
			{
				name: 'email',
				text: Translate('Email', language),
				value: form.email,
				message: messages.email
			},
		];

		if(from !== 'modal')
		{
			inputsArray.push(
			{
				name: 'message',
				text: Translate('Message', language),
				type: 'textarea',
				value: form.message,
				rows: 10,
				message: messages.message
			})
		}

		if(from === 'modal')
		{
			inputsArray.push(
			{
				type: 'content',
				content: <Text class='center' type='p'>{language === 'en' ? 'Thank you for choose our plan. In order to provide you with optimal service and create your business record, we need you to provide us with some important information. We will respond to you within a maximum of 20 minutes. Thank you for trusting us. We are excited to work with you!' : 'Gracias por elegir nuestro plan. Para brindarte un servicio óptimo y crear el registro de tu negocio, necesitamos que nos proporciones algunos datos importantes. Te responderemos en un máximo de 20 minutos. Gracias por confiar en nosotros. ¡Estamos emocionados de trabajar contigo!'}</Text>
			})
		}

		return inputsArray;
	}, [Translate, form, from, language, messages]);

	const ContactForm = React.useCallback(() =>
	{
		if(deleteForm)
		{
			setDeleteForm(false);
			
			return <></>;
		}
		else
		{
			return (
				<Form
					key = {formKey}
					inputs = {inputs}
					Change = {setForm}
					response = {message}
					autoFocus = {false}
				/>
			)
		}
	}, [deleteForm, inputs, message, formKey]);

	return <Card>
				{
					from === 'modal' ?
					<Header>
						<Text class='bold'>{Translate(`Request ${tags[1]}`, language)}</Text>
					</Header> : <></>
				}
				<Body class='vertical-scroll left' style={{maxWidth: 480}}>
					{ContactForm()}
				</Body>
				<Footer class='right'>
					<Button
						text = {Translate('Submit', language)}
						class = 'background-blue white'
						Click = {HandleSubmit}
					/>
					{
						from === 'modal' ?
						<Button
							text = {Translate('Cancel', language)}
							Click = {CloseModal}
						/> : <></>
					}
				</Footer>
			</Card>
}

ContactUsForm.defaultProps =
{
	language: 'es',
	tags: []
};

export {ContactUsForm}