import React from "react";
import {Generic} from 'crack-functions';

// Contexts
import {BaseContextProvider, useBaseContext} from "../../objects/BaseContext";
import {useSession} from '../general/Session';

const Users = React.createContext();

const UsersProvider = (props) =>
{
	const Session = useSession();

	const CreateUser = async (user) =>
	{		
		return await Generic.Fetch('/user/create', user);
	}
	
	const UpdateUser = async (user) =>
	{	
		return await Generic.Fetch('/user/update', user).then(response =>
		{
			if(user._id === Session?.session?.user?._id)
			{
				Session.UpdateSession();
			}

			return response;
		});
	}

	const SearchUser = async (emailOrUser) =>
	{
		return await Generic.Fetch('/users/read', {_where: {$or: [{email: emailOrUser}, {user: emailOrUser}]}, _limit: 1});
	}

	const SavePassword = async (passwords) =>
	{
		return Generic.Fetch('/user/change-password', passwords);
	}

	const value =
	{
		...useBaseContext(),
		CreateUser,
		UpdateUser,
		SavePassword,
		SearchUser
	}

	return <Users.Provider value={value} {...props}/>;
}

const useUsers = () => React.useContext(Users);

const UsersProviderWithContext = (props) =>
{
	return (
		<BaseContextProvider {...props} list='users' item='user'>
			<UsersProvider {...props}/>
		</BaseContextProvider>
	)
}

UsersProvider.defaultProps =
{
	users: [],
	conditions:
	{
		create: false
	},
	settings:
	{
		socket: false
	}
}

export {UsersProviderWithContext as UsersProvider, useUsers};