import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Card, Header, Body, Footer, Form, Button, Text, Icon} from 'crack-ux';

// Contexts
import {useUsers} from '../../../contexts/main/Users';
import {useSession} from '../../../contexts/general/Session';
import {useModal} from '../../../contexts/general/Modal';
import {useTraductor} from '../../../contexts/general/Traductor';

// Objects
import Validate from '../../../objects/Validate';

// Static data
import phoneCodes from '../../../static-data/PhoneCodes.json';

const SignUp = (props) =>
{
	const {from} = props;

	const [data, setData] = React.useState({});
	const [response, setResponse] = React.useState(null);
	const [messages, setMessages] = React.useState({});

	const {Create: CreateUser} = useUsers();
	const {Login} = useSession();
	const {Translate} = useTraductor();
	const {CloseModal} = useModal();

	const navigate = useNavigate();

	const HandleSubmit = React.useCallback(async () =>
	{
		const passwordsValidation = Validate.ValidatePasswords(data.password, data.rePassword);

		setResponse(passwordsValidation);

		if(passwordsValidation === null)
		{
			data.phone = 
			{
				country: data.country,
				area: null,
				number: data.number
			}

			const createUserResponse = await CreateUser({...data, businessLimit: 0});
	
			setMessages(Validate.ParseByField(createUserResponse.validations));

			if(createUserResponse.status === 200)
			{
				const loginResponse = await Login({email: data.email, password: data.password});

				if(loginResponse.status === 200)
				{
					navigate('/p/business');
				}
			}
		}
	}, [CreateUser, Login, data, navigate]);

	const signUpForm = React.useMemo(() =>
	{
		return (
		<>
			<Body class='vertical-scroll' style={{maxHeight: 'calc(100%)'}}>
				<Form
					inputs =
					{[
						{
							name: 'user',
							text: Translate('Username'),
							placeholder: 'Enter your username',
							Change: () => setMessages({...messages, user: null}),
							message: messages.user
						},
						{
							name: 'name',
							text: Translate('Name'),
							class: 'desktop6',
							placeholder: 'Enter your Name',
							Change: () => setMessages({...messages, name: null}),
							message: messages.name
						},
						{
							name: 'lastName',
							text: Translate('Last Name'),
							class: 'desktop6',
							placeholder: 'Enter your Last Name',
							Change: () => setMessages({...messages, lastName: null}),
							message: messages.lastName
						},
						{
							name: 'email',
							text: Translate('Email'),
							placeholder: 'Enter your email',
							//Change: () => setMessages({...messages, email: null}),
							message: messages.email
						},
						{
							name: 'country',
							text: Translate('Phone'),
							type: 'select',
							class: 'mobile4',
							options: phoneCodes,
							value: "591"
						},
						{
							name: 'number',
							style: {marginTop: '-4px'},
							class: 'mobile8'
						},
						{
							name: 'password',
							text: Translate('Password'),
							type: 'password',
							class: 'desktop6',
							placeholder: 'Enter your password',
							Change: () => setMessages({...messages, password: null}),
							message: messages.password
						},
						{
							name: 'rePassword',
							text: Translate('Confirm Password'),
							type: 'password',
							class: 'desktop6',
							placeholder: 'Repeat your password',
							Change: () => setMessages({...messages, rePassword: null}),
							message: messages.rePassword
						}
					]}
					response = {response}
					Change = {(response) => setData(response)}
					Submit = {(response) => HandleSubmit(response)}
				/>
			</Body>
			<Footer>
				<Button class='background-blue white' text='Signup' Click={HandleSubmit} style={{width: '100%'}}/>
			</Footer>
		</>);
	}, [HandleSubmit, Translate, messages, response]);

	if(from === 'modal')
	{
		return (
			<Card style={{maxWidth: 420, maxheight: 'calc(100vh - 50px)'}}>
				<Header>
					<Text class='uppercase'>{Translate('Sing Up')}</Text>
					<Icon class='icon-cancel float-right pointer gray' Click={() => CloseModal()}/>
				</Header>
				{signUpForm}
			</Card>
		)
	}
	else
	{
		return signUpForm;
	}
}

SignUp.defaultProps =
{
	from: null
}

export {SignUp};