import React from 'react';
import {Card, Header, Body, Footer, Form, Button, Text} from 'crack-ux';

// Contexts
import {useModal} from '../../../../../contexts/general/Modal';
import {useTraductor} from '../../../../../contexts/general/Traductor';
import {useChatBots} from '../../../../../contexts/collections/crm/ChatBots';

// Static Data
import channels from '../../../../../static-data/Channels.json';

const ChatBotOptionForm = (props) =>
{
	const {option, channel, Submit} = props;

	const [form, setForm] = React.useState(option);

	const {CloseModal} = useModal();
	const {Translate} = useTraductor();

	const {chatBots} = useChatBots();

	const chatBotsOptions = React.useMemo(() =>
	{
		const channelIndex = channels.findIndex(item => item.value === channel.value);
		const workingNodes = chatBots.filter(item => item.channel === channelIndex);

		return workingNodes.map(item =>
		({
			text: item.name,
			value: item._id
		}))
	}, [chatBots, channel]);

	return (
		<Card>
			<Header>
				<Text class='bold'>{Translate(option._id ? 'Edit Option' : 'New Option')}</Text>
			</Header>
			<Body style={{maxHeight: 'calc(100vh - var(--header) - 67px)'}}>
				<Form
					inputs =
					{[
						{
							name: 'value',
							text: Translate('Value'),
							value: form.value
						},
						{
							name: 'chatBot',
							text: Translate('Chat Node'),
							value: form.chatBot,
							type: 'select',
							options: chatBotsOptions
						}
					]}
					Change = {setForm}
				/>
			</Body>
			<Footer class='right'>
				<Button
					class = 'background-blue white'
					text = {Translate('Save')}
					Click = {() =>
					{
						if(form.value || form.value === '0')
						{
							const optionData = {...option, ...form};
	
							Submit(optionData);
							CloseModal();
						}
					}}
				/>
				<Button
					text = {Translate('Cancel')}
					Click = {CloseModal}
				/>
			</Footer>
		</Card>
	);
}

export {ChatBotOptionForm};