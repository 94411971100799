import React from "react";

// Contexts
import {useModal} from "../../../general/Modal";
import {InvoicesProvider} from "../../../collections/home/Invoices";

// Forms
// import {ListOptionForm} from "../../../../components/forms/modules/home/List/ListOption";
import {InvoiceDetailsForm} from "../../../../components/forms/modules/home/InvoiceDetails/InvoiceDetails";

const InvoicesPageContext = React.createContext({});

const InvoicesPageProvider = (props) =>
{
	const {modals, AddModal} = useModal();

	const ShowInvoiceDetails = (invoice = {}) =>
	{
		AddModal(<InvoiceDetailsForm invoice = {invoice}/>);
	}

	const value =
	{
		ShowInvoiceDetails,
	}

	return (
		<InvoicesProvider>
			<InvoicesPageContext.Provider value={value}>
				{props.children}
				{modals}
			</InvoicesPageContext.Provider>
		</InvoicesProvider>
	);
}

const useInvoicesPage = () => React.useContext(InvoicesPageContext);

export {InvoicesPageProvider, useInvoicesPage};