import {Content, Panel, Box, Text} from 'crack-ux';

// Context
import {useTraductor} from '../../../contexts/general/Traductor';
import {usePublic} from '../../../contexts/layouts/Public';

// Forms
import {ContactUsForm} from '../../../components/forms/ContactUs/ContactUs';

const ContactUs = (props) =>
{
	const {language} = props;

	const {Translate} = useTraductor();
	const {contactUsRef} = usePublic();

	return (
		<Content reference={contactUsRef} class='contact-us section background-blue'>
			<Content class='laptop'>
				<Text class='title white'>{Translate('Contact Us', language)}</Text>
				<Panel class='center'>
					<Box style={{maxWidth: 420}}>
						<ContactUsForm from='page' language={language}/>
					</Box>
				</Panel>
			</Content>
		</Content>
	)
}

export {ContactUs};