import React from 'react';

// Context
import {useSocket} from '../../general/Socket';
import {useBusiness} from '../../general/Business';
import {useContacts} from './Contacts';

// Reducers
import {CampaignsReducer, SetCampaign} from '../../../reducers/collections/crm/Campaigns';

const CampaignsContext = React.createContext({});

const CampaignsProvider = (props) =>
{
	const [state, dispatch] = React.useReducer(CampaignsReducer, {campaigns: [], code: 0});
	const [currentCampaign, setCurrentCampaign] = React.useState({});
	const [eventId, setEventId] = React.useState('');
	const [oldSocket, setOldSocket] = React.useState({});

	const Socket = useSocket();
	const {Fetch, business} = useBusiness();

	const {contactsTags} = useContacts();

	const room = React.useMemo(() => `crm_campaigns_${business._id}`, [business._id]);
	const unique = React.useMemo(() =>
	{
		return eventId ? `CRM_CAMPAIGN_${eventId}` : 'CRM_CAMPAIGN';
	}, [eventId]);

	React.useEffect(() =>
	{
		Fetch('/crm/campaigns/code').then(response =>
		{
			if(response.status === 200)
			{
				dispatch({type: 'SET_LATEST_CAMPAIGN_CODE', code: response.data});
			}
		});
	}, [Fetch]);

	React.useEffect(() =>
	{
		const events =
		[
			{
				name: `CREATE_${unique}`,
				Function: (campaign) =>
				{
					dispatch({type: 'CREATE_CAMPAIGN', campaign});

					if(unique === 'CRM_CAMPAIGN') dispatch({type: 'SET_LATEST_CAMPAIGN_CODE', code: state.code + 1});
				}
			},
			{
				name: `UPDATE_${unique}`,
				Function: (campaign) =>
				{
					dispatch({type: 'UPDATE_CAMPAIGN', campaign});

					if(campaign._id === currentCampaign._id)
					{
						setCurrentCampaign(SetCampaign(campaign));
					}
				}
			},
			{
				name: `DELETE_${unique}`,
				Function: (campaign) =>
				{
					dispatch({type: 'DELETE_CAMPAIGN', campaign});

					// if(campaign._id === currentCampaign._id)
					// {
					// 	setCurrentCampaign({});
					// }
				}
			}
		];

		if(unique !== 'CRM_CAMPAIGN')
		{
			events.push
			({
				name: 'CREATE_CRM_CAMPAIGN',
				Function: () =>
				{
					dispatch({type: 'SET_LATEST_CAMPAIGN_CODE', code: state.code + 1});
				}
			})
		}

		Socket.ConnectEvents(room, events, oldSocket);

		if(oldSocket.unique !== unique)
		{
			setOldSocket({room, events, unique});
		}
	}, [Socket, room, unique, oldSocket, state.code, currentCampaign]);

	const globalCampaign = React.useMemo(() => SetCampaign
	({
		_id: 'global',
		code: 0,
		name: 'Global',
		tags: contactsTags
	}), [contactsTags]);

	// React.useEffect(() =>
	// {
	// 	if(!currentCampaign._id)
	// 	{
	// 		setCurrentCampaign(globalCampaign);
	// 	}
	// }, [currentCampaign, globalCampaign]);

	const LoadCampaigns = React.useCallback((campaigns = []) =>
	{
		dispatch({type: 'LOAD_CAMPAIGNS', campaigns});
	}, []);

	const CreateCampaign = async (campaign) =>
	{
		campaign.code = state.code + 1;
		campaign._socket = room;

		return await Fetch('/crm/campaign/create', campaign);
	}

	const UpdateCampaign = async (campaign) =>
	{
		campaign._socket = room;

		return await Fetch('/crm/campaign/update', campaign);
	}

	const DeleteCampaign = async (campaign) =>
	{
		campaign._socket = room;

		return await Fetch('/crm/campaign/delete', campaign);
	}
	
	const UpdateBusinessPhoneBot = async (data) =>
	{
		return await Fetch('/crm/campaign/set', data);
	}

	const value =
	{
		code: state.code,
		campaigns: [globalCampaign, ...state.campaigns],
		currentCampaign,
		setCurrentCampaign,
		SetEventId: setEventId,
		LoadCampaigns,
		CreateCampaign,
		UpdateCampaign,
		DeleteCampaign,
		UpdateBusinessPhoneBot
	}

	return <CampaignsContext.Provider value={value} {...props}/>
}

const useCampaigns = () => React.useContext(CampaignsContext);

export {CampaignsProvider, useCampaigns};