import React from 'react';
import {Card, Header, Body, Footer, Form, Button, Text} from 'crack-ux';

// Context
import {useModal} from '../../../../../contexts/general/Modal';
import {useTraductor} from '../../../../../contexts/general/Traductor';
import {useLists, listsWithColor, GetFieldsForForm} from '../../../../../contexts/collections/home/Lists';

// Functions
import Validations from '../../../../../objects/Validate';

const ListOptionForm = (props) =>
{
	const {internalName, listOption} = props;

	const [form, setForm] = React.useState(GetFieldsForForm(internalName, listOption));
	const [messages, setMessages] = React.useState({});

	const {CloseModal} = useModal();
	const {Translate} = useTraductor();

	const {AddItem, UpdateItem} = useLists();

	const HandleSubmit = async () =>
	{
		const optionData = {...form, order: listOption.order};

		if(listOption._id) optionData._id = listOption._id;

		const result = listOption._id ? await UpdateItem(internalName, optionData) : await AddItem(internalName, optionData);

		setMessages(Validations.ParseByField(result.validations));

		if(result.status === 200)
		{
			CloseModal();
		}
	}

	const specificInputs = React.useMemo(() =>
	{
		const result = [];

		if(listsWithColor.includes(internalName))
		{
			result.push
			({
				name: 'color',
				message: messages.color,
				text: Translate('Color'),
				value: form.color,
				type: 'color'
			});
		}

		switch(internalName)
		{
			case 'salesFlow':
				result.push
				({
					name: 'sideWay',
					text: Translate('Side Way'),
					value: form.sideWay,
					type: 'checkbox',
				});
			break;

			default:
			break;
		}

		return result;
	}, [internalName, form, messages, Translate]);

	return (
		<Card>
			<Header>
				<Text class='bold'>{listOption._id ? Translate('Edit Option') : Translate('New Option')}</Text>
			</Header>
			<Body style={{maxHeight: 'calc(100vh - (var(--header) * 2) - 65px)', width: 320, maxWidth: '100%'}}>
				<Form
					inputs =
					{
						[
							{
								name: 'text',
								text: Translate('Text'),
								value: form.text,
								message: messages.text
							},
							...specificInputs
						]
					}
					Change = {(response) => setForm(response)}
					Submit = {HandleSubmit}
				/>
			</Body>
			<Footer class='right'>
				<Button
					text = {Translate('Save')}
					class = 'background-blue white'
					Click = {HandleSubmit}
				/>
				<Button
					text = {Translate('Cancel')}
					Click = {CloseModal}
				/>
			</Footer>
		</Card>
	)
}

export {ListOptionForm};