import React from 'react';
import {Card, Header, Body, Footer, Form, Button, Text} from 'crack-ux';

// Context
import {useModal} from '../../../../../contexts/general/Modal';
import {useTraductor} from '../../../../../contexts/general/Traductor';
import {useCategories} from '../../../../../contexts/collections/store/Categories';

// Functions
import Validations from '../../../../../objects/Validate';

const CategoryForm = (props) =>
{
	const {category} = props;

	const [form, setForm] = React.useState(category);
	const [messages, setMessages] = React.useState({});

	const {CloseModal} = useModal();
	const {Translate} = useTraductor();

	const {CreateCategory, UpdateCategory} = useCategories();

	const HandleSubmit = async () =>
	{
		const data = {...category, ...form};

		const result = category._id ? await UpdateCategory(data) : await CreateCategory(data);

		setMessages(Validations.ParseByField(result.validations));

		if(result.status === 200)
		{
			CloseModal();
		}
	}

	return (
		<Card>
			<Header>
				<Text class='bold'>{category._id ? Translate('Edit Category') : Translate('New Category')}</Text>
			</Header>
			<Body>
				<Form
					inputs =
					{
						[
							{
								name: 'name',
								text: Translate('Name'),
								value: form.name,
								message: messages.name
							}
						]
					}
					Change = {(response) => setForm(response)}
					Submit = {HandleSubmit}
				/>
			</Body>
			<Footer class='right'>
				<Button
					text = {Translate('Save')}
					class = 'background-blue white'
					Click = {HandleSubmit}
				/>
				<Button
					text = {Translate('Cancel')}
					Click = {CloseModal}
				/>
			</Footer>
		</Card>
	)
}

export {CategoryForm};