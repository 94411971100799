import React from 'react';
import {Section, Icon} from 'crack-ux';

// Contexts
import {useScreen} from '../../contexts/general/Screen';
import {useBusiness} from '../../contexts/layouts/Business';
import {useModules} from '../../contexts/main/Modules';

// Styles
import './Modules.css';

const Modules = () =>
{
	const {deviceType} = useScreen();
	const {SwitchMenu, SetShowMenu, showMenu} = useBusiness();
	const {modules, currentModule, SetCurrentModule} = useModules();

	const modulesIcons = React.useMemo(() =>
	{
		return modules.map(module =>
		{
			return (
				<Icon
					key = {module._id}
					class = {`module pointer ${module._id === currentModule._id && 'current'} ${module.icon}`}
					Click = {() =>
					{
						SetCurrentModule(module);

						if(module._id === currentModule._id)
						{
							SwitchMenu();
						}
						else
						{
							SetShowMenu(true);
						}
					}}
				/>
			)
		});
	}, [modules, currentModule, SetCurrentModule, SetShowMenu, SwitchMenu]);

	return (
		<Section class='navigation-modules'>
			<Section class={`menu-icon ${deviceType === 'mobile' ? 'mobile-menu-icon' : ''}`}>
				<Icon class='icon-menu pointer' Click={SwitchMenu}/>
			</Section>
			<Section class={`modules ${deviceType === 'mobile' ? showMenu ? 'mobile-modules show' : 'mobile-modules hide' : ''}`}>
				{modulesIcons}
			</Section>
		</Section>
	)
}

export {Modules};