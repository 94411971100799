import React from 'react';
import {Content, Card, Header, Body, Text} from 'crack-ux';

// Tables
import {MovesTable} from '../../../../components/tables/modules/store/Moves';

// Contexts
import {useTraductor} from '../../../../contexts/general/Traductor';
import {useMoves} from '../../../../contexts/collections/store/Moves';

const Moves = () =>
{
	const {Translate} = useTraductor();

	const {moves} = useMoves();

	return (
		<Content>
			<Card>
				<Header>
					<Text class='bold'>{Translate('Moves')}</Text>
				</Header>
				<Body class='no-padding' style={{height: 'calc(100vh - (var(--header) * 2) - 20px)'}}>
					<MovesTable moves={moves}/>
				</Body>
			</Card>
		</Content>
	)
}

const MovesWithProviders = () =>
{
	return <Moves/>;
};

export {MovesWithProviders as Moves};