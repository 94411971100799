import React from 'react';
import {Table} from 'crack-ux';

// Contexts
import {useTraductor} from '../../../../../contexts/general/Traductor';
import {useChatBots as useChatBotsPage} from '../../../../../contexts/pages/business/crm/ChatBots';
import {useChatBots} from '../../../../../contexts/collections/crm/ChatBots';

// Static Data
import channels from '../../../../../static-data/Channels.json';

const ChatBotsTable = (props) =>
{
	const {Translate} = useTraductor();
	const {ShowChatBotForm, ShowDeleteChatBot, channel} = useChatBotsPage();

	const {chatBots} = useChatBots();

	const body = React.useMemo(() =>
	{
		const index = channels.findIndex(item => item.value === channel.value);

		return chatBots.filter(chatBot => chatBot.channel === index).map(chatBot =>
		{
			chatBot.chatStatusText = Translate(chatBot.chatStatusText);

			return chatBot;
		});
	}, [chatBots, channel, Translate])

	return (
		<Table
			header =
			{[
				{
					name: 'name',
					text: Translate('Name')
				},
				{
					name: 'message',
					text: Translate('Message'),
					maxWidth: 300,
					title: 'message'
				},
				{
					name: 'mainText',
					text: Translate('Main')
				},
				{
					name: 'chatStatusText',
					text: Translate('Chat Status')
				}
			]}
			body = {body}
			actions =
			{[
				{
					text: Translate('New Chat Bot'),
					class: 'background-blue white',
					section: 'grouped',
					Click: () => ShowChatBotForm({})
				},
				{
					text: Translate('Edit Chat Bot'),
					icon:
					{
						class: 'icon-pencil'
					},
					section: 'context',
					Click: (chatBot) => ShowChatBotForm(chatBot)
				},
				{
					text: Translate('Delete Chat Bot'),
					class: 'red',
					icon:
					{
						class: 'icon-trash'
					},
					section: 'context',
					Click: (chatBot) => ShowDeleteChatBot(chatBot)
				}
			]}
			DoubleClickRow = {(chatBot) => ShowChatBotForm(chatBot)}
			searcher =
			{
				{text: Translate('Search')}
			}
			texts =
			{
				{
					noRecords:Translate('There Are Not Records To Show'),
					records:Translate('Records'),
					fields: Translate('Fields'),
					settings: Translate('Settings'),
					export: Translate('Export')
				}
			}
			filters = 
			{{
				columns:
				{
					editable: true
				},
				Apply:
				{
					text: Translate('Apply'),
					Click: () => {}
				},
				Reset:
				{
					text: Translate('Reset'),
					Click: () => {}
				}
			}}
		/>
	)
};

export {ChatBotsTable};