import React from 'react';
import {Card, Header, Body, Footer, Form, Button, Text} from 'crack-ux';
import {Dates} from 'crack-functions';

// Context
import {useSession} from '../../../../../contexts/general/Session';
import {useModal} from '../../../../../contexts/general/Modal';
import {useTraductor} from '../../../../../contexts/general/Traductor';
import {useBusiness} from '../../../../../contexts/general/Business';
import {useSystemVariables} from '../../../../../contexts/collections/home/SystemVariables';
import {useTemplates} from '../../../../../contexts/collections/crm/Templates';
import {useContactsNotes} from '../../../../../contexts/collections/crm/ContactsNotes';

// Components
import {TextEditor} from '../../../../TextEditor/TextEditor';

// Functions
import Validations from '../../../../../objects/Validate';

const ContactNoteForm = ({note, contact}) =>
{
    const [form, setForm] = React.useState({...note, date: Dates.GetDateForForm(note.date || new Date())});
	const [title, setTitle] = React.useState(note.title || '');
	const [content, setContent] = React.useState(note.content || '');
	const [replaceContent, setReplaceContent] = React.useState(null);
	const [messages, setMessages] = React.useState({});

	const {session} = useSession();
    const {CloseModal} = useModal();
    const {Translate} = useTraductor();

	const {VariableReplacer} = useSystemVariables();
	const {business} = useBusiness();
	const {templates} = useTemplates();
	const {CreateNote, UpdateNote, code: ContactNoteCode} = useContactsNotes();

	const code = note.code || ContactNoteCode + 1;

	const HandleSubmit = async () =>
	{
		const cloneForm = {...form};

		cloneForm.code = code;
		cloneForm.content = content;
		cloneForm.title = title;

		const result = note._id ? await UpdateNote({...cloneForm, _id: note._id}) : await CreateNote({...cloneForm, contact: contact._id});

		setMessages(Validations.ParseByField(result.validations));
		
		if(result.status === 200)
		{
			CloseModal();
		}
	}

	const HandleTemplate = React.useCallback((option) =>
	{
		const templateFound = templates.find(template => template._id === option.value);

		if(templateFound)
		{
			setReplaceContent(templateFound.content ? VariableReplacer(templateFound.content, {business, contact, user: session.user}, 'crm-contact') : '');
			setTitle(templateFound.title ? VariableReplacer(templateFound.title, {business, contact, user: session.user}, 'crm-contact') : '');
		}
	}, [templates, VariableReplacer, business, contact, session.user]);

	const templatesOptions = React.useMemo(() =>
	(
		templates.filter(template => template.channel === 'note').map(template =>
		({
			text: template.name,
			value: template._id
		}))
	), [templates]);

	return (
		<Card>
			<Header>
				<Text class='bold'>{note._id ? Translate('Edit Note') : Translate('New Note')}</Text>
			</Header>
			<Body class='vertical-scroll' style={{width: '500px', maxWidth: 'calc(100vw - 20px)'}}>
				<Form
					inputs =
					{
						[
							{
								name: 'template',
								text: Translate('Template'),
								class: 'desktop8',
								type: 'select',
								options: templatesOptions,
								Change: HandleTemplate
							},
							{
								name: 'code',
								text: Translate('Code'),
								value: `NOTE-${code}`,
								class: 'desktop4',
								type: 'code',
								disabled: true
							},
							{
								name: 'title',
								text: Translate('Title'),
								value: title,
								class: 'desktop8',
								Change: setTitle,
								message: messages.title
							},
							{
								name: 'date',
								text: Translate('Date'),
								value: form.date,
								type: 'date',
								class: 'desktop4',
								message: messages.date
							},
							{
								text: <Text class='semi-bold'>{Translate('Content')}</Text>,
								type: 'content', 
								content: <TextEditor content={content} replaceContent={replaceContent} Change={setContent}/>
							}
						]                        
					}
					Change = {(response) => setForm(response)}
					Submit = {HandleSubmit}
				/>
			</Body>
			<Footer class='right'>
				<Button
					text = {Translate('Save')}
					class = 'background-blue white'
					Click = {HandleSubmit}
				/>
				<Button
					text = {Translate('Cancel')}
					Click = {CloseModal}
				/>
			</Footer>
		</Card>
	)
}

export {ContactNoteForm};