import React from "react";

// Contexts
import {MovesProvider} from "../../../collections/store/Moves";
import {ProductsProvider} from "../../../collections/store/Products";

const DashboardPageContext = React.createContext({});

const DashboardPageProvider = (props) =>
{	
	const value =
	{
	}

	return (
		<ProductsProvider>
			<MovesProvider>
				<DashboardPageContext.Provider value={value}>
					{props.children}
				</DashboardPageContext.Provider>
			</MovesProvider>
		</ProductsProvider>
	)
}

const useDashboardPage = () => React.useContext(DashboardPageContext);

export {DashboardPageProvider, useDashboardPage};