import React from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';

// Contexts
import {ModalProvider} from '../../../contexts/general/Modal';
import {StaffProviderModule} from '../../../contexts/modules/Staff';
import {useModules} from '../../../contexts/main/Modules';

// Module Section
import {Users} from './Users';

const Staff = () =>
{
	const {modulesByName} = useModules();

	const menu = React.useMemo(() => modulesByName?.staff?.menu || [], [modulesByName.staff]);
	const availableRoutes = React.useMemo(() =>
	{
		const result = [];
		const routesComponents =
		{
			'users': Users,
		};

		menu.forEach(item =>
		{
			const Page = routesComponents[item.route];

			if(Page)
			{
				result.push(<Route key={item.route} path={`/${item.route}`} element={<Page/>}/>)
			}
		});

		return result;
	}, [menu]);

	return (
		<Routes>
			{availableRoutes}
			<Route path='*' element={<Navigate to={`./../home/${modulesByName.home?.menu?.[0]?.route}`}/>}/>
		</Routes>
	)
}

const StaffWithProviders = () =>
{
	return (
		<ModalProvider>
			<StaffProviderModule>
				<Staff/>
			</StaffProviderModule>
		</ModalProvider>
	)
}

export {StaffWithProviders as Staff};