import React from 'react';
import {Section, Card, Header, Body, Footer, Form, Button, Text, Icon, FileList2} from 'crack-ux';

// Context
import {useModal} from '../../../../../contexts/general/Modal';
import {useTraductor} from '../../../../../contexts/general/Traductor';
import {useStore} from '../../../../../contexts/modules/Store';
import {useStorages} from '../../../../../contexts/collections/storage/Storage';
import {useProducts} from '../../../../../contexts/collections/store/Products';
import {useCategories} from '../../../../../contexts/collections/store/Categories';

// Functions
import Validations from '../../../../../objects/Validate';

// Styles
import './Product.css';

const ProductForm = (props) =>
{
	const {product} = props;

	const [form, setForm] = React.useState(product);
	const [messages, setMessages] = React.useState({});
	const [productCategories, setProductCategories] = React.useState(product.categories || []);
	const [pictures, setPictures] = React.useState(Array.isArray(product.pictures) ? product.pictures : []);
	const [features, setFeatures] = React.useState(product.features || [{_id: Date.now()}]);

	const {CloseModal} = useModal();
	const {Translate} = useTraductor();
	const {ShowCategoryForm} = useStore();

	const {storagesById} = useStorages();
	const {categories} = useCategories();

	const {code, CreateProduct, UpdateProduct} = useProducts();

	const Feature = React.useCallback((props) =>
	{
		const {feature, addFeature} = props;

		const iconClasses = addFeature ? 'icon-plus blue' : 'icon-minus red';

		const HandleFeatures = () =>
		{
			if(addFeature)
			{
				setFeatures([...features, {_id: Date.now()}])
			}
			else
			{
				const cloneFeatures = [...features];

				const index = features.findIndex(item => item._id === feature._id);
				cloneFeatures.splice(index, 1);

				setFeatures(cloneFeatures);
			}
		}

		const HandleUpdateFeature = (response) =>
		{
			const cloneFeatures = [...features];

			const index = features.findIndex(item => item._id === feature._id);
			cloneFeatures[index] = 
			{
				_id: cloneFeatures[index]._id,
				...response
			};

			setFeatures(cloneFeatures);
		}

		return (
			<Section key={feature._id}>
				<Form
					inputs =
					{[
						{
							name: 'title',
							text: Translate('Title'),
							class: 'desktop4',
							value: feature.title
						},
						{
							name: 'description',
							text: Translate('Description'),
							class: 'desktop7',
							value: feature.description
						},
						{
							type: 'content',
							class: 'desktop1',
							style:
							{
								height: 38
							},
							content: <Icon class={`${iconClasses} pointer`} Click={HandleFeatures}/>
						}
					]}
					autoFocus = {false}
					Change = {(response) => HandleUpdateFeature(response)}
				/>
			</Section>
		)
	}, [features, Translate]);

	const HandleSubmit = React.useCallback(async () =>
	{
		const data =
		{
			...form,
			code: parseInt(form.code.replace('PROD-', '')),
			price:
			{
				purchase: form.purchase,
				sale: form.sale
			},
			features: features.map(feature => ({title: feature.title, description: feature.description})),
			pictures: pictures.map(picture =>
			{
				const clonePicture = {...picture};

				if(clonePicture.localFile)
				{
					delete clonePicture.localFile;

					clonePicture.replaceFile = true;

					if(clonePicture._newFileFlag)
					{
						delete clonePicture._id;
					}
				}

				return clonePicture;
			}),
			available: product._id ? product.available : true,
			_files:
			{
				pictures: pictures.filter(picture => picture.localFile).map(picture => picture.localFile)
			}
		};

		if(product._id) data._id = product._id;

		const result = product._id ? await UpdateProduct(data) : await CreateProduct(data);

		setMessages(Validations.ParseByField(result.validations));

		if(result.status === 200)
		{
			CloseModal();
		}
	}, [features, pictures, CloseModal, CreateProduct, UpdateProduct, form, product]);

	return (
		<Card class='product-form' style={{width: 620}}>
			<Header>
				<Text class='bold'>{product._id ? Translate('Edit Product') : Translate('New Product')}</Text>
			</Header>
			<Body class='vertical-scroll full-height'>
				<Form
					inputs =
					{
						[
							{
								name: 'name',
								text: Translate('Name'),
								class: 'desktop9',
								value: form.name,
								message: messages.name
							},
							{
								name: 'code',
								text: Translate('Code'),
								class: 'desktop3',
								disabled: true,
								value: product.codeText || `PRO-${code + 1}`,
								message: messages.code
							},
							{
								name: 'description',
								text: Translate('Description'),
								type: 'textarea',
								value: form.description,
								message: messages.description
							},
							{
								text: Translate('Pictures'),
								type: 'content',
								content: (
									<FileList2
										fileList = {pictures.map(picture =>
										{
											picture.src = storagesById?.[picture?.file]?.publicURL || '/assets/box.png';
		
											return picture;
										})}
										accept = 'image/*'
										Change = {(response) => setPictures(response.files)}
									/>
								)
							},
							{
								text: Translate('Features'),
								type: 'content',
								content: 
								<Body class='features full-width no-break horizontal-scroll'>
									{features.map((feature, index) =>
									{
										const addFeature = features.length === 1 || index === features.length - 1;

										return Feature(
										{
											feature,
											addFeature
										})
									})}
								</Body>
							},
							{
								name: 'purchase',
								text: Translate('Purchase Price'),
								class: 'desktop6',
								type: 'number',
								value: form.price?.purchase || '0',
							},
							{
								name: 'sale',
								text: Translate('Sales Price'),
								class: 'desktop6',
								type: 'number',
								value: form.price?.sale || '0',
							},
							{
								name: 'categories',
								text: Translate('Categories'),
								class: 'desktop9',
								type: 'autocomplete',
								options: categories.map(category =>
								{
									return {
										text: category.name,
										value: category._id
									}	
								}),
								multiple: true,
								value: productCategories,
								Change: (categories) => setProductCategories(categories),
								message: messages.categories
							},
							{
								class: 'desktop3',
								type: 'content',
								content: <Button class='full-width background-blue white' text={Translate('Add Category')} Click={() => ShowCategoryForm()}/>
							},
						]
					}
					Change = {(response) => setForm({...product, ...response})}
					Submit = {HandleSubmit}
				/>
			</Body>
			<Footer class='right'>
				<Button
					text = {Translate('Save')}
					class = 'background-blue white'
					Click = {HandleSubmit}
				/>
				<Button
					text = {Translate('Cancel')}
					Click = {CloseModal}
				/>
			</Footer>
		</Card>
	)
}

export {ProductForm};