import React from "react";

// contexts
import {useModal} from "../../general/Modal";

// Forms
import {ChangePassword} from "../../../components/forms/ChangePassword/ChangePassword";

const ProfileContext = React.createContext({});

const ProfileProvider = (props) =>
{
	const [displayProfileForm, setDisplayProfileForm] = React.useState(false);

	const {modals, AddModal} = useModal();

	const ShowPasswordForm = (password = {}) =>
	{
		AddModal(<ChangePassword password={password}/>);
	}

	const value =
	{
		displayProfileForm,
		SetDisplayProfileForm: setDisplayProfileForm,
		ShowPasswordForm,
	}

	return ( 		
        <ProfileContext.Provider value={value}>
            {props.children}
			{modals}
        </ProfileContext.Provider>
	);
}

const useProfile = () => React.useContext(ProfileContext);

export {ProfileProvider, useProfile};