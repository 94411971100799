import React from "react";

// Contexts
import {useModal} from "../../../general/Modal";

// Forms
import {ScheduledMessageForm} from "../../../../components/forms/modules/crm/ScheduledMessage/ScheduledMessage";
import {ScheduledMessageContactsForm} from "../../../../components/forms/modules/crm/ScheduleMessageContacts/ScheduledMessageContacts";

// Warnings
import {DeleteScheduledMessage} from "../../../../components/warnings/crm/ScheduledMessage/ScheduledMessaje";

// Static Data
import channels from '../../../../static-data/Channels.json';
import scheduledMessagesTypes from '../../../../static-data/ScheduledMessagesTypes.json';

const ScheduledMessagesContext = React.createContext({});

const ScheduledMessagesProviderPage = (props) =>
{
	const [channel, setChannel] = React.useState(channels.filter(channel => !channel.hidden)[0]);
	const [scheduledMessageType, setScheduledMessageType] = React.useState(scheduledMessagesTypes[0]);

	const {AddModal} = useModal();

	const ShowScheduledMessageForm = (scheduledMessage = {}) =>
	{
		AddModal(<ScheduledMessageForm scheduledMessage = {scheduledMessage} channel={channel}/>);
	}

	const ShowDeleteScheduledMessage = (scheduledMessage = {}) =>
	{
		AddModal(<DeleteScheduledMessage scheduledMessage = {scheduledMessage}/>);
	}

	const ShowScheduledMessageContacts = (contacts, filteredContacts, setFilteredContacts) =>
	{
		AddModal(<ScheduledMessageContactsForm contacts={contacts} filteredContacts={filteredContacts} setFilteredContacts={setFilteredContacts}/>);
	}
	
	const value =
	{
		channel,
		scheduledMessageType,
		SetChannel: setChannel,
		setScheduledMessageType,
		ShowScheduledMessageForm,
		ShowDeleteScheduledMessage,
		ShowScheduledMessageContacts,
	}

	return (
		<ScheduledMessagesContext.Provider value={value}>
			{props.children}
		</ScheduledMessagesContext.Provider>
	)
}

const useScheduledMessagesPage = () => React.useContext(ScheduledMessagesContext);

export {ScheduledMessagesProviderPage, useScheduledMessagesPage};