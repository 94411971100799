const SetModule = (module) =>
{
	return module;
}

const ModulesReducer = (state = {}, action) =>
{
	switch (action.type)
	{
		case 'LOAD_MODULES':
			return {
				...state,
				modules: action.modules.map(module => SetModule(module))
			}

		case 'CREATE_MODULE':
			return {
				...state,
				modules: [...state.modules, SetModule(action.module)],
			}

		case 'UPDATE_MODULE':
			return {
				...state,
				modules: state.modules.map(module => module._id === action.module._id ? SetModule(action.module) : module)
			}

		case 'DELETE_MODULE':
		{
			return {
				...state,
				modules: state.modules.filter(module => module._id !== action.module._id)
			}
		}

		default:
			return state;
	}
}

export {ModulesReducer};