import React from "react";
import {Generic} from "crack-functions";

const Session = React.createContext();

const SessionProvider = (props) =>
{
	const [session, setSession] = React.useState(null);

	const Login = async (user) =>
	{
		const response = await Generic.Fetch('/user/login', user);
		if(response.status === 200)
		{
			setSession(response.data);
		}

		return response;
	}

	const Logout = async () =>
	{
		await Generic.Fetch('/user/logout');
	}

	const CheckSession = React.useCallback(async () =>
	{
		const response = await Generic.Fetch('/user/check-session');

		if(response.status === 200)
		{
			return response.data;
		}
	}, []);

	const GetSession = React.useCallback(async () =>
	{
		const response = await Generic.Fetch('/user/get-session');

		if(response.status === 200)
		{
			setSession(response.data);
			return response.data;
		}
	}, []);

	const UpdateSession = React.useCallback(async () =>
	{
		const updateResponse = await Generic.Fetch('/user/update-session');

		if(updateResponse.status === 200 && updateResponse.data)
		{
			const getResponse = await Generic.Fetch('/user/get-session');

			if(getResponse.status === 200)
			{
				setSession(getResponse.data);
			}
		}
		else
		{
			setSession({});	
		}
	}, []);

	React.useEffect(() =>
	{
		UpdateSession();
	}, [UpdateSession]);

	const SetPreference = async ({key, value}) =>
	{
		const data =
		{
			preferences:
			{
				...session.user.preferences,
				[key]: value
			}
		}

		return await Generic.Fetch('/user/setPreferences', data).then(res =>
		{
			if(res.status === 200)
			{
				setSession(res.data);
				window.location.reload(false);
			}
		});
	}

	const localStorage = React.useMemo(() =>
	{
		let result = {};

		if(session?.user?._id && typeof(Storage) !== 'undefined')
		{
			const foundStorage = window.localStorage.getItem(session.user._id);

			if(foundStorage !== null)
			{
				try
				{
					result = JSON.parse(foundStorage);
				}
				catch(e)
				{
					console.log('Error getting local storage', e);
				}
			}
		}

		return result;
	}, [session?.user?._id]);

	const SaveLocalStorage = React.useCallback((storage = {}) =>
	{
		if(session?.user?._id && typeof(Storage) !== 'undefined')
		{
			window.localStorage.setItem(session.user._id, JSON.stringify(storage));
		}
	}, [session?.user?._id]);

	const value =
	{
		session,
		localStorage,
		SaveLocalStorage,
		CheckSession,
		GetSession,
		Login,
		Logout,
		UpdateSession,
		SetPreference
	}

	return <Session.Provider value={value} {...props}/>;
}

const useSession = () => React.useContext(Session);

export {SessionProvider, useSession};