import React from 'react';
import {Panel, Box, Card, Header, Body, Footer, Button, Text, Icon, Table, Input, Form, ButtonGroup} from 'crack-ux';
import {Amount, Dates} from 'crack-functions';
import moment from 'moment';

// Context
import {useModal} from '../../../../../contexts/general/Modal';
// import {useBusiness} from '../../../../../contexts/general/Business';
import {useTraductor} from '../../../../../contexts/general/Traductor';
// import {useInvoices} from '../../../../../contexts/collections/invoicing/Invoices';
// import {useInvoices} from '../../../../contexts/pages/business/invoicing/Invoicing';

// Objects
// import Validations from '../../../../../objects/Validate';

// CSS
import './Invoice.css';

const defaultDetail =
{
	productCode: '', 
	amount: 0,
	unitMeasure: '',
	description: '',
	price: 0,
	total: 0,
}

const InvoiceForm = (props) =>
{
	const [form, setForm] = React.useState({date: Dates.GetDateForForm(moment())});
	const [details, setDetails] = React.useState([{...defaultDetail, _id: Date.now()}]);
	const [messages] = React.useState({});
	// const [total, setTotal] = React.useState(0);

	// const {business} = useBusiness();
	const {Translate} = useTraductor();
	const {CloseModal} = useModal();
	// const {CreateInvoice} = useInvoices();

	const UpdateValue = React.useCallback((detail) =>
	{
		const cloneDetails = [...details];

		const index = details.findIndex(item => item._id === detail._id);

		cloneDetails[index] = detail;
		cloneDetails[index].total = Amount.CalculateTotal({quantity: detail.quantity, price: detail.price})

		setDetails(cloneDetails);
	}, [details]);

	const Row = React.useCallback((detail = {}) =>
	{
		return {
			productCodeInput:
			(
				<Input 
					type='string'
					value={detail.productCode} 
					style={{width: 90}} 
					Change={(value)=> UpdateValue({...detail, productCode: value})}
				/>
			),
			amountInput:
			(
				<Input 
					type='number' 
					value={detail.amount} 
					style={{width: 52}} 
					Change={(value)=> UpdateValue({...detail, amount: value})}
				/>
			),
			unitMeasureInput:
			(
				<Input 
					value={detail.unitMeasure} 
					style={{width: 88}} 
					Change={(value)=> UpdateValue({...detail, unitMeasure: value})}
				/>
			),
			descriptionInput:
			(
				<Input 
					value={detail.description} 
					style={{width: 200}} 
					Change={(value)=> UpdateValue({...detail, description: value})}
				/>
			),
			priceInput:
			(
				<Input 
					type='number' 
					value={detail.price} 
					style={{width: 68}} 
					Change={(value)=> UpdateValue({...detail, price: value})}
				/>
			),
			totalText: Amount.CalculateTotal({quantity: detail.amount, price: detail.price, tax: 0}),
			optionIcon:
			(
				<Icon 
					class='icon-minus pointer red'
					style={{padding: 10}}
					Click={() =>
					{
						const filteredDetails = details.filter(item => item._id !== detail._id);
						setDetails(filteredDetails);
					}}
				/>
			)
		};
	}, [details, UpdateValue]);

	const HandleSubmit = async () =>
	{
		console.log(details);

		// const cloneForm = {...form};
		// const cloneDetails = [...details];

		// const newDetails = cloneDetails.map((detail) => 
		// 	{
		// 		return {
		// 			productCode: detail.productCode, 
		// 			amount: detail.amount,
		// 			unitMeasure: detail.unitMeasure,
		// 			description: detail.description,
		// 			price: detail.price,
		// 			discount: detail.discount,
		// 			total: detail.total,
		// 		}
		// 	}
		// );
		
		// console.log('handlesubmit newdetails: ', newDetails);
		
		// const newForm = {...cloneForm, details: newDetails, date: moment()};

		// newForm.businessNit = 101;
		// newForm.authorizationCode = 10101;
		// newForm.businessLocation = business.location.street;
		// newForm.businessPhone = business.phones;
		// newForm.state = 'available';
		// newForm.total = 1690;
		// newForm.number = 10003;
		// newForm.total = 101;

		// const result = await CreateInvoice(newForm);
		
		// setMessages(Validations.ParseByField(result.validations));

		// if(result.status === 200)
		// {
		// 	CloseModal();
		// }
	};

	return (
		<Card style = {{width: 'calc(100vw - 50px)', height: 'calc(100vh - 50px)'}}>
			<Body class='no-padding' style={{height: 'calc(100% - 67px)'}}>
				<Panel class='desktop3 no-padding full-height'>
					<Header>
						<Text class='bold'>{Translate('New Invoice')}</Text>
					</Header>
					<Body class='vertical-scroll' style={{height: 'calc(100% - var(--header))'}}>
						<Form
							inputs =
							{
								[
									{
										name: 'clientName',
										text: Translate('Name'),
										value: form.name,
										message: messages.name
									},
									{
										name: 'uniqueName',
										text: Translate("Invoice Type"),
										type: 'select',
										options:
										[
											{
												text: 'Factura Normal',
												value: 'normal-invoice'
											},
										],
										message: messages.uniqueName
									},
									{
										name: 'clientNit',
										text: Translate('TIN/NID'),
										// value: form.name,
									},
									{
										name: 'documentType',
										text: Translate("Document Type"),
										type: 'select',
										options:
										[
											{
												text: 'Carnet de Indetidad',
												value: 'normal-invoice'
											},
											{
												text: 'NIT',
												value: 'normal-invoice2'
											},
											{
												text: 'Passport',
												value: 'normal-invoice3'
											},
											{
												text: 'Documento Extranjero',
												value: 'normal-invoice4'
											}
										]
										// value: form.uniqueName,
										// Change: () => setMessages({...messages, uniqueName: null}),
									},
									{
										name: 'date',
										text: Translate('Date'),
										type: 'date',
										value: form.date
										// value: form.name,
									},
									{
										name: 'time',
										text: Translate('time'),
										type: 'time',
										value: form.time
										// value: form.name,
									},
								]
							}
							Change = {(response) => {setForm(response)}}
						/>
					</Body>
				</Panel>
				<Panel class='desktop9 no-padding full-height'>
					<Header>
						<Text class='bold'>{Translate('Details')}</Text>
					</Header>
					<Body class='no-padding' style={{height: 'calc(100% - var(--header) - 67px)'}}>
						<Table
							header =
							{[
								{
									name: 'productCodeInput',
									text: Translate('Product Code')
								},
								{
									name: 'amountInput',
									text: Translate('Amount'),
									widht: 200
								},
								{
									name: 'unitMeasureInput',
									text: Translate('Unit Measure'),
								},
								{
									name: 'descriptionInput',
									text: Translate('Description')
								},
								{
									name: 'priceInput',
									text: Translate('Unit Price')
								},
								{
									name: 'totalText',
									text: Translate('SubTotal')
								},
								{
									name: 'optionIcon',
									text: ''
								}
							]}
							body = {details.map((detail) =>
							{
								return Row(detail);	
							})}
							actions =
							{[
								{
									text: Translate('New Detail'),
									class: 'background-blue white',
									Click: () => setDetails([...details, {...defaultDetail, _id: Date.now()}])
								}
							]}
							exportable = {false}
							searcher = {false}
							footer = {false}
							pager = 
							{{
								rows: 1000
							}}
						/>
					</Body>
				</Panel>
			</Body>
			<Footer class='right'>
				<Box class='float-left'>
					<Text>Total:</Text>
				</Box>
				<ButtonGroup
					actions =
					{[
						{
							text: Translate('Save'),
							class: 'background-blue white',
							Click: HandleSubmit
						},
						{
							text: Translate('Save and Print'),
							class: 'background-blue white',
							Click: HandleSubmit
						},
					]}
				/>
				<Button
					style = 
					{{
						marginLeft: 10
					}}
					text = {Translate('Cancel')}
					Click = {CloseModal}
				/>
			</Footer>
		</Card>
	) 
}

export {InvoiceForm};