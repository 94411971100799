import React from 'react';
import {Table} from 'crack-ux';

// Contexts
import {useTraductor} from '../../../../../contexts/general/Traductor';
import {useStore} from '../../../../../contexts/modules/Store';
import {useProviders} from '../../../../../contexts/collections/store/Providers';

const ProvidersTable = () =>
{
	const {Translate} = useTraductor();

	const {providers} = useProviders();
	const {ShowProviderForm, ShowDeleteProviderWarning, ShowMoves} = useStore();

	return (
		<Table
			class = 'hover pointer'
			header = 
			{[
				{
					name: 'codeText',
					text: Translate('Code')
				},
				{
					name: 'name',
					text: Translate('Name')
				},
				{
					name: 'nit',
					text: Translate('DNI/NIT')
				}
			]}
			body = {providers}
			actions =
			{[
				{
					text: Translate('New Provider'),
					class: 'background-blue white',
					section: 'grouped',
					Click: () => ShowProviderForm()
				},
				{
					text: Translate('List of Acquisitions'),
					section: 'context',
					icon:
					{
						class: 'icon-clipboard'
					},
					Click: (provider) => ShowMoves({type: 2, contact: provider})
				},
				{
					text: Translate('Edit Provider'),
					section: 'context',
					icon:
					{
						class: 'icon-pencil'
					},
					Click: (provider) => ShowProviderForm(provider)
				},
				{
					text: Translate('Delete Provider'),
					class: 'red',
					section: 'context',
					icon:
					{
						class: 'icon-trash'
					},
					Click: (provider) => ShowDeleteProviderWarning(provider)
				}
			]}
			exportable = {false}
			searcher =
			{
				{text: Translate('Search')}
			}
			texts =
			{
				{
					noRecords:Translate('There Are Not Records To Show'),
					records:Translate('Records'),
					fields: Translate('Fields'),
					settings: Translate('Settings')
				}
			}
			filters = 
			{{
				columns:
				{
					editable: true
				},
				Apply:
				{
					text: Translate('Apply'),
					Click: () => {}
				},
				Reset:
				{
					text: Translate('Reset'),
					Click: () => {}
				}
			}}
		/>
	)
}

export {ProvidersTable};