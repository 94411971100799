import React from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';

// Contexts
import {ModalProvider} from '../../../contexts/general/Modal';
import {useBusiness} from '../../../contexts/general/Business';
import {useStaffSession} from '../../../contexts/general/StaffSession';
import {SchedulerProviderModule} from '../../../contexts/modules/Scheduler';
import {useModules} from '../../../contexts/main/Modules';
import {useEventsConfig} from '../../../contexts/collections/scheduler/EventsConfig';
import {useEvents} from '../../../contexts/collections/scheduler/Events';

// Module Section
import {CalendarPage} from './Calendar';

// Components
import {Loading} from '../../../components/views/Loading';

const Scheduler = () =>
{
	const [loading, setLoading] = React.useState(true);

	const {Fetch} = useBusiness();
	const {member} = useStaffSession();
	const {modulesByName} = useModules();
	const {LoadEventsConfig} = useEventsConfig();
	const {LoadEvents} = useEvents();

	React.useEffect(() =>
	{
		if(member._id)
		{
			const requests =
			[
				Fetch(`/events-config/read`, {_where: {staff: {$in: [member._id]}}}),
				Fetch(`/events/read`, {_where: {staff: {$in: [member._id]}}}),
			];
	
			Promise.all(requests).then(responses =>
			{
				if(responses.every(response => response.status === 200))
				{
					LoadEventsConfig(responses[0].data || []);
					LoadEvents(responses[1].data || []);
			
					setLoading(false);
				}
			});
		}
	}, [Fetch, member, LoadEventsConfig, LoadEvents]);

	const menu = React.useMemo(() => modulesByName?.scheduler?.menu || [], [modulesByName.scheduler]);
	const availableRoutes = React.useMemo(() =>
	{
		const result = [];
		const routesComponents =
		{
			'calendar': CalendarPage,
		};

		menu.forEach(item =>
		{
			const Page = routesComponents[item.route];

			if(Page)
			{
				result.push(<Route key={item.route} path={`/${item.route}`} element={<Page/>}/>)
			}
		});

		return result;
	}, [menu]);

	if(loading) return <Loading/>

	return (
		<Routes>
			{availableRoutes}
			<Route path='*' element={<Navigate to={`./../home/${modulesByName.home?.menu?.[0]?.route}`}/>}/>
		</Routes>
	)
}

const SchedulerWithProviders = () =>
{
	return (
		<ModalProvider>
			<SchedulerProviderModule>
				<Scheduler/>
			</SchedulerProviderModule>
		</ModalProvider>
	)
}

export {SchedulerWithProviders as Scheduler};