import React from "react";

// Contexts
import {useModal} from "../general/Modal";

// Forms
import {StaffInvitation} from "../../components/forms/modules/staff/StaffInvitation/StaffInvitation";

const StaffModuleContext = React.createContext({});

const StaffProviderModule = (props) =>
{
	const {AddModal, modals} = useModal();

	const ShowStaffInvitationForm = () =>
	{
		AddModal(<StaffInvitation/>);
	}

	const value =
	{
		ShowStaffInvitationForm,
	}

	return (
		<StaffModuleContext.Provider value={value}>
			{props.children}
			{modals}
		</StaffModuleContext.Provider>
	);
}

const useStaffModule = () => React.useContext(StaffModuleContext);

export {StaffProviderModule, useStaffModule};