import React from 'react';
import {Card, Header, Body, Footer, Section, Button, Text} from 'crack-ux';

// Context
import {useModal} from '../../../../../contexts/general/Modal';
import {useTraductor} from '../../../../../contexts/general/Traductor';

// Tables
import {InvoiceDetailsTable} from '../../../../tables/modules/home/InvoiceDetails/InvoiceDetails';

const InvoiceDetailsForm = (props) =>
{
	const {invoice} = props;

	const {CloseModal} = useModal();
	const {Translate} = useTraductor();

	return (
		<Card>
			<Header>
				<Text class='bold'>{Translate('Invoice Details')}</Text>
			</Header>
			<Body class='vertical-scroll no-padding' style={{height: 400, maxHeight: 'calc(100vh - (var(--header) * 2) - 65px - 102px)', width: 800, maxWidth: '100%'}}>
				<InvoiceDetailsTable detail={invoice.detail}/>
			</Body>
			<Body class='right'>
				<Section>
					<Text type='p' style={{width: 80}}>{Translate('Subtotal')}:</Text>
					<Text type='p' style={{width: 80}}>{invoice.subtotalText}</Text>
				</Section>
				<Section>
					<Text type='p' style={{width: 80}}>{Translate('IVA')}:</Text>
					<Text type='p' style={{width: 80}}>13%</Text>
				</Section>
				<Section>
					<Text class='bold' type='p' style={{width: 80}}>{Translate('Total')}:</Text>
					<Text class='bold' type='p' style={{width: 80}}>{invoice.totalText}</Text>
				</Section>
			</Body>
			<Footer class='right'>
				<Button
					text = {Translate('Cancel')}
					Click = {CloseModal}
				/>
			</Footer>
		</Card>
	)
}

export {InvoiceDetailsForm};