import React from 'react';
import {Table} from 'crack-ux';

// Contexts
import {useTraductor} from '../../../../../contexts/general/Traductor';
import {useMoves} from '../../../../../contexts/collections/store/Moves';
import {useStore} from '../../../../../contexts/modules/Store';

const MovesTable = ({moves}) =>
{
	const {Translate} = useTraductor();

	const {AnnulledMove} = useMoves();
	const {ShowMoveDetailView} = useStore();
	
	return (
		<Table
			class = 'hover pointer'
			header = 
			{[
				{
					name: 'status',
					text: Translate('Status'),
					width: 73
				},
				{
					name: 'codeText',
					text: Translate('Code')
				},
				{
					name: 'dateTimeText',
					text: Translate('Date')
				},
				{
					name: 'typeText',
					text: Translate('Type')
				},
				{
					name: 'totalText',
					text: Translate('Total')
				},
				{
					name: 'name',
					text: Translate('Name')
				},
				{
					name: 'nit',
					text: Translate('TIN/NID')
				}
			]}
			body = {moves}
			actions =
			{[
				{
					text: Translate('View Detail'),
					section: 'context',
					icon:
					{
						class: 'icon-clipboard'
					},
					Click: (move) => ShowMoveDetailView(move)
				},
				{
					text: Translate('Anulled'),
					section: 'context',
					class: 'red',
					icon:
					{
						class: 'icon-dot-circled'
					},
					Click: (move) => AnnulledMove(move)
				},
			]}
			exportable = {false}
			searcher =
			{
				{text: Translate('Search')}
			}
			texts =
			{
				{
					noRecords:Translate('There Are Not Records To Show'),
					records:Translate('Records'),
					fields: Translate('Fields'),
					settings: Translate('Settings')
				}
			}
			filters = 
			{{
				columns:
				{
					editable: true
				},
				Apply:
				{
					text: Translate('Apply'),
					Click: () => {}
				},
				Reset:
				{
					text: Translate('Reset'),
					Click: () => {}
				}
			}}
		/>
	)
}

export {MovesTable};