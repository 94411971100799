import React from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';
import {Page} from 'crack-ux';

// Pages
import {Home} from '../../pages/public/Home';
import {PasswordRecover} from '../../pages/public/PasswordRecover';
import {Business} from '../../pages/public/business/Business';
import {AcceptedInvitation} from '../../pages/public/AcceptInvitation/AcceptInvitation';

// Contexts
import {TraductorProvider} from '../../contexts/general/Traductor';
import {ModalProvider} from '../../contexts/general/Modal';
import {SessionProvider} from '../../contexts/general/Session';
import {UsersProvider} from '../../contexts/main/Users';
import {PublicProvider} from '../../contexts/layouts/Public';

// Styles
import './Public.css';

const Public = () =>
{
	return (
		<Page class='public'>
			<Routes>
				<Route path='/es' exact={true} element={<Home language='es'/>}/>
				<Route path='/en' exact={true} element={<Home language='en'/>}/>
				<Route path='/:business/*' element={<Business/>}/>
				<Route path="/recover-password" exact={true} element={<PasswordRecover/>}/>
				<Route path="/recover-password/:token" exact={true} element={<PasswordRecover/>}/>
				<Route path="/accept-invitation/:business/:user" exact={true} element={<AcceptedInvitation/>}/>
				<Route path='/' element={<Navigate to='/es'/>}/>
			</Routes>
		</Page>
	)
}

const PublicWithProviders = () =>
{
	return (
		<ModalProvider>
			<SessionProvider>
				<TraductorProvider>
					<UsersProvider>
						<PublicProvider>
							<Public/>
						</PublicProvider>
					</UsersProvider>
				</TraductorProvider>
			</SessionProvider>
		</ModalProvider>
	)	
}

export {PublicWithProviders as Public};