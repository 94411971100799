import React from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';
import {Section} from 'crack-ux';

// Module Section
import {Dashboard} from './Dashboard/Dashboard';
import {BusinessInfo} from './BusinessInfo';
import {SystemVariables} from './SystemVariables';
import {Integrations} from './Integrations';
import {Lists} from './Lists';
import {Credits} from './Credits';
import {Invoices} from './Billings';
import {Modules} from './Modules';

// Contexts
import {ModalProvider} from '../../../contexts/general/Modal';
import {useBusiness} from '../../../contexts/general/Business';
import {useModules as useSystemModules} from '../../../contexts/main/Modules';
import {ModulesProvider, useModules} from '../../../contexts/collections/home/Modules';

// Components
import {Loading} from '../../../components/views/Loading';

const Home = () =>
{
	const [loading, setLoading] = React.useState(true);

	const {Fetch} = useBusiness();
	const {modulesByName} = useSystemModules();
	const {LoadModules, LoadModulesPlans} = useModules();

	const menu = React.useMemo(() => modulesByName?.home?.menu || [], [modulesByName.home]);

	React.useEffect(() =>
	{
		const requests =
		[
			Fetch('/modules/read-all', {syssec: 'home'}),
			Fetch('/modules/read-plans', {syssec: 'home'}),
		];

		Promise.all(requests).then(responses =>
		{
			if(responses.every(response => response.status === 200))
			{
				LoadModules(responses[0].data || []);
				LoadModulesPlans(responses[1] || {});

				setLoading(false);
			}
		});
	}, [Fetch, LoadModules, LoadModulesPlans]);

	const availableRoutes = React.useMemo(() =>
	{
		const result = [];
		const routesComponents =
		{
			'dashboard': Dashboard,
			'business-info': BusinessInfo,
			'system-variables': SystemVariables,
			'integrations': Integrations,
			'lists': Lists,
			'credits': Credits,
			'billings': Invoices,
			'modules': Modules,
		};

		menu.forEach(item =>
		{
			const Page = routesComponents[item.route];

			if(Page)
			{
				result.push(<Route key={item.route} path={`/${item.route}`} element={<Page/>}/>)
			}
		});

		return result;
	}, [menu]);

	if(loading) return <Loading/>

	return (
		<Section class='home-module full-height'>
			<Routes>
				{availableRoutes}
				<Route path='*' element={modulesByName.home?.menu?.[0]?.route ? <Navigate to={`./${modulesByName.home?.menu?.[0]?.route}`}/> : <></>}/>
			</Routes>
		</Section>
	)
}

const HomeWithProviders = () =>
{
	return (
		<ModalProvider>
			<ModulesProvider>
				<Home/>
			</ModulesProvider>
		</ModalProvider>
	)
}

export {HomeWithProviders as Home};