import React from 'react';
import {Generic} from 'crack-functions';

const GetScreenProps = () => ({width: window.innerWidth, height: window.innerHeight, deviceType: Generic.CurrentScreen()});

const ScreenContext = React.createContext({});

const ScreenProvider = (props) =>
{
	const [screen, setScreen] = React.useState(GetScreenProps());

	React.useEffect(() =>
	{
		const handleResize = () =>
		{
			setScreen(GetScreenProps());
		}

		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const value =
	{
		...screen
	};

	return (
		<ScreenContext.Provider value={value} {...props}/>
	);
}

const useScreen = () => React.useContext(ScreenContext);

export {ScreenProvider, useScreen};