import {Dates} from 'crack-functions';
// const SetDateTime = event =>
// {
// 	const {startDate, startTime, endTime} = event;

// 	let dateObj = new Date(startDate);
// console.log('dateObj', dateObj);
// 	let dateStartHours = dateObj.getHours();
// 	let dateStartMinutes = dateObj.getMinutes();

// 	const startParts = String(startTime).split(':');
// 	const endParts = String(endTime).split(':');

// 	if(startParts.length === 2)
// 	{
// 		const [startHours, startMinutes] = startParts;

// 		dateObj = new Date(dateObj.getTime() + (parseInt(startHours) * 60 + parseInt(startMinutes)) * 60000);
// 	}

// 	event.dateObj = dateObj;
// }

const SetEventConfig = (eventConfig) =>
{
	// SetDateTime(eventConfig);
	// console.log('eventConfig', eventConfig);

	eventConfig.startDateObj = new Date(eventConfig.startDate);
	eventConfig.staff = Array.isArray(eventConfig.staff) ? eventConfig.staff : [];

	eventConfig.startTimeObj = new Date(eventConfig.startTime);
	eventConfig.endTimeObj = eventConfig.endTime ? new Date(eventConfig.endTime) : null;

	eventConfig.startTimeValue = Dates.GetTimeForForm(eventConfig.startTimeObj);
	eventConfig.endTimeValue = Dates.GetTimeForForm(eventConfig.endTimeObj);

	if(eventConfig?.recurrence?.form?.startTime) eventConfig.recurrence.form.startTime = eventConfig.startTimeValue;
	if(eventConfig?.recurrence?.form?.endTime) eventConfig.recurrence.form.endTime = eventConfig.endTimeValue;

	return eventConfig;
}

const EventsConfigReducer = (state = {}, action) =>
{
	switch (action.type)
	{
		case 'LOAD_EVENTS_CONFIG':
			return {
				...state,
				eventsConfig: action.eventsConfig.map(eventConfig => SetEventConfig(eventConfig))
			}

		case 'CREATE_EVENT_CONFIG':
			return {
				...state,
				eventsConfig: [...state.eventsConfig, SetEventConfig(action.eventConfig)],
			}

		case 'UPDATE_EVENT_CONFIG':
			return {
				...state,
				eventsConfig: state.eventsConfig.map(eventConfig => eventConfig._id === action.eventConfig._id ? SetEventConfig(action.eventConfig) : eventConfig)
			}

		case 'DELETE_EVENT_CONFIG':
		{
			return {
				...state,
				eventsConfig: state.eventsConfig.filter(eventConfig => eventConfig._id !== action.eventConfig._id)
			}
		}

		default:
			return state;
	}
}

export {EventsConfigReducer};