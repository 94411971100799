import React from 'react';
import {Content, Panel, Header, Body, Card, Text, List} from 'crack-ux';

// Components
import {ListOptionsTable} from '../../../../components/tables/modules/home/ListOptions';

// Contexts
import {useTraductor} from '../../../../contexts/general/Traductor';
import {ListsProvider} from '../../../../contexts/pages/business/home/Lists';
import {useLists} from '../../../../contexts/collections/home/Lists';

const Lists = (props) =>
{
	const {Translate} = useTraductor();

	const {lists, currentList, SetCurrentList} = useLists();

	React.useEffect(() =>
	{
		if(lists.length > 0)
		{
			if(!currentList._id)
			{
				SetCurrentList(lists[0])
			}
		}
		else
		{
			SetCurrentList({})
		}
	}, [lists, currentList._id, SetCurrentList]);

	return (
		<Content>
			<Panel class='no-padding full-height' swipe='horizontal'>
				<Panel class='desktop3 full-height'>
					<Card>
						<Header>
							<Text class='bold'>{Translate('Lists')}</Text>
						</Header>
						<Body class='no-padding' style={{height: 'calc(100% - var(--header))'}}>
							<List
								class = 'hover'
								currentClass = 'background-blue white'
								current = {currentList._id}
								options = {lists.map(list =>
								{
									return {
										identifier: list._id,
										text: Translate(list.title),
										Click: () => SetCurrentList(list)
									}
								})}
							/>
						</Body>
					</Card>
				</Panel>
				<Panel class='desktop9 full-height'>
					<Card>
						<Header>
							<Text class='bold'>{Translate('Options')}</Text>
						</Header>
						<Body class='no-padding' style={{height: 'calc(100% - var(--header))'}}>
							<ListOptionsTable/>
						</Body>
					</Card>
				</Panel>
			</Panel>
		</Content>
	);
}

const ListsWithProviders = (props) =>
{
	return (
		<ListsProvider>
			<Lists {...props}/>
		</ListsProvider>
	);
}

export {ListsWithProviders as Lists};