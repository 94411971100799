import React from 'react';
import {Table} from 'crack-ux';
import {Generic} from 'crack-functions';

// Contexts
import {useTraductor} from '../../../../../contexts/general/Traductor';
import {useCRM} from '../../../../../contexts/modules/CRM';
import {useScheduledMessagesPage} from '../../../../../contexts/pages/business/crm/ScheduledMessages';
import {useScheduledMessages} from '../../../../../contexts/collections/crm/ScheduledMessages';
import {useContacts} from '../../../../../contexts/collections/crm/Contacts';

// Static Data
import channels from '../../../../../static-data/Channels.json';
import scheduledMessagesTypes from '../../../../../static-data/ScheduledMessagesTypes.json';

const whatsappId = channels.find(channel => channel.value === 'whatsapp');
const personalId = scheduledMessagesTypes.find(item => item.value === 'personal'); // Personal

const ScheduledMessagesTable = ({contact, base, historical}) =>
{
	const {Translate} = useTraductor();
	const {ShowScheduledMessageForm, ShowDeleteScheduledMessage, ShowContactsList} = useCRM();
	const {channel, scheduledMessageType} = useScheduledMessagesPage();

	const {scheduledMessages, GetFilteredContacts} = useScheduledMessages();
	const {contacts} = useContacts();

	const currentChannel = contact ? whatsappId : channel;
	const currentType = contact ? personalId : scheduledMessageType;

	const filteredContactsTexts = React.useMemo(() =>
	{
		const result = {};

		scheduledMessages.filter(scheduledMessage => !scheduledMessage.sent).forEach(scheduledMessage =>
		{
			const filteredContacts = Array.isArray(scheduledMessage.recipients) && scheduledMessage.recipients.length > 0 ? scheduledMessage.recipients.length : GetFilteredContacts(scheduledMessage.filters, 'number');

			result[scheduledMessage._id] = `0/${filteredContacts}`;
		});

		return result;
	}, [scheduledMessages, GetFilteredContacts]);

	const contactsObj = React.useMemo(() => Generic.ArrayToObjectByIdentifier(contacts), [contacts]);

	const body = React.useMemo(() =>
	{
		const foundChannel = channels.find(item => item.value === currentChannel?.value);
		const foundType = scheduledMessagesTypes.find(item => item.value === currentType?.value);
		const Filter = contact ?
		a => a.contact === contact?._id && a.type === foundType.id && !!a.base === base :
		a => a.channel === foundChannel.id && a.type === foundType.id && !!a.base === base;
		const FilterBirthday = foundType?.value === 'birthday' ? a => a.base || (!a.base && a.sent): () => true;

		return scheduledMessages.filter(item => Filter(item) && FilterBirthday(item)).map(scheduledMessage =>
		{
			if(!scheduledMessage.sent && filteredContactsTexts[scheduledMessage._id])
			{
				scheduledMessage.filterSentContactsText = filteredContactsTexts[scheduledMessage._id];
			}

			if(scheduledMessage.contact)
			{
				scheduledMessage.filterSentContactsText = contactsObj[scheduledMessage?.contact]?.fullName || '';
			}

			return scheduledMessage;
		});
	}, [scheduledMessages, filteredContactsTexts, currentChannel, contact, contactsObj, currentType, base]);

	const header = React.useMemo(() =>
	{
		const result =
		[
			{
				name: 'title',
				text: Translate('Title'),
				maxWidth: 240
			},
			{
				name: 'message',
				text: Translate('Message'),
				maxWidth: 300,
				title: 'message'
			},
		];

		if(!base)
		{
			result.push
			(
				{
					name: 'dateText',
					text: Translate('Date')
				},
				{
					name: 'sentText',
					text: Translate('Sent')
				}
			);
		}
		else
		{
			result.push
			({
				name: 'hourText',
				text: Translate('Hour')
			});
		}

		if(!contact && !base)
		{
			result.push
			({
				name: 'filterSentContactsText',
				text: Translate('Contacts')
			});
		}

		return result;
	}, [contact, base, Translate]);

	const actions = React.useMemo(() =>
	{
		const result =
		[
			{
				text: Translate('New Scheduled Message'),
				class: 'background-blue white',
				section: 'grouped',
				Click: () => ShowScheduledMessageForm({}, {channel: currentChannel, contact: contact?._id, type: currentType, base})
			},
			{
				text: Translate('Edit Scheduled Message'),
				icon:
				{
					class: 'icon-pencil'
				},
				section: 'context',
				Click: (scheduledMessage) => ShowScheduledMessageForm(scheduledMessage, {channel: currentChannel, contact: scheduledMessage?.contact || contact?._id, type: currentType, base})
			},
			{
				text: Translate('Delete Scheduled Message'),
				class: 'red',
				icon:
				{
					class: 'icon-trash'
				},
				section: 'context',
				Click: (scheduledMessage) => ShowDeleteScheduledMessage(scheduledMessage)
			}
		];

		if(historical)
		{
			result.splice(0, 1);
			result.splice(1, 1);
		}
		else if(currentType.value === 'personal' && !contact)
		{
			result.splice(0, 1);
		}

		if(currentType.value !== 'personal' && !base)
		{
			result.unshift
			({
				text: Translate('Contacts'),
				icon:
				{
					class: 'icon-users'
				},
				section: 'context',
				Click: (scheduledMessage) =>
				{
					const recipientIds = Array.isArray(scheduledMessage.recipients) && scheduledMessage.recipients.length > 0 ? scheduledMessage.recipients : GetFilteredContacts(scheduledMessage.filters).map(contact => contact._id);
					const contactIds = scheduledMessage.contacts || [];

					if(recipientIds.length > 0)
					{
						const filteredContacts = contacts.filter(contact => recipientIds.includes(contact._id)).map(contact =>
						({
							...contact,
							sentText: Translate(contactIds.includes(contact._id) ? 'Yes' : 'No')
						}));
	
						ShowContactsList(filteredContacts);
					}
				}
			});
		}

		return result;
	}, [currentChannel, currentType, base, historical, contact, contacts, Translate, ShowScheduledMessageForm, ShowDeleteScheduledMessage, ShowContactsList, GetFilteredContacts]);

	return (
		<Table
			header = {header}
			body = {body}
			actions = {actions}
			DoubleClickRow = {(scheduledMessage) => ShowScheduledMessageForm(scheduledMessage, {channel: currentChannel, contact: scheduledMessage?.contact || contact?._id, type: currentType, base})}
			searcher =
			{
				{text: Translate('Search')}
			}
			texts =
			{
				{
					noRecords:Translate('There Are Not Records To Show'),
					records:Translate('Records'),
					fields: Translate('Fields'),
					settings: Translate('Settings'),
					export: Translate('Export'),
				}
			}
			filters = 
			{{
				columns:
				{
					editable: true
				},
				Apply:
				{
					text: Translate('Apply'),
					Click: () => {}
				},
				Reset:
				{
					text: Translate('Reset'),
					Click: () => {}
				}
			}}
		/>
	)
};

ScheduledMessagesTable.defaultProps =
{
	contact: null,
	base: false,
	historical: false,
}

export {ScheduledMessagesTable};