import React from 'react';
import {Card, Header, Body, Footer, Text, Button} from 'crack-ux';

// Contexts
import {useTraductor} from '../../../../contexts/general/Traductor';
import {useModal} from '../../../../contexts/general/Modal';
import {useScheduledMessages} from '../../../../contexts/collections/crm/ScheduledMessages';

const DeleteScheduledMessage = (props) =>
{
	const {scheduledMessage} = props;

	const {Translate} = useTraductor();
	const {CloseModal} = useModal();

	const {DeleteScheduledMessage} = useScheduledMessages();

	const HandleDeleteScheduledMessage = () =>
	{
		DeleteScheduledMessage(scheduledMessage).then(response =>
		{
			if(response.status === 200)
			{
				CloseModal();
			}
		});
	}

	return (
		<Card>
			<Header>
				<Text class='bold'>{Translate('Delete Contact')}</Text>
			</Header>
			<Body>
				<Text>{Translate('Are you sure you want to delete next scheduled message?')}</Text>
				<Text class='block'>{scheduledMessage.name}</Text>
			</Body>
			<Footer class='right'>
				<Button class='background-red white' text={Translate('Continue')} Click={HandleDeleteScheduledMessage}/>
				<Button text={Translate('Cancel')} Click={CloseModal}/>
			</Footer>
		</Card>
	)
}

export {DeleteScheduledMessage};