import React from 'react';
import {Section, Header, Body, Text} from 'crack-ux';
import {Line} from 'react-chartjs-2';
import {Chart, registerables} from 'chart.js';

// Contexts
import {useTraductor} from '../../../../../contexts/general/Traductor';

// Static Data
import Colors from '../../../../../static-data/Colors.json';

Chart.register(...registerables);

const SalesChart = (props) =>
{
	const {dates, periodText, showHeader} = props;

	const {Translate} = useTraductor();

	const options = React.useMemo(() =>
	{
		const result = {
			responsive: true,
			interaction: {
			mode: 'index',
			intersect: false,
			},
			stacked: false,
			scales: {
			y: {
				type: 'linear',
				display: true,
				position: 'left',
			}
			}
		};

		if(periodText)
		{
			result.plugins =
			{
				title:
				{
					display: true,
					text: periodText
				}
			}
		}

		return result;
	}, [periodText]);

	return (
		<Section>
			{
				showHeader ?
				<Header>
					<Text>{Translate('Sales per Date')}</Text>
				</Header> :
				<></>
			}
			<Body>
				<Line
					data =
					{{
						labels: dates.map(date => date.date),
						datasets:
						[
							{
								label: `${Translate('Incomes')} (Bs)`,
								data: dates.map(date => date.total),
								borderColor: Colors.border.green,
								tension: 0.1,
								backgroundColor: Colors.background.green,
								yAxisID: 'y',
							}
						]
					}}
					options = {options}
				/>
			</Body>
		</Section>
	)
}

export {SalesChart};