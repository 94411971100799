import {Text as TextF} from 'crack-functions';

const memberStatus = ['Inactive', 'Active'];

const SetMember = (member) =>
{
	member.nameText = member.user.name;
	member.lastNameText = member.user.lastName;
	member.fullName = TextF.FullName(member.user.name, member.user.lastName);
	member.emailText = member.user.email;
	member.phoneText = member.user.number;
	member.statusText = memberStatus[member.status];
	member.invitationText = member.accepted ? 'Accepted' : 'Pending';

	return member;
}

const StaffReducer = (state = {}, action) =>
{
	switch (action.type)
	{
		case 'LOAD_STAFF':
			return {
				...state,
				staff: action.staff.map(member => SetMember(member))
			}

		case 'CREATE_MEMBER':
			return {
				...state,
				staff: [...state.staff, SetMember(action.member)]
			}

		case 'UPDATE_MEMBER':
			return {
				...state,
				staff: state.staff.map(member => member._id === action.member._id ? SetMember(action.member) : member)
			}

		case 'DELETE_MEMBER':
			return {
				...state,
				staff: state.staff.filter(member => member._id !== action.member._id)
			}

		default:
			return state;
	}
}

export {StaffReducer, SetMember};