import {convertFromRaw} from 'draft-js';
import moment from 'moment';

const SetNote = (note) =>
{
	note.codeText = `NOTE-${note.code}`;
	note.contentText = note.content ? convertFromRaw(JSON.parse(note.content)).getPlainText() : '';
	note.dateText = moment(note.date).format('DD/MM/YYYY');

	return note;
}

const ContactsNotesReducer = (state = {}, action) =>
{
	switch (action.type)
	{
		case 'LOAD_NOTES':
		{
			const contactsNotes = {};

			if(Array.isArray(action.notes))
			{
				action.notes.forEach(note =>
				{
					if(!contactsNotes[note.contact]) contactsNotes[note.contact] = [];

					contactsNotes[note.contact].push(SetNote(note));
				});
			}

			return {
				...state,
				notes: action.notes.map(note => SetNote(note)),
				contactsNotes
			}
		}

		case 'LOAD_CONTACT_NOTES':
		{
			return {
				...state,
				contactsNotes:
				{
					...state.contactsNotes,
					[action.contact]: action.notes.map(note => SetNote(note))
				}
			}
		}

		case 'CREATE_NOTE':
		{
			return {
				...state,
				contactsNotes:
				{
					...state.contactsNotes,
					[action.note.contact]:
					[
						...(state.contactsNotes[action.note.contact] || []),
						SetNote(action.note)
					]
				}
			}
		}

		case 'UPDATE_NOTE':
		{
			return {
				...state,
				contactsNotes:
				{
					...state.contactsNotes,
					[action.note.contact]:
					[
						...(state.contactsNotes[action.note.contact] ? state.contactsNotes[action.note.contact].map(note => note._id === action.note._id ? SetNote(action.note) : note) : [SetNote(action.note)])
					]
				}
			}
		}

		case 'DELETE_NOTE':
		{
			return {
				...state,
				contactsNotes:
				{
					...state.contactsNotes,
					[action.note.contact]:
					[
						...(state.contactsNotes[action.note.contact] ? state.contactsNotes[action.note.contact].filter(note => note._id !== action.note._id) : [])
					]
				}
			}
		}

		case 'SET_LATEST_NOTE_CODE':
		{
			return {
				...state,
				code: action.code
			}	
		}

		default:
			return state;
	}
}

export {ContactsNotesReducer};