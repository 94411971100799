import React from "react";

const SalesPoint = React.createContext({});

const SalesPointPageProvider = (props) =>
{
	const [customer, setCustomer] = React.useState({});
	const [productsSelected, setProductsSelected] = React.useState({});

	const AddProduct = React.useCallback((product) =>
	{
		const cloneProductsSelected = {...productsSelected};

		if(cloneProductsSelected[product._id])
		{
			cloneProductsSelected[product._id].amount++;
		}
		else
		{
			cloneProductsSelected[product._id] =
			{
				...product,
				amount: 1,
				salePrice: product.price.sale
			}
		}

		setProductsSelected(cloneProductsSelected);
	}, [productsSelected]);

	const RemoveProduct = React.useCallback((product) =>
	{
		if(productsSelected[product._id]?.amount)
		{
			const cloneProductsSelected = {...productsSelected};

			if(cloneProductsSelected[product._id].amount > 1)
			{
				cloneProductsSelected[product._id].amount--;
			}
			else
			{
				delete cloneProductsSelected[product._id];
			}

			setProductsSelected(cloneProductsSelected);
		}
	}, [productsSelected]);

	const ClearProduct = React.useCallback((product) =>
	{
		const cloneProductsSelected = {...productsSelected};
		delete cloneProductsSelected[product._id];

		setProductsSelected(cloneProductsSelected);
	}, [productsSelected]);

	const UpdatePrice = (product, price) =>
	{
		if(productsSelected[product._id])
		{
			const cloneProductsSelected = {...productsSelected};
			cloneProductsSelected[product._id].salePrice = price;
	
			setProductsSelected(cloneProductsSelected);
		}
	}

	const UpdateAmount = (product, amount) =>
	{
		if(productsSelected[product._id])
		{
			const cloneProductsSelected = {...productsSelected};
			cloneProductsSelected[product._id].amount = amount <= cloneProductsSelected[product._id].quantity.current ? amount : cloneProductsSelected[product._id].quantity.current; 

			setProductsSelected(cloneProductsSelected);
		}
	}

	const ClearAll = () =>
	{
		setCustomer({});
		setProductsSelected({});
	}

	const value =
	{
		customer,
		productsSelected,
		AddProduct,
		RemoveProduct,
		ClearProduct,
		UpdatePrice,
		UpdateAmount,
		SetCustomer: setCustomer,
		ClearAll
	}

	return (
		<SalesPoint.Provider value={value}>
			{props.children}
		</SalesPoint.Provider>
	)
}

const useSalesPointPage = () => React.useContext(SalesPoint);

export {SalesPointPageProvider, useSalesPointPage}