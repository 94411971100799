import React from 'react';

// Context
import {useSocket} from '../../general/Socket';
import {useBusiness} from '../../general/Business';

// Reducers
import {InvoicesReducer} from '../../../reducers/main/Invoices';

const maxUnpaidInvoices = 1;

const InvoicesContext = React.createContext({});

const InvoicesProvider = (props) =>
{
	const [state, dispatch] = React.useReducer(InvoicesReducer, {invoices: [], code: 0});
	const [currentInvoice, setCurrentInvoice] = React.useState({});
	const [eventId, setEventId] = React.useState('');
	const [oldSocket, setOldSocket] = React.useState({});

	const Socket = useSocket();
	const {Fetch, business} = useBusiness();

	const room = React.useMemo(() => `invoices_${business._id}`, [business._id]);
	const unique = React.useMemo(() =>
	{
		return eventId ? `INVOICE_${eventId}` : 'INVOICE';
	}, [eventId]);

	React.useEffect(() =>
	{
		Fetch('/invoices/code').then(response =>
		{
			if(response.status === 200)
			{
				dispatch({type: 'SET_LATEST_INVOICE_CODE', code: response.data});
			}
		});
	}, [Fetch]);

	React.useEffect(() =>
	{
		const events =
		[
			{
				name: `CREATE_${unique}`,
				Function: (invoice) =>
				{
					dispatch({type: 'CREATE_INVOICE', invoice});

					if(unique === 'INVOICE') dispatch({type: 'SET_LATEST_INVOICE_CODE', code: state.code + 1});
				}
			},
			{
				name: `UPDATE_${unique}`,
				Function: (invoice) => dispatch({type: 'UPDATE_INVOICE', invoice})
			},
			{
				name: `DELETE_${unique}`,
				Function: (invoice) => dispatch({type: 'DELETE_INVOICE', invoice})
			}
		];

		if(unique !== 'INVOICE')
		{
			events.push
			({
				name: 'CREATE_INVOICE',
				Function: () =>
				{
					dispatch({type: 'SET_LATEST_INVOICE_CODE', code: state.code + 1});
				}
			})
		}

		Socket.ConnectEvents(room, events, oldSocket);

		if(oldSocket.unique !== unique)
		{
			setOldSocket({room, events, unique});
		}
	}, [Socket, room, unique, oldSocket, state.code]);

	const LoadInvoices = React.useCallback((invoices = []) =>
	{
		dispatch({type: 'LOAD_INVOICES', invoices});
	}, []);

	const CreateInvoice = async (invoice) =>
	{
		invoice._socket = room;

		return await Fetch('/invoice/create', invoice);
	}

	const UpdateInvoice = async (invoice) =>
	{
		invoice._socket = room;

		return await Fetch('/invoice/update', invoice);
	}

	const DeleteInvoice = async (invoice) =>
	{
		const data =
		{
			_id: invoice._id,
			_socket: room
		}

		return await Fetch('/invoice/delete', data);
	}

	const value =
	{
		code: state.code,
		invoices: state.invoices,
		currentInvoice,
		SetCurrentInvoice: setCurrentInvoice,
		SetEventId: setEventId,
		LoadInvoices,
		CreateInvoice,
		UpdateInvoice,
		DeleteInvoice,
	}

	return (
		<InvoicesContext.Provider value={value}>
			{props.children}
		</InvoicesContext.Provider>
	)
}

const useInvoices = () => React.useContext(InvoicesContext);

export {InvoicesProvider, useInvoices, maxUnpaidInvoices};