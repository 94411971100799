import {useNavigate} from 'react-router-dom';
import {Header, Text, Box, Button, Select, Icon} from 'crack-ux';
import config from '../../../config.json';

// Contexts
import {useTraductor} from '../../../contexts/general/Traductor';
import {usePublic} from '../../../contexts/layouts/Public';

const Menu = (props) =>
{
	const {language} = props;

	const navigate = useNavigate();
	const {Translate} = useTraductor();	
	const {showMenu, setShowMenu, GoTo} = usePublic();

    return (
        <Header class='menu center fixed no-border'>
			<Box class='float-left'>
            	<Text class='masque white' size={30}>Sice</Text>
            	<Text class='light-gray absolute' style={{top: 28, marginLeft: 5}}>{config.app.version}</Text>
			</Box>
			<Box class='float-right'>
				<Select
					class = 'language'
					options =
					{[
						{
							text: 'Español',
							value: 'es',
						},
						{
							text: 'English',
							value: 'en',
						}
					]}
					selected = {language}
					Change = {(response) => navigate(`../${response.value}`)}
				/>
				<Icon class={`menu-icon ${showMenu ? 'icon-cancel' : 'icon-menu'} white`} Click={() => setShowMenu(!showMenu)}/>
			</Box>
			<Box class={`navigation ${showMenu ? 'show' : 'hide'}`}>
				<Button text={Translate('Home', language)} Click={() => GoTo('home')}/>
				<Button text={Translate('Features', language)} Click={() => GoTo('features')}/>
				{/* <Button text={Translate('Services', language)} Click={() => GoTo('services')}/> */}
				<Button text={Translate('Modules', language)} Click={() => GoTo('modules')}/>
				{/* <Button text={Translate("FAQ's", language)} Click={() => GoTo('faqs')}/> */}
				<Button text={Translate("Contact Us", language)} Click={() => GoTo('contact-us')}/>
			</Box>
        </Header>
    )
}

export {Menu};