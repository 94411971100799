import {Content, Header, Body, Footer, Text, Tabs, Button} from 'crack-ux';

// Contexts
import {useTraductor} from '../../../contexts/general/Traductor';
import {usePublic} from '../../../contexts/layouts/Public';

// Components
import {Info} from '../../business/home/Modules/Info';

const Modules = (props) =>
{
	const {language} = props;

	const {Translate} = useTraductor();
	const {modulesRef, ShowSignUpForm} = usePublic();

	return (
		<Content reference={modulesRef} class='modules-section section'>
			<Content class='home-module laptop no-padding'>
				<Text class='title blue'>{Translate('Modules', language)}</Text>
				<Header class='center no-border'>
					<Text type='p' size={14}>{language === 'en' ? "Welcome! If you are the owner or administrator of a company, you surely know how important it is to have a good management system to achieve success in your operations. That is why we invite you to explore our business management system modules. With them you can improve the efficiency of your processes and make more informed decisions. Don't wait any longer, find out everything we have to offer you and make your company grow to the maximum!" : '¡Bienvenidos! Si eres dueño o administrador de una empresa, seguro sabes lo importante que es tener un buen sistema de gestión para lograr el éxito en tus operaciones. Es por eso que te invitamos a explorar nuestros módulos del sistema de gestión de empresas. Con ellos podrás mejorar la eficiencia de tus procesos y tomar decisiones más informadas. ¡No esperes más, conoce todo lo que tenemos para ofrecerte y haz que tu empresa crezca al máximo!'}</Text>
				</Header>
				<Body class='modules'>
					<Tabs
						tabs =
						{[
							{
								name: Translate('CRM', language),
								content: <Info module={{name: 'CRM', route: 'crm'}} language={language} action={false}/>
							},
							{
								name: Translate('Store', language),
								content: <Info module={{name: 'Store', route: 'store'}} language={language} action={false}/>
							}
						]}
					/>
				</Body>
				<Footer class='center no-border no-padding' style={{marginTop: 100}}>
					<Text class='block' type='p' size={14}>{language === 'en' ? 'Create a free account and try all our modules with no time limit right now!' : '¡Cree una cuenta gratuita y pruebe todos nuestros módulos sin límite de tiempo ahora mismo!'}</Text>
					<Text class='block' type='p' size={14}>{language === 'en' ? 'Improve your business with our complete business management solution!' : '¡Mejore su negocio con nuestra solución completa de gestión de empresas!'}</Text>
					<Button class='background-blue white' text={Translate('Sign Up For Free', language)} Click={ShowSignUpForm} style={{marginTop: 25}}/>
				</Footer>
			</Content>
		</Content>
	)
};

export {Modules};