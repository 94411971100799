import React from 'react';
import {Table} from 'crack-ux';

// Contexts
import {useTraductor} from '../../../../../contexts/general/Traductor';

const ContactNotesTable = (props) =>
{
	const {notes, actions, DoubleClickRow} = props;

	const {Translate} = useTraductor();

	return (
		<Table
			header =
			{[
				{
					name: 'codeText',
					text: Translate('Code')
				},
				{
					name: 'title',
					text: Translate('Title'),
					maxWidth: 240
				},
				{
					name: 'contentText',
					text: Translate('Content'),
					title: 'contentText',
					maxWidth: 300
				},
				{
					name: 'dateText',
					text: Translate('Date')
				},
			]}
			body = {[...notes]}
			actions = {actions}
			DoubleClickRow = {DoubleClickRow}
		/>
	)
};

export {ContactNotesTable};