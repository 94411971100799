import React from 'react';

// Context
import {useSocket} from '../../general/Socket';
import {useBusiness} from '../../general/Business';

// Reducers
import {ChatBotsReducer} from '../../../reducers/collections/crm/ChatBots';

const ChatBotsContext = React.createContext({});

const ChatBotsProvider = (props) =>
{
	const [state, dispatch] = React.useReducer(ChatBotsReducer, {chatBots: []});
	const [eventId, setEventId] = React.useState('');
	const [oldSocket, setOldSocket] = React.useState({});

	const Socket = useSocket();
	const {Fetch, business} = useBusiness();

	const room = React.useMemo(() => `crm_chat_bots_${business._id}`, [business._id]);
	const unique = React.useMemo(() =>
	{
		return eventId ? `CRM_CHAT_BOT_${eventId}` : 'CRM_CHAT_BOT';
	}, [eventId]);

	React.useEffect(() =>
	{
		const events =
		[
			{
				name: `CREATE_${unique}`,
				Function: (chatBot) => dispatch({type: 'CREATE_CHAT_BOT', chatBot})
			},
			{
				name: `UPDATE_${unique}`,
				Function: (chatBot) => dispatch({type: 'UPDATE_CHAT_BOT', chatBot})
			},
			{
				name: `DELETE_${unique}`,
				Function: (chatBot) => dispatch({type: 'DELETE_CHAT_BOT', chatBot})
			}
		];

		Socket.ConnectEvents(room, events, oldSocket);

		if(oldSocket.unique !== unique)
		{
			setOldSocket({room, events, unique});
		}
	}, [Socket, room, unique, oldSocket]);

	const LoadChatBots = React.useCallback((chatBots = []) =>
	{
		dispatch({type: 'LOAD_CHAT_BOTS', chatBots});
	}, []);

	const CreateChatBot = async (chatBot) =>
	{
		chatBot._socket = room;

		return await Fetch('/crm/chat-bot/create', chatBot);
	}

	const UpdateChatBot = async (chatBot) =>
	{
		chatBot._socket = room;

		return await Fetch('/crm/chat-bot/update', chatBot);
	}

	const DeleteChatBot = async (chatBot) =>
	{
		chatBot._socket = room;

		return await Fetch('/crm/chat-bot/delete', chatBot);
	}
	
	const UpdateBusinessPhoneBot = async (data) =>
	{
		return await Fetch('/crm/chat-bot/set', data);
	}

	const value =
	{
		code: state.code,
		chatBots: state.chatBots,
		SetEventId: setEventId,
		LoadChatBots,
		CreateChatBot,
		UpdateChatBot,
		DeleteChatBot,
		UpdateBusinessPhoneBot
	}

	return <ChatBotsContext.Provider value={value} {...props}/>
}

const useChatBots = () => React.useContext(ChatBotsContext);

export {ChatBotsProvider, useChatBots};