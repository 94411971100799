import React from 'react';
import {QRCodeSVG} from 'qrcode.react';
import {Card, Section, Panel, Box, Tabs, Text, Button, Select} from 'crack-ux';

// Views
import {Loading} from '../../../../components/views/Loading';

// Contexts
import {useTraductor} from '../../../../contexts/general/Traductor';
import {useSocket} from '../../../../contexts/general/Socket';
import {useBusiness} from '../../../../contexts/general/Business';
import {useWhatsappSession} from '../../../../contexts/main/WhatsappSession';
import {useChatBots} from '../../../../contexts/collections/crm/ChatBots';

// TODO: Improve this function
const IsValidPhone = phone => phone.country > 0 && phone.number > 0;

const GetPhoneText = (phone) =>
{
	const parts = ['country', 'area', 'number'];
	const phoneParts = [];

	parts.forEach(part =>
	{
		if(phone[part] !== undefined && phone[part] !== null)
		{
			phoneParts.push(phone[part]);
		}
	});

	return `+${phoneParts.join(' ')}`;
}

const WhatsappStatus = (props) =>
{
	const {phone} = props;

	const [status, setStatus] = React.useState(0); // 0.- Checking, 1.- Logged In, 2.- Logged Out, 3.- SignIn
	const [QR, setQR] = React.useState(null);

	const Socket = useSocket();
	const {business, GetBusiness} = useBusiness();
	const {Translate} = useTraductor();
	const {CheckStatus, LogIn, LogOut} = useWhatsappSession();

	const {chatBots, UpdateBusinessPhoneBot} = useChatBots();

	React.useEffect(() =>
	{
		setStatus(0);
		CheckStatus({_id: business._id, phoneId: phone._id});
	}, [business._id, phone._id, CheckStatus]);

	React.useEffect(() =>
	{
		const events =
		[
			{
				name: `status_${phone._id}`,
				Function: (data) =>
				{
					if(data.phone._id === phone._id)
					{
						setStatus(data.status)
					}
				}
			},
			{
				name: `new_qr_${phone._id}`,
				Function: (data) =>
				{
					if(data.phone._id === phone._id)
					{
						setQR(data.qr)
					}
				}
			}
		];

		Socket.ConnectEvents(`whatsapp_${business._id}`, events);
	}, [Socket, business._id, phone._id]);

	const QRComponent = React.useMemo(() =>
	{
		if(status === 3)
		{
			if(QR)
			{
				return <QRCodeSVG value = {QR} size = {264}/>
			}

			return <Loading/>
		}

		return <></>
	}, [QR, status]);

	const chatBotsOptions = React.useMemo(() =>
	{
		return [{value: null, text: ''}, ...chatBots.filter(chatBot => chatBot.channel === 0 && chatBot.main).map(chatBot =>
		({
			value: chatBot._id,
			text: chatBot.name
		}))];
	}, [chatBots]);

	if(status === 0)
	{
		return (
			<Box class='full-center center'>
				<Box style={{marginBottom: 10}}>
					<Text size={18}>{Translate('Checking Whatsapp status...')}:</Text>
				</Box>
			</Box>
		);
	}
	else if(status === 3)
	{
		return (
			<Box class='full-center center'>
				<Box style={{marginBottom: 10}}>
					<Text size={18}>{Translate(QR === null ? 'Getting Whatsapp QR' : 'Please scan the QR code with your phone to SignIn to Whatsapp')}:</Text>
				</Box>
				<Box class='block' style={{marginTop: 10}}>
					{QRComponent}
				</Box>
			</Box>
		);
	}
	else
	{
		const loggedIn = status === 1;

		return (
			<Box class='full-center center'>
				<Box style={{marginBottom: 10}}>
					<Text size={18}>{Translate('Whatsapp Status')}:&nbsp;</Text>
					<Text size={18} class={`${loggedIn ? 'green' : 'red'}`}>{Translate(loggedIn ? 'Active' : 'Inactive')}</Text>
				</Box>
				<Box class='block'>
					<Button
						text = {Translate(loggedIn ? 'Log Out' : 'Log In')}
						class = {`background-${loggedIn ? 'red' : 'blue'} white`}
						Click = {() =>
						{
							const data = {_id: business._id, phoneId: phone._id};

							loggedIn ? LogOut(data) : LogIn(data)
						}}
					/>
				</Box>
				<Box style={{marginTop: 30}}>
					<Text size={18}>{Translate('Chat Bot')}</Text>
					<Select
						class = 'left'
						style = {{width: 120}}
						options = {chatBotsOptions}
						selected = {phone?.whatsapp?.chatBot}
						Change = {(newChatBot) =>
						{
							const data = {chatBot: newChatBot.value, phone: phone._id};

							UpdateBusinessPhoneBot(data).then(response =>
							{
								if(response.status === 200)
								{
									GetBusiness();
								}
							});
						}}
					/>
				</Box>
			</Box>
		);
	}
}

const Whatsapp = (props) =>
{
	const {Translate} = useTraductor();
	const {business} = useBusiness();

	const phones = React.useMemo(() => Array.isArray(business.phones) ? business.phones.filter(phone => IsValidPhone(phone)) : [], [business.phones]);

	if(phones.length === 0)
	{
		return (
			<Card>
				<Panel class='full-height relative'>
					<Box class='full-center'>
						<Text size={20}>{Translate('Is necessary to have a least one Whatsapp Phone Number')}</Text>
					</Box>
				</Panel>
			</Card>
		);
	}
	else
	{
		return (
			<Tabs
				tabs = {phones.map(phone =>
				{
					return {
						identifier: phone._id,
						name: GetPhoneText(phone),
						content: (
							<Section class='relative full-height'>
								<WhatsappStatus phone = {phone}/>
							</Section>
						)
					}
				})}
				current = {phones[0]._id}
			/>
		);
	}
}

const WhatsappWithProviders = (props) =>
{
	return (
		<Whatsapp {...props}/>
	);
}

export {WhatsappWithProviders as Whatsapp}