import React from 'react';
import {Card, Header, Body, Footer, Text, Button} from 'crack-ux';

// Contexts
import {useTraductor} from '../../../../contexts/general/Traductor';
import {useModal} from '../../../../contexts/general/Modal';

const ForceLogin = (props) =>
{
	const {Confirm, preferences} = props;

	const {Translate} = useTraductor();
	const {CloseModal} = useModal();

	const language = preferences?.language || 'en';

	return (
		<Card>
			<Header>
				<Text class='bold'>{Translate('Session Duplicada', language)}</Text>
			</Header>
			<Body>
				<Text>{Translate("It looks like you're already logged in from another device", language)}.</Text>
				<Text class='block'>{Translate('do you want to log in on this device and log out the other session', language)}?</Text>
			</Body>
			<Footer class='right'>
				<Button class='background-blue white' text={Translate('Continue', language)} Click={() => {CloseModal(); Confirm();}}/>
				<Button text={Translate('Cancel', language)} Click={CloseModal}/>
			</Footer>
		</Card>
	)
}

export {ForceLogin};