import React from 'react';
import {Dates} from 'crack-functions';

// Context
import {useSocket} from '../../general/Socket';
import {useBusiness} from '../../general/Business';
import {useLists} from '../home/Lists';

// Reducers
import {ContactsReducer} from '../../../reducers/collections/crm/Contacts';

const GetWSPhoneText = (phone) =>
{
	const parts = ['country', 'area', 'number'];
	const phoneParts = [];

	parts.forEach(part =>
	{
		if(phone[part] !== undefined && phone[part] !== null)
		{
			phoneParts.push(phone[part]);
		}
	});

	return phoneParts.join('');
}

const Contacts = React.createContext({});

const ContactsProvider = (props) =>
{
	const {syssec} = props;

	const [state, dispatch] = React.useReducer(ContactsReducer, {contacts: [], code: 0});
	const [currentContact, setCurrentContact] = React.useState({});
	const [eventId, setEventId] = React.useState('');
	const [oldSocket, setOldSocket] = React.useState({});

	const Socket = useSocket();
	const {Fetch, business} = useBusiness();

	const {GetListFieldsValuesForForm, listsByName} = useLists();

	const salesFlowItemsById = React.useMemo(() => listsByName.salesFlow?.itemsById || {}, [listsByName.salesFlow?.itemsById]);

	const room = React.useMemo(() => `crm_contacts_${business._id}`, [business._id]);
	const unique = React.useMemo(() =>
	{
		return eventId ? `CRM_CONTACT_${eventId}` : 'CRM_CONTACT';
	}, [eventId]);

	React.useEffect(() =>
	{
		Fetch('/crm/contacts/code').then(response =>
		{
			if(response.status === 200)
			{
				dispatch({type: 'SET_LATEST_CONTACT_CODE', code: response.data});
			}
		});
	}, [Fetch]);

	React.useEffect(() =>
	{
		const events =
		[
			{
				name: `CREATE_${unique}`,
				Function: (contact) =>
				{
					dispatch({type: 'CREATE_CONTACT', contact});

					if(unique === 'CRM_CONTACT') dispatch({type: 'SET_LATEST_CONTACT_CODE', code: state.code + 1});
				}
			},
			{
				name: `UPDATE_${unique}`,
				Function: (contact) => dispatch({type: 'UPDATE_CONTACT', contact})
			},
			{
				name: `DELETE_${unique}`,
				Function: (contact) => dispatch({type: 'DELETE_CONTACT', contact})
			}
		];

		if(unique !== 'CRM_CONTACT')
		{
			events.push
			({
				name: 'CREATE_CRM_CONTACT',
				Function: () =>
				{
					dispatch({type: 'SET_LATEST_CONTACT_CODE', code: state.code + 1});
				}
			})
		}

		Socket.ConnectEvents(room, events, oldSocket);

		if(oldSocket.unique !== unique)
		{
			setOldSocket({room, events, unique});
		}
	}, [Socket, room, unique, oldSocket, state.code]);

	const contactsTags = React.useMemo(() =>
	{
		const allTags = state.contacts.reduce((acc, curr) =>
		{
			return Array.isArray(curr.tags) ? acc.concat(curr.tags) : acc;
		}, []);

		return [...new Set(allTags)];
	}, [state.contacts]);

	const contactsTagsOptions = React.useMemo(() =>
	(
		contactsTags.map(tag =>
		({
			text: tag,
			value: tag
		}))
	), [contactsTags]);

	const LoadContacts = React.useCallback((contacts = []) =>
	{
		dispatch({type: 'LOAD_CONTACTS', contacts});
	}, []);

	const AddContacts = React.useCallback((contacts = [], from = 0) =>
	{
		dispatch({type: 'ADD_CONTACTS', contacts, from});
	}, []);

	const ParseForm = async (form) =>
	{
		const listFieldsValues = await GetListFieldsValuesForForm(form, ['gender']);
		const contactData = {...form, ...listFieldsValues};

		contactData.dob = Dates.GetDateFromForm(contactData.dob);

		if(Array.isArray(contactData.phones))
		{
			contactData.phones = contactData.phones.filter(phone => phone.country && phone.number).map(phone => ({...phone, phone: GetWSPhoneText(phone)}));
		}

		if(Array.isArray(contactData.emails))
		{
			contactData.emails = contactData.emails.filter(email => email.email).map(email => email);
		}

		contactData.salesFlow = Array.isArray(contactData.salesFlow) ? contactData.salesFlow : [];

		if(contactData.saleFlow && contactData.saleFlow !== contactData.salesFlow.slice(-1)[0])
		{
			contactData.salesFlow.push(contactData.saleFlow);
		}

		return contactData;
	}

	const CreateContact = async (form) =>
	{
		const contact = await ParseForm(form);

		contact.code = state.code + 1;
		contact._socket = room;
		contact.syssec = syssec;

		return await Fetch('/crm/contact/create', contact);
	}

	const UpdateContact = async (form) =>
	{
		const contact = await ParseForm(form);

		contact._socket = room;

		return await Fetch('/crm/contact/update', contact);
	}

	const SimpleUpdate = async (contact) =>
	{
		contact._socket = room;

		return await Fetch('/crm/contact/update', contact);
	}

	const DeleteContact = async (contact) =>
	{
		const data =
		{
			_id: contact._id,
			_socket: room
		}

		return await Fetch('/crm/contact/delete', data);
	}

	const workingContacts = React.useMemo(() =>
	(
		state.contacts.map(contact =>
		{
			return {...contact, lastSaleFlow: salesFlowItemsById[contact.salesFlow.slice(-1)[0]]?._id || 'no-assigned', previousSaleFlow: salesFlowItemsById[contact.salesFlow[contact.salesFlow.length - 2]]?._id || null};
		})
	), [state.contacts, salesFlowItemsById]);

	const value =
	{
		code: state.code,
		contacts: workingContacts,
		currentContact,
		contactsTags,
		contactsTagsOptions,
		SetCurrentContact: setCurrentContact,
		SetEventId: setEventId,
		LoadContacts,
		AddContacts,
		CreateContact,
		UpdateContact,
		DeleteContact,
		SimpleUpdate,
	}

	return (
		<Contacts.Provider value={value}>
			{props.children}
		</Contacts.Provider>
	)
}

const useContacts = () => React.useContext(Contacts);

ContactsProvider.defaultProps =
{
	syssec: 'crm'
}

export {ContactsProvider, useContacts};