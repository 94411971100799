import React from 'react';
import {Card, Header, Body, Footer, Button, Text, Form} from 'crack-ux';

// Context
import {useModal} from '../../../../../contexts/general/Modal';
import {useTraductor} from '../../../../../contexts/general/Traductor';
import {useLists} from '../../../../../contexts/collections/home/Lists';
import {useCRM} from '../../../../../contexts/modules/CRM';
import {useCompanies} from '../../../../../contexts/collections/crm/Companies';

// Functions
import Validations from '../../../../../objects/Validate';

// Static Date
import BusinessType from '../../../../../static-data/BusinessType.json';

const CompanyForm = (props) =>
{
	const {company} = props

    const [form, setForm] = React.useState
	({
		...company,
		economicActivities: Array.isArray(company?.economicActivities) ? company?.economicActivities : company?.economicActivities ? [company?.economicActivities] : []
	});
	const [location, setLocation] = React.useState(company.location || {});
	const [messages, setMessages] = React.useState({});

    const {CloseModal} = useModal();
	const {ShowLocationForm, ShowListOptionForm} = useCRM();
	const {CreateCompany, UpdateCompany} = useCompanies();
	const {listsByName} = useLists();

    const {Translate} = useTraductor();

	const HandleSubmit = async () =>
	{
		const companyData = {...form, location};

		const result = company._id ? await UpdateCompany({...companyData, _id: company._id}) : await CreateCompany(companyData);

		setMessages(Validations.ParseByField(result.validations));

		if(result.status === 200)
		{
			CloseModal();
		}
	}

	return (
		<Card>
			<Header>
				<Text class='bold'>{company._id ? Translate('Edit Company') : Translate('New Company')}</Text>
			</Header>
			<Body class='vertical-scroll' style={{width: 400, maxWidth: 'calc(100vw - 40px)'}}>
				<Form
					inputs =
					{[
						{
							name: 'name',
							text: Translate('Company Name'),
							value: form.name,
							message: messages.name
						},
						{
							name: 'nit',
							text: Translate('TIN'),
							value: form.nit,
							message: messages.nit
						},
						{
							name: 'type',
							text: Translate('Type'),
							type: 'select',
							options:
							[
								{
									text: Translate('No selected'),
									value: null
								},
								...BusinessType
							],
							value: form.type,
							message: messages.type
						},
						{
							name: 'description',
							text: Translate('Description'),
							type: 'textarea',
							value: form.description,
							message: messages.description
						},
						{
							name: 'location',
							text: Translate("Location"),
							value: location.address,
							icon:
							{
								class: `icon-location background-blue white pointer`,
								Click: () => ShowLocationForm
								(
									location,
									(location) => setLocation(location)
								)
							},
							disabled: true
						},
						{
							name: 'economicActivities',
							text: Translate('Economic Activities'),
							class: 'desktop9',
							type: 'autocomplete',
							options: listsByName.economicActivities?.selectOptions || [],
							multiple: true,
							value: form.economicActivities,
							message: messages.companies
						},
						{
							class: 'desktop3',
							type: 'content',
							content: <Button class='full-width background-blue white' text={Translate('Add')} Click={() => ShowListOptionForm('economicActivities')}/>
						},
					]}
					Change = {setForm} 
				/>
			</Body>
			<Footer class='right'>
				<Button
					text = {Translate('Save')}
					class = 'background-blue white'
					Click = {HandleSubmit}
				/>
				<Button
					text = {Translate('Cancel')}
					Click = {CloseModal}
				/>
			</Footer>
		</Card>
	)
}

export {CompanyForm};