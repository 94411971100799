import React from 'react';
import {Content, Panel, Card, Header, Body, Box, Text} from 'crack-ux';

// Tables
import {StaffTable} from '../../../../components/tables/modules/staff/Users/Users';

// Contexts
import {useTraductor} from '../../../../contexts/general/Traductor';
import {useStaff} from '../../../../contexts/collections/staff/Staff';

// Components
import {MemberTabs} from './MemberTabs';

const Users = () =>
{
	const {Translate} = useTraductor();

	const {currentMember} = useStaff();

	return (
		<Content class='no-padding'>
			<Panel class='no-padding full-height' swipe='horizontal'>
				<Panel class='desktop6 full-height'>
					<Card>
						<Header>
							<Text class='bold'>{Translate('Users')}</Text>
						</Header>
						<Body class='no-padding' style={{height: 'calc(100% - var(--header))'}}>
							<StaffTable/>
						</Body>
					</Card>
				</Panel>
				<Panel class='desktop6 full-height'>
				{
					currentMember._id ?
					<MemberTabs/> :
					<Card class='relative'>
						<Box class='full-center center'>
							<Text size={24}>Select a staff member to view their details</Text>
						</Box>
					</Card>
				}
				</Panel>
			</Panel>
		</Content>
	)
}

const UsersWithProviders = () =>
{
	return <Users/>;
};

export {UsersWithProviders as Users};