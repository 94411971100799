import {Section, Box} from 'crack-ux';
import {Amount} from 'crack-functions';
import moment from "moment";

const SetMoves = (move) =>
{
	const status = move.annulled ? 'background-red' : 'background-green';

	move.status = <Section class='center'><Box class={`round ${status}`} style={{width: 15, height: 15}}></Box></Section>;
	move.codeText = `MOVE-${move.code}`;
	move.dateTimeText = moment(move.date).format('DD-MM-YYYY hh:mm A');
	move.dateObj = moment(move.date);
	move.typeText = move.type === 1 ? 'Sale' : 'Adquisition';
	move.totalText = Amount.CurrencyFormat(move.total);

	return move;
}

const MovesReducer = (state = {}, action) =>
{
	switch (action.type)
	{
		case 'LOAD_MOVES':
			return {
				...state,
				moves: action.moves.map(move => SetMoves(move))
			}

		case 'CREATE_MOVE':
			return {
				...state,
				moves: [SetMoves(action.move), ...state.moves],
			}

		case 'UPDATE_MOVE':
			return {
				...state,
				moves: state.moves.map(move => move._id === action.move._id ? SetMoves(action.move) : move)
			}

		case 'DELETE_MOVE':
		{
			return {
				...state,
				moves: state.moves.filter(move => move._id !== action.move._id)
			}
		}

		case 'SET_LATEST_MOVE_CODE':
		{
			return {
				...state,
				code: action.code
			}	
		}

		default:
			return state;
	}
}

export {MovesReducer};