import React from 'react';
import {Content, Panel, Card, Header, Body, Text} from 'crack-ux';

// Contexts
import {useTraductor} from '../../../../contexts/general/Traductor';
import {useCompanies} from '../../../../contexts/collections/crm/Companies';
import {useContacts} from '../../../../contexts/collections/crm/Contacts';

// Tables
import {CompaniesTable} from '../../../../components/tables/modules/crm/Companies/Companies';
import {ContactList} from '../Contacts/ContactList';

const Companies = () =>
{
	const {Translate} = useTraductor();

	const {currentCompany} = useCompanies();
	const {contacts} = useContacts();

	const companyContacts = React.useMemo(() =>
	(
		contacts.filter(contact => Array.isArray(contact.companies) && contact.companies.indexOf(currentCompany?._id) !== -1)
	), [contacts, currentCompany?._id]);

	return (
		<Content class='no-padding'>
			<Panel class='no-padding full-height' swipe='horizontal'>
				<Panel class='desktop6 full-height'>
					<Card>
						<Header>
							<Text class='bold'>{Translate('Companies')}</Text>
						</Header>
						<Body class='no-padding' style={{height: 'calc(100% - 49px)'}}>
							<CompaniesTable/>
						</Body>
					</Card>
				</Panel>
				<Panel class='desktop6 full-height'>
					<Card>
						<Header>
							<Text class='bold'>{Translate('Contacts')}</Text>
						</Header>
						<Body class='no-padding' style={{height: 'calc(100% - 49px)'}}>
							<ContactList contacts={companyContacts} from='company'/>
						</Body>
					</Card>
				</Panel>
			</Panel>
		</Content>
	)
}

export {Companies};