import {Panel, Section, Card, Header, Body, Footer, Text, Button, Icon} from 'crack-ux';
import {Amount} from 'crack-functions';

// Contexts
import {useTraductor} from '../../../contexts/general/Traductor';
import {useModulesPage} from '../../../contexts/pages/business/home/Modules';

const PriceCard = (props) =>
{
	const {name, price, popular, features, aboveLimit, language, currentPlan, id, action} = props;

	const {Translate} = useTraductor();

	const {ShowAddModuleConfirm} = useModulesPage();

	return (
		<Panel class='left' style={{width: 300}}>
			<Section class={popular ? `${popular.color} uppercase center` : 'background-transparent'} style={{padding: 15, height: 48}}>
				{popular &&
					<Text>{Translate(popular.text, language)}</Text>
				}
			</Section>
			<Card>
				<Header class='center'>
					<Section>
						<Text class='blue' size={24} type='p'>{Translate(name, language)}</Text>
					</Section>
					<Section>
						{language === 'en'
						? <Text size={18} style={{marginRight: 5}}>${Amount.Round(price / 7, 0)}</Text>
						: <Text size={18} style={{marginRight: 5}}>Bs. {price}</Text>
						}
						<Text class='mid-gray'>/ {Translate('Month', language)}</Text>
					</Section>
				</Header>
				<Body>
					{features.map((feature, index) =>
					{
						const iconClass = feature.enable ? 'icon-ok green' : 'icon-cancel mid-gray';

						return (
							<Section key={index}>
								<Icon class={iconClass}/>
								{feature.value && <Text class={!feature.enable && 'mid-gray'} type='p' style={{marginRight: 5}}>{feature.value}</Text>}
								<Text class={!feature.enable && 'mid-gray'} type='p'>{Translate(feature.name, language)}</Text>
							</Section>
						)
					})}
				</Body>
				{aboveLimit &&
				<>
					<Footer class='center'>
						<Text size={14}>{Translate('Prices Above The Plan', language)}</Text>
					</Footer>
					<Footer>
						{aboveLimit.map((limit, index) =>
						{
							return (
								<Section key={index}>
									<Text type='p' style={{marginRight: 5}}>{limit.price}</Text>
									<Text type='p'>{Translate(limit.name, language)}</Text>
								</Section>
							)
						})}
					</Footer>
				</>}
				{action &&
				<Footer>
					<Button 
						class = {`full-width uppercase ${currentPlan ? 'default': 'background-blue white'}`}
						text = {Translate(currentPlan ? 'Current Plan' : 'Choose Plan', language)}
						Click = {() => !currentPlan && ShowAddModuleConfirm({id, name})}
					/>
				</Footer>}
			</Card>
		</Panel>
	)
}

PriceCard.defaultProps =
{
	action: true
}

export {PriceCard};