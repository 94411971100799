import React from 'react';
import socketIOClient from "socket.io-client";

// Config
import config from '../../config.json';

const Socket = React.createContext({});

let socket = null;

export const SocketProvider = (props) =>
{
	const [status, setStatus] = React.useState('disconnected');

	const TrySocketDisconnection = React.useCallback(() =>
	{
		if(socket && socket.disconnect)
		{
			socket.disconnect();
			socket = false;
			
			setStatus('disconnected');
			console.log(`Socket Platform Layout disconnected`);
		}
	}, []);

	const TrySocketConnection = React.useCallback(() =>
	{
		TrySocketDisconnection();

		socket = socketIOClient(`${config.server.domain}:${config.server.port}`);

		socket.on('connect', () =>
		{
			setStatus('connected');
			console.log(`Socket Platform Layout connected`);
		})
	}, [TrySocketDisconnection]);

	const ConnectEvents = React.useCallback((room, events = [], remove = {}) =>
	{
		if(socket && room.trim().length > 0)
		{
			socket.emit('join',
			{
				room
			})

			if(remove.events)
			{
				remove.events.forEach(event => socket.removeAllListeners(event.name));
			}

			events.forEach(event =>
			{
				socket.removeAllListeners(event.name);

				socket.on(event.name, (response) =>
				{
					event.Function(response);
				})
			})
		}
	}, []);

	React.useEffect(() =>
	{
		if(status === 'disconnected')
		{
			TrySocketConnection();
		}

		return () =>
		{
			if(status === 'connected')
			{
				TrySocketDisconnection();
			}
		}
	}, [status, TrySocketConnection, TrySocketDisconnection]);

	const value =
	{
		status,
		ConnectEvents
	};

	return <Socket.Provider value={value} {...props}/>;
}

export const useSocket = () => React.useContext(Socket);