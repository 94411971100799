import React from 'react';

// Context
import {useSocket} from '../../general/Socket';
import {useBusiness} from '../../general/Business';

// Reducers
import {TemplatesReducer} from '../../../reducers/collections/crm/Templates';

const Templates = React.createContext({});

const TemplatesProvider = (props) =>
{
	const [state, dispatch] = React.useReducer(TemplatesReducer, {templates: [], code: 0});
	const [currentTemplate, setCurrentTemplate] = React.useState({});
	const [eventId, setEventId] = React.useState('');
	const [oldSocket, setOldSocket] = React.useState({});

	const Socket = useSocket();
	const {Fetch, business} = useBusiness();

	const room = React.useMemo(() => `crm_templates_${business._id}`, [business._id]);
	const unique = React.useMemo(() =>
	{
		return eventId ? `CRM_TEMPLATE_${eventId}` : 'CRM_TEMPLATE';
	}, [eventId]);

	React.useEffect(() =>
	{
		Fetch('/crm/templates/code').then(response =>
		{
			if(response.status === 200)
			{
				dispatch({type: 'SET_LATEST_TEMPLATE_CODE', code: response.data});
			}
		});
	}, [Fetch]);
	React.useEffect(() =>
	{
		const events =
		[
			{
				name: `CREATE_${unique}`,
				Function: (template) =>
				{
					dispatch({type: 'CREATE_TEMPLATE', template});

					if(unique === 'CRM_TEMPLATE') dispatch({type: 'SET_LATEST_TEMPLATE_CODE', code: state.code + 1});
				}
			},
			{
				name: `UPDATE_${unique}`,
				Function: (template) => dispatch({type: 'UPDATE_TEMPLATE', template})
			},
			{
				name: `DELETE_${unique}`,
				Function: (template) => dispatch({type: 'DELETE_TEMPLATE', template})
			}
		];

		if(unique !== 'CRM_TEMPLATE')
		{
			events.push
			({
				name: 'CREATE_CRM_TEMPLATE',
				Function: () =>
				{
					dispatch({type: 'SET_LATEST_TEMPLATE_CODE', code: state.code + 1});
				}
			})
		}

		Socket.ConnectEvents(room, events, oldSocket);

		if(oldSocket.unique !== unique)
		{
			setOldSocket({room, events, unique});
		}
	}, [Socket, room, unique, oldSocket, state.code]);

	const LoadTemplates = React.useCallback((templates = []) =>
	{
		dispatch({type: 'LOAD_TEMPLATES', templates});
	}, []);

	const CreateTemplate = async (template) =>
	{
		template.code = state.code + 1;
		template._socket = room;
		return await Fetch('/crm/template/create', template);
	}

	const UpdateTemplate = async (template) =>
	{
		template._socket = room;

		return await Fetch('/crm/template/update', template);
	}

	const DeleteTemplate = async (template) =>
	{
		const data =
		{
			_id: template._id,
			_socket: room
		}

		return await Fetch('/crm/template/delete', data);
	}

	const value =
	{
		code: state.code,
		templates: state.templates,
		currentTemplate,
		SetCurrentTemplate: setCurrentTemplate,
		SetEventId: setEventId,
		LoadTemplates,
		CreateTemplate,
		UpdateTemplate,
		DeleteTemplate
	}

	return (
		<Templates.Provider value={value}>
			{props.children}
		</Templates.Provider>
	)
}

const useTemplates = () => React.useContext(Templates);

export {TemplatesProvider, useTemplates};