import React from 'react';
import {Card, Header, Body, Footer, Text, Button} from 'crack-ux';

// Contexts
import {useTraductor} from '../../../../contexts/general/Traductor';
import {useModal} from '../../../../contexts/general/Modal';

const DeleteReadOnly = (props) =>
{
	const {CloseModal} = useModal();
	const {Translate} = useTraductor();

	return (
		<Card>
			<Header>
				<Text class='bold'>{Translate('Error')}</Text>
			</Header>
			<Body>
				<Text>{Translate(`You can't delete/modify a system file/folder`)}</Text>
			</Body>
			<Footer class='right'>
				<Button text={Translate('Cancel')} Click={CloseModal}/>
			</Footer>
		</Card>
	)
}

export {DeleteReadOnly};