const BaseReducer = (state = {}, action) =>
{
	const {type, list, record} = action;

	switch (type)
	{
		case 'LOAD':
			return {
				...state,
				[list]: action[list],
			};

		case 'CREATE':
			return {
				...state,
				[list]: [...state[list], record],
			};

		case 'UPDATE':
			return {
				...state,
				[list]: state[list].map((listItem) => listItem.id === record.id ? record : listItem),
			};

		case 'DELETE':
			return {
				...state,
				[list]: state[list].filter((listItem) => listItem.id !== record.id),
			};

		default:
			return state;
	}
};

export {BaseReducer};