import React from 'react';
import {Content, Body, Panel, Header, Text, List, Card} from 'crack-ux';

// Views
import {Loading} from '../../../../components/views/Loading';

// Contexts
import {useTraductor} from '../../../../contexts/general/Traductor';
import {useTemplatesPage, TemplatesProviderPage} from '../../../../contexts/pages/business/crm/Templates';

// Tables
import {WhatsappTemplatesTable} from '../../../../components/tables/modules/crm/WhatsappTemplates/WhatsappTemplates';
import {NotesTemplatesTable} from '../../../../components/tables/modules/crm/NotesTemplates';

// Styles
import './Templates.css';

// Static Data
const templatesTypes = require('../../../../static-data/TemplatesTypes.json');

const Templates = () =>
{
	const [loading] = React.useState(false);

	const {Translate} = useTraductor();

	const {templateType, SetTemplateType} = useTemplatesPage();

	const table = React.useMemo(() =>
	{
		switch(templateType.value)
		{
			case 'whatsapp':
				return <WhatsappTemplatesTable/>

			case 'note':
				return <NotesTemplatesTable/>

			default:
				return <></>
		}
	}, [templateType]);

	if(loading)
	{
		return <Loading/>
	}
	else
	{
		return (
			<Content class='no-padding'>
				<Panel class='no-padding full-height' swipe='horizontal'>
					<Panel class='full-height desktop3'>
						<Card>
							<Header>
								<Text class='bold'>{Translate('Templates')}</Text>
							</Header>
							<Body class='no-padding'>
								<List
									class = 'hover'
									currentClass = 'background-blue white'
									current = {templateType.value}
									options = {templatesTypes.filter(item => !item.hidden).map(item =>
									{
										return {
											identifier: item.value,
											text: Translate(item.text),
											Click: () => SetTemplateType(item)
										}
									})}
								/>
							</Body>
						</Card>
					</Panel>
					<Panel class='full-height desktop9'>
						<Card>
							{table}
						</Card>
					</Panel>
				</Panel>
			</Content>
		);
	}
}

const TemplatesWithProviders = () =>
{
	return (
		<TemplatesProviderPage>
			<Templates/>
		</TemplatesProviderPage>
	)
};

export {TemplatesWithProviders as Templates};