import config from '../../config.json';
import React from "react";
import {Generic} from 'crack-functions';

// Contexts
import {useSession} from './Session';

const WorkerContext = React.createContext();

const WorkerProvider = (props) =>
{
	const [registration, setRegistration] = React.useState({});

	const {session, UpdateSession} = useSession();

	const enabled = React.useMemo(() => !!navigator.serviceWorker, []);

	const Register = React.useCallback(async () =>
	{
		if(enabled && session?.user?._id)
		{
			const register = await navigator.serviceWorker.register('./../../../service-worker.js', {scope: '/'});

			setRegistration(register);
		}
	}, [enabled, session?.user?._id]);

	React.useEffect(() =>
	{
		Register();
	}, [Register]);

	const PushSubscription = React.useCallback(async () =>
	{
		if(registration.pushManager)
		{
			const subscription = await registration.pushManager.subscribe(
			{
				userVisibleOnly: true,
				applicationServerKey: config.webPush.publicKey
			});

			if(subscription)
			{
				Generic.Fetch('/user/web-push/subscription', subscription).then(response =>
				{
					if(response.status === 200)
					{
						UpdateSession();
					}
					else if(response.data.unsubscribe && typeof subscription.unsubscribe === 'function')
					{
						subscription.unsubscribe().then(success =>
						{
							if(success)
							{
								PushSubscription();
							}
						}).catch(e => {});
					}
				});
			}
		}
	}, [registration, UpdateSession]);

	React.useEffect(() =>
	{
		PushSubscription();
	}, [PushSubscription]);

	const value =
	{
		enabled,
		Register
	}

	return <WorkerContext.Provider value={value} {...props}/>;
}

const useWorker = () => React.useContext(WorkerContext);

export {WorkerProvider, useWorker};