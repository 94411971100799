import React from 'react';
import {Table, Input, Icon, Avatar} from 'crack-ux';
import {Generic, Amount} from 'crack-functions';

// Contexts
import {useTraductor} from '../../../../../contexts/general/Traductor';
import {useAcquisitionsPage} from '../../../../../contexts/pages/business/store/Acquisitions';

const Detail = () =>
{
	const {Translate} = useTraductor();
	const {productsSelected, ClearProduct, UpdatePrice, UpdateAmount} = useAcquisitionsPage();

	const products = React.useMemo(() => Generic.ObjectToArray(productsSelected), [productsSelected]);

	return (
		<Table
			header =
			{[
				{
					name: 'pictureMiniature',
					text: Translate('Picture')
				},
				{
					name: 'name',
					text: Translate('Product'),
					widht: 200
				},
				{
					name: 'amountInput',
					text: Translate('Amount'),
				},
				{
					name: 'purchasePriceInput',
					text: Translate('Price')
				},
				{
					name: 'totalText',
					text: Translate('Total')
				},
				{
					name: 'clearProduct',
					text: ''
				},
			]}
			body = {products.map(product =>
			{
				
				product.amountInput = <Input type='number' value={product.amount} style={{width: 65}} Change={(amount) => UpdateAmount(product, amount)}/>;
				product.purchasePriceInput = <Input type='number' value={product.purchasePrice} style={{width: 85}} Change={(price) => UpdatePrice(product, price)}/>;
				product.total = Amount.CalculateTotal({quantity: product.amount, price: product.purchasePrice, tax: 0});
				product.totalText = Amount.CurrencyFormat(product.total);
				product.clearProduct = <Icon class='icon-cancel red pointer' style={{padding: 10}} Click={() => ClearProduct(product)}/>; 
				product.pictureMiniature = <Avatar class='background-white' src={product.pictureURL} error='/assets/box.png' size={50} round={false}/>

				return product;	
			})}
			actions = {false}
			searcher = {false}
			footer = {false}
			pager = 
			{{
				rows: 1000
			}}
		/>
	)
}

export {Detail}