import moment from 'moment';
import {Dates} from 'crack-functions';

const SetEvent = (event) =>
{
	event.staff = Array.isArray(event.staff) ? event.staff : [];
	event.startDateObj = new Date(event.startDate);
	event.endDateObj = new Date(event.endDate);

	event.dateObj = new Date(event.startDate);
	event.dateText = moment(event.dateObj).format('DD/MM/YYYY');
	event.startTime = Dates.GetTimeForForm(event.startDateObj);
	event.endTime = Dates.GetTimeForForm(event.endDateObj);

	event.dateObj.setHours(0, 0, 0, 0);

	return event;
}

const EventsReducer = (state = {}, action) =>
{
	switch (action.type)
	{
		case 'LOAD_EVENTS':
			return {
				...state,
				events: action.events.map(event => SetEvent(event))
			}

		case 'CREATE_EVENT':
			return {
				...state,
				events: [...state.events, ...action.events.map(event => SetEvent(event))],
			}

		case 'UPDATE_EVENT':
			return {
				...state,
				events: state.events.map(event =>
				{
					const foundEvent = action.events.find(uEvent => uEvent._id === event._id);

					if(foundEvent)
					{
						return SetEvent(foundEvent);
					}
					else
					{
						return event;
					}
				})
			}

		case 'DELETE_EVENT':
		{
			return {
				...state,
				events: state.events.filter(event => action.eventsIds.indexOf(event._id) === -1)
			}
		}

		default:
			return state;
	}
}

export {EventsReducer};