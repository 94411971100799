import React from 'react';
import {Table, Section, Box, Icon} from 'crack-ux';

// Contexts
import {useTraductor} from '../../../../../contexts/general/Traductor';
import {useLists as useListsPage} from '../../../../../contexts/pages/business/home/Lists';
import {useLists, listsWithColor, defaultListColor} from '../../../../../contexts/collections/home/Lists';

const ListOptionsTable = (props) =>
{
	const {Translate} = useTraductor();
	const {ShowListOptionForm, ShowDeleteListOption} = useListsPage();

	const {currentList, listsByName, UpdateOrder, sorting} = useLists();

	const workingItems = React.useMemo(() =>
	{
		if(Array.isArray(listsByName?.[currentList.internalName]?.workingItems))
		{
			return listsByName[currentList.internalName].workingItems.map(item =>
			{
				const downButton = item.order < listsByName[currentList.internalName].workingItems.length && <Icon class={`icon-angle-down black ${sorting ? 'disabled' : ''}`} style={{padding: 5, backgroundColor: 'var(--lightGray)'}} Click={() => UpdateOrder(currentList.internalName, item.order, 'down')}/>;
				const upButton = item.order > 1 && <Icon class={`icon-angle-up black ${sorting ? 'disabled' : ''}`} style={{padding: 5, marginLeft: item.order >= listsByName[currentList.internalName].workingItems.length ? 33 : 7, backgroundColor: 'var(--lightGray)'}} Click={() => UpdateOrder(currentList.internalName, item.order, 'up')}/>;

				const specificFields = {};

				switch(currentList.internalName)
				{
					case 'salesFlow':
						specificFields.salesFlowSideWayText = Translate(!!item.data?.sideWay ? 'Yes' : 'No');
					break;

					default:
					break;
				}

				return {
					...item,
					...specificFields,
					colorComp: (
						<Section class='center'>
							<Box style={{width: 15, height: 15, backgroundColor: item.color || defaultListColor}}></Box>
						</Section>
					),
					orderButtons: (
						<Box class='right'>
							{downButton}
							{upButton}
						</Box>
					)
				};
			});
		}

		return [];
	}, [currentList.internalName, listsByName, UpdateOrder, sorting, Translate]);

	const header = React.useMemo(() =>
	{
		const result =
		[
			{
				name: 'order',
				text: Translate('Order')
			},
			{
				name: 'text',
				text: Translate('Option')
			},
			{
				name: 'orderButtons',
				text: Translate('Sorter')
			}
		];

		if(listsWithColor.includes(currentList.internalName))
		{
			result.splice(2, 0, {name: 'colorComp', text: Translate('Color'), width: 65});
		}

		switch(currentList.internalName)
		{
			case 'salesFlow':
				result.splice(3, 0, {name: 'salesFlowSideWayText', text: Translate('Side Way')});
			break;

			default:
			break;
		}

		return result;
	}, [currentList.internalName, Translate]);

	return (
		<Table
			class = 'hover'
			header =  {header}
			body = {workingItems}
			actions =
			{[
				{
					text: Translate('New Option'),
					class: 'background-blue white',
					Click: () => ShowListOptionForm(currentList.internalName),
					section: 'grouped'
				},
				{
					text: Translate('Edit Option'),
					section: 'context',
					icon:
					{
						class: 'icon-pencil'
					},
					Click: (listOption) => ShowListOptionForm(currentList.internalName, listOption)
				},
				{
					text: Translate('Delete Option'),
					class: 'red',
					section: 'context',
					icon:
					{
						class: 'icon-trash'
					},
					Click: (listOption) => ShowDeleteListOption(currentList.internalName, listOption)
				}
			]}
			DoubleClickRow = {(listOption) => ShowListOptionForm(currentList.internalName, listOption)}
			exportable = {false}
			searcher =
			{
				{text: Translate('Search')}
			}
			texts =
			{
				{
					noRecords:Translate('There Are Not Records To Show'),
					records:Translate('Records'),
					fields: Translate('Fields'),
					settings: Translate('Settings'),
					export: Translate('Export')
				}
			}
			filters = 
			{{
				columns:
				{
					editable: true
				},
				Apply:
				{
					text: Translate('Apply'),
					Click: () => {}
				},
				Reset:
				{
					text: Translate('Reset'),
					Click: () => {}
				}
			}}
		/>
	)
}

export {ListOptionsTable};