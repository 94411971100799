import React from 'react';
import {Table} from 'crack-ux';
import {Generic} from 'crack-functions';

// Contexts
import {useTraductor} from '../../../../../contexts/general/Traductor';
import {useCompanies} from '../../../../../contexts/collections/crm/Companies';
import {useLists} from '../../../../../contexts/collections/home/Lists';
import {useCRM} from '../../../../../contexts/modules/CRM';

import BusinessType from '../../../../../static-data/BusinessType.json';

const CompaniesTable = (props) =>
{
	const {Translate} = useTraductor();

	const {listsByName} = useLists();
	const {companies, SetCurrentCompany} = useCompanies();
	const {ShowCompanyForm, ShowDeleteCompany} = useCRM();

	const economicActivitiesItemsById = React.useMemo(() => listsByName.economicActivities?.itemsById || {}, [listsByName.economicActivities?.itemsById]);

	const body = React.useMemo(() =>
	{
		const businessTypeObj = Generic.ArrayToObjectByIdentifier(BusinessType, 'value');

		return companies.map(company =>
		{
			const economicActivitiesIds = Array.isArray(company?.economicActivities) ? company?.economicActivities : company?.economicActivities ? [company?.economicActivities] : [];

			company.typeText = businessTypeObj?.[company?.type]?.text || '';
			company.economicActivitiesText = economicActivitiesIds.map(economicActivityId => economicActivitiesItemsById[economicActivityId]?.text || null).filter(economicActivityText => economicActivityText).join(', ');

			return company;
		});
	}, [companies, economicActivitiesItemsById]);

	return (
		<Table
			class = 'current'
			header = 
			{[
				{
					name: 'codeText',
					text: Translate('Code')
				},
				{
					name: 'name',
					text: Translate('Company Name')
				},
				{
					name: 'nit',
					text: Translate('NIT')
				},
				{
					name: 'typeText',
					text: Translate('Type')
				},
				{
					name: 'description',
					text: Translate('Description'),
					maxWidth: 360,
					title: 'description'
				},
				{
					name: 'locationText',
					text: Translate('Direction'),
					maxWidth: 280,
					title: 'locationText'
				},
				{
					name: 'economicActivitiesText',
					text: Translate('Economic Activities')
				}
			]}
			body = {body}
			actions =
			{[
				{
					text: Translate('New Company'),
					class: 'background-blue white',
					section: 'grouped',
					Click: () => ShowCompanyForm()
				},
				{
					text: Translate('Edit Company'),
					section: 'context',
					icon:
					{
						class: 'icon-pencil'
					},
					Click: (company) => ShowCompanyForm(company)
				},
				{
					text: Translate('Delete Company'),
					class: 'red',
					section: 'context',
					icon:
					{
						class: 'icon-trash'
					},
					Click: (company) => ShowDeleteCompany(company)
				}
			]}
			ClickRow = {SetCurrentCompany}
			exportable = {false}
			searcher =
			{
				{text: Translate('Search')}
			}
			texts =
			{
				{
					noRecords:Translate('There Are Not Records To Show'),
					records:Translate('Records'),
					fields: Translate('Fields'),
					settings: Translate('Settings')
				}
			}
			filters = 
			{{
				columns:
				{
					editable: true
				},
				Apply:
				{
					text: Translate('Apply'),
					Click: () => {}
				},
				Reset:
				{
					text: Translate('Reset'),
					Click: () => {}
				}
			}}
		/>
	)
}

export {CompaniesTable};