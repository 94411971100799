import {Content, Image} from 'crack-ux'

const Catalogue = () =>
{
	return (
		<Content class='center background-black'>
			<Image class='background-white' src='/logo192.png' width={60} height={60}/>
		</Content>
	)
}

export {Catalogue};