import React from 'react';
import {Card, Header, Body, Footer, Text, Button} from 'crack-ux';

// Contexts
import {useTraductor} from '../../../../contexts/general/Traductor';
import {useModal} from '../../../../contexts/general/Modal';
import {useCustomers} from '../../../../contexts/collections/store/Customers';

const DeleteCustomer = (props) =>
{
	const {customer} = props;

	const {Translate} = useTraductor();
	const {DeleteCustomer} = useCustomers();
	const {CloseModal} = useModal();

	const HandleDeleteCustomer = () =>
	{
		DeleteCustomer(customer).then(response =>
		{
			if(response.status === 200)
			{
				CloseModal();
			}
		})
	}

	return (
		<Card>
			<Header>
				<Text class='bold'>{Translate(`Delete Customer`)}</Text>
			</Header>
			<Body>
				<Text type='p'>{Translate(`Are you sure you want to delete next customer?`)}</Text>
				<Text class='block' type='p'>{customer.name}</Text>
			</Body>
			<Footer class='right'>
				<Button class='background-blue white' text={Translate('Continue')} Click={HandleDeleteCustomer}/>
				<Button text={Translate('Cancel')} Click={CloseModal}/>
			</Footer>
		</Card>
	)
}

export {DeleteCustomer};