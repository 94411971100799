import React from 'react';
import {Chat} from 'crack-ux';

// Contexts
import {useSession} from '../../../../contexts/general/Session';
import {useBusiness} from '../../../../contexts/general/Business';
import {useSystemVariables} from '../../../../contexts/collections/home/SystemVariables';
import {useMessages} from '../../../../contexts/collections/crm/Messages';
import {useTemplates} from '../../../../contexts/collections/crm/Templates';
import {useStorages} from '../../../../contexts/collections/storage/Storage';
import {useCRM} from '../../../../contexts/modules/CRM';

// Components
import {Loading} from '../../../../components/views/Loading';

const CRMChat = (props) =>
{
	const {contact} = props;

	const {session} = useSession();
	const {business} = useBusiness();

	const {ShowFileViewer, currentContactId} = useCRM();

	const {VariableReplacer} = useSystemVariables();
	const {contactsMessages, CreateMessage} = useMessages();
	const {templates} = useTemplates();
	const {storagesById} = useStorages();

	const businessPhones = React.useMemo(() => Array.isArray(business.phones) ? business.phones.map(phone => ({text: `+${phone.country} ${phone.number}`, value: phone._id})) : [], [business.phones]);
	const templatesOptions = React.useMemo(() => templates.filter(template => template.channel === 'whatsapp').map(template =>
	({
		text: template.name,
		value: template._id,
		data: template,
		SetMessage: (template) =>
		{
			const attachedData = [];

			if(Array.isArray(template.data?.files))
			{
				template.data.files.forEach(file =>
				{
					if(storagesById[file])
					{
						attachedData.push(storagesById[file])
					}
				});
			}

			return ({
				message: template?.data?.content ? VariableReplacer(template.data.content, {business, contact, user: session.user}, 'crm-contact') : '',
				template: template.value,
				finishChat: !!template?.data?.finishChat,
				attached:
				{
					files: [],
					data: attachedData
				}
			});
		}
	})), [templates, VariableReplacer, business, contact, session.user, storagesById]);

	const contactMessages = contactsMessages[contact._id];
	const messages = React.useMemo(() =>
	{
		if(Array.isArray(contactMessages))
		{
			return contactMessages.map(message =>
			{
				return {
					...message,
					channel: message.channelValue || 'default',
					files: Array.isArray(message.storages) ? message.storages.map(storage =>
					{
						const document = storagesById?.[storage];

						return {
							url: document?.defaultURL || '',
							title: document?.fullName,
							Click: () => document ? ShowFileViewer(document) : () => {}
						}
					}) : []
				};
			});
		}

		return [];
	}, [contactMessages, storagesById, ShowFileViewer]);

	if(contact?._id)
	{
		return (
			<Chat
				key = {contact._id}
				contact = {contact}
				messages = {messages}
				whatsapp = 
				{{
					templates: templatesOptions,
					phones: businessPhones
				}}
				SendMessage = {(message) =>
				{
					CreateMessage({...message, contact: contact._id});
				}}
				droppable =
				{{
					types: ['files', 'data'],
				}}
				visible = {currentContactId === contact._id}
			/>
		);
	}
	else
	{
		return <Loading/>
	}
}

export {CRMChat as Chat}