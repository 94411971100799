import {Section, Box} from 'crack-ux';
import {Amount} from 'crack-functions';

const SetProducts = (product) =>
{
	const statusColor = product.available ? 'green' : 'red';
	const grossProfit = product.price?.sale - product.price?.purchase;
	const grossMargin = product.price?.sale ? Amount.Round((grossProfit / product.price?.sale) * 100) : 0;

	product.codeText = `PROD-${product.code}`;
	product.purchaseText = Amount.CurrencyFormat(product.price?.purchase);
	product.salesText = Amount.CurrencyFormat(product.price?.sale);
	product.grossProfit = Amount.CurrencyFormat(grossProfit);
	product.grossMargin = `${grossMargin}%`;
	product.status = <Section class='center'><Box class={`round background-${statusColor}`} style={{width: 15, height: 15}}/></Section>;
	product.currentQuantity = product.quantity?.current || 0;
	product.currentQuantityText = String(product.currentQuantity);

	return product;
}

const ProductsReducer = (state = {}, action) =>
{
	switch (action.type)
	{
		case 'LOAD_PRODUCTS':
			return {
				...state,
				products: action.products.map(product => SetProducts(product))
			}

		case 'CREATE_PRODUCT':
			return {
				...state,
				products: [...state.products, SetProducts(action.product)],
			}

		case 'UPDATE_PRODUCT':
			return {
				...state,
				products: state.products.map(product => product._id === action.product._id ? SetProducts(action.product) : product)
			}

		case 'DELETE_PRODUCT':
		{
			return {
				...state,
				products: state.products.filter(product => product._id !== action.product._id)
			}
		}

		case 'SET_LATEST_PRODUCT_CODE':
		{
			return {
				...state,
				code: action.code
			}	
		}

		default:
			return state;
	}
}

export {ProductsReducer};