import React from 'react';
import moment from 'moment';
import {Content, Panel, Box, Card, Header, Body, Footer, Text, List} from 'crack-ux';
import {Generic} from 'crack-functions';
import {Dates} from 'crack-functions';
import {Link} from 'react-router-dom';

// Contexts
import {useScreen} from '../../../../contexts/general/Screen';
import {useSession} from '../../../../contexts/general/Session';
import {useTraductor} from '../../../../contexts/general/Traductor';
import {useBusiness} from '../../../../contexts/general/Business';
import {DashboardPageProvider} from '../../../../contexts/pages/business/home/Dashboard';
import {useModules} from '../../../../contexts/main/Modules';
import {useProducts} from '../../../../contexts/collections/store/Products';
import {useMoves, GetSalesByDay} from '../../../../contexts/collections/store/Moves';

// Components
import {SalesChart} from '../../store/Reports/Sales/SalesChart';
import {Loading} from '../../../../components/views/Loading';

// CSS
import './Dashboard.css';

const options =
[
	{
		module: 'crm',
		route: 'contacts',
		name: 'Contacts'
	},
	{
		module: 'crm',
		route: 'scheduled-messages',
		name: 'Scheduled Messages'
	},
	{
		module: 'store',
		route: 'sales-point',
		name: 'Sales Point'
	},
	{
		module: 'store',
		route: 'acquisitions',
		name: 'Acquisitions'
	},
	{
		module: 'storage',
		route: 'files',
		name: 'Files'
	},
];

const startDate = moment();
const endDate = moment();

startDate.subtract(7, 'days');

const Dashboard = () =>
{
	const [loading, setLoading] = React.useState(true);

	const {deviceType} = useScreen();
	const {session} = useSession();
	const {Translate} = useTraductor();

	const {Fetch, business} = useBusiness();

	const {modulesByName} = useModules();
	const {products, LoadProducts} = useProducts();
	const {moves, LoadMoves} = useMoves();

	React.useEffect(() =>
	{
		if(business?._id)
		{
			const requests =
			[
				Fetch('/store/products/read'),
				Fetch('/store/moves/read', {_where: {annulled: false}})
			];
	
			Promise.all(requests).then(responses =>
			{
				if(responses.every(response => response.status === 200))
				{
					LoadProducts(responses[0].data || [])
					LoadMoves(responses[1].data || [])

					setLoading(false);
				}
			});
		}
	}, [Fetch, business._id, LoadProducts, LoadMoves]);

	const productsObj = React.useMemo(() => Generic.ArrayToObjectByIdentifier(products), [products]);
	const datesData = React.useMemo(() =>
	{
		const sales = moves.filter(move => move.type === 1 && move.dateObj >= startDate && move.dateObj <= endDate);

		return Generic.ObjectToArray(GetSalesByDay(sales, productsObj).datesData);
	}, [moves, productsObj]);

	const panelClass = React.useMemo(() => deviceType !== 'mobile' ? 'full-height' : '', [deviceType]);

	const actionsOptions = React.useMemo(() =>
	{
		return options.filter(option =>
		{
			const foundModule = modulesByName[option.module];

			if(foundModule && Array.isArray(foundModule.menu))
			{
				return foundModule.menu.find(item => item.route === option.route);
			}

			return false;
		}).map(option =>
		({
			identifier: `link-${option.module}-${option.route}`,
			content: <Link to={`../../${option.module}/${option.route}`}><Text class='block'>{Translate(option.name)}</Text></Link>
		}))
	}, [modulesByName, Translate]);

	if(loading) return <Loading/>

	return (
		<Content class='dashboard-page vertical-scroll'>
			<Panel class={`desktop3 ${panelClass}`}>
				<Card>
					<Header>
						<Text>{Translate(Dates.Greeting())} {session.user.name}</Text>
						<Box class='float-right'>
							<Text size={20} style={{marginTop: -7}}>👋</Text>
						</Box>
					</Header>
					<Body style={{height: 'calc(100% - var(--header) * 2)'}}>
						<Text type='p' style={{marginBottom: 20}}>{Translate('Some recommended actions')}</Text>
						<List
							class = 'dashboard-menu'
							autoSelect = {false}
							options = {actionsOptions}
						/>
					</Body>
				</Card>
			</Panel>
			<Panel class={`desktop9 vertical-scroll no-padding ${panelClass}`}>
				<Panel class='desktop6'>
					<Card style={{height: 'auto'}}>
						<Header>
							<Text>{Translate('Sales in the last 7 days')}</Text>
						</Header>
						<Body class='no-padding'>
							<SalesChart dates={datesData} periodText={null} showHeader={false}/>
						</Body>
						<Footer class='right'>
							<Link to={'../../store/reports'}><Text class='block blue pointer'>{Translate('Go to Reports')}</Text></Link>
						</Footer>
					</Card>
				</Panel>
			</Panel>
		</Content>
	)
}

const DashboardWithProviders = props =>
(
	<DashboardPageProvider>
		<Dashboard {...props}/>
	</DashboardPageProvider>
)

export {DashboardWithProviders as Dashboard};