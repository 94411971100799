import React from "react";

// Contexts
import {useModal} from "../general/Modal";

const PersonalContext = React.createContext({});

const PersonalProvider = (props) =>
{
	const {modals} = useModal();
	const value ={}

	return ( 		
		<PersonalContext.Provider value={value}>
			{props.children}
			{modals}
		</PersonalContext.Provider>
	);
}

const usePersonal = () => React.useContext(PersonalContext);

export {PersonalProvider, usePersonal};