import React from "react";

// Contexts
import {useModal} from "../../../general/Modal";

// Forms
import {ListOptionForm} from "../../../../components/forms/modules/home/List/ListOption";

// Warnings
import {DeleteListOption} from "../../../../components/warnings/home/DeleteListOption/DeleteListOption";

const ListsContext = React.createContext({});

const ListsProvider = (props) =>
{
	const {AddModal, modals} = useModal();

	const ShowListOptionForm = (internalName = '', listOption = {}) =>
	{
		AddModal(<ListOptionForm internalName = {internalName} listOption = {listOption}/>);
	}

	const ShowDeleteListOption = (internalName = '', listOption = {}) =>
	{
		AddModal(<DeleteListOption internalName = {internalName} listOption = {listOption}/>);
	}

	const value =
	{
		ShowListOptionForm,
		ShowDeleteListOption
	}

	return (
		<ListsContext.Provider value={value}>
			{props.children}
			{modals}
		</ListsContext.Provider>
	);
}

const useLists = () => React.useContext(ListsContext);

export {ListsProvider, useLists};