import React from 'react';
import {Card, Header, Body, Footer, Text, Button} from 'crack-ux';

// Contexts
import {useTraductor} from '../../../../contexts/general/Traductor';
import {useModal} from '../../../../contexts/general/Modal';

const DeleteChatBotOption = (props) =>
{
	const {option, Delete} = props;

	const {Translate} = useTraductor();
	const {CloseModal} = useModal();

	const HandleDeleteChatBotOption = () =>
	{
		Delete(option);
		CloseModal();
	}

	return (
		<Card>
			<Header>
				<Text class='bold'>{Translate('Delete Contact')}</Text>
			</Header>
			<Body>
				<Text>{Translate('Are you sure you want to delete next option?')}</Text>
				<Text class='block'>{option.value}</Text>
			</Body>
			<Footer class='right'>
				<Button class='background-red white' text={Translate('Continue')} Click={HandleDeleteChatBotOption}/>
				<Button text={Translate('Cancel')} Click={CloseModal}/>
			</Footer>
		</Card>
	)
}

export {DeleteChatBotOption};