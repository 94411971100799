import React from 'react';
import {Routes, Route, useNavigate, Navigate} from 'react-router-dom';
import {Page, Section, Header, Body, ContextMenu, Box, SidePanel, Text, Avatar, Badge, Icon} from 'crack-ux';
import {Text as TextF} from 'crack-functions';

// Views
import {Loading} from '../../components/views/Loading';

// Components
import {Modules} from './Modules';
import {Menu} from './Menu';
import {Account} from './Account';
import {BusinessSelector} from './BusinessSelector';

// Pages
import {CRM} from '../../pages/business/crm';
import {Home} from '../../pages/business/home';
import {Store} from '../../pages/business/store';
import {Invoicing} from '../../pages/business/invoicing';
import {Storage} from '../../pages/business/storage';
import {Staff} from '../../pages/business/staff';
import {Scheduler} from '../../pages/business/scheduler';

// Contexts
import {useScreen} from '../../contexts/general/Screen';
import {SessionProvider, useSession} from '../../contexts/general/Session';
import {SocketProvider} from '../../contexts/general/Socket.js';
import {TraductorProvider, useTraductor} from '../../contexts/general/Traductor';
import {ModalProvider, useModal} from '../../contexts/general/Modal';
import {StaffSessionProvider} from '../../contexts/general/StaffSession';
import {BusinessProvider, useBusiness} from '../../contexts/general/Business';
import {BusinessProvider as BusinessProviderLayout, useBusiness as useBusinessLayout} from '../../contexts/layouts/Business';
import {WorkerProvider} from '../../contexts/general/Worker';
import {ModulesProvider, useModules} from '../../contexts/main/Modules';
import {SystemVariablesProvider} from '../../contexts/collections/home/SystemVariables';
import {ListsProvider} from '../../contexts/collections/home/Lists';
import {StaffProvider} from '../../contexts/collections/staff/Staff';
import {NotificationsProvider, useNotifications} from '../../contexts/main/Notifications';

// Styles
import './Business.css';

const Business = () =>
{
	const [loading, setLoading] = React.useState(true);

	const {deviceType} = useScreen();
	const {session, CheckSession, GetSession} = useSession();
	const {Translate} = useTraductor();
	const {business} = useBusiness();
	const {modals} = useModal('business');
	const {showMenu, showAccount, SetShowAccount, SwitchAccount, currentTab, SetCurrentTab, path} = useBusinessLayout();
	const {notSeen} = useNotifications();
	const {modules, modulesByName} = useModules();
	const navigate = useNavigate();

	const HandleShowAccount = React.useCallback((identifier) =>
	{
		if(currentTab === identifier)
		{
			SwitchAccount();
		}
		else
		{
			SetCurrentTab(identifier);

			if(!showAccount)
			{
				SetShowAccount(identifier)
			}
		}
	}, [SetCurrentTab, SetShowAccount, SwitchAccount, currentTab, showAccount]);

	React.useEffect(() =>
	{
		CheckSession().then(sessionStatus =>
		{
			if(sessionStatus)
			{
				GetSession();
			}
			else
			{
				navigate('/');
			}
		});
	}, [CheckSession, GetSession, navigate]);

	React.useEffect(() =>
	{
		if(session?.user && business._id && modules.length > 0)
		{
			setLoading(false);
		}
	}, [session, business._id, modules]);

	const availableRoutes = React.useMemo(() =>
	{
		const result = [];
		const routesComponents =
		{
			'home': Home,
			'crm': CRM,
			'store': Store,
			'invoicing': Invoicing,
			'storage': Storage,
			'staff': Staff,
			'scheduler': Scheduler,
		};

		modules.forEach(item =>
		{
			const Page = routesComponents[item.route];

			if(Page)
			{
				result.push(<Route key={item.route} path={`/${item.route}/*`} element={<Page/>}/>)
			}
		});

		return result;
	}, [modules]);

	const badge = React.useMemo(() => notSeen > 0 ? <Text size={11} class='background-red white center' style={{paddingTop: 2.5, paddingLeft: 2}}>{notSeen > 9 ? '9+' : notSeen}</Text> : <></>, [notSeen]);

	if(loading)
	{
		return <Loading/>;
	}
	else
	{
		const fullName = TextF.FullName(session.user?.name, session.user?.lastName);
		const pathModule = path?.module ? path.module.split('-').map(part => Translate(TextF.Capitalize(part))).join(' ') : '';
		const pathMenu = path?.menu ? path.menu.split('-').map(part => Translate(TextF.Capitalize(part))).join(' ') : '';

		return (
			<Page class='business-layout relative'>
				<Section class={`navigation ${deviceType === 'mobile' ? 'mobile-navigation' : ''}`}>
					<Modules/>
					<Menu/>
				</Section>
				<Section class={`content ${deviceType !== 'mobile' ? showMenu ? 'contracted' : 'expanded' : ''}`}>
					<Header class='background-blue'>
						<Box>
							<Box style={{marginTop: -11, marginLeft: deviceType === 'mobile' ? 45 : 0}}>
								<BusinessSelector/>
							</Box>
							{deviceType !== 'mobile' ? <Text class='white'>/ {pathModule} / {pathMenu}</Text> : <></>}
						</Box>
						<Box class='float-right'>
							{/* <Box class='messages'>
								<Icon
									class = 'icon-chat white pointer'
									Click = {() => HandleShowAccount('messages')}
								/>
							</Box>*/}
							<Box class='notifications'>
								<Badge
									class = 'pointer'
									content = {badge}
									Click = {() =>
									{
										HandleShowAccount('notifications');
									}}
									vertical = 'bottom'
									horizontal = 'right'
									style = {{marginTop: -3}}
								>
									<Icon
										class = 'icon-globe white pointer'
										size = {24}
									/>
								</Badge>
							</Box>
							<Box
								class = 'profile-account pointer'
								Click = {() => HandleShowAccount('profile')}
							>
								{deviceType !== 'mobile' ? <Text class='white'>{fullName}</Text> : <></>}
								<Avatar
									class = 'center background-green white' 
									src = {session.user.pictureURL || TextF.Initials(fullName)} 
									style =
									{{
										marginTop: -8,
										marginLeft: 10
									}}
									error = {TextF.Initials(fullName)}
								/>
							</Box>
						</Box>
					</Header>
					<Body class='no-padding' style={{height: 'calc(100vh - var(--header))'}}>
						<Routes>
							{availableRoutes}
							<Route path='*' element={modulesByName.home?.menu?.[0]?.route ? <Navigate to={`./home/${modulesByName.home?.menu?.[0]?.route}`}/> : <></>}/>
						</Routes>
					</Body>
				</Section>
				<SidePanel class='account no-scroll' show={showAccount} style={{backgroundColor: '#ffffff'}}>
					<Account/>
				</SidePanel>
				{modals}
			</Page>
		)
	}
}

const BusinessWithProviders = (props) =>
{
	return (
		<SocketProvider>
			<SessionProvider>
				<WorkerProvider>
					<TraductorProvider>
						<ModalProvider id='business'>
							<BusinessProvider>
								<StaffSessionProvider>
									<ListsProvider>
										<StaffProvider>
											<NotificationsProvider>
												<SystemVariablesProvider>
													<BusinessProviderLayout>
														<ModulesProvider>
															<Business {...props}/>
															<ContextMenu/>
														</ModulesProvider>
													</BusinessProviderLayout>
												</SystemVariablesProvider>
											</NotificationsProvider>
										</StaffProvider>
									</ListsProvider>
								</StaffSessionProvider>
							</BusinessProvider>
						</ModalProvider>
					</TraductorProvider>
				</WorkerProvider>
			</SessionProvider>
		</SocketProvider>
	)
}

export {BusinessWithProviders as Business}