import React from 'react';
import {Content, Card} from 'crack-ux';

// Components
import {ContactList} from './ContactList';

// Contexts
import {ContactsProvider as ContactsProviderPage} from '../../../../contexts/pages/business/crm/Contacts';
import {useContacts} from '../../../../contexts/collections/crm/Contacts';

// Styles
import './Contacts.css';

const Contacts = () =>
{
	const {contacts} = useContacts();

	return (
		<Content class='crm-contacts'>
			<Card>
				<ContactList contacts={contacts} from='contacts'/>
			</Card>
		</Content>
	)
}

const ContactsWithProviders = () =>
{
	return (
		<ContactsProviderPage>
			<Contacts/>
		</ContactsProviderPage>
	)
};

export {ContactsWithProviders as Contacts};