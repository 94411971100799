import React from "react";

// Contexts
import {useModal} from "../../../general/Modal";

// Forms
import {TemplateForm} from '../../../../components/forms/modules/crm/Template';
import {NoteTemplateForm} from "../../../../components/forms/modules/crm/NoteTemplate/NoteTemplate";

// Warnings
import {DeleteTemplate} from "../../../../components/warnings/crm/DeleteTemplate";

// Static Data
const templatesTypes = require('../../../../static-data/TemplatesTypes.json');

const Templates = React.createContext({});

const TemplatesProviderPage = (props) =>
{
	const [templateType, setTemplateType] = React.useState(templatesTypes.filter(templateType => !templateType.hidden)[0]);

	const {AddModal} = useModal();	

	const ShowTemplateForm = (template = {}, {templateType}) =>
	{
		AddModal(<TemplateForm template = {template} templateType={templateType}/>);
	}

	const ShowNoteTemplateForm = (template = {}, {templateType}) =>
	{
		AddModal(<NoteTemplateForm template = {template} templateType={templateType}/>);
	}

	const ShowDeleteTemplateWarning = (template = {}) =>
	{
		AddModal(<DeleteTemplate template = {template}/>);
	}

	const value =
	{
		templateType,
		SetTemplateType: setTemplateType,
		ShowTemplateForm,
		ShowNoteTemplateForm,
		ShowDeleteTemplateWarning
	}

	return (
		<Templates.Provider value={value}>
			{props.children}
		</Templates.Provider>
	);
}

const useTemplatesPage = () => React.useContext(Templates);

export {TemplatesProviderPage, useTemplatesPage};