import React from 'react';
import {Section} from 'crack-ux';

// Tables
import {ScheduledMessagesTable} from '../../../../components/tables/modules/crm/ScheduledMessages/ScheduledMessages';

const ScheduledMessagesTab = ({contact}) =>
{
	return (
		<Section class='full-height'>
			<ScheduledMessagesTable contact={contact}/>
		</Section>
	);
};

export {ScheduledMessagesTab};