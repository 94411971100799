import React from "react";
import {Content, Card, Panel, Header, Body, Text, Select} from 'crack-ux';

// Functions
import {useTraductor} from "../../../contexts/general/Traductor";

// Context
import {useSession} from '../../../contexts/general/Session';

// Static-data
import languages from '../../../static-data/Languages.json'

const Preferences = (props) =>
{
    const {Translate} = useTraductor();
	const {session, SetPreference} = useSession();

	const languageOptions = languages.map(language =>
	{	
		return {
			"text": Translate(language.text),
			"value": language.value
		};
	});
	return (
		<Content class='no-padding full-height'>
			<Panel class='full-height'>
				<Card>
					<Header>
						<Text class='bold'>{Translate('Preferences')}</Text>
					</Header>
					<Body>
						<Panel class='desktop4'>
							<Select
								text = {Translate('Languages')}
								options = {languageOptions}
								selectedClass = 'background-blue white'
								selected = {(session?.user?.preferences?.language ? session.user.preferences.language : 'en')}
								Change = {(response) => {SetPreference({key: 'language', value: response.value})}}
							/>
						</Panel>
					</Body>
				</Card>
			</Panel>
		</Content>
	)
}

export {Preferences};