const SetList = (list) =>
{
	return list;
}

const ListsReducer = (state = {}, action) =>
{
	switch (action.type)
	{
		case 'LOAD_LISTS':
			return {
				...state,
				lists: action.lists.map(list => SetList(list))
			}

		case 'UPDATE_LIST':
			return {
				...state,
				lists: state.lists.map(list => list._id === action.list._id ? SetList(action.list) : list)
			}

		default:
			return state;
	}
}

export {ListsReducer, SetList};