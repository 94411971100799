import {Section, Header, Body, Text, List} from 'crack-ux';
import {Link} from 'react-router-dom';

// Contexts
import {useScreen} from '../../contexts/general/Screen';
import {useBusiness} from '../../contexts/layouts/Business';
import {useTraductor} from '../../contexts/general/Traductor';
import {useModules} from '../../contexts/main/Modules';

// Styles
import './Menu.css'

const Menu = () =>
{
	const {deviceType} = useScreen();
	const {showMenu, path} = useBusiness();
	const {Translate} = useTraductor();
	const {currentModule} = useModules();

	return (
		<Section class={`navigation-menu ${showMenu ? 'show' : 'hide'} ${deviceType === 'mobile' ? 'mobile-menu' : ''}`}>
			<Header class='module'>
				<Text>{Translate(currentModule.name)}</Text>
			</Header>
			<Body>
				<List
					class = 'menu'
					currentClass = 'background-blue white'
					current = {`./${path.module}/${path.menu}`}
					autoSelect = {false}
					options = {Array.isArray(currentModule.menu) ? currentModule.menu.map(option =>
					{
						return {
							identifier: `./${currentModule.route}/${option.route}`,
							content: <Link to={`./${currentModule.route}/${option.route}`}><Text class='block'>{Translate(option.name)}</Text></Link>
						}
					}) : []}
				/>
			</Body>
		</Section>
	)
}

export {Menu};