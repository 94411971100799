import React from 'react';
import {Content, Card, Calendar} from 'crack-ux';
import {Dates} from 'crack-functions';

// Contexts
import {useTraductor} from '../../../../contexts/general/Traductor';
import {useSchedulerModule} from '../../../../contexts/modules/Scheduler';
import {useLists} from '../../../../contexts/collections/home/Lists';
import {useEventsConfig} from '../../../../contexts/collections/scheduler/EventsConfig';
import {useEvents} from '../../../../contexts/collections/scheduler/Events';

const CalendarPage = (props) =>
{
	const {ShowEventConfigForm, ShowEventForm, ShowConfirmEventRecurrence} = useSchedulerModule();

	const {Translate} = useTraductor();
	const {listsByName} = useLists();
	const {eventsConfig} = useEventsConfig();
	const {events, UpdateEvent, DeleteEvent} = useEvents();

	const eventTypesObj = React.useMemo(() => listsByName.eventTypes?.itemsById || {}, [listsByName.eventTypes?.itemsById]);

	const eventsList = React.useMemo(() =>
	{
		const newEvents = [];

		events.forEach(event =>
		{
			if(event.startTime && event.startTime)
			{
				const backgroundColor = eventTypesObj?.[event.eventType]?.color || '#666666';
	
				newEvents.push
				({
					title: event.title,
					date: event.dateObj,
					startTime: event.startTime,
					endTime: event.endTime,
					style: {backgroundColor},
					Click: () => console.log(event),
					data: event
				});
			}
		});

		return newEvents;
	}, [events, eventTypesObj]);

	const HandleChangeEvent = event =>
	{
		const formDate = Dates.GetDateForForm(event.date);

		const eventData =
		{
			startTime: Dates.GetDateFromForm(formDate, event.startTime),
			endTime: Dates.GetDateFromForm(formDate, event.endTime),
			_id: event.data._id,
		};

		const foundEventConfig = eventsConfig.find(eventConfig => eventConfig._id === event.data.eventConfig);

		if(foundEventConfig && foundEventConfig.isRecurring)
		{
			ShowConfirmEventRecurrence(eventData, {action: 'update'});
		}
		else
		{
			UpdateEvent(eventData);
		}

		return true;
	}

	return (
		<Content>
			<Card>
				<Calendar
					viewType = 'Weekly'
					events =
					{{
						list: eventsList,
						movable: true,
						resizable: true,
						Change: HandleChangeEvent,
						actions:
						[
							{
								text: Translate('Edit Event'),
								icon:
								{
									class: 'icon-edit'
								},
								Click: (event) =>
								{
									ShowEventForm(event.data);
								}
							},
							{
								text: Translate('Edit Recurrence'),
								icon:
								{
									class: 'icon-edit'
								},
								Click: (event) =>
								{
									const foundEventConfig = eventsConfig.find(eventConfig => eventConfig._id === event.data.eventConfig);

									if(foundEventConfig)
									{
										ShowEventConfigForm(foundEventConfig);
									}
								}
							},
							{
								text: Translate('Delete Event'),
								icon:
								{
									class: 'icon-trash'
								},
								class: 'red',
								Click: (event) =>
								{
									const eventData =
									{
										_id: event.data._id,
									};

									const foundEventConfig = eventsConfig.find(eventConfig => eventConfig._id === event.data.eventConfig);

									if(foundEventConfig && foundEventConfig.isRecurring)
									{
										ShowConfirmEventRecurrence(eventData, {action: 'delete'});
									}
									else
									{
										DeleteEvent(eventData);
									}
								}
							},
						]
					}}
					ChangeDates = {(dates) =>
					{
						dates.startDate.setTime(dates.startDate.getTime() - 1);
						dates.endDate.setTime(dates.endDate.getTime() + 1);
						
						console.log('New Dates', dates);
					}}
					actions =
					{[
						{
							text: Translate('New Event'),
							icon:
							{
								class: 'icon-ok'
							},
							Click: (day) =>
							{
								ShowEventConfigForm
								({
									startDate: day.date,
									startTimeValue: day.startTime,
									recurrence:
									{
										form:
										{
											startDate: day.date,
											startTime: day.startTime,
											endTime: '',
											FREQ: 'NEVER'
										},
										rrule: ''
									}
								});
							}
						},
					]}
				/>
			</Card>
		</Content>
	);
}

const CalendarWithProviders = (props) =>
{
	return (
		<CalendarPage {...props}/>
	);
}

export {CalendarWithProviders as CalendarPage};