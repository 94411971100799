import moment from "moment";

const SetCampaign = (campaign) =>
{
	campaign.codeText = `CAMP-${campaign.code}`;
	campaign.startDateText = campaign.startDate ? moment(campaign.startDate).format('DD/MM/YYYY') : '';
	campaign.endDateText = campaign.endDate ? moment(campaign.endDate).format('DD/MM/YYYY') : '';
	campaign.tags = Array.isArray(campaign.tags) ? campaign.tags : [];
	campaign.tagsText = campaign.tags.join(', ');

	return campaign;
}

const CampaignsReducer = (state = {}, action) =>
{
	switch (action.type)
	{
		case 'LOAD_CAMPAIGNS':
			return {
				...state,
				campaigns: action.campaigns.map(campaign => SetCampaign(campaign))
			}

		case 'CREATE_CAMPAIGN':
			return {
				...state,
				campaigns: [...state.campaigns, SetCampaign(action.campaign)],
			}

		case 'UPDATE_CAMPAIGN':
			return {
				...state,
				campaigns: state.campaigns.map(campaign => campaign._id === action.campaign._id ? SetCampaign(action.campaign) : campaign)
			}

		case 'DELETE_CAMPAIGN':
		{
			return {
				...state,
				campaigns: state.campaigns.filter(campaign => campaign._id !== action.campaign._id)
			}
		}

		case 'SET_LATEST_CAMPAIGN_CODE':
		{
			return {
				...state,
				code: action.code
			}	
		}

		default:
			return state;
	}
}

export {CampaignsReducer, SetCampaign};