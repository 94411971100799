import {Content, Panel, Box, Header, Body, Footer, Text, Button} from 'crack-ux';

// Context
import {useTraductor} from '../../../contexts/general/Traductor';
import {usePublic} from '../../../contexts/layouts/Public';

// Views
import {Login} from '../../../components/views/Login';

const Init = (props) =>
{
	const {language} = props;

	const {Translate} = useTraductor();
	const {homeRef, GoTo, ShowSignUpForm} = usePublic();

	return (
		<Content reference={homeRef} class='init'>
			<Content class='laptop' style={{height: 'calc(100% - 64px)'}}>
				<Panel class='welcome desktop7 relative'>
					<Box class='full-width' style={{marginTop: '-4vh'}}>
						<Header class='no-border'>
							<Text class='bold white'>{language === 'en' ? 'Streamline your success with' : 'Optimiza tu éxito con'}</Text>
							<Text class='bold white masque'>{Translate('Sice', language)}</Text>
						</Header>
						<Body>
							<Text class='white'>{language === 'en' ? "The smart CRM solution that connects directly to WhatsApp! Say goodbye to manual messaging. With our integrated CRM, you can schedule messages, create bots, and send mass messages with ease. Whether you are managing leads, sales, or customer support, Sice has got you covered." : "¡La solución CRM inteligente que se conecta directamente a WhatsApp! Di adiós a la mensajería manual. Con nuestro CRM integrado, puede programar mensajes, crear bots y enviar mensajes masivos con facilidad. Ya sea que esté administrando clientes potenciales, ventas o atención al cliente, Sice lo tiene cubierto."}</Text>
						</Body>
						<Footer class='no-border'>
							<Button class='contact-us' text={Translate('Contact Us', language)} Click={() => GoTo('contact-us')}/>
							<Button class='signup' text={Translate('Sign Up For Free', language)} Click={ShowSignUpForm}/>
						</Footer>
					</Box>
				</Panel>
				<Panel class='access desktop5 relative'>
					<Box style={{width: 320}}>
						<Login language={language}/>
					</Box>
				</Panel>
			</Content>
		</Content>
	)
}

export {Init};