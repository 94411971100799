import React from "react";
import {Header, Body, Footer, Form, Text, Button} from 'crack-ux';
import {Generic} from 'crack-functions';

const EmailForm = () =>
{
	const [form, setForm] = React.useState({});
	const [message, setMessage] = React.useState(null);

	const SendEmail = () =>
	{
		if(form.email)
		{
			Generic.Fetch('/user/recover-password-email', form).then(response=>
			{
				const color = (response.status === 200 && response.data) ? 'green' : 'red';
				setMessage(<Text class={`block center ${color}`}>{response.message}</Text>);
			})
		}
		else
		{
			setMessage(<Text class='block center red'>Please enter your email</Text>);
		}
	}

	return (
		<>
			<Header>
				<Text class='block center uppercase blue' size={20}>Recover Password</Text>
			</Header>
			<Body>
				<Form
					inputs= 
					{[
						{
							name: 'email',
							text: 'Email',
							required: true
						}
					]}
					Change = {(response) => setForm(response)}
					Submit = {SendEmail}
					response = {message}
				/>
			</Body>
			<Footer>
				<Button class='full-width background-blue white' text='Send Email' Click={SendEmail}/>
			</Footer>
		</>
	)
}

export {EmailForm};