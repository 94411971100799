import React from "react";

// Contexts
import {MovesProvider} from "../../../collections/store/Moves";

const Reports = React.createContext({});

const reports =
[
	{
		identifier: 'sales',
		text: 'Sales Report',
		picture: 'sales-report.png',
		route: 'sales'
	}
];

const ReportsPageProvider = (props) =>
{
	const value =
	{
		reports,
	}

	return (
		<MovesProvider>
			<Reports.Provider value={value}>
				{props.children}
			</Reports.Provider>
		</MovesProvider>
	)
}

const useReportsPage = () => React.useContext(Reports);

export {ReportsPageProvider, useReportsPage}