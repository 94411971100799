import React from 'react';
import {Card, Header, Body, Footer, Form, Button, Text} from 'crack-ux';

// Context
import {useModal} from '../../../../../contexts/general/Modal';
import {useTemplates} from '../../../../../contexts/collections/crm/Templates';
import {useTraductor} from '../../../../../contexts/general/Traductor';

// Components
import {TextEditor} from '../../../../TextEditor/TextEditor';

// Functions
import Validations from '../../../../../objects/Validate';

const NoteTemplateForm = (props) =>
{
	const {templateType} = props;

    const [form, setForm] = React.useState(props.template);
	const [content, setContent] = React.useState(props.template.content || '');
	const [messages, setMessages] = React.useState({});

    const {CloseModal} = useModal();
	const {CreateTemplate, UpdateTemplate} = useTemplates();
    const {Translate} = useTraductor();

	const HandleSubmit = async () =>
	{
		const cloneForm = {...form};

		cloneForm.channel = templateType.value;
		cloneForm.content = content || null;

		const result = props.template._id ? await UpdateTemplate({...cloneForm, _id: props.template._id}) : await CreateTemplate(cloneForm);

		setMessages(Validations.ParseByField(result.validations));

		if(result.status === 200)
		{
			CloseModal();
		}
	}

	return (
		<Card>
			<Header>
				<Text class='bold'>{props.template._id ? Translate('Edit Template') : Translate('New Template')}</Text>
			</Header>
			<Body class='vertical-scroll' style={{width: '400px', maxWidth: 'calc(100vw - 40px)'}}>
				<Form
					inputs =
					{
						[
							{
								name: 'name',
								text: Translate('Template Name'),
								value: form.name,
								message: messages.name
							},
							{
								name: 'title',
								text: Translate('Title'),
								value: form.title,
								message: messages.title
							},
							{
								text: <Text class='semi-bold'>{Translate('Content')}</Text>,
								type: 'content', 
								content: (
									<>
										<TextEditor content={content} Change={setContent}/>
										{messages.content}
									</>
								)
							}
						]                        
					}
					Change = {(response) => setForm(response)}
					Submit = {HandleSubmit}
				/>
			</Body>
			<Footer class='right'>
				<Button
					text = {Translate('Save')}
					class = 'background-blue white'
					Click = {HandleSubmit}
				/>
				<Button
					text = {Translate('Cancel')}
					Click = {CloseModal}
				/>
			</Footer>
		</Card>
	)
}

export {NoteTemplateForm};