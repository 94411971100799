
import React from 'react';
import {Card, Panel, Header, Body, Footer, Table, Form, Text, Button, FileList2} from 'crack-ux';
import {Generic} from 'crack-functions';

// Contexts
import {useTraductor} from '../../../../../contexts/general/Traductor';
import {useModal} from '../../../../../contexts/general/Modal';
import {useCRM as useCRMModule} from '../../../../../contexts/modules/CRM';
import {useChatBots} from '../../../../../contexts/collections/crm/ChatBots';
import {useStorages} from '../../../../../contexts/collections/storage/Storage';

// Functions
import Validations from '../../../../../objects/Validate';

const ChatBotForm = (props) =>
{
	const {chatBot, channel} = props;

	const [form, setForm] = React.useState(chatBot);
	const [files, setFiles] = React.useState(Array.isArray(chatBot.filesAsObj) ? chatBot.filesAsObj : []);
	const [messages, setMessages] = React.useState({});
	const [options, setOptions] = React.useState(Array.isArray(chatBot.options) ? chatBot.options : []);

	const {CloseModal} = useModal();
	const {Translate} = useTraductor();
	const {ShowChatBotOptionForm, ShowDeleteChatBotOption} = useCRMModule();

	const {chatBots, CreateChatBot, UpdateChatBot} = useChatBots();
	const {storagesById} = useStorages();

	const HandleSubmit = async () =>
	{
		const chatBotData = {...form, options, channel: channel.id};

		chatBotData.files = files.map(file =>
		{
			const cloneFile = {...file};

			if(cloneFile.localFile)
			{
				cloneFile.replaceFile = true;
			}

			return cloneFile;
		});
		chatBotData._files =
		{
			chatBot: files.map(file => file.localFile)
		};

		const result = chatBot._id ? await UpdateChatBot({...chatBotData, _id: chatBot._id}) : await CreateChatBot(chatBotData);

		setMessages(Validations.ParseByField(result.validations));

		if(result.status === 200)
		{
			CloseModal();
		}
	}

	const body = React.useMemo(() =>
	{
		const chatBotsById = Generic.ArrayToObjectByIdentifier(chatBots);

		return options.map(option =>
		{
			option.tmpId = option._id || option.tmpId;
			option.chatBotText = chatBotsById?.[option?.chatBot]?.name;

			return option;
		});
	}, [chatBots, options]);

	return (
		<Card>
			<Panel class='no-padding' style={{height: 600, maxHeight: 'calc(100vh - var(--header))', width: 1200, maxWidth: 'calc(100vw - 40px)'}} swipe='horizontal'>
				<Panel class='desktop4 no-padding full-height' style={{borderRight: '1px solid var(--lightGray)'}}>
					<Header>
						<Text class='bold'>{Translate(chatBot._id ? 'Edit Chat Node' : 'New Chat Node')}</Text>
					</Header>
					<Body class='vertical-scroll' style={{height: 'calc(100% - var(--header) - 67px)'}}>
						<Form
							inputs =
							{[
								{
									name: 'name',
									text: Translate('Name'),
									value: form.name,
									message: messages.name,
								},
								{
									name: 'message',
									text: Translate('Message'),
									value: form.message,
									message: messages.message,
									rows: 16,
									type: 'textarea'
								},
								{
									text: Translate('Files'),
									type: 'content',
									content: (
										<FileList2
											fileList = {files.map(file =>
											{
												file.src = storagesById?.[file?._id]?.publicURL;
			
												return file;
											})}
											Change = {(response) => setFiles(response.files)}
										/>
									)
								},
								{
									name: 'main',
									text: Translate('Main'),
									value: form.main,
									class: 'desktop6',
									type: 'checkbox'
								},
								{
									type: 'content',
									content: <Text class='semi-bold'>{Translate('Chat Status')}</Text>
								},
								{
									name: 'chatStatus',
									value: form.chatStatus || 0,
									type: 'radio',
									options:
									[
										{text: Translate('None'), value: 0},
										{text: Translate('Finish Chat'), value: 1},
										{text: Translate('Request Operator'), value: 2}
									]
								}
							]}
							Change = {setForm}
						/>
					</Body>
					<Footer class='right'>
						<Button
							class = 'background-blue white'
							text = {Translate('Save')}
							Click = {HandleSubmit}
						/>
						<Button
							text = {Translate('Cancel')}
							Click = {CloseModal}
						/>
					</Footer>
				</Panel>
				<Panel class='desktop8 no-padding full-height'>
					<Header>
						<Text class='bold'>{Translate('Options')}</Text>
					</Header>
					<Body class='no-padding' style={{height: 'calc(100% - var(--header))'}}>
						<Table
							header =
							{[
								{
									name: 'value',
									text: Translate('Value')
								},
								{
									name: 'chatBotText',
									text: Translate('Chat Node')
								}
							]}
							body = {body}
							actions =
							{[
								{
									text: Translate('New Option'),
									class: 'background-blue white',
									section: 'grouped',
									Click: () => ShowChatBotOptionForm({chatBot, Submit: (option) => setOptions(current =>  [...current, {...option, tmpId: Date.now()}]), channel})
								},
								{
									text: Translate('Edit Option'),
									icon:
									{
										class: 'icon-pencil'
									},
									section: 'context',
									Click: (option) => ShowChatBotOptionForm({chatBot, option, Submit: (newOption) =>
									{
										const optionsClone = [...options];
										const index = optionsClone.findIndex(item => item.tmpId === newOption.tmpId);

										optionsClone[index] = newOption;

										setOptions(optionsClone);
									}, channel})
								},
								{
									text: Translate('Delete Option'),
									class: 'red',
									icon:
									{
										class: 'icon-trash'
									},
									section: 'context',
									Click: (option) => ShowDeleteChatBotOption({option, Delete: (deleteOption) =>
									{
										const optionsClone = [...options];
										const index = optionsClone.findIndex(item => item.tmpId === deleteOption.tmpId);

										optionsClone.splice(index, 1);

										setOptions(optionsClone)
									}})
								}
							]}
							exportable = {false}
						/>
					</Body>
				</Panel>
			</Panel>
		</Card>
	);
}

export {ChatBotForm}