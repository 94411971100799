import React from 'react';

// Context
import {useSocket} from './Socket';
import {useSession} from './Session';
import {useBusiness} from './Business';

const StaffContext = React.createContext({});

const StaffSessionProvider = (props) =>
{
	const [member, setMember] = React.useState({});

	const Socket = useSocket();
	const {Fetch, business} = useBusiness();

	const {session} = useSession();

	React.useEffect(() =>
	{
		if(session?.user?._id && business?._id)
		{
			const requests =
			[
				Fetch('/staff/read', {_where: {user: session.user._id}, _limit: 1}),
			];

			Promise.all(requests).then(responses =>
			{
				if(responses.every(response => response.status === 200))
				{
					setMember(responses[0].data || {});
				}
			});
		}
	}, [Fetch, session?.user?._id, business?._id]);

	React.useEffect(() =>
	{
		if(typeof Socket.ConnectEvents === 'function')
		{
			if(business?._id && member._id) // working with a business
			{
				Socket.ConnectEvents(`staff_session_${business._id}`,
				[
					{
						name: `UPDATE_MEMBER_${member._id}`,
						Function: (response) =>
						{
							setMember(response);
						}
					}
				]);
			}
		}
	}, [Socket, business?._id, member._id]);

	const permissions = React.useMemo(() =>
	{
		const result = typeof member?.permissions === 'object' && member.permissions !== null ? {...member.permissions} : {};

		result.HasAccess = module => member?.user?._id === business.owner || result[module]?.active;

		for(let module in result)
		{
			result[module].Can = id => result[module]?.permissions?.[id];
		}

		result.Can = (module, id) => member?.user?._id === business.owner || (result.HasAccess(module) && result[module]?.Can(id));

		return result;
	}, [business?.owner, member?.user?._id, member?.permissions]);

	const value =
	{
		member,
		permissions,
	}

	return <StaffContext.Provider value={value} {...props}/>
}

const useStaffSession = () => React.useContext(StaffContext);

export {StaffSessionProvider, useStaffSession};