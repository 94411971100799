import React from 'react';

// Context
import {useSession} from './Session'; 

// Languages
import Spanish from '../../static-data/languages/Spanish.json';

const Traductor = React.createContext({});

const TraductorProvider = (props) =>
{
    const {session} = useSession();

    const Translate = (text = '', language = 'en') =>
	{
		if(session?.user?.preferences?.language)
		{
			language = session.user.preferences.language;
		}

		const lowercaseText = text.toLowerCase();

		switch (language)
		{
			case 'es':
				return (Spanish[lowercaseText]) ? Spanish[lowercaseText] : text;
			default:
				return text;
		}
	}


    const value =
    {
        Translate
    }

    return <Traductor.Provider value={value} {...props}/>
}

const useTraductor = () => React.useContext(Traductor);

export {TraductorProvider, useTraductor};