import React from 'react';
import {Link, useNavigate, useLocation} from 'react-router-dom';
import {Card, Header, Body, Text, List} from 'crack-ux';

// Functions
import {Generic} from 'crack-functions';

// Contexts
import {useTraductor} from '../../contexts/general/Traductor';

// Style
import './Menu.css';

const ProfileMenuSettings = () =>
{
	const navigate = useNavigate();
	const {pathname} = useLocation();
	const {Translate} = useTraductor();

	const page = pathname.split('/').pop();
	
	return (
		<Card 
			class = 'background-white'
			
			style = {{height: 'calc(100vh - var(--header) - 35px)'}}
		>
			<Header>
				<Text class='bold'>{Translate('Profile Settings')}</Text>
			</Header>
			<Body>
				<List
					class = 'personal-menu center'
					currentClass = 'background-blue white'
					options =
					{[
						{
							identifier: 'business',
							content: <Link to='./business'><Text class='block'>{Translate('Business')}</Text></Link>
						},
						{
							identifier: 'profile',
							content: <Link to='./profile'><Text class='block'>{Translate('Profile')}</Text></Link>
						},
						{
							identifier: 'preferences',
							content: <Link to='./preferences'><Text class='block'>{Translate('Preferences')}</Text></Link>
						},
						{
							identifier: 'logout',
							text: Translate('Logout'),
							Click: () => {
								Generic.Fetch('/user/logout').then(response =>
								{
									if(response.status === 200)
									{
										navigate('/');
									}
								});
							},
						},
					]}
					current = {page}
				/>
			</Body>
		</Card>
	)
}

export {ProfileMenuSettings};