import config from '../../config.json';
import React from 'react';
import {generatePath} from 'react-router';
import {useParams} from 'react-router-dom';
import {Select} from 'crack-ux';

// Contexts
import {useSession} from '../../contexts/general/Session';
import {useBusiness} from '../../contexts/general/Business';

// Styles
import './BusinessSelector.css';

const BusinessSelector = (props) =>
{
	const {session} = useSession();
	const {business} = useBusiness();

	const params = useParams();
	// const navigate = useNavigate();

	const HandleChange = (response) =>
	{
		const path = generatePath(`/b/:business/${params['*']}`, {business: response.value});

		// navigate(path); // This causes issues
		window.location.href = `${config.client.domain}${config.client.port ? `:${config.client.port}`: ''}${path}`;
	}

	const businessOptions = React.useMemo(() =>
	{
		if(Array.isArray(session?.user?.business))
		{
			return session.user.business.map(businessItem =>
			({
				text: businessItem.name,
				value: businessItem.uniqueName
			}));
		}

		return [];
	}, [session.user.business]);

	return (
		<Select
			class = 'business-selector inline-block pointer'
			options = {businessOptions}
			selected = {business?.uniqueName}
			Change = {HandleChange}
		/>
	);
};

export {BusinessSelector};