import React from "react";
import {Content, Panel} from 'crack-ux';

// Views
import {ProductsGrid} from "../../../../components/views/modules/store/ProductsGrid";

// Forms
import {Sale} from "../../../../components/forms/modules/store/Sale/Sale";

// Contexts
import {SalesPointPageProvider} from "../../../../contexts/pages/business/store/SalesPoint";

// Styles
import "./SalesPoint.css";

const SalesPoint = () =>
{
	return (
		<Content class='store-sales-point'>
			<Panel class='no-padding full-height' swipe='horizontal'>
				<Panel class='desktop7 no-padding-vertical no-padding-left'>
					<ProductsGrid _type='sales'/> 
				</Panel>
				<Panel class='desktop5 no-padding'>
					<Sale/>
				</Panel>
			</Panel>
		</Content>
	)
}

const SalesPointWithProviders = () =>
{
	return (
		<SalesPointPageProvider>
			<SalesPoint/>
		</SalesPointPageProvider>
	)
}

export {SalesPointWithProviders as SalesPoint};