import React from 'react';
import {Card, Header, Body, Footer, Text, Button} from 'crack-ux';
import {Text as TextF} from 'crack-functions';

// Contexts
import {useTraductor} from '../../../../contexts/general/Traductor';
import {useModal} from '../../../../contexts/general/Modal';
import {useContacts} from '../../../../contexts/collections/crm/Contacts';

const DeleteContact = (props) =>
{
	const {contact, RemoveContactTab} = props;

	const {Translate} = useTraductor();
	const {CloseModal} = useModal();

	const {DeleteContact} = useContacts();

	const HandleDeleteContact = () =>
	{
		RemoveContactTab(contact._id);
		DeleteContact(contact).then(response =>
		{
			if(response.status === 200)
			{
				CloseModal();
			}
		})
	}

	return (
		<Card>
			<Header>
				<Text class='bold'>{Translate('Delete Contact')}</Text>
			</Header>
			<Body>
				<Text>{Translate('Are you sure you want to delete next contact?')}</Text>
				<Text class='block'>{TextF.FullName(contact.name, contact.lastName)}</Text>
			</Body>
			<Footer class='right'>
				<Button class='background-blue white' text={Translate('Continue')} Click={HandleDeleteContact}/>
				<Button text={Translate('Cancel')} Click={CloseModal}/>
			</Footer>
		</Card>
	)
}

export {DeleteContact};