import React from 'react';
import {Panel, Form, Text, Button, Header, Body, Footer, Card} from 'crack-ux';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

// Components
import {Map} from './Map';

// Contexts
import {useTraductor} from '../../../contexts/general/Traductor';
import {useModal} from '../../../contexts/general/Modal';

import './Location.css';

const defaultLocation =
{
	country: '',
	state: '',
	province: '',
	city: '',
	street: '',
	postalCode: '',
	latitude: -16.496008734161734,
	longitude: -68.13365539583876,
}

const Location = (props) =>
{
	const {location, GetLocation, readOnly} = props;

	const [currentLocation, setCurrentLocation] = React.useState(defaultLocation);
	const [searcher, setSearcher] = React.useState('');

	const {Translate} = useTraductor();
	const {CloseModal} = useModal();

	React.useEffect(() =>
	{
		setCurrentLocation(Object.keys(location).length > 0 ? location : defaultLocation);
	}, [location]);

	const GetAddress = (response) =>
	{
		const result = response.results[0];

		const location = {};

		result.address_components.forEach(component =>
		{
			location[component.types[0]] = component.long_name;
		})

		const street = 
		[
			location.route,
			location.street_number
		]

		if(!readOnly)
		{
			setCurrentLocation(
			{
				street: street.filter(value => value).join(', '),
				postalCode: location.postal_code,
				city: location.locality,
				province: location.administrative_area_level_2,
				state: location.administrative_area_level_1, 
				country: location.country,
				latitude: result.geometry.location.lat,
				longitude: result.geometry.location.lng,
			});
		}
	}

	return (
		<Card>
			<Panel class='no-padding' style={{height: 600, maxHeight: 'calc(100vh - var(--header))', width: 1200, maxWidth: 'calc(100vw - 40px)'}} swipe='horizontal'>
				<Panel class='desktop4 full-height no-padding-vertical'>
					<Header>
						<Text class='bold'>{Translate('Location')}</Text>
					</Header>
					<Body class='no-padding-bottom'>
						<Text class='block'>
							<GooglePlacesAutocomplete
								apiKey="AIzaSyBKlbA051wqGwFbzw5jYhUSuWnMBsjs5XU"
								selectProps=
								{{
									value: searcher,
									onChange: (response) =>
									{
										if(!readOnly)
										{
											setSearcher(response.label);
										}
									},
								}}
							/>
						</Text>
					</Body>
					<Body class='no-padding-horizontal vertical-scroll' style={{maxHeight: 'calc(100vh - var(--header) - 103px)'}}>
						<Form
							inputs =
							{[
								{
									name: 'country',
									text: 'Country',
									value: currentLocation.country, 
									Change: (value) => setCurrentLocation({...currentLocation, country: value}),
									disabled: readOnly,
								},
								{
									name: 'state',
									text: 'State',
									value: currentLocation.state, 
									Change: (value) => setCurrentLocation({...currentLocation, state: value}),
									disabled: readOnly,
								},
								{
									name: 'province',
									text: 'Province',
									value: currentLocation.province, 
									Change: (value) => setCurrentLocation({...currentLocation, province: value}),
									disabled: readOnly,
								},
								{
									name: 'city',
									text: 'City',
									value: currentLocation.city, 
									Change: (value) => setCurrentLocation({...currentLocation, city: value}),
									disabled: readOnly,
								},
								{
									name: 'street',
									text: 'Street',
									value: currentLocation.street, 
									Change: (value) => setCurrentLocation({...currentLocation, street: value}),
									disabled: readOnly,
								},
								{
									name: 'zipCode',
									text: 'Post Code',
									value: currentLocation.postalCode, 
									Change: (value) => setCurrentLocation({...currentLocation, postalCode: value}),
									disabled: readOnly,
								},
							]}
						/>
					</Body>
				</Panel>
				<Panel class='desktop8 full-height no-padding-vertical'>
					<Header>
						<Text class='bold'>{Translate('Map')}</Text>
					</Header>
					<Body class='vertical-scroll' style={{height: 'calc(100% - var(--header) - 67px)'}}>
						<Map latitude={location.latitude} longitude={location.longitude} addresRequired={searcher} GetAddress={GetAddress}/>
					</Body>
					<Footer class='right'>
						{
							!readOnly
							&&
							<Button
								class='background-blue white'
								text='Save'
								Click={() => {
									GetLocation({...currentLocation, address: Object.keys(currentLocation).filter(key => key !== 'latitude' && key !== 'longitude').map(key => currentLocation[key]).join(', ')});
									CloseModal();
								}}
							/>
						}
						<Button text='Close' Click={CloseModal}/>
					</Footer>
				</Panel>
			</Panel>
		</Card>
	)
}

Location.defaultProps =
{
	location: {},
	GetLocation: () => {},
	readOnly: false,
}

export {Location};