import React from 'react';
// import {Dates} from 'crack-functions';

// Context
import {useSocket} from '../../general/Socket';
import {useBusiness} from '../../general/Business';

// Reducers
import {MessagesReducer} from '../../../reducers/collections/crm/Messages';

const MessagesContext = React.createContext({});

const MessagesProvider = (props) =>
{
	const [state, dispatch] = React.useReducer(MessagesReducer, {messages: [], contactsMessages: {}, code: 0});
	const [oldSocket, setOldSocket] = React.useState({});

	const Socket = useSocket();
	const {Fetch, business} = useBusiness();

	const room = React.useMemo(() => `crm_messages_${business._id}`, [business._id]);
	const unique = 'CRM_MESSAGE';

	React.useEffect(() =>
	{
		const events =
		[
			{
				name: `CREATE_${unique}`,
				Function: (message) =>
				{
					dispatch({type: 'CREATE_MESSAGE', message, contact: message?.contact})
				}
			},
			{
				name: `UPDATE_${unique}`,
				Function: (message) => dispatch({type: 'UPDATE_MESSAGE', message, contact: message?.contact})
			},
			{
				name: `DELETE_${unique}`,
				Function: (message) => dispatch({type: 'DELETE_MESSAGE', message, contact: message?.contact})
			}
		];

		Socket.ConnectEvents(room, events, oldSocket);

		if(oldSocket.unique !== unique || oldSocket.room !== room)
		{
			setOldSocket({room, events, unique});
		}
	}, [Socket, room, unique, oldSocket]);

	const LoadContactMessages = React.useCallback(contact =>
	{
		if(contact)
		{
			Fetch('/crm/messages/read', {_where: {contact}, _sort: {sendDate: 1}}).then(response =>
			{
				if(response.status === 200)
				{
					dispatch({type: 'LOAD_CONTACT_MESSAGES', messages: response.data || [], contact});
				}
			});
		}
	}, [Fetch]);

	const LoadMessages = React.useCallback(messages =>
	{
		dispatch({type: 'LOAD_MESSAGES', messages});
	}, []);

	const CreateMessage = async (message) =>
	{
		message.storages = message.attached.data.map(item => item._id);
		message._files =
		{
			chat: message.attached.files.map(file => file.localFile)
		};

		delete message.attached;

		message._socket = room;

		return await Fetch('/crm/message/create', message);
	}

	const GetChatStatus = React.useCallback((contact) =>
	{
		// const date24 = Dates.Add(new Date(), -24, 'hours');

		const contactMessages = state.contactsMessages[contact?._id] || []; // TODO: Check: A contact without _id ?
		const lastMessage = contactMessages.slice(-1)[0];

		let statusColor = '';
		let title = 'No Chat';
		let type = '';

		if(!lastMessage || (lastMessage.chatStatus === 1 /*1.- finishChat*/))
		{
			statusColor = 'light-gray';
			type = 'chatStatusNoChat';
		}
		else if(lastMessage.chatStatus === 2 /*2.- requestOperator*/)
		{
			statusColor = 'green';
			title = 'Awaiting for Reply';
			type = 'chatStatusAnswered';
		}
		else if(lastMessage.fromSection === 1) // Chat Bot
		{
			statusColor = 'blue';
			title = 'Chat Bot Message';
			type = 'chatStatusChatBot';
		}
		else if(lastMessage.fromSection === 2) // Scheduled Messages
		{
			statusColor = 'purple';
			title = 'Scheduled Message';
			type = 'chatStatusScheduledMessage';
		}
		else if(lastMessage.transmiter)
		{
			statusColor = 'red';
			title = 'Already Replied';
			type = 'chatStatusNotAnswered';
		}
		else
		{
			statusColor = 'green';
			title = 'Awaiting for Reply';
			type = 'chatStatusAnswered';
		}

		return {statusColor, title, type};
	}, [state.contactsMessages])

	const value =
	{
		code: state.code,
		contactsMessages: state.contactsMessages,
		LoadMessages,
		LoadContactMessages,
		CreateMessage,
		GetChatStatus
	}

	return <MessagesContext.Provider value={value} {...props}/>
}

const useMessages = () => React.useContext(MessagesContext);

export {MessagesProvider, useMessages};