import {Text} from 'crack-ux';
import {Amount} from 'crack-functions';
import moment from 'moment';

const SetCredit = (credit) =>
{
	const color = credit.type !== 1 ? 'green' : '';

	credit.codeText = `CRED-${credit.code}`;
	credit.amountText = <Text class={`${color} right`}>{Amount.CurrencyFormat(credit.amount)}</Text>
	credit.dateText = moment(credit.date).format('DD/MM/YYYY');

	return credit;
}

const CreditsReducer = (state = {}, action) =>
{
	switch (action.type)
	{
		case 'LOAD_CREDITS':
			return {
				...state,
				credits: action.credits.map(credit => SetCredit(credit))
			}

		case 'CREATE_CREDIT':
			return {
				...state,
				credits: [SetCredit(action.credit), ...state.credits],
			}

		case 'UPDATE_CREDIT':
			return {
				...state,
				credits: state.credits.map(credit => credit._id === action.credit._id ? SetCredit(action.credit) : credit)
			}

		case 'DELETE_CREDIT':
		{
			return {
				...state,
				credits: state.credits.filter(credit => credit._id !== action.credit._id)
			}
		}

		case 'SET_LATEST_CREDIT_CODE':
		{
			return {
				...state,
				code: action.code
			}	
		}

		default:
			return state;
	}
}

export {CreditsReducer};