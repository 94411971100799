import React from "react";

// Contexts
import {useModal} from "../../../general/Modal";
import {useMessages} from "../../../collections/crm/Messages";
import {useContactsNotes} from "../../../collections/crm/ContactsNotes";

// Forms
import {ContactForm} from '../../../../components/forms/modules/crm/Contact';
import {ContactNoteForm} from "../../../../components/forms/modules/crm/ContactNote/ContactNote";
import {DocumentForm} from "../../../../components/forms/modules/storage/Folder/Folder";
import {UploadFilesForm} from "../../../../components/forms/modules/storage/UploadFiles/UploadFiles";
import {FileViewer} from "../../../../components/views/FileViewer/FileViewer";

// Warnings
import {DeleteContact} from "../../../../components/warnings/crm/DeleteContact";
import {DeleteNote} from "../../../../components/warnings/crm/DeleteContactNote/DeleteContactNote";
import {DeleteStorage} from "../../../../components/warnings/storage/DeleteStorage/DeleteStorage";
import {DeleteReadOnly} from "../../../../components/warnings/storage/DeleteReadOnly/DeleteReadOnly";

const Contacts = React.createContext({});

const ContactsProvider = (props) =>
{
	const [contactsIds, setContactsIds] = React.useState([]);

	const {AddModal} = useModal();

	const {LoadContactMessages} = useMessages();
	const {LoadContactNotes} = useContactsNotes();

	const AddContactTab = (contactId) =>
	{
		const foundContactId = contactsIds.find(item => item === contactId);

		if(!foundContactId)
		{
			setContactsIds(current => [...current, contactId]);

			LoadContactMessages(contactId);
			LoadContactNotes(contactId);
		}
	}

	const RemoveContactTab = (contactId) =>
	{
		const foundContactId = contactsIds.find(item => item === contactId);

		if(foundContactId)
		{
			setContactsIds(contactsIds.filter(item => item !== contactId));
		}
	}

	const ShowContactForm = (contact = {}) =>
	{
		AddModal(<ContactForm contact = {contact}/>);
	}

	const ShowDeleteContactWarning = (contact = {}, params = {}) =>
	{
		AddModal(<DeleteContact contact = {contact}/>);
	}

	const ShowContactNoteForm = (note, params = {}) =>
	{
		const
		{
			contact = {}
		} = params;

		AddModal(<ContactNoteForm note = {note} contact = {contact}/>);
	}

	const ShowDeleteNoteWarning = (note = {}) =>
	{
		AddModal(<DeleteNote note = {note}/>);
	}

	const ShowDocumentForm = (document = {}, {module = {}, parent}) =>
	{
		AddModal(<DocumentForm document = {document} module = {module} parent = {parent}/>);
	}

	const ShowUploadFilesForm = ({module = {}, parent}) =>
	{
		AddModal(<UploadFilesForm module = {module} parent = {parent}/>);
	}

	const ShowDeleteWarning = (document = {}) =>
	{
		AddModal(<DeleteStorage document = {document}/>);
	}

	const ShowReadOnlyWarning = (document = {}) =>
	{
		AddModal(<DeleteReadOnly document = {document}/>);
	}

	const ShowFileViewer = (document = {}) =>
	{
		AddModal(<FileViewer document = {document}/>);
	}
	
	const value =
	{
		contactsIds,
		AddContactTab,
		RemoveContactTab,
		ShowContactForm,
		ShowDeleteContactWarning,
		ShowContactNoteForm,
		ShowDeleteNoteWarning,
		ShowDocumentForm,
		ShowUploadFilesForm,
		ShowDeleteWarning,
		ShowReadOnlyWarning,
		ShowFileViewer,
	}

	return (
		<Contacts.Provider value={value}>
			{props.children}
		</Contacts.Provider>
	);
}

const useContacts = () => React.useContext(Contacts);

export {ContactsProvider, useContacts};