import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {Generic} from 'crack-functions';

// Config
import config from './config';

// Layouts
import {Public} from './layouts/Public';
import {Personal} from './layouts/Personal';
import {Business} from './layouts/Business';

// Context
import {ScreenProvider} from './contexts/general/Screen';

const AppRoutes = () =>
{
	Generic.domain = `${config.server.domain}${config.server.port ? `:${config.server.port}` : ''}`;

	return (
		<ScreenProvider>
			<BrowserRouter>
				<Routes>
					<Route path='/p/*' element={<Personal/>}/>
					<Route path='/b/:business/*' element={<Business/>}/>
					<Route path='*' element={<Public/>}/>
				</Routes>
			</BrowserRouter>
		</ScreenProvider>
	)
}

export default AppRoutes;  