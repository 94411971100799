const SetCustomer = (customer) =>
{
	customer.codeText = `CUST-${customer.code}`;

	return customer;
}

const CustomersReducer = (state = {}, action) =>
{
	switch (action.type)
	{
		case 'LOAD_CUSTOMERS':
			return {
				...state,
				customers: action.customers.map(customer => SetCustomer(customer))
			}

		case 'CREATE_CUSTOMER':
			return {
				...state,
				customers: [...state.customers, SetCustomer(action.customer)],
			}

		case 'UPDATE_CUSTOMER':
			return {
				...state,
				customers: state.customers.map(customer => customer._id === action.customer._id ? SetCustomer(action.customer) : customer)
			}

		case 'DELETE_CUSTOMER':
		{
			return {
				...state,
				customers: state.customers.filter(customer => customer._id !== action.customer._id)
			}
		}

		case 'SET_LATEST_CUSTOMER_CODE':
		{
			return {
				...state,
				code: action.code
			}	
		}

		default:
			return state;
	}
}

export {CustomersReducer};