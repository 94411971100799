import React from 'react';
import {Content, Panel, Card, Header, Body, Text, List} from 'crack-ux';

// Contexts
import {useBusiness} from '../../../../contexts/general/Business';
import {useTraductor} from '../../../../contexts/general/Traductor';
import {IntegrationsProvider, useIntegrations} from '../../../../contexts/pages/business/home/Integrations';
import {useChatBots} from '../../../../contexts/collections/crm/ChatBots';

// Components
import {Loading} from '../../../../components/views/Loading';
import {Whatsapp} from './Whatsapp';

// Static Data
import channels from '../../../../static-data/Channels.json';

const Integrations = (props) =>
{
	const [loading, setLoading] = React.useState(true);

	const {Translate} = useTraductor();

	const {Fetch} = useBusiness();

	const {channel, SetChannel} = useIntegrations();

	const {LoadChatBots} = useChatBots();

	React.useEffect(() =>
	{
		const requests =
		[
			Fetch('/crm/chat-bots/read')
		];

		Promise.all(requests).then(responses =>
		{
			if(responses.every(response => response.status === 200))
			{
				LoadChatBots(responses[0].data || []);

				setLoading(false);
			}
		});
	}, [Fetch, LoadChatBots]);

	const channelSection = React.useMemo(() =>
	{
		switch(channel.value)
		{
			case 'whatsapp':
				return <Whatsapp/>
			default:
				return <></>
		}
	}, [channel]);

	if(loading) return <Loading/>

	return (
		<Content class='crm-contacts'>
			<Panel class='no-padding' style={{height: 'calc(100vh - var(--header) - 20px)'}} swipe='horizontal'>
				<Panel class='full-height desktop3'>
					<Card>
						<Header>
							<Text class='bold'>{Translate('Channels')}</Text>
						</Header>
						<Body class='no-padding'>
							<List
								class = 'hover'
								currentClass = 'background-blue white'
								current = {channel.value}
								options = {channels.filter(item => item.value === 'whatsapp').map(item =>
								{
									return {
										identifier: item.value,
										text: Translate(item.text),
										Click: () => SetChannel(item)
									}
								})}
							/>
						</Body>
					</Card>
				</Panel>
				<Panel class='full-height desktop9'>
					{channelSection}
				</Panel>
			</Panel>
		</Content>
	);
}

const IntegrationsWithProviders = (props) =>
{
	return (
		<IntegrationsProvider>
			<Integrations {...props}/>
		</IntegrationsProvider>
	);
}

export {IntegrationsWithProviders as Integrations}