import {Panel, Body, Footer, Button, Section, Box, Text, Header} from 'crack-ux';
import React from 'react';

// Contexts
import {useTraductor} from '../../../../contexts/general/Traductor';
import {useContacts} from '../../../../contexts/collections/crm/Contacts';

// Components
import {Chat} from './Chat';
import {SimpleContactForm} from '../../../../components/forms/modules/crm/Contact/SimpleContact';
import {InfoTabs} from './InfoTabs';

// Contexts
import {useCRM} from '../../../../contexts/modules/CRM';
import {useCompanies} from '../../../../contexts/collections/crm/Companies';
import {useLists} from '../../../../contexts/collections/home/Lists';

// Functions
import Validations from '../../../../objects/Validate';

// Styles
import './ContactInfo.css';

// Static Data
import BusinessType from '../../../../static-data/BusinessType.json';

const ContactBody = (props) =>
{
	const {contact, editing, messages, Change} = props;

	return editing ? <SimpleContactForm key='enabled' contact={contact} messages={messages} Change={Change}/> : <SimpleContactForm key='disabled' contact={contact} messages={messages} Change={Change} disabled={true}/>
}

const ContactInfo = (props) =>
{
	const {contact} = props;

	const [editing, setEditing] = React.useState(false);
	const [form, setForm] = React.useState({});
	const [messages, setMessages] = React.useState({});

	const {Translate} = useTraductor();

	const {ShowCompanyForm} = useCRM();
	const {UpdateContact} = useContacts();
	const {companies} = useCompanies();
	const {listsByName} = useLists();

	const contactCompaniesData = React.useMemo(() => companies.filter(company => (contact.companies ?? []).includes(company._id)), [contact.companies, companies]);

	const HandleSubmit = async () =>
	{
		const response = await UpdateContact({...form, salesFlow: contact.salesFlow, _id: contact._id});

		setMessages(Validations.ParseByField(response.validations));

		if(response.status === 200)
		{
			setEditing(false);
		}
	}

	const companiesSection = React.useMemo(() =>
	{
		return contactCompaniesData.map(company =>
		{
			return (
				<Section key={company._id}>
					<Header>
						<Text class='bold'>{Translate('Company')}</Text>
						<Box class='float-right'>
							<Button class='blue' text={Translate('Edit Company')} Click={() => ShowCompanyForm(company)} style={{marginTop: -8}}/>
						</Box>
					</Header>
					<Header>
						<Text class='block' type='p'><span className='bold' style={{marginRight: 5}}>{Translate('Company Name')}:</span>{company.name}</Text>
						<Text class='block' type='p'><span className='bold' style={{marginRight: 5}}>{Translate('TIN')}:</span>{company.nit}</Text>
						<Text class='block' type='p'><span className='bold' style={{marginRight: 5}}>{Translate('Type')}:</span>{BusinessType.find(type => type.value === company.type)?.text}</Text>
						<Text class='block' type='p'><span className='bold' style={{marginRight: 5}}>{Translate('Location')}:</span>{company.location?.address}</Text>
						<Text class='block' type='p'><span className='bold' style={{marginRight: 5}}>{Translate('Economic Activities')}:</span>{(Array.isArray(company.economicActivities) ? company.economicActivities : []).map(item => listsByName.economicActivities?.itemsById?.[item]?.text || '').join(', ')}</Text>
					</Header>
				</Section>
			);
		});
	}, [contactCompaniesData, listsByName.economicActivities, Translate, ShowCompanyForm]);

	return (
		<Panel class='contact-info no-scroll no-padding' swipe='horizontal'>
			<Panel class='info desktop3 no-padding'>
				<Section class='vertical-scroll' style={{height: 'calc(100% - 67px)'}}>
					<Header>
						<Text class='bold'>{Translate('Contact Info')}</Text>
						{/* <Avatar src={TextF.Initials(TextF.FullName(contact.name, contact.lastName))} size={150}/> */}
					</Header>
					<Header>
						<ContactBody contact={contact} editing={editing} messages={messages} Change={setForm}/>
					</Header>
					<Body class='no-padding'>
						{companiesSection}
					</Body>
				</Section>
				<Footer class='right'>
					<Button
						text = {editing ? Translate('Save') : Translate('Edit Info')}
						class = 'background-blue white'
						Click = {() => editing ? HandleSubmit() : setEditing(true)}
					/>
					{
						editing
						&&
						<Button
							text = {Translate('Cancel Edit')}
							class = 'default'
							Click = {() => setEditing(false)}
						/>
					}
				</Footer>
			</Panel>
			<Panel class='desktop5 no-padding contact-tabs-container' style={{borderRight: '1px solid var(--lightGray)', backgroundColor: '#f9f9f9'}}>
				<InfoTabs contact={contact}/>
			</Panel>
			<Panel class='desktop4 no-padding'>
				<Chat contact={contact}/>
			</Panel>
		</Panel>
	)
}

const ContactInfoWithProviders = (props) =>
{
	return (
		<ContactInfo {...props}/>
	);
}

export {ContactInfoWithProviders as ContactInfo};