import {Text} from 'crack-functions';

class Times
{
	static GetDuration(startTime, endTime)
	{
		let result = '00:00';

		if(typeof startTime === 'string' && typeof endTime === 'string')
		{
			const startParts = startTime.split(':');
			const endParts = endTime.split(':');

			if(startParts.length === 2 && endParts.length === 2)
			{
				const [startHours, startMinutes] = startParts;
				const [endHours, endMinutes] = endParts;

				const hours = parseInt(endHours) - parseInt(startHours);
				const minutes = parseInt(endMinutes) - parseInt(startMinutes);

				const totalMinutes = hours * 60 + minutes;

				if(totalMinutes > 0)
				{
					result = `${Text.AddChars(parseInt(totalMinutes / 60), '0', 2)}:${Text.AddChars(totalMinutes % 60, '0', 2)}`;
				}
			}
		}

		return result;
	}
}

export default Times;