import React from "react";

// Static Data
const filesModules = require('../../../../static-data/FilesModules.json');

const FilesPageContext = React.createContext({});

const FilesPageProvider = (props) =>
{
	const [module, setModule] = React.useState(filesModules.filter(item => !item.hidden)[0]);

	const value =
	{
		module,
		SetModule: setModule
	}

	return (
		<FilesPageContext.Provider value={value}>
			{props.children}
		</FilesPageContext.Provider>
	);
}

const useFilesPage = () => React.useContext(FilesPageContext);

export {FilesPageProvider, useFilesPage};