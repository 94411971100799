import React from 'react';

// Context
import {useSocket} from '../../general/Socket';
import {useBusiness} from '../../general/Business';

// Reducers
import {CustomersReducer} from '../../../reducers/collections/store/Customers';

const Customers = React.createContext({});

const CustomersProvider = (props) =>
{
	const [state, dispatch] = React.useReducer(CustomersReducer, {customers: [], code: 0});
	const [currentCustomer, setCurrentCustomer] = React.useState({});
	const [eventId, setEventId] = React.useState('');
	const [oldSocket, setOldSocket] = React.useState({});

	const Socket = useSocket();
	const {Fetch, business} = useBusiness();

	const room = React.useMemo(() => `store_customers_${business._id}`, [business._id]);
	const unique = React.useMemo(() =>
	{
		return eventId ? `STORE_CUSTOMER_${eventId}` : 'STORE_CUSTOMER';
	}, [eventId]);

	React.useEffect(() =>
	{
		Fetch('/store/customers/code').then(response =>
		{
			if(response.status === 200)
			{
				dispatch({type: 'SET_LATEST_CUSTOMER_CODE', code: response.data});
			}
		});
	}, [Fetch]);

	React.useEffect(() =>
	{
		const events =
		[
			{
				name: `CREATE_${unique}`,
				Function: (customer) =>
				{
					dispatch({type: 'CREATE_CUSTOMER', customer});

					if(unique === 'STORE_CUSTOMER') dispatch({type: 'SET_LATEST_CUSTOMER_CODE', code: state.code + 1});
				}
			},
			{
				name: `UPDATE_${unique}`,
				Function: (customer) => dispatch({type: 'UPDATE_CUSTOMER', customer})
			},
			{
				name: `DELETE_${unique}`,
				Function: (customer) => dispatch({type: 'DELETE_CUSTOMER', customer})
			}
		];

		if(unique !== 'CONTACT')
		{
			events.push
			({
				name: 'CREATE_CUSTOMER',
				Function: () =>
				{
					dispatch({type: 'SET_LATEST_STORE_CUSTOMER_CODE', code: state.code + 1});
				}
			})
		}

		Socket.ConnectEvents(room, events, oldSocket);

		if(oldSocket.unique !== unique)
		{
			setOldSocket({room, events, unique});
		}
	}, [Socket, room, unique, oldSocket, state.code]);

	const LoadCustomers = React.useCallback((customers = []) =>
	{
		dispatch({type: 'LOAD_CUSTOMERS', customers});
	}, []);

	const CreateCustomer = async (customer) =>
	{
		customer.code = state.code + 1;
		customer._socket = room;

		return await Fetch('/store/customer/create', customer);
	}

	const UpdateCustomer = async (customer) =>
	{
		customer._socket = room;

		return await Fetch('/store/customer/update', customer);
	}

	const DeleteCustomer = async (customer) =>
	{
		const data =
		{
			_id: customer._id,
			_socket: room
		}

		return await Fetch('/store/customer/delete', data);
	}

	const value =
	{
		code: state.code,
		customers: state.customers,
		currentCustomer,
		SetCurrentCustomer: setCurrentCustomer,
		SetEventId: setEventId,
		LoadCustomers,
		CreateCustomer,
		UpdateCustomer,
		DeleteCustomer
	}

	return (
		<Customers.Provider value={value}>
			{props.children}
		</Customers.Provider>
	)
}

const useCustomers = () => React.useContext(Customers);

export {CustomersProvider, useCustomers};