import React from 'react';
import {Dates} from 'crack-functions';

// Contexts
import {useTraductor} from '../../general/Traductor';
import {useBusiness} from '../../general/Business';

// Reducers
import {SystemVariablesReducer} from '../../../reducers/collections/SystemVariables';

const SystemVariables = React.createContext({});

const SystemVariablesProvider = (props) =>
{
	const [state, dispatch] = React.useReducer(SystemVariablesReducer, {systemVariables: {}});

	const {Translate} = useTraductor();
	const {business, Fetch} = useBusiness();

	React.useEffect(() =>
	{
		if(business._id)
		{
			Fetch('/system-variables/read').then(response =>
			{
				if(response.status === 200)
				{
					dispatch({type: 'LOAD_ALL_SYSTEM_VARIABLES', systemVariables: response.data || {}});
				}
			});
		}
	}, [business._id, Fetch]);

	const VariableReplacer = React.useCallback((text, data, section) =>
	{
		let result = text;

		const matches = [...new Set(text.match(/{{[A-Z-]+}}/g))];

		if(matches.length > 0)
		{
			if(!data?.system)
			{
				data.system =
				{
					greeting: Translate(Dates.Greeting())
				};
			}

			const fieldsValues = (() =>
			{
				const result = {};
	
				for(let i in data)
				{
					for(let j in data[i])
					{
						result[`${i}.${j}`] = data[i][j];
					}
				}

				return result;
			})();
	
			const variablesFields = state.systemVariables[section].variables.reduce((acc, curr) =>
			{
				acc[curr.variableText] = curr.field;
	
				return acc;
			}, {});

			matches.forEach(match =>
			{
				result = result.replaceAll(match, fieldsValues?.[variablesFields?.[match]] ? fieldsValues[variablesFields[match]] : '');
			});
		}

		return result;
	}, [state.systemVariables, Translate]);

	const value =
	{
		systemVariables: state.systemVariables,
		VariableReplacer
	}

	return (
		<SystemVariables.Provider value={value}>
			{props.children}
		</SystemVariables.Provider>
	)
}

const useSystemVariables = () => React.useContext(SystemVariables);

export {SystemVariablesProvider, useSystemVariables};