import React from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {Generic} from 'crack-functions';

// Contexts
import {useSession} from './Session';

const Business = React.createContext({});

const BusinessProvider = (props) =>
{
	const [business, setBusiness] = React.useState({});
	
	const params = useParams();
	const location = useLocation();

	const {localStorage: sessionLocalStorage, SaveLocalStorage: SaveSessionLocalStorage} = useSession();

	const path = React.useMemo(() =>
	{
		const splittedPath = location.pathname.split('/');

		return {
			module: splittedPath[2],
			menu: splittedPath[3],
		}
	}, [location.pathname]);

	const GetBusiness = React.useCallback(async () =>
	{
		if(params.business)
		{
			const data =
			{
				_where:
				{
					uniqueName: params.business
				},
				_limit: 1
			}
	
			Generic.Fetch('/business/read', data).then(response =>
			{
				if(response.status === 200)
				{
					setBusiness(response.data);
				}
			});
		}
	}, [params.business]);

	const Fetch = React.useCallback(async (route, data = {}, method = 'POST') =>
	{
		data._properties =
		{
			business: 
			{
				_id: business._id,
				uniqueName: business.uniqueName
			}
		}

		return await Generic.Fetch(route, data, method);
	}, [business]);

	React.useEffect(() =>
	{	
		GetBusiness();
	}, [GetBusiness]);

	const localStorage = React.useMemo(() => sessionLocalStorage[business?._id] || {}, [sessionLocalStorage, business?._id]);

	const SaveLocalStorage = React.useCallback((storage = {}) =>
	{
		if(business?._id)
		{
			SaveSessionLocalStorage({...sessionLocalStorage, [business._id]: storage});
		}
	}, [business?._id, sessionLocalStorage, SaveSessionLocalStorage])

	const value =
	{
		path,
		business,
		localStorage,
		SaveLocalStorage,
		Fetch,
		GetBusiness
	}

	return (
		<Business.Provider value={value} {...props}/>
	);
}

const useBusiness = () => React.useContext(Business);

export {BusinessProvider, useBusiness};