import React from "react"
import {Content, Panel, Card, Header, Body, Text} from 'crack-ux';
import {Amount} from 'crack-functions';

// Contexts
import {useTraductor} from "../../../../contexts/general/Traductor";
import {useBusiness} from "../../../../contexts/general/Business";
import {CreditsPageProvider} from "../../../../contexts/pages/business/home/Credits"
import {useCredits} from "../../../../contexts/collections/home/Credits";

// Components
import {Loading} from "../../../../components/views/Loading/Loading";
import {CreditsTable} from "../../../../components/tables/modules/home/Credits/Credits";

const Credits = () =>
{
	const [loading, setLoading] = React.useState(true);

	const {Translate} = useTraductor();
	const {Fetch} = useBusiness();

	const {LoadCredits, balance} = useCredits();

	React.useEffect(() =>
	{
		const requests =
		[
			Fetch('/credits/read', {_sort: {date: -1}})
		];

		Promise.all(requests).then(responses =>
		{
			if(responses.every(response => response.status === 200))
			{
				LoadCredits(responses[0].data || []);

				setLoading(false);
			}
		});
	}, [Fetch, LoadCredits]);

	if(loading) <Loading/>

	return (
		<Content>
			<Panel class='no-padding full-height' swipe='horizontal'>
				<Panel class='desktop3'>
					<Card>
						<Header>
							<Text >Total {Translate('Credit')}</Text>
							<Text class='green float-right'>{Amount.CurrencyFormat(balance)}</Text>
						</Header>
					</Card>
				</Panel>
				<Panel  class='desktop9 full-height'>
					<Card>
						<Header>
							<Text class='bold'>{Translate('Credit')}</Text>
						</Header>
						<Body class='no-padding' style={{height: 'calc(100% - var(--header))'}}>
							<CreditsTable/>
						</Body>
					</Card>
				</Panel>
			</Panel>
		</Content>
	);
}

const CreditsWithProviders = props =>
(
	<CreditsPageProvider>
		<Credits {...props}/>
	</CreditsPageProvider>
);

export {CreditsWithProviders as Credits};