import React from 'react';
import {Section, Text} from 'crack-ux';

class Validations extends React.Component
{
	/**
	 * This object contains all the specific friendly messages
	 * @type {Object}
	 */
	static codes =
	{
		// '00-00-0001': 'My custom message replaces the message from code'
	};

	/**
	 * This function will parse the validations by field and place the validation messages using the Text component
	 * e.g.
	 * {
	 * 	field1: [<Text>Validation1</Text>, <Text>Validation2</Text>]
	 * 	field2: [<Text>Validation1</Text>]
	 * }
	 * @param {Object} validations
	 * @return {Object}
	 */
	static ParseByField(validations)
	{
		const result  = {};

		if(typeof validations === 'object')
		{
			const keys = Object.keys(validations);

			if(keys.length > 0)
			{
				for(let fieldName in validations)
				{
					const parsedField = Validations.ParseField(validations[fieldName], [validations[fieldName].title]);

					if(parsedField.length > 0)
					{
						result[fieldName] = parsedField;
					}
				}
			}
		}

		return result;
	}

	/**
	 * This function parse the validations for a field
	 * @param {Object} field
	 * @param {array} titles
	 * @param {boolean} title
	 */
	static ParseField(field, titles = [], title = false)
	{
		let result = [];

		if(Array.isArray(field.validations) && field.validations.length > 0)
		{
			const messages = Validations.ParseMessages(field.validations);

			result = messages.map((message, key) =>
			{
				const messageValue = title ? `${titles.join(' > ')}: ${message}` : message;

				return <Text class='red' size={9} key={key}>{messageValue}</Text>
			});
		}
		else if(Array.isArray(field.items) && field.items.length > 0)
		{
			field.items.forEach(item =>
			{
				const itemResult = Validations.ParseField(item, [...titles, `${item.title} ${item.index}`], true);

				result = [...result, itemResult];
			});
		}
		else if(typeof field.fields === 'object' && Object.keys(field.fields).length > 0)
		{
			for(let fieldName in field.fields)
			{
				const fieldResult = Validations.ParseField(field.fields[fieldName], [...titles, field.fields[fieldName].title], true);

				result = [...result, fieldResult];
			}
		}

		return result;
	}

	/**
	 * This function returns the parsed messages
	 * This function replaces the message by a friendly in case that the code exists
	 * @param {Object}
	 * @return {array}
	 */
	static ParseMessages(validations)
	{
		const messages = [];

		validations.forEach((validation, key) =>
		{
			let message = validation.message;

			// Specific friendly message
			if(validation.code && Validations.codes[validation.code])
			{
				message = Validations.codes[validation.code];
			}

			messages.push(message);
		});

		return messages;
	}

	static HasSpace(str)
	{
		return /\s/.test(str);
	}

	static HasNumber(str)
	{ 
		return /[0-9]/.test(str); 
	}

	static HasLetter(str)
	{ 
		return /[a-z]/i.test(str); 
	}

	static HasSpecialCharacter(str)
	{
		return /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(str);
	}

	static HasLowercase(str)
	{
		return /[a-z]/.test(str);
	}

	static HasUppercase(str)
	{
		return /[A-Z]/.test(str);
	}

	// Is specific this function ? (TODO: Should be placed in another fille ?)
	static ValidateUniqueName(uniqueName)
	{
		const messages = [];

		uniqueName = uniqueName ? uniqueName : '';
		
		if(uniqueName.length < 4)
		{
			messages.push('Unique Name needs at least 4 characters');
		}

		if(this.HasSpace(uniqueName))
		{
			messages.push('Unique Name should not have any kind of Space');
		}

		if(this.HasUppercase(uniqueName))
		{
			messages.push('Unique Name does not allow uppercase letters');
		}

		if(/[^a-z0-9-_.]+/i.test(uniqueName))
		{
			messages.push('Unique Name does not allow special characters');
		}

		if(messages.length > 0)
		{
			return <Text class='red' size={9}>{messages.join(', ')}</Text>;
		}

		return null;
	}

	static ValidatePasswords(password, rePassword)
	{
		const messages = [];

		password = password ? password : '';
		rePassword = rePassword ? rePassword : '';

		if(password !== rePassword)
		{
			messages.push('Passwords do not match');
		}

		if(password.length < 8)
		{
			messages.push('Passwords needs at least 8 characters');
		}

		if(!this.HasNumber(password))
		{
			messages.push('Passwords needs at least 1 number');
		}
		
		if(!this.HasSpecialCharacter(password))
		{
			messages.push('Passwords needs at least 1 Special Character');
		} 
		
		if(!this.HasUppercase(password))
		{
			messages.push('Passwords needs at least 1 uppercase letter');
		}
		
		if(!this.HasLowercase(password))
		{
			messages.push('Passwords needs at least 1 lowercase letter');
		}

		if(messages.length > 0)
		{
			return <Section class='left'>{messages.map((msg, index) => <Text key={index} class='red' size={9}>{msg}</Text>)}</Section>
		}

		return null;
	}

	/**
	 * This function will parse the validations returning a table body data
	 * @param {array} arrayValidations
	 * @return {array}
	 */
	static ParseCSVForTable(arrayValidations)
	{
		let result = [];

		if(Array.isArray(arrayValidations))
		{
			arrayValidations.forEach((validations, index) =>
			{
				const parsedValidations = Validations.ParseByField(validations);

				for(let field in parsedValidations)
				{
					const row =
					{
						index: index + 1,
						field: validations[field].title,
						value: String(validations[field].value),
						message: parsedValidations[field]
					}

					result.push(row);
				}
			});
		}

		return result;
	}
}

export default Validations;