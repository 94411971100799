import React from "react";
import {Panel, Card, Box, Header, Body, Text} from 'crack-ux';

// Components
import {ProductCard} from "./ProductCard";

// Contexts
import {useTraductor} from "../../../../../contexts/general/Traductor";
import {useProducts} from "../../../../../contexts/collections/store/Products";
import {useMoves} from "../../../../../contexts/collections/store/Moves";
import {useStorages} from "../../../../../contexts/collections/storage/Storage";

// Styles
import './ProductGrid.css';

const ProductsGrid = (props) =>
{
	const {_type} = props;

	const {Translate} = useTraductor();
	const {products} = useProducts();
	const {code} = useMoves();
	const {storagesById} = useStorages();

	const availableProducts = React.useMemo(() =>
	{
		return products.filter(product => product.available).map(product =>
		{
			product.pictureURL = storagesById?.[product?.pictures?.[0]?.file]?.publicURL || '/assets/box.png';

			return product;
		});
	}, [products, storagesById])

	return (
		<Card class='products-grid'>
			<Header>
				<Text class='bold'>{Translate(_type === 'sales' ? 'Sales Point' : 'Acquisitions')}</Text>
				<Box class='float-right'>
					<Text class='bold'>{`MOVE-${code + 1}`}</Text>
				</Box>
			</Header>
			<Body class='no-padding vertical-scroll' style={{height: 'calc(100vh - (2 * var(--header)) - 20px)'}}>
				<Panel>
					{availableProducts.map(product => <ProductCard key={product._id} {...product} _type={_type}/>)}
				</Panel>
			</Body>
		</Card>
	)
}

ProductsGrid.defaultProps =
{
	_type: 'sales' // sales or acquisitions
}

export {ProductsGrid};