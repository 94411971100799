import React from 'react';
import {Table} from 'crack-ux';

// Contexts
import {useTraductor} from '../../../../../contexts/general/Traductor';
import {useCRM} from '../../../../../contexts/modules/CRM';
import {useCampaigns} from '../../../../../contexts/collections/crm/Campaigns';

const CampaignsTable = (props) =>
{
	const {Translate} = useTraductor();
	const {ShowCampaignForm, ShowDeleteCampaign, ShowDeleteCampaignReadOnly} = useCRM();

	const {campaigns, setCurrentCampaign} = useCampaigns();

	const body = React.useMemo(() =>
	{
		return campaigns.map(campaign =>
		{
			return campaign;
		});
	}, [campaigns])

	return (
		<Table
			class = 'current'
			header =
			{[
				{
					name: 'codeText',
					text: Translate('Code')
				},
				{
					name: 'name',
					text: Translate('Name')
				},
				// {
				// 	name: 'startDateText',
				// 	text: Translate('Start Date'),
				// },
				// {
				// 	name: 'endDateText',
				// 	text: Translate('End Date'),
				// },
				{
					name: 'tagsText',
					text: Translate('Tags'),
					maxWidth: 300,
					title: 'tagsText'
				}
			]}
			body = {body}
			actions =
			{[
				{
					text: Translate('New Campaign'),
					class: 'background-blue white',
					section: 'grouped',
					Click: () => ShowCampaignForm({})
				},
				{
					text: Translate('Edit Campaign'),
					icon:
					{
						class: 'icon-pencil'
					},
					section: 'context',
					Click: (campaign) => campaign._id !== 'global' ? ShowCampaignForm(campaign) : ShowDeleteCampaignReadOnly(campaign)
				},
				{
					text: Translate('Delete Campaign'),
					class: 'red',
					icon:
					{
						class: 'icon-trash'
					},
					section: 'context',
					Click: (campaign) => campaign._id !== 'global' ? ShowDeleteCampaign(campaign) : ShowDeleteCampaignReadOnly(campaign)
				}
			]}
			DoubleClickRow = {(campaign) => campaign._id !== 'global' ? ShowCampaignForm(campaign) : ShowDeleteCampaignReadOnly(campaign)}
			ClickRow = {setCurrentCampaign}
			searcher =
			{
				{text: Translate('Search')}
			}
			texts =
			{
				{
					noRecords:Translate('There Are Not Records To Show'),
					records:Translate('Records'),
					fields: Translate('Fields'),
					settings: Translate('Settings'),
					export: Translate('Export')
				}
			}
			filters = 
			{{
				columns:
				{
					editable: true
				},
				Apply:
				{
					text: Translate('Apply'),
					Click: () => {}
				},
				Reset:
				{
					text: Translate('Reset'),
					Click: () => {}
				}
			}}
		/>
	)
};

export {CampaignsTable};