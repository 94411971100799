import React from 'react';
import {Table} from 'crack-ux';

// Contexts
import {useTraductor} from '../../../../../contexts/general/Traductor';
import {useCategories} from '../../../../../contexts/collections/store/Categories';
import {useStore} from '../../../../../contexts/modules/Store';


const CategoriesTable = (props) =>
{
	const {Translate} = useTraductor();

	const {categories} = useCategories();
	const {ShowCategoryForm, ShowDeleteCategoryWarning} = useStore();

	return (
		<Table
			class = 'hover pointer'
			header = 
			{[
				{
					name: 'name',
					text: Translate('Category Name')
				}
			]}
			body = {categories}
			actions =
			{[
				{
					text: Translate('New Category'),
					class: 'background-blue white',
					section: 'grouped',
					Click: () => ShowCategoryForm()
				},
				{
					text: Translate('Edit Category'),
					section: 'context',
					icon:
					{
						class: 'icon-pencil'
					},
					Click: (category) => ShowCategoryForm(category)
				},
				{
					text: Translate('Delete Category'),
					class: 'red',
					section: 'context',
					icon:
					{
						class: 'icon-trash'
					},
					Click: (category) => ShowDeleteCategoryWarning(category)
				}
			]}
			exportable = {false}
			searcher =
			{
				{text: Translate('Search')}
			}
			texts =
			{
				{
					noRecords:Translate('There Are Not Records To Show'),
					records:Translate('Records'),
					fields: Translate('Fields'),
					settings: Translate('Settings')
				}
			}
			filters = 
			{{
				columns:
				{
					editable: true
				},
				Apply:
				{
					text: Translate('Apply'),
					Click: () => {}
				},
				Reset:
				{
					text: Translate('Reset'),
					Click: () => {}
				}
			}}
		/>
	)
}

export {CategoriesTable};