import React from 'react';
import {Panel, Box, Section, Header, Body, Footer, Switch, Text, Button} from 'crack-ux';

// Contexts
import {useTraductor} from '../../../../contexts/general/Traductor';
import {useBusiness} from '../../../../contexts/general/Business';
import {useStaff} from '../../../../contexts/collections/staff/Staff';

// CSS
import './Permissions.css';

const Owner = (props) =>
{
	const {Translate} = useTraductor();

	return(
		<Section class='relative full-height'>
			<Box class='full-center center'>
				<Text size={18}>{Translate('Owner of this business')}. {Translate('This user have full control over this business')}.</Text>
			</Box>
		</Section>
	)
}

const Permissions = (props) =>
{
	const [enabled, setEnabled] = React.useState(false);
	const [localPermissions, setLocalPermissions] = React.useState({});

	const {Translate} = useTraductor();

	const {business} = useBusiness();
	const {currentMember, basePermissions, ChangePermission} = useStaff();

	React.useEffect(() =>
	{
		if(!enabled)
		{
			setLocalPermissions(currentMember.permissions || {});
		}
	}, [enabled, currentMember.permissions]);

	const modulesPermissions = React.useMemo(() =>
	{
		const result = [];

		for(let module in basePermissions)
		{
			const moduleData = basePermissions[module];
			const checkedModule = typeof localPermissions[module]?.active === 'boolean' ? localPermissions[module]?.active : moduleData.default;

			result.push
			(
				<Header key={`${module}_${currentMember._id}`} class={`right module-title`}>
					<Text class='float-left' style={{marginTop: 5}} size={16}>{Translate(moduleData.text)}</Text>
					<Switch 
						class = 'inline-block'
						type = 'toggle'
						text = ''
						Change = {value => setLocalPermissions(current => ({...current, [module]: {...current[module], active: value}}))}
						checked = {checkedModule}
						disabled = {moduleData.readonly || !enabled}
					/>
				</Header>
			);

			if(moduleData.permissions)
			{
				const {permissions} = moduleData;
				const memberPermissions = localPermissions[module]?.permissions || {};
				const groups = {};

				for(let i in permissions)
				{
					const group = permissions[i].group;

					if(!groups[group])
					{
						groups[group] = {};
						groups[group].header = <Text class='bold'>{Translate(group)}</Text>;
						groups[group].body = [];
					}

					groups[group].body.push(
						<Panel class='desktop4' key={`${currentMember._id}_${i}`}>
							<Switch 
								type = 'toggle'
								Change = {value => setLocalPermissions(current => ({...current, [module]: {...current[module], permissions: {...current[module].permissions, [i]: value}}}))}
								text = {Translate(permissions[i].text)} 
								checked = {typeof memberPermissions[i] === 'boolean' ? memberPermissions[i] : permissions[i].value}
								disabled = {!enabled}
							/>
						</Panel>
					);
				}

				const groupsBody = Object.keys(groups).map(group =>
				(
					<Section key={group}>
						<Header class='no-border'>
							{groups[group].header}
						</Header>
						<Body>
							{groups[group].body}
						</Body>
					</Section>
				));

				result.push
				(
					<Body key={`${module}_${currentMember._id}_body`} class={`no-padding`} style={{display: checkedModule ? 'block' : 'none'}}>
						{groupsBody}
					</Body>
				);
			}
		}

		return (
			<Section class='full-height'>
				<Body class={`no-padding vertical-scroll`} style={{height: 'calc(100% - 67px)'}}>
					{result}
				</Body>
				<Footer class='right'>
					<Button
						text = {Translate(enabled ? 'Save' : 'Edit')}
						class = 'background-blue white'
						Click = {() =>
						{
							if(!enabled)
							{
								setEnabled(true);
							}
							else
							{
								ChangePermission(currentMember._id, localPermissions).then(response =>
								{
									if(response.status === 200)
									{
										setEnabled(false);
									}
								});
							}
						}}
					/>
					{
						enabled
						&&
						<Button
							text = {Translate('Cancel')}
							Click = {() =>
							{
								setEnabled(false);
							}}
						/>
					}
				</Footer>
			</Section>
		);
	}, [basePermissions, currentMember._id, localPermissions, enabled, Translate, ChangePermission]);

	return(
		<Section class='full-height permissions'>
			{business.owner === currentMember.user._id ? <Owner/> : modulesPermissions}
		</Section>
	)
}

export {Permissions};