import React from 'react';
import {Card, Header, Body, Footer, Text, Button} from 'crack-ux';

// Contexts
import {useModal} from '../../../../contexts/general/Modal';
import {useTraductor} from '../../../../contexts/general/Traductor';
import {useLists} from '../../../../contexts/collections/home/Lists';

const DeleteListOption = (props) =>
{
	const {internalName, listOption} = props;

	const {CloseModal} = useModal();
	const {Translate} = useTraductor();
	const {DeleteItem} = useLists();

	return (
		<Card>
			<Header>
				<Text class='bold'>{Translate('Delete Option')}</Text>
			</Header>
			<Body style = {{width: 320}}>
			<Text>{Translate('Are you sure want to delete')} {listOption.text}?</Text>
			</Body>
			<Footer class='right'>
				<Button
					text = {Translate('Continue')}
					class = 'background-blue white'
					Click = {() =>
					{
						DeleteItem(internalName, listOption);
						CloseModal();
					}}
				/>
				<Button
					text = {Translate('Cancel')}
					Click = {CloseModal}
				/>
			</Footer>
		</Card>
	);
}

export {DeleteListOption}